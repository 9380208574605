import { useCallback } from 'react'

import { Location } from 'common/types/Location'
import { genericTo as to } from 'common/helpers/awaitTo'
import { parseResult } from './useFetchLocations.helpers'
import { SuggestionItemAdapter } from 'components/Suggestion'
import LocationHelper from 'common/Location.helpers'
import toArray from 'common/helpers/toArray'
import get from 'common/helpers/get'
import GoogleMaps from 'common/GoogleMaps'

export const LocationAdapter: SuggestionItemAdapter<Location> = {
  getValue: (location: Location) => String(location.kma || location.place_id),
  getLabel: (location: Location) => {
    if (location.kma) {
      return LocationHelper(location).format(`${LocationHelper.KMA} - ${LocationHelper.ADDRESS}`)
    }
    return LocationHelper(location).format(LocationHelper.ADDRESS)
  },
}

export async function getLocationGeocode(location: Location) {
  const googleMaps = new GoogleMaps()

  if (!googleMaps.getGeocoder()) {
    return Promise.reject()
  }

  return new Promise((resolve, reject) => {
    if (location.place_id && googleMaps.getPlacesService()) {
      const options: any = {
        placeId: location.place_id,
        fields: ['address_components', 'geometry', 'place_id'],
        sessionToken: googleMaps.getSessionToken(),
      }

      googleMaps.getPlacesService().getDetails(options, (results: any, status: any) => {
        if (status === googleMaps.getGoogleMapsInstance().places.PlacesServiceStatus.OK) {
          googleMaps.getSessionToken(true)

          resolve({
            ...location,
            ...parseResult(results || {}),
          })
        } else {
          reject(status)
        }
      })
    } else {
      const options = {
        address: location.address,
        // bounds: this.props.bounds,
        componentRestrictions: { country: 'us' },
        // location: this.props.location,
      }

      googleMaps.getGeocoder().geocode(options, (results: any, status: any) => {
        if (status === googleMaps.getGoogleMapsInstance().GeocoderStatus.OK) {
          console.debug('[RGF] geoc:', results)
          resolve({
            ...location,
            ...parseResult(results[0] || {}),
          })
        } else {
          reject(status)
        }
      })
    }
  })
}

export type FetchLocationType = 'geocode' | 'address' | 'establishment' | 'regions' | 'cities'

export interface FetchLocationOptions {
  types?: FetchLocationType | FetchLocationType[] | null
}

function getLocationType(type: FetchLocationType): string | null {
  const supportedTypes = {
    geocode: 'geocode',
    address: 'address',
    establishment: 'establishment',
    regions: '(regions)',
    cities: '(cities)',
  }

  return supportedTypes[type] || null
}

function getTypes(options?: FetchLocationOptions) {
  const types = get(options, 'types', null)
  if (!types) {
    return null
  }

  return toArray(types).map((type) => getLocationType(type))
}

export function generateUseFetchLocations(options?: FetchLocationOptions) {
  return function useFetchLocations() {
    async function getPredictions(query: string): Promise<Location[]> {
      return new Promise(async (resolve, reject) => {
        const googleMaps = new GoogleMaps()
        if (!googleMaps.getAutoCompleteService()) {
          reject()
          return
        }

        const predictionOpts = {
          input: query,
          sessionToken: googleMaps.getSessionToken(),
          types: getTypes(options),
          componentRestrictions: { country: 'us' },
        }

        let locations: Location[] = []

        if (String(query || '').length >= 3) {
          googleMaps
            .getAutoCompleteService()
            .getPlacePredictions(predictionOpts, (suggestsGoogle: any, status: any) => {
              if (status !== 'OK') {
                reject([])
              }

              ;(suggestsGoogle || []).forEach((suggest: any) => {
                locations.push({
                  address: suggest.description,
                  place_id: suggest.place_id,
                })
              })

              resolve(locations)
            })
        }
      })
    }

    const fetch = useCallback(
      function fetchLocations({ query }: { query: string }): Promise<Location[]> {
        async function fetch() {
          const [error, result] = await to(getPredictions(query))

          if (!error) {
            return result || []
          }

          return []
        }

        return fetch()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    return {
      fetch,
      adapter: LocationAdapter,
    }
  }
}

const useFetchLocations = generateUseFetchLocations()

export default useFetchLocations
