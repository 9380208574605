import React, { useState } from 'react'
import { Card } from 'components/Card'
import ToggleGroup from 'components/ToggleGroup'

import Icon from 'components/Icon'
import PriceOverTimeLoader from './PriceOverTimeLoader'
import QuotesCreationOverTimeLoader from './QuotesCreationOverTimeLoader'
import { QuotesFilterFields } from '../../QuotesList/QuotesList.types'
import clsx from 'clsx'

export interface OverallPerformanceProps {
  filters: Partial<QuotesFilterFields>
  className?: string
}

const OverallPerformance = ({ filters, className }: OverallPerformanceProps) => {
  const [selectedChart, setSelectedChart] = useState('quote')

  return (
    <>
      <Card className={clsx('w-full', className)}>
        <Card.Title className="flex ml-2 mb-4 mt-4" leading={<Icon name="graph" size={24} />}>
          <span className="text-lg">Overall Performance</span>
        </Card.Title>
        <div className="w-36 ml-6 mb-8">
          <ToggleGroup
            id="chart_options"
            name="chart_options"
            onChange={(e) => setSelectedChart(e.target.value as string)}
            value={selectedChart}
            options={[
              {
                label: 'Quote',
                value: 'quote',
              },
              {
                label: 'Price',
                value: 'price',
              },
            ]}
            scale="default"
          />
        </div>
        {selectedChart === 'price' && <PriceOverTimeLoader filters={filters} className="px-8" />}
        {selectedChart === 'quote' && <QuotesCreationOverTimeLoader filters={filters} className="px-8" />}
      </Card>
    </>
  )
}

export default OverallPerformance
