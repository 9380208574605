import React, { ReactNode } from 'react'

import wallpaper from './login-wallpaper-with-shape.png'
import Logo from 'components/Logo/Logo'

const FORM_WRAPPER_STYLE = { width: 350 }

export interface AuthLayoutProps {
  children: ReactNode
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className="flex w-full h-full">
      <div className="hidden pr-6 xl:block">
        <img src={wallpaper} alt="" className="max-h-screen" />
      </div>
      <div className="flex flex-col items-center flex-1 px-0 py-12 wrapper">
        <div className="flex flex-col justify-center flex-1" style={FORM_WRAPPER_STYLE}>
          {children}
        </div>
        <footer>
          <Logo className="fill-current text-primary-500" />
        </footer>
      </div>
    </div>
  )
}

export default AuthLayout
