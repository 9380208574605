import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import Layout from 'components/Layout'
import { EquipmentType } from 'common/types/EquipmentType'
import ShipperSuggestionFormik from 'components/Formik/ShipperSuggestionFormik'
import { Checkbox, TextField, ToggleGroup } from 'packages/formik-miranda/src'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import EquipmentTypeEntry from '../../EquipmentType'
import Section from 'components/Section'
import Button from 'components/Button'
import Text from 'components/Text'
import Divider from 'components/Divider'
import LTLStopFormik from 'components/Formik/LTLStopFormik/LTLStopFormik'
import CommodityItems from './Components/CommodityItem/CommodityItems'
import { QuoteLTLFormValues } from 'common/types/LTLQuote'
import { LTLQuoteValidationSchema } from './LTLQuoteValidationSchema'
import { LTLQuoteValidationSchema as LTLValidationSchemaWhitelabel } from 'pages/WhiteLabel/common/LTLQuoteValidationSchema'
import getEnvVariable from 'common/helpers/getEnvVariable'
import { FormikHelpers } from 'formik/dist/types'
import { GroupedAccessorials } from 'common/types/Accessorial'

const EQUIPMENT_TYPES = EquipmentTypeHelper.getAll(function getCustomProps(type: EquipmentType) {
  return { label: <EquipmentTypeEntry mode="abbr" name={type} /> }
})

interface LTLQuoteFormProps {
  onSubmit: (values: QuoteLTLFormValues, formikHelpers: FormikHelpers<QuoteLTLFormValues>) => void
  onCancel?: () => void
  isWhiteLabel?: boolean
  accessorials: GroupedAccessorials
}

const LTLQuoteForm = ({
  onSubmit,
  onCancel,
  accessorials,
  isWhiteLabel = false,
}: LTLQuoteFormProps) => {
  const [captchaValid, setCaptchaValid] = useState(!isWhiteLabel)

  return (
    <Formik<QuoteLTLFormValues>
      initialValues={{
        customer_reference: '',
        equipment_type: 'DRV',
        requirements: {
          team_driver: false,
        },
        shipper: null,
        items: [],
      }}
      validationSchema={isWhiteLabel ? LTLValidationSchemaWhitelabel : LTLQuoteValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty, handleReset, values }) => (
        <Form>
          <Layout.Stack space="l">
            <div className="px-8 pb-8 pt-6 bg-neutral-lightest">
              <Section title="Shipper and quote details">
                <Layout.Group className="mt-6" align="center">
                  {!isWhiteLabel && (
                    <ShipperSuggestionFormik
                      id="shipper"
                      label="Shipper"
                      name="shipper"
                      className="w-full lg:max-w-1/3"
                      required
                      placeholder="Type to search for shippers"
                      disableDropdownIndicator={true}
                      loadDataOnFocus
                    />
                  )}
                  <TextField
                    label="Customer reference"
                    id="customer_reference"
                    name="customer_reference"
                    placeholder="412415511"
                    type="text"
                    autoComplete="off"
                    labelProps={{
                      tip: "Reference number for this load in this shipper's TMS.",
                    }}
                  />
                  <ToggleGroup
                    label="Equipment type"
                    id="equipment_type"
                    name="equipment_type"
                    required
                    options={EQUIPMENT_TYPES.map((option) => {
                      if (option.value !== 'DRV') {
                        return { ...option, disabled: true }
                      } else {
                        return option
                      }
                    })}
                  />
                  <div>
                    <Checkbox
                      name="requirements.team_driver"
                      label="Team driver"
                      id="requirements.team_driver"
                    />
                  </div>
                </Layout.Group>

                <Divider className="mt-6" />

                <Layout.Group className="mt-6">
                  <Text variant="heading-sm-bold">Pickup and delivery details</Text>
                  <Text variant="body">
                    Select pickup and delivery location for this lane and also the dates for each
                    one. Remember, each quote requires at least 3 hours of lead time.
                  </Text>
                  <LTLStopFormik type="pu" name="stops.0" accessorials={accessorials.pu} />
                  <LTLStopFormik type="del" name="stops.1" accessorials={accessorials.del} />
                </Layout.Group>
              </Section>
              <Divider className="my-6" />
              <Section title="Commodity details">
                <div className="mt-6">
                  <CommodityItems values={values} />
                </div>
              </Section>
              <Divider className="my-6" />
              {isWhiteLabel && (
                <ReCAPTCHA
                  sitekey={getEnvVariable('RECAPTCHA_KEY')}
                  onChange={() => {
                    setCaptchaValid(true)
                  }}
                />
              )}
            </div>
            <div className="flex flex-col mt-6 space-y-4 lg:flex-row lg:items-center lg:justify-start lg:space-y-0">
              <div className="flex justify-between lg:justify-start">
                {!isWhiteLabel && (
                  <Button
                    disabled={isSubmitting}
                    variant="warning"
                    onClick={() => {
                      handleReset()
                      if (onCancel) {
                        onCancel()
                      }
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  disabled={isSubmitting || !dirty}
                  type="reset"
                  variant="secondary"
                  className="ml-4"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
              <Button
                disabled={isSubmitting || !isValid || !dirty || !captchaValid}
                variant="primary"
                type="submit"
                className="lg:ml-auto"
              >
                get quote
              </Button>
            </div>
          </Layout.Stack>
        </Form>
      )}
    </Formik>
  )
}

export default LTLQuoteForm
