import { User } from 'common/types/User'
import { useCallback } from 'react'

import { searchUser } from 'services/users/users.service'
import { SuggestionItemAdapter } from 'components/Suggestion'
import useCancelToken from 'hooks/useCancelToken'

export interface useFetchUsersProps {}

export const UserAdapter: SuggestionItemAdapter<User> = {
  getValue: (user: User) => user.id || '',
  getLabel: (user: User) => `${user.first_name} ${user.last_name}`,
}

function useFetchUsers() {
  const { getSource, clearSource, cancelPending } = useCancelToken()

  const fetch = useCallback(
    function fetchUsers({ query }: { query: string }): Promise<User[]> {
      async function fetch() {
        cancelPending()

        const source = getSource()

        const [error, response] = await searchUser(
          {
            query,
            limit: 10,
            offset: 0,
          },
          {
            cancelToken: source.token,
          },
        )

        clearSource()

        if (!error) {
          return response?.data?.results || []
        }
        return []
      }

      return fetch()
    },
    [cancelPending, clearSource, getSource],
  )

  return {
    fetch,
    adapter: UserAdapter,
  }
}

export default useFetchUsers
