import Environment from '../types/Environment'

const MAP_ENV_VALUE_TO_KEY: { [key: string]: Environment } = {
  dev: Environment.Dev,
  qa: Environment.QA,
  staging: Environment.Staging,
  sandbox: Environment.Sandbox,
  production: Environment.Production,
}

function getEnv(): Environment {
  return MAP_ENV_VALUE_TO_KEY[process.env.REACT_APP_ENVIRONMENT || 'dev'] || Environment.Dev
}

export default getEnv
