import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { del, patch, post } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { RateProviderRule } from 'common/types/RateProviderRule'

export async function listRateProviderRules(
  rulesData: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | boolean | null, AxiosResponse<RateProviderRule[]> | undefined]> {
  try {
    return await to(post('/api/white-label/rules/list', rulesData, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function createRateProviderRule(
  data?: Partial<RateProviderRule>,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | boolean | null, AxiosResponse<RateProviderRule> | undefined]> {
  try {
    return await to(post('/api/white-label/rules', data, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function editRateProviderRule(
  data?: Partial<RateProviderRule>,
): Promise<[AxiosError | boolean | null, AxiosResponse<RateProviderRule> | undefined]> {
  try {
    return await to(patch(`/api/white-label/rules/${data?.id}`, data))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function deleteRateProviderRule(
  ruleId: string,
): Promise<[AxiosError | boolean | null, AxiosResponse<{}> | undefined]> {
  try {
    return await to(del(`/api/white-label/rules/${ruleId}`))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}
