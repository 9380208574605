const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const WHITE_LABEL_TOKEN = 'WTLToken'

interface TokenManager {
  setToken: (token: string) => void
  getToken: () => string | null
  setWhiteLabelToken: (token: string) => void
  getWhiteLabelToken: () => string | null
  setRefreshToken: (refreshToken: string) => void
  getRefreshToken: () => string | null
  clearTokens: () => void
}

const tokenManager: TokenManager = {
  setToken(token: string) {
    localStorage.setItem(ACCESS_TOKEN, token)
  },
  getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN)
  },
  setWhiteLabelToken(token: string) {
    sessionStorage.setItem(WHITE_LABEL_TOKEN, token)
  },
  getWhiteLabelToken(): string | null {
    return sessionStorage.getItem(WHITE_LABEL_TOKEN)
  },
  setRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  },
  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN)
  },
  clearTokens() {
    const storageKeys = [ACCESS_TOKEN, REFRESH_TOKEN]
    storageKeys.forEach((key) => {
      localStorage.removeItem(key)
    })
  },
}

export default tokenManager
