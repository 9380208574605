import * as yup from 'yup'
import { Form, Formik } from 'formik'

import { Dialog } from 'components/Dialog'
import Layout from 'components/Layout'
import { ToggleGroup } from 'packages/formik-miranda/src'
import Button from 'components/Button'
import { toast } from 'components/Toast'
import { RateProviderRule } from 'common/types/RateProviderRule'
import { createRateProviderRule, editRateProviderRule } from 'services/rateProviderRules/rateProviderRules.service'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import { EquipmentType } from 'common/types/EquipmentType'
import EquipmentTypeEntry from '../../Quotes/QuoteForm/EquipmentType'
import React from 'react'
import LocationSuggestionFormik from 'components/Formik/LocationSuggestionFormik'
import DateRangePickerFormik from 'components/Formik/DateRangePickerFormik'
import FormSubmissionHelper from 'common/FormSubmission.helpers'
import { RateProviderIdentifiers } from 'common/types/RateProvider'
import DateHelper from 'common/Date.helpers'
import LocationHelpers from 'common/Location.helpers'
import { API_DATE_FORMAT } from 'common/constants'

const schema = yup.object().shape({
  equipment_types: yup.array(yup.string()).min(1, 'At least one equipment type must be selected'),
  origins: yup.array().nullable(),
  destinations: yup.array().nullable(),
  valid_through: yup
    .object()
    .shape({
      start: yup.string().nullable(),
      end: yup.string().nullable(),
    })
    .nullable(),
})

type RuleFormProps = {
  rule?: RateProviderRule
  rateProvider: RateProviderIdentifiers
  onClose: (refetch?: boolean) => void
}

const EQUIPMENT_TYPES = EquipmentTypeHelper.getAll(function getCustomProps(type: EquipmentType) {
  return { leading: <EquipmentTypeEntry mode="icon-only" name={type} /> }
})

const getInitialValues = (rule: RateProviderRule): RateProviderRule => {
  return {
    ...rule,
    origins: rule.origins.map((item) => {
      return {
        ...item,
        place_id: LocationHelpers(item).format(),
      }
    }),
    destinations: rule.destinations.map((item) => {
      return {
        ...item,
        place_id: LocationHelpers(item).format(),
      }
    }),
    valid_through:
      rule.valid_through && rule.valid_through.start && rule.valid_through.end
        ? {
            start: DateHelper(rule.valid_through.start)?.value().utc().format(API_DATE_FORMAT)!,
            end: DateHelper(rule.valid_through.end)?.value().utc().format(API_DATE_FORMAT)!,
          }
        : null,
  }
}

const RuleForm = ({ rule, rateProvider, onClose }: RuleFormProps) => {
  const isEditing = Boolean(rule)

  async function handleSubmit(values: RateProviderRule) {
    const action = isEditing ? editRateProviderRule : createRateProviderRule

    const formHelper = FormSubmissionHelper(values)
      .transformDateRange('valid_through')
      .transformLocations(['destinations', 'origins'])
      .setFormValue('rate_provider', rateProvider)

    const [error] = await action(formHelper.getFormValues())

    if (error) {
      toast.error('We could not save this smart rule.')
    } else {
      const message = isEditing
        ? 'Smart rule updated with success.'
        : 'Smart rule created with success.'
      toast.success(message)
      onClose(true)
    }
  }

  return (
    <>
      <Dialog.Header>{isEditing ? 'Editing smart rule' : 'Adding smart rule'}</Dialog.Header>

      <Dialog.Body>
        <Layout.Stack space="l">
          <Formik<RateProviderRule>
            initialValues={
              rule
                ? getInitialValues(rule)
                : {
                    rate_provider: rateProvider,
                    equipment_types: [],
                    origins: [],
                    destinations: [],
                    valid_through: null,
                  }
            }
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting, isValid, dirty }) => (
              <Form>
                <Layout.Stack space="l">
                  <ToggleGroup
                    label="Equipment Type"
                    id="equipment_types"
                    name="equipment_types"
                    options={EQUIPMENT_TYPES}
                    multiple
                  />
                  <LocationSuggestionFormik
                    label="Pickup"
                    labelProps={{
                      tip:
                        'Choose the pickup location for this rule or let it empty to apply to all locations.',
                    }}
                    multiple
                    name="origins"
                    id="origins"
                    placeholder="All the locations"
                    options={{ types: 'regions' }}
                    className="text-left"
                  />
                  <LocationSuggestionFormik
                    label="Delivery"
                    labelProps={{
                      tip:
                        'Choose the delivery location for this rule or let it empty to apply to all locations.',
                    }}
                    multiple
                    name="destinations"
                    id="destinations"
                    placeholder="All the locations"
                    options={{ types: 'regions' }}
                    className="text-left"
                  />
                  <DateRangePickerFormik
                    isDayBlocked={(day) => {
                      return Boolean(DateHelper(day.toString())?.is.before(DateHelper.today()))
                    }}
                    label="Valid thru (or empty to do not expire)"
                    labelProps={{ tip: 'Use this field to define the valid period for this rule.' }}
                    name="valid_through"
                    startDateId="valid_through_start"
                    endDateId="valid_through_end"
                    block
                    className="text-left"
                    openDirection="up"
                  />
                  <Layout.Stack space="s">
                    <Button
                      variant="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                      onClick={submitForm}
                    >
                      {isEditing ? 'Save Changes' : 'Add Rule'}
                    </Button>
                    <Button variant="secondary" onClick={() => onClose(false)}>
                      Cancel
                    </Button>
                  </Layout.Stack>
                </Layout.Stack>
              </Form>
            )}
          </Formik>
        </Layout.Stack>
      </Dialog.Body>
    </>
  )
}

export default RuleForm
