import React from 'react'

import { Quote } from 'common/types/Quote'
import { Card } from 'components/Card'
import Button from 'components/Button'
import { ReactComponent as SuccessIllustration } from 'assets/vector/success-art.svg'
import StopsTimeline from 'pages/Quotes/QuoteForm/Stops/StopsTimeline'
import EquipmentType from 'pages/Quotes/QuoteForm/EquipmentType'
import Icon from 'components/Icon'
import { FTLCommoditiesTable } from 'pages/Quotes/components/Commodities'
import CurrencyHelper from 'common/Currency.helpers'
import Paper from '../Paper'
import QuoteHelper from 'common/Quote.helpers'
import LTLCommoditiesTable from 'pages/Quotes/components/Commodities/LTLCommoditiesTable'
import { GroupedAccessorials } from 'common/types/Accessorial'
import { AccessorialsList } from 'pages/Quotes/components/Accessorials'
import { Layout } from '@loadsmart/loadsmart-ui'
import Text from 'components/Text'
import { getEstimatedDeliveryDate } from 'pages/Quotes/components/LTLRates/LTLRateList'
import isEmpty from 'common/helpers/isEmpty'
import { LaneTypes } from 'common/types/QuoteRate'
import Tooltip, { TooltipAlign, TooltipPosition } from 'components/Tooltip'

export interface SuccessMessageProps {
  quote: Quote
  email: string
  onRequote: () => void
  message?: string | null
  accessorials?: GroupedAccessorials
}

function SuccessMessage({ quote, email, onRequote, message, accessorials }: SuccessMessageProps) {
  const quoteHelper = QuoteHelper(quote)
  return (
    <Paper className="relative bg-neutral-lightest">
      <div className="text-center py-8 flex flex-col mx-auto" style={{ maxWidth: '550px' }}>
        <SuccessIllustration className="mx-auto" />
        <h1 className="text-3xl font-bold text-neutral-darker mt-7">
          Thank you for quoting with us
        </h1>
        {message ? (
          <span className="mt-4">{message}</span>
        ) : (
          <span className="mt-4">
            Your quote interest was sent to the broker, and soon you will be contacted to proceed
            with the next steps. Take a look at the quote details below, a copy was sent to your
            <span className="font-bold"> {email}</span>
          </span>
        )}
        <Card className="text-left mt-8 w-full">
          <Card.Title>General information</Card.Title>
          <Card.Separator />
          <Card.Body className="flex">
            <div className="flex flex-col ml-2">
              <span className="mb-2">Mode</span>
              <span className="text-dark font-bold">{quote.mode}</span>
            </div>
            <div className="ml-auto flex flex-col">
              <span className="mb-2">Equipment type</span>
              <EquipmentType name={quote.equipment_type} mode="full" />
            </div>
            <div className="ml-auto mr-2 flex flex-col">
              <span className="mb-2">Requirements</span>
              <span className="flex flex-row text-dark font-bold">
                {quote?.requirements?.team_driver ? (
                  <>
                    <Icon className="mr-1" name="team-driver" size={18} />
                    Team driver
                  </>
                ) : (
                  <>-</>
                )}
              </span>
            </div>
          </Card.Body>
        </Card>

        <Card className="text-left mt-6 w-full">
          <Card.Title>Lane details</Card.Title>
          <Card.Separator />
          <Card.Body className="h-72">
            <StopsTimeline variant="vertical" stops={quote.stops} />
          </Card.Body>
        </Card>

        {quoteHelper.mode.is('LTL') && (
          <>
            <Card className="text-left mt-6 w-full">
              <Card.Title>Accessorials information</Card.Title>
              <Card.Separator />
              <Card.Body>
                <AccessorialsList
                  accessorialsList={accessorials ? [...accessorials.pu, ...accessorials.del] : []}
                  stops={quote.stops}
                />
              </Card.Body>
            </Card>
            <Card className="text-left mt-6 w-full">
              <Card.Title>Carrier information</Card.Title>
              <Card.Separator />
              <Card.Body>
                <Layout.Stack className="px-4" space="xl">
                  <Layout.Switcher>
                    <Layout.Stack>
                      <Text>Name</Text>
                      <Text variant="body-bold">{quoteHelper.selectedRate?.carrier?.name}</Text>
                    </Layout.Stack>
                    <Layout.Stack>
                      <Text>Est Delivery date</Text>
                      <Text variant="body-bold">
                        {getEstimatedDeliveryDate(
                          quoteHelper.selectedRate?.est_delivery_date || null,
                          quoteHelper.delivery?.window?.start,
                          quoteHelper.pickup?.window?.start,
                        )}
                      </Text>
                    </Layout.Stack>
                  </Layout.Switcher>
                  <Layout.Switcher>
                    <Layout.Stack>
                      <Text>Network</Text>
                      <Text variant="body-bold">
                        {quoteHelper.selectedRate?.lane_type ? (
                          <>
                            {LaneTypes[quoteHelper.selectedRate?.lane_type].name}
                            {!isEmpty(
                              LaneTypes[quoteHelper.selectedRate?.lane_type].description,
                            ) ? (
                              <Tooltip
                                message={LaneTypes[quoteHelper.selectedRate?.lane_type].description}
                                align={TooltipAlign.Center}
                                position={TooltipPosition.Top}
                              >
                                <Icon
                                  name="question-circle"
                                  className="ml-1"
                                  size={13}
                                  title=""
                                  aria-hidden="true"
                                />
                              </Tooltip>
                            ) : null}
                          </>
                        ) : (
                          '-'
                        )}
                      </Text>
                    </Layout.Stack>
                    <Layout.Stack>
                      <Text>Service level</Text>
                      <Text variant="body-bold">{quoteHelper.selectedRate?.service_level}</Text>
                    </Layout.Stack>
                  </Layout.Switcher>
                </Layout.Stack>
              </Card.Body>
            </Card>
          </>
        )}

        <Card className="text-left mt-6 w-full">
          <Card.Title>Commodity details</Card.Title>
          <Card.Separator />
          <Card.Body>
            {quoteHelper.mode.is('LTL') ? (
              <LTLCommoditiesTable commodities={quote.items} variant="reduced" />
            ) : (
              <FTLCommoditiesTable commodities={quote.items} />
            )}
          </Card.Body>
        </Card>
        <div className="w-full mt-6 bg-neutral-dark rounded flex text-neutral-lightest px-2">
          <span className="ml-1.5 mt-4 mb-4 text-sm inline-flex items-center font-medium">
            Total Distance <span className="font-bold pl-2">{Number(quote.mileage)} miles</span>
          </span>
          <span className="ml-auto mr-1.5 mt-4 mb-4 text-xl font-bold">
            {CurrencyHelper(Number(quote.price)).format()}
          </span>
        </div>
        <div className="w-full mt-6 flex">
          <Button onClick={onRequote} className="ml-auto">
            quote a new lane
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default SuccessMessage
