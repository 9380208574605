import React, { useState } from 'react'
import { Quote, ShipmentModeType } from 'common/types/Quote'
import CreateQuote from './components/CreateQuote'
import QuoteDetails from './components/QuoteDetails'
import { getDefaultQuote } from '../Quotes/QuoteForm/QuoteAdd/QuoteAddContext/QuoteAddContext.helpers'
import SuccessMessage from './components/SuccessMessage'
import ShipmentModeSelector from '../Quotes/QuoteForm/QuoteAdd/ShipmentModeSelector'

interface NewFTLQuoteProps {
  setShipmentMode: (mode: ShipmentModeType) => void
  step: number
  handlePrevious: () => void
  handleNext: () => void
  reset: () => void
  successText: string | null
  showShipmentModeSelector: boolean
}

function NewFTLQuote({
  setShipmentMode,
  step,
  handleNext,
  handlePrevious,
  reset,
  successText,
  showShipmentModeSelector,
}: NewFTLQuoteProps) {
  const [quote, setQuote] = useState<Quote>(getDefaultQuote())
  const [email, setEmail] = useState('')

  const onContactInformationFormSubmit = (email: string) => {
    setEmail(email)
    handleNext()
  }

  const handleCancel = () => {
    reset()
    setQuote(getDefaultQuote())
    setEmail('')
  }

  return (
    <>
      {step === 0 && (
        <>
          {showShipmentModeSelector && (
            <div className="px-8 pt-8 bg-neutral-lightest">
              <ShipmentModeSelector shipmentMode="ftl" setShipmentMode={setShipmentMode} />
            </div>
          )}
          <CreateQuote onNext={handleNext} quote={quote} setQuote={setQuote} />
        </>
      )}
      {step === 1 && (
        <QuoteDetails
          onPrevious={handlePrevious}
          onNext={onContactInformationFormSubmit}
          onCancel={handleCancel}
          quote={quote}
        />
      )}
      {step === 2 && (
        <SuccessMessage
          quote={quote}
          email={email!}
          onRequote={handleCancel}
          message={successText}
        />
      )}
    </>
  )
}

export default NewFTLQuote
