import { lazy, Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'

import QuoteDashboard from './QuotesDashboard'

const QuoteAdd = lazy(() => import('./QuoteForm/QuoteAdd'))
const QuoteDetails = lazy(() => import('./QuoteDetails'))
const QuotePostingLoad = lazy(() => import('./QuotePostingLoad'))

function QuoteIndex() {
  let { path } = useRouteMatch()

  return (
    <Suspense fallback={<h1> Loading ...</h1>}>
      <Switch>
        <Route exact path={path}>
          <QuoteDashboard />
        </Route>
        <Route path={`${path}/new`}>
          <QuoteAdd />
        </Route>
        <Route path={`${path}/refresh/:id`}>
          <QuoteAdd />
        </Route>
        <Route exact path={`${path}/:id/post-load`}>
          <QuotePostingLoad />
        </Route>
        <Route path={`${path}/:id`}>
          <QuoteDetails />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default QuoteIndex
