import { Stop } from 'common/types/Stop'
import clsx from 'clsx'
import Collapsible from 'components/Collapsible'
import AccessorialsList from './AccessorialsList'
import useAccessorials from './useAccessorials'

export interface AccessorialsProps {
  stops: Stop[]
  className?: string
}

function Accessorials({ stops, className }: AccessorialsProps) {
  const { accessorials } = useAccessorials({ isWhiteLabel: false })

  return (
    <Collapsible
      title="Accessorials for pickup and delivery"
      iconName="warehouse"
      className={clsx('px-6 py-1', className)}
    >
      <AccessorialsList
        stops={stops}
        accessorialsList={[...accessorials.pu, ...accessorials.del]}
        className={className}
      />
    </Collapsible>
  )
}

export default Accessorials
