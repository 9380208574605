import type { ValidationError } from 'yup'
import set from './helpers/set'
import get from './helpers/get'
import Status from './types/Status'
import toArray from './helpers/toArray'
import isEmpty from './helpers/isEmpty'

export type TranslatedError = Record<string, string | undefined>
export type RawError = ValidationError
export type CombinedError = TranslatedError | RawError

export type ErrorHelperType = {
  get(path: string): any
  has(path: string): boolean
  status(path: string | string[]): Status.Danger | Status.Neutral
  hasErrors(): boolean
}

/**
 * Takes an array of errors and reduce into an object
 * @param {RawError[]} errors
 */
function transformErrors(errors: RawError[]) {
  return errors.reduce(
    (prev: TranslatedError, error: RawError) => ({
      ...prev,
      [String(error.path || error.type)]: error.message,
    }),
    {},
  )
}

function ErrorHelper(errors: CombinedError = {}): ErrorHelperType {
  let navigableErrors = {}

  if (Array.isArray(errors.inner)) {
    const transformedErrors = transformErrors(errors.inner)
    Object.entries(transformedErrors).forEach(([path, error]) => {
      set(navigableErrors, path, error)
    })
  } else {
    navigableErrors = errors
  }

  return {
    get(path: string) {
      return get(navigableErrors, path, undefined)
    },
    has(path: string) {
      return Boolean(this.get(path))
    },
    status(path: string | string[]) {
      const hasAnyError = toArray(path).some((path) => this.has(path))

      if (hasAnyError) {
        return Status.Danger
      }
      return Status.Neutral
    },
    hasErrors() {
      return !isEmpty(navigableErrors)
    },
  }
}

export default ErrorHelper
