import { enablePartialApplication } from '@loadsmart/utils-function'
import toArray from './toArray'

type Variations = string | number

// TODO: handle ambiguous prop values
function getConditionalClasses(mapping: { [key: string]: Variations | Variations[] }) {
  const mapOfValueToAttr: Record<Variations, string> = {}

  for (let prop in mapping) {
    const possiblePropValues: Variations[] = toArray<Variations>(mapping[prop])

    for (let i = 0; i < possiblePropValues.length; i++) {
      const possiblePropValue: Variations = possiblePropValues[i]
      mapOfValueToAttr[possiblePropValue] = prop
    }
  }

  function combine(
    props: any,
    propValuesCombinations: Variations | Variations[],
    ...otherPropValuesCombinations: (Variations | Variations[])[]
  ) {
    const allPropValuesCombinations = [propValuesCombinations, ...otherPropValuesCombinations]
    const safeProps = props || {}

    return allPropValuesCombinations.some((propValueCombination) => {
      const propValues = toArray(propValueCombination)

      return propValues.every((propValue) => {
        const prop = mapOfValueToAttr[propValue]
        return safeProps[prop] === propValue
      })
    })
  }

  return enablePartialApplication(combine)
}

export default getConditionalClasses
