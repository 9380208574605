import { TextField, TextFieldProps } from '@loadsmart/loadsmart-ui'
import useMask from './useMask'

export interface MaskedTextFieldProps extends TextFieldProps {
  /**
   * You can use a regex pattern, when user input and char matches one to one (ex. `dd:dd`),
   * or an array, when you want, for example, provide a list of accepted chars (ex. `['[01]', ']`).
   */
  mask: string | Array<string>
}

// TODO: evolve the mask handling to allow complex patterns
/**
 * Simple masked input that is able to handle pattern rules where there is one rule for each single char.
 * @param props
 */
function MaskedTextField(props: MaskedTextFieldProps) {
  const maskProps = useMask(props)

  return <TextField {...props} {...maskProps} />
}

export default MaskedTextField
