import { Fragment } from 'react'
import clsx from 'clsx'

import EventLike from '../../../../common/types/EventLike'
import isEmpty from '../../../../common/helpers/isEmpty'
import Stop, { StopProps } from './Stop'
import useStops from './useStops'
import { getMinStopDate, clearNextStopDate, isNextStopDateInvalid } from './Stop.helpers'
import ErrorHelper, { RawError } from '../../../../common/Error.helpers'

export interface StopsProps {
  id?: string
  name?: string
  className?: string
  stops?: Array<StopProps>
  errors?: RawError
  onChange?: (event: EventLike<Partial<StopProps> | StopProps[]>) => void
}

function Stops({ id, name, className, stops: initialStops, errors, onChange }: StopsProps) {
  const { stops, removeStop, updateStop } = useStops({
    stops: initialStops,
  })

  function handleStopsChange(updatedStop: StopProps, index: number) {
    const newStops = [...stops]
    newStops[index] = updatedStop

    if (isNextStopDateInvalid(newStops, index)) {
      clearNextStopDate(newStops, index)
    }

    onChange?.({
      target: { id, name, value: newStops },
    })

    updateStop(updatedStop)
  }

  return (
    <Fragment>
      {!isEmpty(stops) && (
        <div data-testid="stops" className={clsx('flex flex-col space-y-4', className)}>
          {(stops || []).map(function renderStop(stop: StopProps, index: number) {
            return (
              <Stop
                id={String(index)}
                key={stop.id}
                label={`Stop ${index}`}
                stop={stop}
                minDate={getMinStopDate(stops, index)}
                errors={ErrorHelper(errors).get(`stops[${index}]`)}
                onChange={(piece) => {
                  const updatedStop = {
                    ...stop,
                    ...piece,
                  }
                  handleStopsChange(updatedStop, index)
                }}
                onRemove={removeStop}
              />
            )
          })}
        </div>
      )}
    </Fragment>
  )
}

export default Stops
