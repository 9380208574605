import { Dialog } from 'components/Dialog'
import React, { ChangeEvent, useState } from 'react'
import Checkbox from 'components/Checkbox'
import RefreshQuoteAlertModalHelper from '../QuotesList/helpers/RefreshQuoteAlertModal.helpers'

interface RefreshQuoteAlertModalProps {
  open: boolean
  onClose: (result: boolean) => void
}

function RefreshQuoteAlertModal({ open, onClose }: RefreshQuoteAlertModalProps) {
  const [dontShowAgain, setDontShowAgain] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(event.target.checked)
  }

  const onConfirm = () => {
    if (dontShowAgain) {
      RefreshQuoteAlertModalHelper().persistPreventModal()
    }
    onClose(true)
  }

  const onCancel = () => {
    onClose(false)
  }

  return (
    <Dialog open={open} scale="small">
      <Dialog.Header>Refresh quote</Dialog.Header>
      <Dialog.Body className="overflow-y-auto max-modal-body-size mb-4">
        <p className="mb-6">
          By refreshing the quote, you will be redirected to create a new quote based on the
          information from this one, you just need to select the new pickup and delivery date so the
          price will be recalculated.
        </p>
        <p className="text-left ml-1">
          <Checkbox checked={dontShowAgain} onChange={handleChange} data-testid="do_not_show_again-checkbox">
            Don't show this again
          </Checkbox>
        </p>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={() => onConfirm()} />
      <Dialog.ActionCancel onCancel={() => onCancel()} />
    </Dialog>
  )
}

export default RefreshQuoteAlertModal
