import jwtParse from './helpers/jwtParse'

interface SessionManager {
  setSession: (token: string) => void
  clearSession: () => void
}

const sessionManager: SessionManager = {
  setSession(token: string) {
    const decodedToken = jwtParse(token)
    if (decodedToken) {
      Object.keys(decodedToken).forEach(function storeSessionKey(key) {
        sessionStorage.setItem(key, decodedToken[key].toString())
      })
    }
  },
  clearSession() {
    sessionStorage.clear()
  },
}

export default sessionManager
