/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect, MouseEvent } from 'react'
import clsx from 'clsx'
import { isNil } from '@loadsmart/utils-object'
import {
  SingleDatePicker,
  DateRangePicker as RDDateRangePicker,
  DateRangePickerShape,
  SingleDatePickerShape,
  DayPickerRangeController,
  FocusedInputShape,
} from 'react-dates'

import EventLike from '../../common/types/EventLike'
import ColorScheme from '../../common/types/ColorScheme'
import Status from '../../common/types/Status'
import getID from '../../common/helpers/getID'

import 'react-dates/initialize'

import 'react-dates/lib/css/_datepicker.css'
import './DatePicker.css'
import Button from 'components/Button'
import moment, { Moment } from 'moment'
import { API_DATE_FORMAT } from '../../common/constants'

export type DateRangePickerProps = Omit<
  DateRangePickerShape,
  'onDatesChange' | 'focusedInput' | 'onFocusChange'
> & {
  className?: string
  id?: string
  name: string
  onChange?: (event: EventLike<{ startDate: any; endDate: any }>) => void
  scheme?: ColorScheme
  status?: Status
  startDate?: any
  endDate?: any
}

function isOutsideRange() {
  return false
}

export function DateRangePicker({
  className,
  disabled,
  startDate,
  endDate,
  scheme,
  id = getID(),
  name,
  onChange,
  status,
  ...props
}: DateRangePickerProps) {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null)

  const handleChange = useCallback(
    ({ startDate, endDate }) => {
      if (!onChange) {
        return
      }

      onChange({ target: { id, name, value: { startDate, endDate } } })
    },
    [id, name, onChange],
  )

  return (
    <div
      className={clsx(
        'rg-date-range-picker text-sm',
        {
          'cursor-not-allowed opacity-40': disabled,
        },
        {
          '-dark': scheme === 'dark',
          '-light': scheme === 'light',
          '-danger': status === Status.Danger,
        },
        className,
      )}
    >
      <RDDateRangePicker
        displayFormat="MM/DD/YYYY"
        weekDayFormat="ddd"
        {...(props as DateRangePickerShape)}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        small
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        inputIconPosition="after"
        isOutsideRange={isOutsideRange}
        minimumNights={0}
        numberOfMonths={2}
        showClearDates={!isNil(startDate) || !isNil(endDate)}
      />
    </div>
  )
}

DateRangePicker.defaultProps = {
  scheme: 'light',
  status: Status.Neutral,
  startDateId: getID(),
  endDateId: getID(),
}

export type DatePickerProps = Partial<SingleDatePickerShape> & {
  className?: string
  id?: string
  name: string
  date: any
  disabled?: boolean
  onChange?: (event: EventLike<any>) => void
  scheme?: ColorScheme
  status?: Status
}

export function DatePicker({
  className,
  date,
  disabled,
  scheme,
  id = getID(),
  name,
  onChange,
  status,
  ...props
}: DatePickerProps) {
  const [focused, setFocused] = useState(false)

  const handleChange = useCallback(
    (date) => {
      if (!onChange) {
        return
      }

      onChange({ target: { id, name, value: date } })
    },
    [id, name, onChange],
  )

  const handleFocusChange = useCallback(({ focused }) => setFocused(focused), [])

  return (
    <div
      className={clsx(
        'rg-date-picker text-sm',
        {
          'cursor-not-allowed opacity-40': disabled,
        },
        {
          '-dark': scheme === 'dark',
          '-light': scheme === 'light',
          '-danger': status === Status.Danger,
        },
        className,
      )}
    >
      <SingleDatePicker
        id={id}
        displayFormat="MM/DD/YYYY"
        weekDayFormat="ddd"
        date={date}
        {...props}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        small
        disabled={disabled}
        onDateChange={handleChange}
        focused={focused}
        onFocusChange={handleFocusChange}
        inputIconPosition="after"
        isOutsideRange={isOutsideRange}
        numberOfMonths={2}
        showClearDate={!isNil(date)}
      />
    </div>
  )
}

DatePicker.defaultProps = {
  scheme: 'light',
  status: Status.Neutral,
}

export interface DateRangePickerPopupProps {
  open: boolean
  format?: string
  reset?: boolean
  defaultRange: { start: string; end?: string }
  onApply: (range: { start: string; end: string }) => void
  onCancel: () => void
}

const getInitialRange = (format: string, range?: { start: string; end?: string }) => {
  if (!range || !range.end) {
    return { start: null, end: null }
  }

  const initialStart = moment(range.start, format)
  const initialEnd = moment(range.end, format)

  return { start: initialStart, end: initialEnd }
}

export function DateRangePickerPopup({
  onCancel,
  onApply,
  reset,
  defaultRange,
  format = API_DATE_FORMAT,
}: DateRangePickerPopupProps) {
  const [focused, setFocused] = useState<FocusedInputShape>('startDate')
  const [range, setRange] = useState<{ start: Moment | null; end: Moment | null }>(
    getInitialRange(format, defaultRange),
  )

  useEffect(() => {
    if (reset) {
      setRange({ start: null, end: null })
    }
  }, [reset, setRange])

  return (
    <div className="relative m-0">
      <div className="rounded-sm shadow bg-neutral-lightest p-6 flex popup flex flex-col">
        <DayPickerRangeController
          startDate={range.start}
          hideKeyboardShortcutsPanel
          endDate={range.end}
          onDatesChange={({ startDate, endDate }) => setRange({ start: startDate, end: endDate })}
          focusedInput={focused}
          onFocusChange={(focusedInput: FocusedInputShape | null) =>
            setFocused(!focusedInput ? 'startDate' : focusedInput)
          }
          initialVisibleMonth={() => moment().subtract(1, 'months')}
          numberOfMonths={2}
          noBorder
        />
        <hr className="w-full mt-2 mb-2" />
        <div className="flex justify-end">
          <Button
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
              onCancel()
            }}
          >
            cancel
          </Button>
          <Button
            className="ml-2"
            variant="primary"
            disabled={!(range.start && range.end)}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (range.start && range.end) {
                onApply({
                  start: range.start.format(format),
                  end: range.end.format(format),
                })
              }
            }}
          >
            apply
          </Button>
        </div>
      </div>
    </div>
  )
}
