import pick from 'common/helpers/pick'
import DateHelper from 'common/Date.helpers'
import { API_DATE_FORMAT } from 'common/constants'

function transformDateRange({ pickup_date_start, pickup_date_end, ...others }: any) {
  const format = API_DATE_FORMAT
  return {
    ...others,
    pickup_date_start: DateHelper(pickup_date_start)?.format(format) || null,
    pickup_date_end: DateHelper(pickup_date_end)?.format(format) || null,
  }
}

function transformMarginValue({ margin_value, ...others }: any) {
  return {
    ...others,
    margin_value: parseFloat((margin_value / 100).toFixed(4)),
  }
}

function transformLocations({ origin, destination, ...others }: any) {
  const originPayload = origin?.map((origin: any) => ({
    ...pick(origin, ['state', 'country', 'kma']),
    city: origin.city || null,
    zipcode: origin.zipcode || null,
  }))

  const destinationPayload = destination?.map((destination: any) => ({
    ...pick(destination, ['state', 'country', 'kma']),
    city: origin.city || null,
    zipcode: destination.zipcode || null,
  }))

  return {
    ...others,
    origin: originPayload || null,
    destination: destinationPayload || null,
  }
}

function transformShipper({ shipper, ...others }: any) {
  return {
    ...others,
    shipper: shipper?.id ? pick(shipper, ['id']) : null,
  }
}

export const transformers = [
  transformDateRange,
  transformMarginValue,
  transformLocations,
  transformShipper,
]
