import { ReactNode } from 'react'
import clsx from 'clsx'

interface PaperProps {
  children: ReactNode
  className?: string
}

function Paper({ children, className }: PaperProps) {
  return <section className={clsx('p-l shadow rounded mb-l', className)}>{children}</section>
}

export default Paper
