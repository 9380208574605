import React from 'react'

import Button from 'components/Button'
import { AuthLayout } from 'app/AuthLayout'
import { useHistory } from 'react-router-dom'
import ForgotPasswordForm from './ForgotPasswordForm'
import CheckYourEmail from './CheckYourEmail'
import { forgotPassword } from 'services/auth/auth.service'
import { toast } from 'components/Toast'

export type EmailSentStatus = 'unsent' | 'sent' | 'resent'

const ForgotPassword = () => {
  const [email, setEmail] = React.useState('')
  const history = useHistory()

  const sendForgotPasswordRequest = async () => {
    const [, response] = await forgotPassword(email)
    if (response) {
      setEmailSentStatus(emailSentStatus === 'unsent' ? 'sent' : 'resent')
    } else {
      toast.error("We couldn't send the instructions.")
    }
  }

  const [emailSentStatus, setEmailSentStatus] = React.useState<EmailSentStatus>('unsent')

  return (
    <AuthLayout>
      {emailSentStatus === 'unsent' ? (
        <ForgotPasswordForm
          setEmail={setEmail}
          email={email}
          sendForgetPasswordRequest={sendForgotPasswordRequest}
        />
      ) : (
        <CheckYourEmail
          sendForgetPasswordRequest={sendForgotPasswordRequest}
          email={email}
          emailStatus={emailSentStatus}
        />
      )}
      <div className="flex flex-col px-8">
        <Button
          type="button"
          variant="secondary"
          className="mt-4"
          onClick={() => history.push('/login')}
        >
          Back to Login
        </Button>
      </div>
    </AuthLayout>
  )
}

export default ForgotPassword
