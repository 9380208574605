import { useRef, useState } from 'react'

import { genericTo as to } from 'common/helpers/awaitTo'
import { SuggestionDatasource, SuggestionOption, SuggestionQueryStatus } from './Suggestion.types'
import debounce from 'common/helpers/debounce'
import useMounted from 'hooks/useMounted'

async function* getData(datasources: SuggestionDatasource<any>[], query: string) {
  for (let ds of datasources) {
    const [error, data] = await to(Promise.resolve(ds.fetch({ query })))

    if (!error) {
      const items = (data || []).map((item: any) => {
        return {
          value: ds.adapter.getValue(item),
          label: ds.adapter.getLabel(item),
          _type: ds._type,
        }
      })

      yield items
    }
  }
}

const DEFAULT_ARRAY: SuggestionOption[] = []

// TODO: make fetch cancellable (e.g. when user selects an option before all datasources being queried)
function useSuggestion(
  delay = 450,
  minQueryLength: number = 3,
  ...datasources: (() => SuggestionDatasource<any>)[]
) {
  const isMounted = useMounted()

  const [status, setStatus] = useState<SuggestionQueryStatus>(SuggestionQueryStatus.Idle)
  const [options, setOptions] = useState<SuggestionOption[]>(DEFAULT_ARRAY)
  const datasourcesRef = useRef(datasources.map((ds) => ds()))

  function getDatasources() {
    return datasourcesRef.current
  }

  const clear = useRef(function clear({ options }: { options?: SuggestionOption[] } = {}) {
    setStatus(SuggestionQueryStatus.Idle)
    setOptions(options || DEFAULT_ARRAY)
  })

  const fetch = useRef(
    debounce(async function fetch({ query, allowEmptyQuery = false }) {
      if (!isMounted()) {
        return
      }

      if(!allowEmptyQuery && (query || '').length < minQueryLength){
        return
      }

      clear.current()
      setStatus(SuggestionQueryStatus.Querying)

      for await (let items of getData(getDatasources(), query)) {
        if (isMounted()) {
          setStatus(SuggestionQueryStatus.PartiallyDone)
          setOptions((options) => [...options, ...(items || [])])
        }
      }

      if (isMounted()) {
        setStatus(SuggestionQueryStatus.Done)
      }
    }, delay),
  )

  return { clear: clear.current, fetch: fetch.current, options, status }
}

export default useSuggestion
