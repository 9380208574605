import { baseURL, originURL } from 'common/HTTPClient'

function getSnippetCode(appId: string) {
  return `
    <div id="rate-guide-target">
      <script>
      fetch(
        "${baseURL}api/white-label/token",
        { method: "POST", body: '{"app_id":"${appId}"}', headers: { "Content-Type": "application/json" } }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            return
          }
        })
        .then((data) => {
          var params = '';
          if (!!data) {
            params = Object.keys(data)
              .filter((value) => {
                return !!data[value];
              })
              .map((key) => {
                if (typeof data[key] === "object") {
                  return Object.keys(data[key])
                    .map(
                      (innerKey) =>
                        \`\${innerKey}=\${encodeURIComponent(data[key][innerKey])}\`
                    )
                    .join("&");
                } else {
                  return \`\${key}=\${encodeURIComponent(data[key])}\`;
                }
              })
              .join("&");
          }
          const iframe = document.createElement("iframe");
          iframe.setAttribute("src", \`${originURL}white-label?\${params}\`);
          iframe.style.width = "100%";
          iframe.style.height = "100vh";
          iframe.frameBorder = "0";
          document.getElementById("rate-guide-target").appendChild(iframe);
        })
        .catch((e) => {
          console.error(e);
        });
      </script>
    </div>`
}

export default getSnippetCode
