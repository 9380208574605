import { RefObject, useCallback, useEffect, useState } from 'react'

function useClickOutside(ref: RefObject<HTMLElement>, callback: () => void) {
  const [active, setActive] = useState(false)

  const handleEvent = useCallback(
    function handleEvent(this: Document, e: MouseEvent | KeyboardEvent) {
      function activate() {
        setActive(true)
      }

      function deactivate() {
        setActive(false)
      }

      if (!ref.current) {
        return
      }

      if (ref.current.contains(e.target as HTMLElement) && !active) {
        activate()
      } else if (!ref.current.contains(e.target as HTMLElement) && active) {
        deactivate()

        callback()
      }
    },
    [active, callback, ref],
  )

  useEffect(() => {
    function subscribe() {
      document.addEventListener('mousedown', handleEvent, true)
      document.addEventListener('keyup', handleEvent, true)
    }

    function unsubscribe() {
      document.removeEventListener('mousedown', handleEvent, true)
      document.removeEventListener('keyup', handleEvent, true)
    }

    subscribe()

    return () => {
      unsubscribe()
    }
  }, [handleEvent])
}

export default useClickOutside
