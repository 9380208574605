import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { post, get, patch } from '../common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { Quote } from 'common/types/Quote'
import { IContactForm } from '../components/ContactInformation/ContactFormModal'
import { StopType } from 'common/types/Stop'
import { Accessorial } from 'common/types/Accessorial'
import { QuoteLTLFormValues } from 'common/types/LTLQuote'
import { QuoteRequest } from 'services/quotes/quotes.service'


const urlStopType: Record<StopType, string> = {
  pu: 'pickup',
  del: 'delivery',
}

export async function createQuote(
  quote: Quote,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<Quote> | undefined | { data: Quote }]> {
  try {
    return await to(post('/api/white-label/quote', quote, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function setInterest(
  quoteId: string,
  data: IContactForm,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse | undefined | { data: boolean }]> {
  try {
    return await to(post(`/api/white-label/quote/${quoteId}/lead`, data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function getAccessorials(
  type: StopType,
): Promise<[AxiosError | null | boolean, AxiosResponse<{data: Accessorial[]}> | undefined]> {
  try {
    return await to(get(`/api/white-label/accessorials?stop_type=${urlStopType[type]}`))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function createLTLQuoteRequest(
  quoteRequestParams: Partial<QuoteLTLFormValues> & { mode: 'LTL', channel: string },
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<{ id: string }> | undefined]> {
  try {
    return await to(post('/api/white-label/quote-request', quoteRequestParams, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function fetchLTLQuoteRequest(
  quoteRequestId: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<QuoteRequest> | undefined]> {
  try {
    return await to(get(`/api/white-label/quote-request/${quoteRequestId}`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function selectRate(
  quoteID: string,
  rateID: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quote> | undefined]> {
  try {
    return await to(patch(`/api/white-label/quote/${quoteID}`, { selected_rate: { id: rateID } }, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}