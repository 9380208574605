import Environment from 'common/types/Environment'
import getEnv from './getEnv'

function getEnvSubdomain(): string {
  switch (getEnv()) {
    case Environment.Production:
      return ''
    case Environment.Sandbox:
      return 'sandbox.'
    case Environment.QA:
      return 'qa.'
    default:
      return 'staging.'
  }
}

export default getEnvSubdomain
