import clsx from 'clsx'

import { EquipmentType } from '../../../../common/types/EquipmentType'
import { ICON_BY_EQUIPMENT_TYPE } from './EquipmentType.constants'
import EquipmentTypeHelper from '../../../../common/EquipmentType.helpers'
import Icon from '../../../../components/Icon'

export type EquipmentTypeViewMode = 'full' | 'icon-only' | 'label-only' | 'abbr' | 'abbr-only'

export interface EquipmentTypeProps {
  className?: string
  mode: EquipmentTypeViewMode
  name: EquipmentType | undefined
}

export function shouldShowIcon(mode: EquipmentTypeViewMode) {
  return ['full', 'icon-only', 'abbr'].includes(mode)
}

export function shouldShowLabel(mode: EquipmentTypeViewMode) {
  return ['full', 'label-only'].includes(mode)
}

export function shouldShowAbbr(mode: EquipmentTypeViewMode) {
  return ['abbr', 'abbr-only'].includes(mode)
}

function EquipmentTypeEntry({ className, mode, name }: EquipmentTypeProps) {
  if (!name) {
    return <p>No equipment type.</p>
  }

  const iconName = ICON_BY_EQUIPMENT_TYPE[name]
  const equipmentType = EquipmentTypeHelper.getByName(name)

  return (
    <span
      key={equipmentType.label}
      className={clsx('inline-flex items-center space-x-2', className)}
    >
      {shouldShowIcon(mode) && (
        <Icon
          name={iconName}
          title={`${equipmentType.label} icon`}
          size={20}
          data-testid="equipment-type-icon"
          className="fill-current"
        />
      )}
      {shouldShowLabel(mode) && (
        <span className="font-bold" data-testid="equipment-type-label">
          {equipmentType.label}
        </span>
      )}
      {shouldShowAbbr(mode) && (
        <abbr
          className="font-bold no-underline"
          data-testid="equipment-type-abbr"
          title={equipmentType.label}
        >
          {equipmentType.abbr}
        </abbr>
      )}
    </span>
  )
}

EquipmentTypeEntry.defaultProps = {
  mode: 'full',
}

export default EquipmentTypeEntry
