import { useCallback } from 'react'
import Suggestion, { SuggestionDatasource, SuggestionValue } from 'components/Suggestion'
import type ColorScheme from 'common/types/ColorScheme'
import type EventLike from 'common/types/EventLike'
import type Status from 'common/types/Status'
import type { Shipper } from 'common/types/Shipper'
import isEmpty from 'common/helpers/isEmpty'
import useFetchShippers, { ShipperAdapter } from './useFetchShippers'

export interface ShipperSuggestionProps {
  datasources?: (() => SuggestionDatasource<any>)[]
  className?: string
  delay?: number
  multiple?: boolean
  clearable?: boolean
  disabled?: boolean
  id?: string
  name: string
  placeholder?: string
  scheme?: ColorScheme
  status?: Status
  query?: string
  value?: Shipper | Shipper[] | null
  disableDropdownIndicator?: boolean
  onChange?: (event: EventLike<Shipper | Shipper[] | null>) => void
  loadDataOnFocus?: boolean
}

const DATASOURCES = [useFetchShippers]

function ShipperSuggestion({
  name = 'shipper-suggestion',
  id,
  onChange,
  ...others
}: ShipperSuggestionProps) {
  const handleChange = useCallback(
    function handleChange(e: EventLike<SuggestionValue>) {
      const {
        target: { value },
      } = e

      if (Array.isArray(value)) {
        let shippers = [] as Shipper[]

        for (let i = 0; i < value.length; i++) {
          const entry = value[i]
          shippers.push({
            id: String(entry.value),
            name: entry.label,
          })
        }

        onChange?.({
          target: { id, name, value: isEmpty(shippers) ? null : shippers },
        })
      } else {
        onChange?.({
          target: {
            id,
            name,
            value: value
              ? ({
                  id: value.value,
                  name: value.label,
                } as Shipper)
              : null,
          },
        })
      }
    },
    [id, name, onChange],
  )

  function getValue() {
    if (!others.value) {
      return null
    }

    if (Array.isArray(others.value)) {
      return (others.value as Shipper[]).map((location) => ({
        label: ShipperAdapter.getLabel(location),
        value: ShipperAdapter.getValue(location),
      }))
    }
    const shipper: Shipper = others.value
    if (!shipper.id) {
      return null
    }

    return {
      label: ShipperAdapter.getLabel(shipper),
      value: ShipperAdapter.getValue(shipper),
    }
  }

  return (
    <Suggestion
      {...others}
      value={getValue() as any}
      id={id}
      name={name}
      onChange={handleChange}
      delay={450}
      minQueryLength={1}
      datasources={DATASOURCES}
    />
  )
}

export default ShipperSuggestion
