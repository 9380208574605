import DateHelper from './Date.helpers'
import { API_DATETIME_FORMAT } from './constants'
import pick from './helpers/pick'

function FormSubmissionHelper(data: any) {
  let form = data

  return {
    transformDateRange(key: string) {
      let start,
        end = null

      let dateRange: Record<string, { start?: string; end?: string } | null> = {
        [key]: null,
      }

      if (form[key] && form[key].start && form[key].end) {
        start = DateHelper(form[key].start)?.startOfDay().format(API_DATETIME_FORMAT)
        end = DateHelper(form[key].end)?.endOfDay().format(API_DATETIME_FORMAT)
        dateRange = {
          [key]: {
            start,
            end,
          },
        }
      }

      form = {
        ...form,
        ...dateRange,
      }

      return this
    },
    transformLocation(key: string) {
      form = {
        ...form,
        [key]: form[key]
          ? form[key].map((location: any) => ({
              ...pick(location, ['country', 'kma']),
              state: location.state || null,
              city: location.city || null,
              zipcode: location.zipcode || null,
            }))
          : [],
      }

      return this
    },
    transformLocations(keys: string[]) {
      keys.forEach((key) => {
        this.transformLocation(key)
      })

      return this
    },
    setFormValue(key: string, value: any) {
      form = {
        ...form,
        [key]: value,
      }

      return this
    },
    getFormValues() {
      return form
    },
  }
}

export default FormSubmissionHelper
