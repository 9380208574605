const VARS = {
  DATADOG_KEY: process.env.REACT_APP_FRONTEND_DATADOG_CLIENT,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_FRONTEND_GOOGLE_MAPS_KEY,
  HOTJAR_ID: process.env.REACT_APP_FRONTEND_HOTJAR_ID,
  MIXPANEL_KEY: process.env.REACT_APP_FRONTEND_MIXPANEL_KEY,
  SENTRY_DSN: process.env.REACT_APP_FRONTEND_SENTRY_DSN,
  RECAPTCHA_KEY: process.env.REACT_APP_FRONTEND_RECAPTCHA_KEY,
}

function getEnvVariable(
  variable:
    | 'DATADOG_KEY'
    | 'GOOGLE_MAPS_KEY'
    | 'HOTJAR_ID'
    | 'MIXPANEL_KEY'
    | 'SENTRY_DSN'
    | 'RECAPTCHA_KEY',
) {
  return VARS[variable] || ''
}

export default getEnvVariable
