import axios, { AxiosResponse, AxiosError } from 'axios'

import { refreshToken, logout } from '../../services/auth/auth.service'
import HTTPStatus from '../types/HTTPStatus'
import tokenManager from '../tokenManager'

/**
 * This is an async wrapper for waiting a promise to be settled without the hussle of handling error.
 * It returns `[error, data]`.
 * @example
 * const [error, data] = await genericTo(myPromise())
 * @param promise - promise to be awaited for.
 * @param errorExt - error to be appended, in case the promise is rejected.
 */
export function genericTo<T>(
  promise: Promise<T>,
  errorExt?: object,
): Promise<[any, T | undefined]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[any, undefined]>((err: any) => {
      if (errorExt) {
        Object.assign(err, errorExt)
      }

      return [err, undefined]
    })
}

function isUnauthorized(response: AxiosResponse | undefined): boolean {
  return response?.status === HTTPStatus.Unauthorized
}

function isRefreshTokenURL(url: string | undefined): boolean {
  return Boolean(url && url.search('token/refresh') >= 0)
}

/**
 * This more "sophisticated" version of `genericTo`, where we run the given promise and, if it returns a
 * Unauthorized error we run the refresh token flow (if a refresh token is available) and then retry the initial
 * promise.
 * If any of the steps fails, we redirect the user to the login page.
 * @param promise - promise to be awaited for.
 * @param errorExt - error to be appended, in case the promise is rejected.
 */
export async function to<T>(
  promise: Promise<AxiosResponse<T>>,
  errorExt?: object,
): Promise<[AxiosError | null, AxiosResponse<T> | undefined]> {
  const [error, response] = await genericTo<AxiosResponse<T>>(promise, errorExt)

  if (error && isUnauthorized(error.response)) {
    console.error(error)

    if (isRefreshTokenURL(error.config.url) || !tokenManager.getRefreshToken()) {
      logout()

      return [null, undefined]
    }

    try {
      const token = await refreshToken()
      const config = error.config
      config.headers['Authorization'] = `JWT ${token}`

      return to(axios.request(config))
    } catch (error) {
      logout()
    }
  }

  return [error, response]
}
