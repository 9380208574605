import { Shipper } from '../../../../common/types/Shipper'
import { useCallback } from 'react'

import { searchShipper } from '../../../../services/shippers/shippers.service'
import { SuggestionItemAdapter } from 'components/Suggestion'
import useCancelToken from '../../../../hooks/useCancelToken'
import { isBlank } from '@loadsmart/utils-string'

const INACTIVE_STATUS_FLAG = ' (inactive)'

export function isFlaggedInactive(name: string) {
  return name.endsWith(INACTIVE_STATUS_FLAG)
}

function statusLabel(status?: string) {
  return status === 'inactive' ? INACTIVE_STATUS_FLAG : ''
}

export const ShipperAdapter: SuggestionItemAdapter<Shipper> = {
  getValue: (shipper: Shipper) => shipper.id,
  getLabel: (shipper: Shipper) => `${shipper.name || ''}${statusLabel(shipper.status)}`,
}

export interface useFetchShippersProps {}

function useFetchShippers() {
  const { getSource, clearSource, cancelPending } = useCancelToken()

  const fetch = useCallback(
    function fetchShippers({ query }: { query: string }): Promise<Shipper[]> {
      async function fetch() {
        cancelPending()

        const source = getSource()

        let params: { limit: number; offset: number; query?: string } = { limit: 10, offset: 0 }

        if (!isBlank(query)) {
          params = {
            query,
            ...params,
          }
        }

        const [error, response] = await searchShipper(params, {
          cancelToken: source.token,
        })

        clearSource()

        if (!error) {
          return response?.data?.results || []
        }
        return []
      }

      return fetch()
    },
    [cancelPending, clearSource, getSource],
  )

  return {
    fetch,
    adapter: ShipperAdapter,
  }
}

export default useFetchShippers
