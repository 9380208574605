import { EquipmentType } from './types/EquipmentType'

interface EquipmentTypeData {
  label: string
  value: EquipmentType
  abbr: string
}

const DRYVAN: EquipmentTypeData = {
  label: 'Dry Van',
  value: 'DRV',
  abbr: 'DRV',
}

const REEFER: EquipmentTypeData = {
  label: 'Reefer',
  value: 'RFR',
  abbr: 'RFR',
}

const FLATBED: EquipmentTypeData = {
  label: 'Flatbed',
  value: 'FBE',
  abbr: 'FBE',
}

const EQUIPMENT_TYPE_BY_NAME: Record<EquipmentType, EquipmentTypeData> = {
  dryvan: DRYVAN,
  DRV: DRYVAN,
  reefer: REEFER,
  RFR: REEFER,
  flatbed: FLATBED,
  FBE: FLATBED,
}

const ALL_EQUIPMENT_TYPES = [DRYVAN, REEFER, FLATBED]

function getAllTypes(getCustomProps?: (type: EquipmentType) => Record<string, any>) {
  if (!getCustomProps) {
    return ALL_EQUIPMENT_TYPES
  }

  return ALL_EQUIPMENT_TYPES.map((type) => ({
    ...type,
    ...getCustomProps(type.value),
  }))
}

function getByName(
  name: EquipmentType,
  getCustomProps?: (type: EquipmentType) => Record<string, any>,
) {
  if (!getCustomProps) {
    return EQUIPMENT_TYPE_BY_NAME[name]
  }

  return {
    ...EQUIPMENT_TYPE_BY_NAME[name],
    ...getCustomProps(name),
  }
}

function EquipmentTypeHelper(type: EquipmentType) {
  const data: EquipmentTypeData = getByName(type) || {}

  return {
    label() {
      return data.label
    },
    value() {
      return data.value
    },
    abbr() {
      return data.abbr
    },
    is(other: EquipmentType) {
      return getByName(type) === getByName(other)
    },
  }
}

EquipmentTypeHelper.getAll = getAllTypes
EquipmentTypeHelper.getByName = getByName

export default EquipmentTypeHelper
