import { ReactNode } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Login } from '../pages/Login'
import { QuotesIndex } from '../pages/Quotes'
import { MarginsIndex } from '../pages/Margins'

import PrivateRoute from './PrivateRoute'
import { ForgotPassword } from '../pages/ForgotPassword'
import { ResetPassword } from '../pages/ResetPassword'
import WhiteLabelConfiguration from '../pages/WhiteLabelConfiguration'
import { NotFound } from 'components/NotFound'
import CurrentUserHelper from 'common/CurrentUser.helper'
import { Users } from '../pages/Users'
import { Shippers } from 'pages/Shippers'
import { Header } from 'app/Header'
import { Sidebar } from 'app/Sidebar/Sidebar'

function PublicPage({ children }: { children: ReactNode }) {
  return <main className="flex flex-col items-center justify-start h-full p-0">{children}</main>
}

function PrivatePage({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="fixed z-10">
        <Sidebar />
      </div>
      <header data-testid="app-header" className="fixed z-5 w-full">
        <Header />
      </header>
      <main className="flex flex-col items-center justify-start py-6 rg-main wrapper ml-50 pt-24">
        {children}
      </main>
    </>
  )
}

function Router() {
  const currentUserHelper = CurrentUserHelper()

  return (
    <Switch>
      <PrivateRoute path="/quotes">
        <PrivatePage>
          <QuotesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/margins">
        <PrivatePage>
          <MarginsIndex />
        </PrivatePage>
      </PrivateRoute>
      {currentUserHelper.hasAllGroups(['Account Manager']) && (
        <PrivateRoute path="/settings/users">
          <PrivatePage>
            <Users />
          </PrivatePage>
        </PrivateRoute>
      )}
      {currentUserHelper.hasAllGroups(['Account Manager']) && (
        <PrivateRoute path="/settings/shippers">
          <PrivatePage>
            <Shippers />
          </PrivatePage>
        </PrivateRoute>
      )}
      <Route exact path="/login">
        <PublicPage>
          <Login />
        </PublicPage>
      </Route>
      <Route exact path="/forgot-password">
        <PublicPage>
          <ForgotPassword />
        </PublicPage>
      </Route>
      <Route exact path="/password-reset">
        <PublicPage>
          <ResetPassword />
        </PublicPage>
      </Route>
      <Route exact path="/password-set">
        <PublicPage>
          <ResetPassword isNewUser />
        </PublicPage>
      </Route>
      {currentUserHelper.hasAllGroups(['Account Manager', 'White Label Manager']) && (
        <Route exact path="/white-label-settings">
          <PrivatePage>
            <WhiteLabelConfiguration />
          </PrivatePage>
        </Route>
      )}
      <Redirect exact from="/" to="/quotes" />
      <Route path="*">
        <PublicPage>
          <NotFound />
        </PublicPage>
      </Route>
    </Switch>
  )
}

export default Router
