import React from 'react'
import { XAxis } from './index'

export interface RenderXAxisProps {
  dataKey: string
}

const RenderXAxis = ({ dataKey }: RenderXAxisProps) => {
  return <XAxis dataKey={dataKey} padding={{ left: 20, right: 20 }} fontSize="12px" />
}

export default RenderXAxis
