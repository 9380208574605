import React from 'react'
import { Link as ReactLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { login } from 'features/auth/auth.actions'
import { useQueryParams } from 'hooks/useQueryParams'
import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import TextField from 'components/TextField'
import Link from 'components/Link'
import { AuthLayout } from 'app/AuthLayout'

function Login() {
  const queryParams = useQueryParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  return (
    <AuthLayout>
      <h1 className="px-8 mb-2 text-2xl font-bold text-neutral-darker">Welcome,</h1>
      <p className="px-8 text-sm">Enter with your credentials to login.</p>
      <form
        className="flex flex-col px-8 my-12 space-y-6"
        onSubmit={(event) => {
          event.preventDefault()

          dispatch(
            login(username, password, () => {
              const next = queryParams.get('next') || '/'
              history.push(next)
            }),
          )
        }}
      >
        <Field>
          <Label>Username</Label>
          <TextField
            name="username"
            id="username"
            data-testid="username"
            className="h-8"
            placeholder="Username"
            value={username}
            autoComplete="off"
            required
            onChange={(e) => {
              setUsername(e.target.value)
            }}
            autoFocus
          />
        </Field>
        <Field>
          <Label>Password</Label>
          <TextField
            name="password"
            id="password"
            data-testid="password"
            placeholder="*********"
            type="password"
            className="h-8"
            value={password}
            autoComplete="off"
            required
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </Field>
        <Button type="submit" variant="primary" data-testid="submit">
          Login
        </Button>
        <ReactLink
          to="/forgot-password"
          className="underline text-dark font-bold text-sm text-center"
        >
          Forgot your password?
        </ReactLink>
        <Link
          href="https://rate-guide.loadsmart.com/assets/rate-guide-terms-of-service.pdf"
          download
        >
          Terms of Service
        </Link>
      </form>
    </AuthLayout>
  )
}

export default Login
