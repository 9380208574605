import rem from '../utils/rem'

const COLORS = {
  'color-primary': '#33CC60',
  'color-accent': '#0CA933',

  'color-neutral-darkest': '#0A0A0B',
  'color-neutral-darker': '#313336',
  'color-neutral-dark': '#575C61',
  'color-neutral': '#929AA1',
  'color-neutral-light': '#CACCCF',
  'color-neutral-lighter': '#E9EBEC',
  'color-neutral-lightest': '#FCFCFC',
  'color-neutral-white': '#FFFFFF',

  'color-success-dark': '#2A5C85',
  'color-success': '#55B0FB',
  'color-success-light': '#C0E1F5',

  'color-transparent': 'transparent',

  'color-warning-dark': '#955800',
  'color-warning': '#FFCE4F',
  'color-warning-light': '#FFF5D6',

  'color-danger-dark': '#8F002B',
  'color-danger': '#E80045',
  'color-danger-light': '#FFD6E2',
}

const OPACITIES = {
  'opacity-0': 0,
  'opacity-20': 0.2,
  'opacity-40': 0.4,
  'opacity-60': 0.6,
  'opacity-100': 1,
}

const SPACINGS = {
  'space-none': 0,
  'space-2xs': rem('2px'),
  'space-xs': rem('4px'),
  'space-s': rem('8px'),
  'space-m': rem('16px'),
  'space-l': rem('24px'),
  'space-xl': rem('32px'),
  'space-2xl': rem('40px'),
  'space-3xl': rem('48px'),
}

const FONT_FAMILIES = {
  'font-family-default': "'Sharp Sans', Manrope, sans-serif",
  'font-family-monospace': 'Roboto Mono, monospace',
}

const FONT_SIZES = {
  'font-size-6': rem('10px'),
  'font-size-5': rem('12px'),
  'font-size-4': rem('14px'),
  'font-size-3': rem('16px'),
  'font-size-2': rem('20px'),
  'font-size-1': rem('26px'),
}

const FONT_WEIGHTS = {
  'font-weight-regular': 400,
  'font-weight-medium': 500,
  'font-weight-bold': 700,
}

const FONT_HEIGHTS = {
  'font-height-1': '100%',
  'font-height-2': '125%',
  'font-height-3': '140%',
}

const SHADOWS = {
  'shadow-none': 'none',
  'shadow-modal': '0px 2px 6px 0px rgba(10, 10, 11, 0.4)',
  'shadow-droplist': '0px 2px 3px 0px rgba(10, 10, 11, 0.4)',
  'shadow-glow-primary': '0px 0px 4px 0px rgba(51, 204, 96, 1)',
  'shadow-glow-warning': '0px 0px 4px 0px rgba(255, 206, 79, 1.0)',
}

const BORDER_RADIUSES = {
  'border-radius-none': 0,
  'border-radius-s': rem('4px'),
  'border-radius-m': rem('8px'),
  'border-radius-l': rem('16px'),
  'border-radius-circle': '50%',
}

const BORDER_WIDTHS = {
  'border-width-none': 0,
  'border-width-thin': '1px',
  'border-width-medium': '2px',
  'border-width-bold': '4px',
}

const Z_INDEXES = {
  'z-index-none': 0,
  'z-index-default': 1,
  'z-index-droplist': 10,
  'z-index-tooltip': 15,
  'z-index-overlay': 20,
  'z-index-modal': 30,
}

const defaultTheme = {
  COLORS,
  OPACITIES,
  SPACINGS,
  FONT_FAMILIES,
  FONT_SIZES,
  FONT_WEIGHTS,
  FONT_HEIGHTS,
  SHADOWS,
  BORDER_RADIUSES,
  BORDER_WIDTHS,
  Z_INDEXES,
}

export default defaultTheme
