import { useReducer, useCallback, useEffect, useRef, Reducer } from 'react'
import { isNil } from '@loadsmart/utils-object'
import set from '../../common/helpers/set'
import get from '../../common/helpers/get'
import pull from '../../common/helpers/pull'
import getID from '../../common/helpers/getID'
import isEmpty from '../../common/helpers/isEmpty'
import toArray from '../../common/helpers/toArray'

/**
 * Used to customize how an item is added,updated, or removed from a collection.
 */
export interface CollectionCustomizer<T> {
  /**
   * You get and array of item IDs and the id of the item being added.
   */
  order?: (order: Array<string>, itemID: string) => Array<string>
  enhancer?: (item: T, index: number) => T
}

/**
 * Used to customize how an item is added,updated, or removed from a collection.
 */
export interface CollectionItemAdapter {
  /**
   * Attribute to path to attribute to be used as ID.
   */
  accessor: string
}

export type State<T> = {
  order: Array<string>
  itemsByID: Record<string, T>
}

export type Action<T> =
  | { type: 'add'; payload: T }
  | { type: 'remove'; payload: { id: string } }
  | { type: 'update'; payload: T }
  | { type: 'reset'; payload: State<T> }

export type CollectionReducer<T> = Reducer<State<T>, Action<T>>

function createCollectionCustomizer<T>(
  customizer?: Partial<CollectionCustomizer<T>>,
): CollectionCustomizer<T> {
  const DEFAULT_ORDER = (order: Array<string>, itemID: string) => [...toArray(order), itemID]
  const DEFAULT_ENHANCER = (item: T) => item

  const order = customizer?.order ?? DEFAULT_ORDER
  const enhancer = customizer?.enhancer ?? DEFAULT_ENHANCER

  return {
    order,
    enhancer,
  }
}

export const DefaultCollectionItemAdapter: CollectionItemAdapter = {
  accessor: 'id',
}

export function addItem<T>(
  state: State<T>,
  item: T,
  adapter = DefaultCollectionItemAdapter,
  customizer = createCollectionCustomizer<T>(),
): State<T> {
  const itemID = get(item, adapter.accessor) || getID()

  const order = customizer.order?.(state.order, itemID) || state.order
  const index = order.indexOf(itemID) // TODO: throw error if not found

  const newItem = customizer.enhancer?.({ ...item }, index) || (item as Object)
  set(newItem, adapter.accessor, itemID)

  return {
    order,
    itemsByID: {
      ...state.itemsByID,
      [itemID]: newItem as T,
    },
  }
}

export function removeItem<T>(state: State<T>, itemID: string): State<T> {
  const { order, itemsByID } = state

  const { [itemID]: _, ...otherItems } = itemsByID

  return {
    order: pull(order, itemID),
    itemsByID: otherItems,
  }
}

export function updateItem<T>(state: State<T>, item: T, adapter: CollectionItemAdapter): State<T> {
  const itemID = get(item, adapter.accessor)
  const { itemsByID } = state

  if (isNil(itemID) || isNil(itemsByID[itemID])) {
    return state
  }

  return {
    ...state,
    itemsByID: {
      ...itemsByID,
      [itemID]: item,
    },
  }
}

export function reset<T>(state: State<T>, customizer = createCollectionCustomizer<T>()): State<T> {
  const itemsByID = Object.keys(state.itemsByID || {}).reduce((map, itemID, index) => {
    const item = state.itemsByID[itemID]
    const enhancedItem = customizer.enhancer?.({ ...item }, index) || (item as Object)

    return {
      ...map,
      [itemID]: enhancedItem,
    }
  }, {})

  return {
    order: state.order || [],
    itemsByID,
  }
}

function createReducer<T>(adapter: CollectionItemAdapter, customizer: CollectionCustomizer<T>) {
  return function reducer(state: State<T>, action: Action<T>) {
    switch (action.type) {
      case 'add':
        return addItem(state, action.payload, adapter, customizer)

      case 'remove':
        const { id } = action.payload
        return removeItem(state, id)

      case 'update':
        return updateItem(state, action.payload, adapter)

      case 'reset':
        return reset(action.payload, customizer)

      default:
        return state
    }
  }
}

export function createInit<T>(adapter: CollectionItemAdapter) {
  const DEFAULT_EMPTY = [] as Array<T>
  const DefaultCollectionCustomizer = createCollectionCustomizer<T>()

  return function init(items: Array<T>): State<T> {
    const safeItems: Array<T> = isEmpty(items) ? DEFAULT_EMPTY : items
    let state: State<T> = {
      order: [] as Array<string>,
      itemsByID: {} as Record<string, T>,
    }

    for (let i = 0; i < safeItems.length; i++) {
      const item = safeItems[i]

      // Here we use the default customizer as the given items are supposed to be in the corret order already.
      state = addItem(state, item, adapter, DefaultCollectionCustomizer)
    }

    return state
  }
}

function getSortedItems<T>(state: State<T>): Array<T> {
  return state.order.map((itemID: string) => state.itemsByID[itemID])
}

export interface useCollectionProps<T> {
  items?: Array<T>
  onChange?: (items: Array<T>) => void
  options?: {
    customizer?: CollectionCustomizer<T>
    adapter?: CollectionItemAdapter
  }
}

/**
 * Custom hook to manage any collection of items.
 * It exposes the following:
 *  `items`: array with the current items
 *  `addItem`: function to add an item
 *  `removeItem`: function to remove an item
 *  `updateItem`: function to update an item
 *
 * If you need to customize how your collection is managed, you can provide a customizer.
 * Currently we support the following customization:
 *  `order`:
 * @param {Array<T>} items  - initial array of items
 * @param {[CollectionCustomizer]}customizer - function to customize collection management.
 */
function useCollection<T>({ items, onChange, options }: useCollectionProps<T>) {
  const optionsRef = useRef({
    customizer: createCollectionCustomizer(options?.customizer),
    adapter: options?.adapter || DefaultCollectionItemAdapter,
  })
  const initializerRef = useRef(createInit<T>(getAdapter()))

  // lastItemsRef and lastOperationRef help us with preventing infinite loop
  const lastItemsRef = useRef(items)
  const lastOperationRef = useRef<string[]>([])

  const [state, dispatch] = useReducer<CollectionReducer<T>, T[]>(
    createReducer(getAdapter(), getCustomizer()),
    items as Array<T>,
    initializerRef.current,
  )

  useEffect(
    function publishChange() {
      const operation = lastOperationRef.current.shift()

      // we won't trigger changes on reset operation
      if (operation && operation !== 'reset') {
        onChange?.(getSortedItems<T>(state as State<T>))
      }
    },
    [onChange, state],
  )

  function getAdapter() {
    return optionsRef.current.adapter
  }

  function getCustomizer() {
    return optionsRef.current.customizer
  }

  const addItemDispatcher = useCallback(function addItem(item: T) {
    lastOperationRef.current.push('add')

    dispatch({
      type: 'add',
      payload: item,
    })
  }, [])

  const removeItemDispatcher = useCallback(function removeItem(itemID: string) {
    lastOperationRef.current.push('remove')

    dispatch({
      type: 'remove',
      payload: { id: itemID },
    })
  }, [])

  const updateItemDispatcher = useCallback(function updateItem(item: T) {
    lastOperationRef.current.push('update')

    dispatch({
      type: 'update',
      payload: item,
    })
  }, [])

  const resetDispatcher = useCallback(function reset(itemsParam: Array<T>) {
    lastOperationRef.current.push('reset')

    dispatch({
      type: 'reset',
      payload: initializerRef.current(itemsParam),
    })
  }, [])

  useEffect(
    function updateCollection() {
      if (lastItemsRef.current !== items) {
        lastItemsRef.current = items

        resetDispatcher(items as Array<T>)
      }
    },
    [items, resetDispatcher],
  )

  return {
    // TODO: prevent this from happening at every render
    items: getSortedItems<T>(state as State<T>),
    addItem: addItemDispatcher,
    removeItem: removeItemDispatcher,
    updateItem: updateItemDispatcher,
    reset: resetDispatcher,
  }
}

export default useCollection
