export const PREVENT_REFRESH_QUOTE_ALERT_MODAL = 'preventRefreshQuoteAlertModal'


function RefreshQuoteAlertModalHelper() {
  return {
    persistPreventModal() {
      localStorage.setItem(PREVENT_REFRESH_QUOTE_ALERT_MODAL, 'true')
    },
    clearPreventModal() {
      localStorage.removeItem(PREVENT_REFRESH_QUOTE_ALERT_MODAL)
    },
    shouldOpenModal() {
      const prevent = localStorage.getItem(PREVENT_REFRESH_QUOTE_ALERT_MODAL) ?? 'false'

      return JSON.parse(prevent) === false
    },
  }
}

export default RefreshQuoteAlertModalHelper


