import { HTMLProps, ChangeEvent } from 'react'
import clsx from 'clsx'

import './ToggleSwitch.css'

export interface ToggleSwitchProps extends HTMLProps<HTMLLabelElement> {
  active?: boolean
  className?: string
  name: string
  onToggle?: (event: ChangeEvent<HTMLInputElement>) => void
  activeLabel?: string
  inactiveLabel?: string
  disabled?: boolean
}

function ToggleSwitch({
  active = false,
  disabled = false,
  activeLabel = 'On',
  inactiveLabel = 'Off',
  name,
  onToggle,
  className,
  ...others
}: ToggleSwitchProps) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onToggle?.(event)
  }

  return (
    <label
      {...others}
      htmlFor={name}
      title={active ? activeLabel : inactiveLabel}
      className={clsx(
        'toggle-switch flex items-center relative cursor-pointer',
        {
          'toggle-switch--active': active,
        },
        {
          'pointer-events-none opacity-40': disabled,
        },
        className,
      )}
    >
      <span
        className={clsx('toggle-switch__text flex', {
          'bg-primary-500': active,
          'bg-neutral-light': !active,
        })}
      >
        <span
          className={'flex items-center justify-center font-bold'}
          style={{
            opacity: active ? 1 : 0,
          }}
        >
          {activeLabel}
        </span>
        <span
          className={'flex items-center justify-center font-bold'}
          style={{
            opacity: active ? 0 : 1,
          }}
        >
          {inactiveLabel}
        </span>
      </span>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={active}
        onChange={handleChange}
        hidden
      />
    </label>
  )
}

export default ToggleSwitch
