import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { get, patch, post } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { WhiteLabelConfiguration } from 'common/types/WhiteLabelConfiguration'

export async function getConfiguration(
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<WhiteLabelConfiguration> | undefined]> {
  try {
    return await to(get('/api/white-label', config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function createConfiguration(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<WhiteLabelConfiguration> | undefined]> {
  try {
    return await to(post('/api/white-label', data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function updateConfiguration(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<WhiteLabelConfiguration> | undefined]> {
  try {
    return await to(patch(`/api/white-label`, data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}
