import { Dialog } from 'components/Dialog'
import * as yup from 'yup'
import { isValidPrice } from 'common/Form.helpers'
import { useState } from 'react'
import ErrorHelper, { ErrorHelperType } from 'common/Error.helpers'
import Field from 'components/Field'
import Label from 'components/Label'
import { TextField } from '@loadsmart/loadsmart-ui'
import TextArea from 'components/TextArea'
import EventLike from 'common/types/EventLike'
import Feedback from 'components/Feedback'
import Status from 'common/types/Status'
import PriceFeedbackSelector from 'components/PriceFeedbackSelector'
import { RateFeedback } from 'common/types/RateFeedback'

const schema = yup.object().shape({
  feedback: yup.string().nullable().oneOf(['low', 'high'], 'Please specify how the rate fails'),
  suggestion: yup
    .string()
    .test(
      'maxDigits',
      'Must have 6 digits or less before the decimal point and 2 digits or less after decimal point',
      (number) => {
        if (!number) return true
        return isValidPrice(number, false)
      },
    )
    .test('nonZero', "Can't be zero", (number) => {
      return !!number ? parseFloat(number) !== 0 : true
    }),
})

export interface FeedbackModalProps {
  open: boolean
  withForm: boolean
  onSubmit: (feedback: RateFeedback) => void
  onCancel: () => void
}

function FeedbackModal({ open, withForm, onSubmit, onCancel }: FeedbackModalProps) {
  const [errors, setErrors] = useState<ErrorHelperType>()
  const [feedbackOption, setFeedbackOption] = useState<null | 'low' | 'high'>(null)
  const [suggestion, setSuggestion] = useState<string>()
  const [comments, setComments] = useState<string>()

  const onFromSubmit = async () => {
    setErrors(undefined)
    try {
      schema.validateSync(
        { feedback: feedbackOption, suggestion, comments },
        {
          abortEarly: false,
        },
      )
      onSubmit({ feedback: feedbackOption, suggestion, comments })
    } catch (e: any) {
      setErrors(ErrorHelper(e))
    }
  }
  return (
    <Dialog open={open} scale="small">
      <Dialog.Header>Price feedback</Dialog.Header>
      <Dialog.Body>
        {withForm ? (
          <>
            <span>
              Help us improve the rate model and algorithm with your opinion, feedback can only be
              done once.
            </span>
            <Field className="mt-6">
              <Label required>How's the price?</Label>
              <PriceFeedbackSelector selected={feedbackOption} onSelect={setFeedbackOption} />
              {errors?.has('feedback') && (
                <Feedback status={Status.Danger}>{errors.get('feedback')}</Feedback>
              )}
            </Field>
            <Field className="mt-6">
              <Label htmlFor="suggestion">Rate suggestion</Label>
              <TextField
                type="number"
                id="suggestion"
                name="suggestion"
                leading={'$'}
                onChange={(e: EventLike<string>) => setSuggestion(e.target.value)}
                placeholder="Inform the rate"
              />
              {errors?.has('suggestion') && (
                <Feedback status={Status.Danger}>{errors.get('suggestion')}</Feedback>
              )}
            </Field>
            <Field className="mt-6">
              <Label htmlFor="comments">Comments</Label>
              <TextArea
                id="comments"
                name="comments"
                onChange={(e: EventLike<string>) => setComments(e.target.value)}
                placeholder="Tell us more about it (optional)"
              />
            </Field>
          </>
        ) : (
          <span>
            Help us improve the rate model and algorithm with your opinion, feedback can only be
            done once.
          </span>
        )}
      </Dialog.Body>
      <Dialog.ActionConfirm
        onConfirm={() => {
          if (withForm) {
            onFromSubmit()
          } else {
            onSubmit({ feedback: 'fair' })
          }
        }}
      >
        confirm feedback
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onCancel} />
    </Dialog>
  )
}

export default FeedbackModal
