import { useState } from 'react'

import getID from 'common/helpers/getID'

function useFingerprint<T>(adapter: (item: T) => string, items: T[]) {
  const [known, setKnow] = useState<Record<string, T | string>>({})

  /**
   * Get a fingerprint based on the assumption that regardless of the order of items, known will always
   * have them in the same order.
   * @param {string[]} items - array of items
   * @param {boolean} insert - insert item to `known` if not found
   * @returns {string}
   */
  function getFingerprint(items: T[], insert = false) {
    const safeItems = items || []
    let newKnown: Record<string, T | string> = {}

    for (let i = 0; i < safeItems.length; i++) {
      const item = safeItems[i]
      const key = adapter(item)

      newKnown = {
        ...newKnown,
        [key]: known[key] ?? getID(),
      }
    }

    if (insert) {
      setKnow(newKnown)
    }

    const fingerprint = Object.keys(newKnown).reduce((fingerprint, item) => {
      return `${fingerprint}${newKnown[item]}`
    }, '')

    return fingerprint
  }

  const [fingerprint, setFingerprint] = useState(() => getFingerprint(items, true))

  return {
    fingerprint,
    getFingerprint,
    resetFingerprint(items: T[]) {
      setFingerprint(getFingerprint(items, true))
    },
    /**
     * Check if the provided items have the same fingerprint.
     * @param {string[]} otherItems - items
     * @returns {boolean}
     */
    hasSameFingerprint(otherItems: T[]) {
      return fingerprint === getFingerprint(otherItems)
    },
  }
}

export default useFingerprint
