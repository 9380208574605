import { useState, useEffect, useCallback } from 'react'

import { toast } from '../../../components/Toast'
import { getReasons } from '../../../services/margins/margins.service'
import useCancelToken from '../../../hooks/useCancelToken'
import { MarginReason } from 'common/types/Margin'

function useFetchReasons() {
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const [reasons, setReasons] = useState<MarginReason[]>([])
  const [loading, setLoading] = useState(false)

  const fetchReasons = useCallback(() => {
    async function fetch() {
      cancelPending()

      const source = getSource()

      const [error, response] = await getReasons({
        cancelToken: source.token,
      })

      clearSource()
      setLoading(false)

      if (error) {
        toast.error('We could not retrieve the reasons.')
        setReasons([])
      } else if (response) {
        setReasons(response.data.results as any)
      }
    }

    setLoading(true)
    fetch()
  }, [cancelPending, clearSource, getSource])

  useEffect(() => {
    fetchReasons()

    return function cancelFetchMargins() {
      cancelPending()
    }
  }, [fetchReasons, cancelPending])

  return { reasons, loading }
}

export default useFetchReasons
