import { InputHTMLAttributes, ReactNode, useCallback, ChangeEvent } from 'react'
import clsx from 'clsx'

import { MaskedTextField, TextFieldProps } from '../TextField'
import { ReactComponent as Clock } from './clock-icon.svg'
import ColorScheme from '../../common/types/ColorScheme'
import getConditionalClasses from '../../common/helpers/getConditionalClasses'
import getValueFromEvent from '../../common/helpers/getValueFromEvent'
import Status from '../../common/types/Status'

export interface TimeFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  children?: ReactNode
  disabled?: boolean
  id?: string | undefined
  name: string
  value: string | undefined
  status?: Status
  scheme?: ColorScheme
}

const combine = getConditionalClasses({
  variant: ['primary', 'secondary', 'warning'],
  scheme: ['light', 'dark'],
  status: ['neutral', 'danger'],
})

const HH_MM_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
function TimeField(props: TimeFieldProps): JSX.Element {
  const { className, disabled = false, status, scheme, onChange, id, name, ...others } = props
  const when = combine(props)

  const handleChange = useCallback(
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      const time = getValueFromEvent(e) || ''
      const [hours, minutes] = HH_MM_REGEX.exec(String(time)) || []

      if (hours && minutes) {
        onChange?.(e)
      }
    },
    [onChange],
  )

  return (
    <div
      className={clsx('relative', {
        'text-neutral-lightest ': when('dark'),
        'text-neutral-darker': when('light'),
      })}
    >
      <MaskedTextField
        type="text"
        mask="dd:dd"
        id={id}
        name={name}
        {...others}
        onChange={handleChange}
        className={clsx('h-input w-24 pr-9', className)}
        disabled={disabled}
        scheme={scheme}
        status={status as TextFieldProps['status']}
        data-testid="input"
      />
      <Clock
        className={clsx(
          'absolute transition-colors fill-current right-2 top-1/2 transform -translate-y-1/2',
          {
            'opacity-40': disabled,
          },
        )}
      />
    </div>
  )
}

TimeField.defaultProps = {
  status: Status.Neutral,
  scheme: 'light',
}

export default TimeField
