import { PropsWithChildren } from 'react'
import { CellProps } from 'react-table'

import { QuoteResult } from '../../../common/types/Quote'
import { TimeWindow } from '../../../common/types/Time'
import DateHelper from '../../../common/Date.helpers'
import Pill from '../../../components/Pill'
import Status from '../../../common/types/Status'

import './StopRenderer.css'

function isDayPlusOne(dateWindowStart: string, dateWindowEnd: string) {
  return dateWindowStart !== dateWindowEnd
}

function StopRenderer({ city, stopWindow }: { city: string; stopWindow: TimeWindow }) {
  const [dateWindowStart, dateWindowEnd] = [stopWindow.start, stopWindow.end].map((date) => {
    return DateHelper(date)?.format('MM/DD/YYYY') || ''
  })
  const [timeWindowStart, timeWindowEnd] = [stopWindow.start, stopWindow.end].map((date) => {
    return DateHelper(date)?.format('hh:mmA') || ''
  })

  return (
    <div className="flex flex-col space-y-2 lg:space-y-0 lg:items-center lg:justify-between lg:flex-row">
      <span className="inline-block px-2 pt-1 truncate lg:pt-0 lg:px-0">{city}</span>
      {isDayPlusOne(dateWindowStart, dateWindowEnd) && (
        <Pill className="mt-0 mb-0 ml-auto mr-0" status={Status.Neutral}>
          +1
        </Pill>
      )}
      <div className="flex flex-col py-1 pl-2 pr-2 lg:pr-4 stop-window">
        <span className="text-sm font-semibold">{dateWindowStart}</span>
        <span className="text-xs">
          {timeWindowStart} - {timeWindowEnd}
        </span>
      </div>
    </div>
  )
}

export function PickupRenderer({ row }: PropsWithChildren<CellProps<{}, any>>) {

  const { original } = row

  const { pickup, pickup_window } = original as QuoteResult

  return <StopRenderer city={pickup} stopWindow={pickup_window} />
}

export function DeliveryRenderer({ row }: PropsWithChildren<CellProps<{}, any>>) {
  const { original } = row
  const { delivery, delivery_window } = original as QuoteResult

  return <StopRenderer city={delivery} stopWindow={delivery_window} />
}

export default StopRenderer
