import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import { createHTTPClient } from 'common/HTTPClient'
import tokenManager from 'common/tokenManager'


const CancelToken = axios.CancelToken
export const httpClient = createHTTPClient()

export function getCancelTokenSource(): CancelTokenSource {
  return CancelToken.source()
}

export function getWhiteLabelHeaders(overriddes?: {
  [key: string]: string
}): { [key: string]: string | undefined } {
  const token = tokenManager.getWhiteLabelToken()

  return {
    'Content-Type': 'application/json',
    Authorization: token ? `WLT ${token}` : undefined,
    ...overriddes,
  }
}

function enhanceConfig(baseConfig: AxiosRequestConfig) {
  const defaultHeaders = getWhiteLabelHeaders()
  const { headers } = baseConfig

  return {
    ...baseConfig,
    headers: {
      ...headers,
      ...defaultHeaders,
    },
  }
}

export function get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.get(url, enhancedConfig)
}

export function post(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.post(url, data, enhancedConfig)
}

export function put(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.put(url, data, enhancedConfig)
}

export function patch(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.patch(url, data, enhancedConfig)
}

export function del(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.delete(url, enhancedConfig)
}
