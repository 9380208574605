import { useCallback, useState } from 'react'

import { Quoteline } from 'common/types/Quote'
import { toast } from 'components/Toast'
import useCollection, { CollectionItemAdapter } from 'hooks/useCollection'
import { addQuoteline, removeQuoteline } from 'services/quotes/quotes.service'

const adapter: CollectionItemAdapter = {
  accessor: 'quoteline_id',
}

export interface useQuotelinesProps {
  quoteID?: string
  quotelines?: Array<Quoteline>
  onChange?: (items: Array<Quoteline>) => void
}

// TODO: enhance with axios cancelable capabilities
async function performAddQuoteline(quoteID: string, quoteline: Quoteline) {
  return addQuoteline(quoteID, { quoteline })
}

async function performRemoveQuoteline(quoteID: string, quotelineID: string) {
  return removeQuoteline(quoteID, quotelineID)
}

function useQuotelines({ quoteID, quotelines, onChange }: useQuotelinesProps) {
  const { items, addItem, removeItem, reset } = useCollection<Quoteline>({
    items: quotelines,
    onChange,
    options: {
      adapter,
    },
  })
  const [isBusy, setBusy] = useState(false)

  const add = useCallback(
    function add(quoteline: Quoteline) {
      async function performAdd() {
        setBusy(true)

        if (quoteID) {
          const [error, response] = await performAddQuoteline(quoteID, quoteline)

          if (error) {
            toast.error('We could not save this adjustment.')
            console.error('save quoteline', error)
          } else if (response) {
            addItem(response.data as Quoteline)
          }
        }

        setBusy(false)
      }

      performAdd()
    },
    [addItem, quoteID],
  )

  const remove = useCallback(
    function remove(quotelineID: string) {
      async function performRemove() {
        setBusy(true)

        if (quoteID) {
          const [error, response] = await performRemoveQuoteline(quoteID, quotelineID)

          if (error) {
            toast.error('We could not remove this adjustment.')
            console.error('remove quoteline', error)
          } else if (response) {
            removeItem(quotelineID)
          }
        }

        setBusy(false)
      }

      performRemove()
    },
    [quoteID, removeItem],
  )

  const update = useCallback(
    function update(quoteline: Quoteline) {
      async function performUpdate() {
        setBusy(true)

        if (quoteID) {
          let [error, response] = await performRemoveQuoteline(
            quoteID,
            String(quoteline.quoteline_id),
          )

          if (error) {
            toast.error('We could not update this adjustment.')
            console.error('update quoteline - remove', error)
            return
          }

          ;[error, response] = await performAddQuoteline(quoteID, quoteline)

          if (error) {
            toast.error('We could not update this adjustment.')
            console.error('update quoteline - (re)add', error)
            return
          }

          removeItem(String(quoteline.quoteline_id))
          addItem(response?.data as Quoteline)
        }

        setBusy(false)
      }

      performUpdate()
    },
    [addItem, quoteID, removeItem],
  )

  return {
    isBusy,
    quotelines: items,
    addQuoteline: add,
    removeQuoteline: remove,
    updateQuoteline: update,
    reset,
  }
}

export default useQuotelines
