import { isBlank } from '@loadsmart/utils-string'
import { Stop, StopType } from 'common/types/Stop'
import DateHelper from 'common/Date.helpers'
import { PU_LEAD_TIME, PU_TODAY_HOUR_LIMIT } from './Stop.constants'
import omit from 'common/helpers/omit'

export function isPU(stop: Stop): boolean {
  return stop.type === 'pu'
}

export function isDEL(stop: Stop): boolean {
  return stop.type === 'del'
}

export function getLabel(stop: Stop, label: string) {
  const { intermediary } = stop || {}
  if (intermediary && !isBlank(label)) {
    return label
  }

  if (isPU(stop)) {
    return 'Pickup'
  }

  return 'Delivery'
}

/**
 * Get `stop.type` or return `pu` for index `0`, `del` otherwise
 * @param {Stop} stop - Stop to be evaluated
 * @param {number} index - Index to be considered if no `type` is found.
 */
export function getType(stop: Stop, index: number): StopType {
  if (!stop.type) {
    return index === 0 ? 'pu' : 'del'
  }

  return stop.type
}

/**
 * Clears dates from a stop list based on a index
 */
export function clearNextStopDate(stops: Stop[], index: number) {
  stops[index + 1] = omit(stops[index + 1], 'window')
}

/**
 * Returns the minimal stop date for a stop based on the previous stop date
 */
export function getMinStopDate(stops: Stop[], index: number) {
  if (index === 0) {
    return null
  }
  const prevStop = stops[index - 1]
  return prevStop.window?.end || null
}

/**
 * Given a stop list and a stop index, returns if next stop date is invalid
 */
export function isNextStopDateInvalid(stops: Stop[], index: number) {
  const nextStop = stops[index + 1]

  if (!nextStop) return false

  const currentStop = stops[index]
  const currDate = DateHelper(currentStop.window?.end)
  const nextDate = DateHelper(nextStop.window?.start)

  return !currDate || nextDate?.is.before(currDate)
}

export function isDayBlocked(day: string) {
  const now = DateHelper.now()
  const isPastDay = DateHelper(day)?.is.before(now, 'day')

  return Boolean(
    isPastDay || (DateHelper(day)?.is.today() && Number(now?.get('hours')) >= PU_TODAY_HOUR_LIMIT),
  )
}

export function getLastAvailableDay() {
  const now = DateHelper.now()

  if (Number(now?.get('hours')) < PU_TODAY_HOUR_LIMIT) {
    return now?.subtract(1, 'day')
  }

  return now
}

export function getMinValidDate() {
  const now = DateHelper.now()
  if (Number(now?.get('hours')) >= PU_TODAY_HOUR_LIMIT) {
    return now?.add(1, 'day').set({ hours: PU_LEAD_TIME, minutes: 0 })
  }
  return now?.add(3, 'hours')
}

/**
 * Normalizes the date based on the PU lead time
 * @param {DateHelper} date - Date to be normalized
 */
export function normalizeDate(date: ReturnType<typeof DateHelper>) {
  const minValidDate = getMinValidDate()
  return date?.is.sameOrAfter(minValidDate, 'minutes')
    ? { date, fixed: false }
    : { date: minValidDate, fixed: true }
}

export function getParsedAddress(stop: Stop) {
  return [stop.city, stop.state, stop.zipcode].filter(Boolean).join(', ')
}
