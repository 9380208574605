import { Fragment, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import type { PartialQuotesFilter, QuotesFilterFields } from './QuotesList/QuotesList.types'
import Button from 'components/Button'
import Icon from 'components/Icon'
import isEmpty from 'common/helpers/isEmpty'
import QuotesFilter from './QuotesFilter'
import QuotesList from './QuotesList'
import QuoteAnalytics from './QuoteAnalytics'
import { RGTab } from 'components/Tab'
import analytics from 'common/analytics'
import isEqual from 'common/helpers/isEqual'
import { defaultFilters } from './QuotesFilter/QuotesFilter.helpers'
import Text from 'components/Text'

function QuotesDashboard() {
  const history = useHistory()
  const [filters, setFilters] = useState<PartialQuotesFilter>()

  const handleFilterChange = useCallback(function handleFilterChange(fields: PartialQuotesFilter) {
    if (!isEmpty(fields) && !isEqual(fields, defaultFilters)) {
      analytics.track('Filter Quotes', fields)
    }
    setFilters(isEmpty(fields) ? undefined : fields)
  }, [])

  return (
    <Fragment>
      <div className="flex flex-row w-full">
        <div>
          <Text as="h2" variant="heading-lg" className="mb-2">
            Quotes Dashboard
          </Text>
          <Text color="color-neutral-darker">
            Manage and follow your quotes, choose the view mode and start working on them.
          </Text>
        </div>
        <Button
          className="mx-0 ml-auto mr-0 my-auto"
          leading={<Icon name="add" title="Add quote" size={16} />}
          variant="primary"
          onClick={() => {
            history.push('/quotes/new/')
          }}
        >
          New Quote
        </Button>
      </div>

      <QuotesFilter onChange={handleFilterChange} />

      <RGTab variant="secondary" className="flex flex-col w-full my-10">
        <RGTab.Items>
          <RGTab.Item name="tab-1" isDefault>
            Analytics
          </RGTab.Item>
          <RGTab.Item name="tab-2">Quote list</RGTab.Item>
        </RGTab.Items>
        <RGTab.Panels>
          <RGTab.Panel name="tab-1">
            <QuoteAnalytics className="w-full" filters={filters as QuotesFilterFields} />
          </RGTab.Panel>
          <RGTab.Panel name="tab-2" className="p-2">
            <QuotesList className="w-full mt-4" filters={filters} />
          </RGTab.Panel>
        </RGTab.Panels>
      </RGTab>
    </Fragment>
  )
}

export default QuotesDashboard
