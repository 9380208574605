import { Bar } from './index'
import React from 'react'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

export interface RenderStackedBarProps {
  items: { dataKey: string; color: keyof RateGuideThemeInterface }[]
  stackId?: string
  isAnimationActive?: boolean
}

const RenderStackedBar = ({
  items = [],
  stackId = 'a',
  isAnimationActive = false,
}: RenderStackedBarProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  return (
    <>
      {items.map((item) => (
        <Bar
          key={item.dataKey}
          barSize={40}
          dataKey={item.dataKey}
          stackId={stackId}
          fill={String(theme[item.color])}
          isAnimationActive={isAnimationActive}
        />
      ))}
    </>
  )
}

export default RenderStackedBar
