import { Fragment, useCallback } from 'react'
import clsx from 'clsx'

import Button from 'components/Button'
import Icon from 'components/Icon'
import Commodity, { CommodityProps } from './Commodity'
import isEmpty from 'common/helpers/isEmpty'
import useCommodities from './useCommodities'
import EventLike from 'common/types/EventLike'

export interface CommoditiesProps {
  id?: string | undefined
  name?: string | undefined
  className?: string
  commodities?: Array<CommodityProps>
  onChange?: (event: EventLike<Array<CommodityProps>>) => void
}

function Commodities({
  id,
  name,
  className,
  commodities: initialCommodities,
  onChange,
}: CommoditiesProps) {
  const handleChange = useCallback(
    function handleChange(items: CommodityProps[]) {
      onChange?.({ target: { id, name, value: items } })
    },
    [id, name, onChange],
  )

  const { commodities, addCommodity, removeCommodity, updateCommodity } = useCommodities({
    commodities: initialCommodities,
    onChange: handleChange,
  })

  return (
    <Fragment>
      {!isEmpty(commodities) && (
        <div data-testid="commodities" className={clsx('flex flex-col space-y-4', className)}>
          {commodities.map(function renderCommodity(commodity: CommodityProps) {
            return (
              <Commodity
                key={commodity.id}
                commodity={commodity}
                onChange={updateCommodity}
                onRemove={removeCommodity}
              />
            )
          })}
        </div>
      )}
      <Button
        className="mt-6"
        leading={<Icon name="add" title="Add commodity" size={16} />}
        onClick={() => addCommodity()}
      >
        Add Items
      </Button>
    </Fragment>
  )
}

export default Commodities
