import { useEffect, useState } from 'react'
import { GroupedAccessorials } from 'common/types/Accessorial'
import { getAccessorials } from 'services/accessorials/accessorials.service'
import { getAccessorials as getAccessorialsWhiteLabel } from 'pages/WhiteLabel/services/whiteLabel.service'

interface useAccessorialsProps {
  isWhiteLabel?: boolean
}

const useAccessorials = ({ isWhiteLabel }: useAccessorialsProps) => {
  const [accessorials, setAccessorials] = useState<GroupedAccessorials>({
    pu: [],
    del: [],
  })

  useEffect(() => {
    async function fetchAccessorials() {
      const action = isWhiteLabel ? getAccessorialsWhiteLabel : getAccessorials
      const [puResult, delResult] = await Promise.all([action('pu'), action('del')])
      const [puError, puResponse] = puResult
      const [delError, delResponse] = delResult
      if (!puError && puResponse && !delError && delResponse) {
        setAccessorials({ pu: puResponse.data.data, del: delResponse.data.data })
      }
    }
    fetchAccessorials()
  }, [isWhiteLabel])

  return { accessorials }
}

export default useAccessorials
