import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'
import EquipmentType from '../../../Quotes/QuoteForm/EquipmentType'
import Text from 'components/Text'
import { StopsTimeline } from '../../../Quotes/QuoteForm/Stops'
import { FTLCommoditiesTable } from 'pages/Quotes/components/Commodities'
import Button from 'components/Button'
import QuoteHelper from 'common/Quote.helpers'
import Pill from 'components/Pill'
import Section from 'components/Section'
import Collapsible from 'components/Collapsible'
import Paper from '../Paper'
import CurrencyHelper from 'common/Currency.helpers'
import { Quote } from 'common/types/Quote'
import { useDialog } from 'components/Dialog'
import ContactFormModal from '../ContactInformation/ContactFormModal'

interface QuoteDetailsProps {
  onPrevious: () => void
  onNext: (email: string) => void
  quote: Quote
  onCancel: () => void
}

const Bar = styled.span`
  border-left: 1px solid;
  height: 1rem;
  margin-right: 4px;
`

function QuoteDetails({ onNext, quote, onCancel }: QuoteDetailsProps) {
  const contactFormDialogHandler = useDialog({
    open: false,
  })

  const openContactForm = () => {
    contactFormDialogHandler.show()
  }

  return (
    <>
      <ContactFormModal
        onCancel={() => contactFormDialogHandler.hide()}
        onSubmit={onNext}
        open={contactFormDialogHandler.open}
        quoteId={quote.quote_id!}
      />
      <Paper className="relative">
        <div className="p-8 bg-neutral-lightest">
          <Text as="h1" variant="heading-lg">
            Here is your quote result
          </Text>
          <Text as="p" variant="body" className="mt-4">
            Take a look at the price below. If you want to proceed with this quote, click on the
            button below to start negotiating.
          </Text>
          <Section title="Quote Details" className="mt-8">
            <div className="flex flex-col mt-2 rounded-sm shadow xl:flex-row border-neutral-light w-full">
              <div className="flex flex-col justify-between p-6 space-y-6 xl:w-1/3">
                <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-4">
                  <div className="flex flex-col flex-1">
                    <Label as="div" className="mb-1">
                      Equipment type
                    </Label>
                    <EquipmentType name={quote.equipment_type} mode="full" />
                  </div>
                  <div className="flex flex-col flex-1">
                    <Label as="div" className="mb-1">
                      Customer reference
                    </Label>
                    {quote.customer_reference ? (
                      <b className="text-base font-bold">{quote.customer_reference}</b>
                    ) : (
                      <span className="text-neutral">n/a</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-4">
                  <div className="flex flex-col flex-1">
                    <Label as="div" className="mb-1">
                      Drop at the delivery?
                    </Label>
                    <Pill className="self-start">
                      {QuoteHelper(quote).has('drop_trailer') ? 'yes' : 'no'}
                    </Pill>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Label as="div" className="mb-1">
                      Hazard material?
                    </Label>
                    <Pill className="self-start">
                      {QuoteHelper(quote).has('hazmat') ? 'yes' : 'no'}
                    </Pill>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-8 py-6 xl:w-2/3 bg-neutral-lighter">
                <StopsTimeline mileage={quote.mileage} stops={quote.stops} />
              </div>
            </div>
            <div className="mt-4">
              <Collapsible
                title="Commodity and items details"
                iconName="dollar"
                className="px-6 py-1"
              >
                <div className="mt-2">
                  <FTLCommoditiesTable commodities={quote.items} />
                </div>
              </Collapsible>
            </div>

            <div className="w-full mt-6 bg-neutral-dark rounded flex text-neutral-lightest px-2">
              <span className="ml-1.5 mt-4 mb-4 text-sm inline-flex items-center font-medium">
                Total Distance <span className="font-bold pl-2">{Number(quote.mileage)} miles</span>
              </span>
              <span className="ml-auto mr-1.5 mt-4 mb-4 text-xl font-bold">
                {CurrencyHelper(Number(quote.price)).format()}
              </span>
              <span className="inline-flex items-center">
                <Bar />
              </span>
              <span className="mr-2 mt-4 mb-4 text-xs inline-flex items-center">
                {CurrencyHelper(Number(quote.price) / Number(quote.mileage)).format()} per mile
              </span>
            </div>
          </Section>
        </div>
      </Paper>
      <div className="flex flex-col mt-6 space-y-4 lg:flex-row lg:items-center lg:justify-start lg:space-y-0">
        <div className="flex justify-between lg:justify-start">
          <Button type="reset" variant="secondary" onClick={onCancel}>
            Quote a new lane
          </Button>
        </div>
        <Button type="button" variant="primary" className="lg:ml-auto" onClick={openContactForm}>
          I'm interested
        </Button>
      </div>
    </>
  )
}

export default QuoteDetails
