import { Quote } from 'common/types/Quote'
import LTLRateList from './LTLRateList'
import QuoteHelper from 'common/Quote.helpers'

export interface LTLRateSelectionProps {
  quote: Quote
  showConfirmation?: boolean
  disabled?: boolean
  setQuote: (quote: Quote) => void
  onRateSelection: (selectedRate: string) => Promise<boolean>
}

function LTLRateSelection({
  quote,
  showConfirmation,
  disabled,
  onRateSelection,
}: LTLRateSelectionProps) {
  return (
    <>
      {quote.selected_rate && (
        <LTLRateList
          rates={quote.rates}
          selectedRate={quote.selected_rate}
          defaultDeliveryDate={QuoteHelper(quote).delivery?.window?.start}
          pickupDate={QuoteHelper(quote).pickup?.window?.start}
          onSelect={onRateSelection}
          showConfirmation={showConfirmation}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default LTLRateSelection
