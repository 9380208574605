import { LoadType } from './types/LoadType'

interface LoadTypeData {
  label: string
  value: LoadType
  abbr: string
}

const PTL: LoadTypeData = {
  label: 'PTL',
  value: 'PTL',
  abbr: 'PTL',
}

const FTL: LoadTypeData = {
  label: 'FTL',
  value: 'FTL',
  abbr: 'FTL',
}

const LTL: LoadTypeData = {
  label: 'LTL',
  value: 'LTL',
  abbr: 'LTL',
}

const RAIL: LoadTypeData = {
  label: 'Rail',
  value: 'RAIL',
  abbr: 'RAIL',
}

const DRAYAGE: LoadTypeData = {
  label: 'Drayage',
  value: 'DRAYAGE',
  abbr: 'DRAYAGE',
}

const LOAD_TYPE_BY_NAME: Record<LoadType, LoadTypeData> = {
  PTL,
  ptl: PTL,
  FTL,
  ftl: FTL,
  LTL,
  ltl: LTL,
  RAIL,
  rail: RAIL,
  DRAYAGE,
  drayage: DRAYAGE,
}

const ALL_LOAD_TYPES = [FTL]

function getAllTypes(getCustomProps?: (type: LoadType) => Record<string, any>) {
  if (!getCustomProps) {
    return ALL_LOAD_TYPES
  }

  return ALL_LOAD_TYPES.map((type) => ({
    ...type,
    ...getCustomProps(type.value),
  }))
}

function getByName(name: LoadType, getCustomProps?: (type: LoadType) => Record<string, any>) {
  if (!getCustomProps) {
    return LOAD_TYPE_BY_NAME[name]
  }

  return {
    ...LOAD_TYPE_BY_NAME[name],
    ...getCustomProps(name),
  }
}

function LoadTypeHelper(type: LoadType) {
  const data: LoadTypeData = getByName(type) || {}

  return {
    label() {
      return data.label
    },
    value() {
      return data.value
    },
    abbr() {
      return data.abbr
    },
    is(other: LoadType) {
      return getByName(type) === getByName(other)
    },
  }
}

LoadTypeHelper.getAll = getAllTypes
LoadTypeHelper.getByName = getByName

export default LoadTypeHelper
