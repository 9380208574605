// TODO: optimize cache to accept maximum number of entries.
function GeocodingCache() {
  let cache = {}

  return {
    add(key, geocoded) {
      if (key != null) {
        cache = {
          ...cache,
          [key]: geocoded,
        }
      }

      return this
    },
    get(key) {
      return cache[key] ?? null
    },
    has(key) {
      return cache[key] != null
    },
  }
}

export default GeocodingCache
