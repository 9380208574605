import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import getEnvSubdomain from 'common/helpers/getEnvSubdomain'

export const baseURL = `https://api.rate-guide.${getEnvSubdomain()}loadsmart.com/`
export const originURL = `https://rate-guide.${getEnvSubdomain()}loadsmart.com/`

function createHTTPClient(overrides?: AxiosRequestConfig): AxiosInstance {
  const headers: Record<string, string | undefined> = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  }

  const client = axios.create({
    headers,
    withCredentials: true,
    baseURL,
    ...overrides,
  })

  return client
}

export default createHTTPClient
