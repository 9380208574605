import type { QuotesFilterFields } from '../QuotesList/QuotesList.types'
import type { Shipper } from 'common/types/Shipper'
import DateHelper from 'common/Date.helpers'
import EventLike from 'common/types/EventLike'
import get from 'common/helpers/get'
import pick from 'common/helpers/pick'
import getValueFromEvent from 'common/helpers/getValueFromEvent'
import moment from 'moment'
import { API_DATE_FORMAT } from 'common/constants'

export function buildFilterFields(initialFields: QuotesFilterFields) {
  return {
    created_at: {
      value: initialFields.created_at,
      set(range: { start: string; end?: string }) {
        return range
      },
      get(range: QuotesFilterFields['created_at']) {
        return range
      },
    },
    shipper: {
      value: initialFields.shipper,
      set(e: Event) {
        const shippers = get(e, 'target.value', [])

        return shippers
      },
      get(options: QuotesFilterFields['shipper']) {
        return (options || []).map((option) => option.id)
      },
    },
    pickup: {
      value: initialFields.pickup,
      set(e: Event) {
        const pickup = get(e, 'target.value', [])

        return pickup
      },
      get(options: QuotesFilterFields['pickup']) {
        return (options || []).map(({ zipcode, ...option }: any) => ({
          ...pick(option, ['city', 'state', 'country']),
          ...(zipcode && { zipcode }),
        }))
      },
    },
    pickup_date: {
      value: DateHelper(initialFields.pickup_date)?.value() ?? null,
      set(e: EventLike<any>) {
        const value = getValueFromEvent(e)

        return value
      },
      clear() {
        return null
      },
      get(value: QuotesFilterFields['pickup_date']) {
        return value ? DateHelper(value.toString())?.format(API_DATE_FORMAT) : null
      },
    },
    delivery: {
      value: initialFields.delivery,
      set(e: Event) {
        const delivery = get(e, 'target.value', [])

        return delivery
      },
      get(options: QuotesFilterFields['delivery']) {
        return (options || []).map(({ zipcode, ...option }: any) => ({
          ...pick(option, ['city', 'state', 'country']),
          ...(zipcode && { zipcode }),
        }))
      },
    },
    delivery_date: {
      value: DateHelper(initialFields.delivery_date)?.value() ?? null,
      set(e: EventLike<unknown>) {
        const value = getValueFromEvent(e)

        return value
      },
      clear() {
        return null
      },
      get(value: QuotesFilterFields['delivery_date']) {
        return value ? DateHelper(value.toString())?.format(API_DATE_FORMAT) : null
      },
    },
    status: {
      value: initialFields.status,
      set(e: EventLike<unknown>) {
        return getValueFromEvent(e)
      },
      get(options: QuotesFilterFields['status']) {
        return options || []
      },
    },
    equipment_type: {
      value: initialFields.equipment_type,
      set(e: EventLike<unknown>) {
        const equipment_types = getValueFromEvent(e)

        return equipment_types
      },
      get(options: QuotesFilterFields['equipment_type']) {
        return options || []
      },
    },
    quoted_by: {
      value: initialFields.quoted_by,
      set(e: Event) {
        const quoted_by = get(e, 'target.value', [])

        return quoted_by
      },
      get(options: QuotesFilterFields['quoted_by']) {
        return (options || []).map((option) => option.id)
      },
    },
  }
}

export const defaultFilters = {
  created_at: {
    start: moment().subtract(30, 'd').format(API_DATE_FORMAT),
  },
}

export const initialFields = buildFilterFields({
  created_at: { start: defaultFilters.created_at.start },
  shipper: [] as Shipper[],
  pickup: [],
  pickup_date: null,
  delivery: [],
  delivery_date: null,
  status: null,
  equipment_type: [],
  quoted_by: [],
})
