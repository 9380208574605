import { QuoteRate } from 'common/types/QuoteRate'
import { Table } from 'components/Table'
import { Column, useTable } from 'react-table'
import Button from 'components/Button'
import { Tag } from 'components/Tag'
import Text from 'components/Text'
import CurrencyHelper from 'common/Currency.helpers'
import { LaneTypes } from 'common/types/QuoteRate'
import isEmpty from 'common/helpers/isEmpty'
import Tooltip, { TooltipAlign, TooltipPosition } from 'components/Tooltip'
import Icon from 'components/Icon'
import DateHelper from 'common/Date.helpers'
import differenceInBuissnessDaysText from 'common/helpers/date/differenceInBusinessDaysText'
import ConfirmRateChangeModal from './ConfirmRateChangeModal'
import { useDialog } from 'components/Dialog'
import { useRef } from 'react'
import QuoteRateHelper from 'common/QuoteRate.helper'

export interface LTLRateListProps {
  rates: QuoteRate[]
  selectedRate: { id: string }
  defaultDeliveryDate?: string | null
  pickupDate?: string | null
  showConfirmation?: boolean
  disabled?: boolean
  onSelect: (rateId: string) => Promise<boolean | undefined>
}

export const getEstimatedDeliveryDate = (
  estDeliveryDate: string | null,
  defaultDelivery: string | null | undefined,
  pickupDate: string | null | undefined,
) => {
  const startDate = pickupDate || DateHelper.today()?.value().toISOString()
  const finalDate = estDeliveryDate || defaultDelivery

  if (!startDate || !finalDate) {
    return ''
  }

  return `${DateHelper(finalDate)?.format('ddd, MMM D')} (${differenceInBuissnessDaysText(
    finalDate,
    startDate,
  )})`
}

const COLUMNS = [
  {
    Header: 'carrier',
    accessor: 'carrier',
    className: '',
    style: { height: '52px' },
    Cell({ value }: { value: QuoteRate['carrier'] }) {
      return <Text variant="heading-sm-bold">{value?.name}</Text>
    },
  },
  {
    Header: 'est. delivery date',
    accessor: 'est_delivery_date',
    style: {},
    Cell({
      value,
      defaultDeliveryDate,
      pickupDate,
    }: {
      value: QuoteRate['est_delivery_date']
      defaultDeliveryDate: string
      pickupDate: string
    }) {
      return (
        <Text variant="body">
          {getEstimatedDeliveryDate(value, defaultDeliveryDate, pickupDate)}
        </Text>
      )
    },
  },
  {
    Header: 'network',
    accessor: 'lane_type',
    style: {},
    Cell({ value }: { value: QuoteRate['lane_type'] }) {
      if (!value) {
        return null
      }
      return (
        <Text variant="body">
          {LaneTypes[value].name}
          {!isEmpty(LaneTypes[value].description) ? (
            <Tooltip
              message={LaneTypes[value].description}
              align={TooltipAlign.Center}
              position={TooltipPosition.Top}
            >
              <Icon name="question-circle" className="ml-1" size={13} title="" aria-hidden="true" />
            </Tooltip>
          ) : null}
        </Text>
      )
    },
  },
  {
    Header: 'service level',
    accessor: 'service_level',
    style: {},
    Cell({ value }: { value: QuoteRate['service_level'] }) {
      return <Text variant="body">{value}</Text>
    },
  },
  {
    Header: 'price',
    accessor: 'price',
    style: {},
    Cell({ value }: { value: QuoteRate['price'] }) {
      return (
        <Text variant="heading-sm-bold" color="color-accent">
          {CurrencyHelper(value).format()}
        </Text>
      )
    },
  },
]

interface IRateRow {
  rowId: string
  _type: string
}

function LTLRateList({
  rates,
  selectedRate,
  onSelect,
  defaultDeliveryDate,
  pickupDate,
  showConfirmation,
  disabled,
}: LTLRateListProps) {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: COLUMNS as Column[],
    data: rates,
  })
  const { open, hide, show } = useDialog({ open: false })
  const selection = useRef<string>('')
  const selectCallback = useRef<() => void>()

  const runOnSelect = async () => {
    const success = await onSelect(selection.current)

    if (success) {
      selectCallback.current?.()
    }
  }

  const handleClick = (selected: string, callback: any) => {
    selection.current = selected
    selectCallback.current = callback
    if (showConfirmation) {
      show()
    } else {
      runOnSelect()
    }
  }

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      runOnSelect()
    }
    hide()
  }

  return (
    <>
      <ConfirmRateChangeModal open={open} onClose={handleConfirm} />
      <Table<IRateRow>
        selection={{
          multiple: false,
          adapters: {
            rateRow: {
              getKey(row) {
                return row.rowId
              },
            },
          },
          selected: [{ rowId: selectedRate.id, _type: 'rateRow' }],
        }}
        className="w-full"
      >
        <Table.Head>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Table.Cell header {...column.getHeaderProps()} style={column.style}>
                  {column.render('Header')}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row)
            return (
              <Table.Row {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell: any) => {
                  return (
                    <Table.Cell key={cell.column.id} className="text-sm" style={cell.column.style}>
                      {cell.render('Cell', { defaultDeliveryDate, pickupDate })}
                    </Table.Cell>
                  )
                })}
                <Table.Selection.Cell value={{ _type: 'rateRow', rowId: row.original.id }}>
                  {({ selected, toggle }) => {
                    return selected ? (
                      <Tag variant="accent" size="default">
                        SELECTED
                      </Tag>
                    ) : (
                      <Button
                        scale="small"
                        disabled={disabled || !QuoteRateHelper(row.original).status.is('open')}
                        onClick={() => handleClick(row.original.id, toggle)}
                      >
                        Select
                      </Button>
                    )
                  }}
                </Table.Selection.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default LTLRateList
