import React from 'react'
import CurrencyHelper from 'common/Currency.helpers'

interface CustomTooltipProps {
  active?: boolean
  payload?: any
  label?: string
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  const getPrice = (identifier: string): string => {
    return CurrencyHelper(payload[0].payload[identifier]).integer()
  }

  if (!(active && payload && payload.length)) {
    return null
  }

  return (
    <div className="w-32 bg-black rounded-md p-2 text-neutral-lightest">
      {payload[0].payload['market'] && <p className="font-bold text-sm">{getPrice('market')}</p>}
      <p className="font-bold text-primary-500 text-sm">{getPrice('rate-guide')}</p>
      <div className="flex flex-row text-xs mt-1">
        <div className="w-full">
          <p>Min</p>
          <p className="text-primary-500 font-bold">{getPrice('min')}</p>
        </div>
        <div className="w-full">
          <p>Max</p>
          <p className="text-primary-500 font-bold">{getPrice('max')}</p>
        </div>
      </div>
    </div>
  )
}

export default CustomTooltip
