import { Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'

import MarginsDashboard from './MarginsDashboard'

function QuoteIndex() {
  let { path } = useRouteMatch()

  return (
    <Suspense fallback={<h1> Loading ...</h1>}>
      <Switch>
        <Route exact path={path}>
          <MarginsDashboard />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default QuoteIndex
