import { QuoteLTLFormValues } from './types/LTLQuote'
import { FREIGHT_CLASS_REFERENCE } from '../pages/Quotes/QuoteForm/QuoteAdd/LTLQuote/Components/CommodityItem/options'
import { getFixedNumber } from './utils'

const INCHES_IN_A_CUBIC_FEET = 1728
export const MAX_TOTAL_CUBIC_VOLUME = 750
export const MAX_TOTAL_CUBIC_DENSITY = 6

export function calculateDimensions({
  length,
  width,
  height,
  count,
  weight,
}: QuoteLTLFormValues['items'][number]['ltl_item']) {
  if (!(length && width && height && count)) {
    return { volume: 0, density: 0 }
  }

  const totalCubicInches = Number(length) * Number(width) * Number(height) * Number(count)
  const volume = getFixedNumber(totalCubicInches / INCHES_IN_A_CUBIC_FEET)
  const density = getFixedNumber(Number(weight) / volume)

  return { volume, density }
}

export function calculateTotalDimensions(items: QuoteLTLFormValues['items']) {
  const { totalLinear, totalVolume, totalDensity } = items.reduce(
    (acc, current) => {
      const { volume, density } = calculateDimensions(current.ltl_item)

      acc.totalLinear += Number(current.ltl_item.length)
      acc.totalVolume += volume
      acc.totalDensity += density

      return acc
    },
    { totalLinear: 0, totalVolume: 0, totalDensity: 0 },
  )
  const totalLinearFeet = getFixedNumber(totalLinear / 12)
  const totalCubicVolume = getFixedNumber(totalVolume / items.length) || 0
  const totalCubicDensity = getFixedNumber(totalDensity / items.length) || 0

  return { totalLinearFeet, totalCubicVolume, totalCubicDensity }
}

export function getFreightClass(density: number) {
  if (density >= 50) return FREIGHT_CLASS_REFERENCE.CLASS_50.value

  if (density >= 35) return FREIGHT_CLASS_REFERENCE.CLASS_55.value

  if (density >= 30) return FREIGHT_CLASS_REFERENCE.CLASS_60.value

  if (density >= 22.5) return FREIGHT_CLASS_REFERENCE.CLASS_65.value

  if (density >= 15) return FREIGHT_CLASS_REFERENCE.CLASS_70.value

  if (density >= 13.5) return FREIGHT_CLASS_REFERENCE.CLASS_77.value

  if (density >= 12) return FREIGHT_CLASS_REFERENCE.CLASS_85.value

  if (density >= 10.5) return FREIGHT_CLASS_REFERENCE.CLASS_92.value

  if (density >= 9) return FREIGHT_CLASS_REFERENCE.CLASS_100.value

  if (density >= 8) return FREIGHT_CLASS_REFERENCE.CLASS_110.value

  if (density >= 7) return FREIGHT_CLASS_REFERENCE.CLASS_125.value

  if (density >= 6) return FREIGHT_CLASS_REFERENCE.CLASS_150.value

  if (density >= 5) return FREIGHT_CLASS_REFERENCE.CLASS_175.value

  if (density >= 4) return FREIGHT_CLASS_REFERENCE.CLASS_200.value

  if (density >= 3) return FREIGHT_CLASS_REFERENCE.CLASS_250.value

  if (density >= 2) return FREIGHT_CLASS_REFERENCE.CLASS_300.value

  if (density >= 1) return FREIGHT_CLASS_REFERENCE.CLASS_400.value

  if (density > 0 && density <= 1) return FREIGHT_CLASS_REFERENCE.CLASS_500.value

  return ''
}
