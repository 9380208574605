import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

import Layout from 'components/Layout'
import { Checkbox, Select, TextField } from 'packages/formik-miranda/src'
import { EXCESSIVE_LENGTH_CLASS, LTL_FREIGHT_CLASSES, PACKAGE_TYPES } from './options'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { QuoteLTLFormValues } from 'common/types/LTLQuote'
import { calculateDimensions, getFreightClass } from 'common/LTLCommodity.helpers'
import { HAZMAT_CLASSES } from 'pages/Quotes/components/Commodities/Commodity.constants'

interface CommodityItemProps {
  onDuplicate: () => void
  onRemoveItem: () => void
  index: number
  item: QuoteLTLFormValues['items'][number]
}

const StyledCommodityItemContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${token('color-neutral-light')};
  box-shadow: 0 2px 3px ${token('color-neutral')};
  & .commodity-item-form {
    flex-grow: 1;
    border-radius: inherit;
    background-color: ${token('color-neutral-lightest')};
    padding: 16px;
    & .commodity-name {
      flex: 5;
    }
    & .commodity-input {
      flex: 1;
      min-width: 120px;
    }
  }
  & .commodity-item-remove {
    display: flex;
    border-radius: 0 8px 8px 0;
    justify-content: center;
    background-color: ${token('color-neutral-lighter')};
    width: 2.5rem;
    align-items: center;
    & span {
      cursor: pointer;
      &:hover {
        color: ${token('color-primary')};
      }
    }
  }
`

const CommodityItem = ({ onDuplicate, index, onRemoveItem, item }: CommodityItemProps) => {
  const { setFieldValue } = useFormikContext<QuoteLTLFormValues>()
  const { length, weight, width, count, height, package_type } = item.ltl_item

  useEffect(() => {
    const { density } = calculateDimensions(item.ltl_item)
    const estimatedFreightClass = getFreightClass(density)

    if (estimatedFreightClass) {
      setFieldValue(`items[${index}].ltl_item.classification`, estimatedFreightClass)
    }

    setFieldValue(`items[${index}].ltl_item.density`, density)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, length, weight, width, count, height, setFieldValue])

  useEffect(() => {
    if (package_type === 'std_pallets') {
      setFieldValue(`items[${index}].ltl_item.length`, 48)
      setFieldValue(`items[${index}].ltl_item.width`, 40)
    }
  }, [index, package_type, setFieldValue])

  return (
    <StyledCommodityItemContainer>
      <div className="commodity-item-form">
        <Layout.Stack>
          <Layout.Group>
            <TextField
              label="Commodity Name"
              id={`items[${index}].description`}
              name={`items[${index}].description`}
              placeholder="Insert commodity"
              type="text"
              className="commodity-name"
              required
            />
            <TextField
              label="Total Weight"
              id={`items[${index}].ltl_item.weight`}
              name={`items[${index}].ltl_item.weight`}
              placeholder="e.g. 800 lbs"
              type="number"
              className="commodity-input"
              required
            />
          </Layout.Group>
          <Layout.Group>
            <Select
              label="Package Type"
              name={`items[${index}].ltl_item.package_type`}
              id={`items[${index}].ltl_item.package_type`}
              options={PACKAGE_TYPES}
              placeholder="Select a package type"
              className="commodity-input"
              required
            />
            <TextField
              label="Package Quantity"
              name={`items[${index}].ltl_item.count`}
              id={`items[${index}].ltl_item.count`}
              placeholder="e.g. 4"
              type="number"
              className="commodity-input"
              required
            />
            <TextField
              label="Length"
              name={`items[${index}].ltl_item.length`}
              id={`items[${index}].ltl_item.length`}
              placeholder="in inches"
              type="number"
              disabled={item.ltl_item.package_type === 'std_pallets'}
              className="commodity-input"
              required
            />
            <TextField
              label="Width"
              name={`items[${index}].ltl_item.width`}
              id={`items[${index}].ltl_item.width`}
              placeholder="in inches"
              className="commodity-input"
              disabled={item.ltl_item.package_type === 'std_pallets'}
              type="number"
              required
            />
            <TextField
              label="Height"
              name={`items[${index}].ltl_item.height`}
              id={`items[${index}].ltl_item.height`}
              placeholder="in inches"
              type="number"
              className="commodity-input"
              required
            />
          </Layout.Group>
          <Layout.Group>
            <TextField
              label="Density (Pounds/Cubic feet)"
              id={`items[${index}].ltl_item.density`}
              name={`items[${index}].ltl_item.density`}
              placeholder="in inches"
              type="number"
              className="commodity-input"
              disabled
              required
            />
            <Select
              label="Freight Class"
              id={`items[${index}].ltl_item.classification`}
              name={`items[${index}].ltl_item.classification`}
              options={LTL_FREIGHT_CLASSES}
              className="commodity-input"
              required
            />
            <TextField
              label="NMFC Code"
              id={`items[${index}].ltl_item.nmfc_code`}
              name={`items[${index}].ltl_item.nmfc_code`}
              placeholder="Insert NMFC Code"
              type="string"
              className="commodity-input"
            />
            <Select
              label="Excessive length"
              id={`items[${index}].ltl_item.ltl_excessive_length_class`}
              name={`items[${index}].ltl_item.ltl_excessive_length_class`}
              options={EXCESSIVE_LENGTH_CLASS}
              className="commodity-input"
              placeholder="Select the excessive length class"
            />
          </Layout.Group>
          <Layout.Group>
            <div className="flex justify-between w-full items-center">
              <Layout.Group align="center">
                <Checkbox
                  label="Hazmat"
                  name={`items[${index}].hazmat`}
                  id={`items[${index}].hazmat`}
                />
                {item.hazmat && (
                  <Select
                    label="Hazmat Class"
                    name={`items[${index}].hazmat_class`}
                    id={`items[${index}].hazmat_class`}
                    options={HAZMAT_CLASSES}
                    placeholder="Select a hazmat class"
                    required
                    className="commodity-input"
                  />
                )}
                <Checkbox
                  label="Stackable"
                  name={`items[${index}].ltl_item.stackable`}
                  id={`items[${index}].ltl_item.stackable`}
                />
              </Layout.Group>
              <Button disabled={false} variant="secondary" className="ml-4" onClick={onDuplicate}>
                Duplicate
              </Button>
            </div>
          </Layout.Group>
        </Layout.Stack>
      </div>
      <div className="commodity-item-remove" onClick={onRemoveItem}>
        <span>
          <Icon name="remove" size={24} />
        </span>
      </div>
    </StyledCommodityItemContainer>
  )
}

export default CommodityItem
