import type { MarginsFilterFields } from './MarginsList.types'
import type { Location } from 'common/types/Location'
import type { MarginResult } from 'common/types/Margin'
import isEmpty from 'common/helpers/isEmpty'
import DateHelper from 'common/Date.helpers'
import LocationHelper from 'common/Location.helpers'

const GROUP_KEYS = {
  ALL_SHIPPERS: 'All Shippers',
  DISABLED: 'Disabled',
}

const SORT_CRITERIA = [
  { criterion: 'kma', strict: true },
  { criterion: 'zipcode', strict: false },
  { criterion: 'city', strict: false },
  { criterion: 'state', strict: true },
]

export const SORT_SCORE: Record<string, number> = {
  ALL_ALL: 0,
  STATE_ALL: 1,
  STATE_STATE: 2,
  KMA_ALL: 3,
  KMA_KMA: 4,
  ZIPCODE_ALL: 5,
  ZIPCODE_ZIPCODE: 6,
  CITY_ALL: 7,
  CITY_CITY: 8,
  OTHER: 9,
}

const getMarginGroupKey = ({ status, shipper }: MarginResult) => {
  // if (status === 'DISABLED') {
  //   return GROUP_KEYS.DISABLED
  // }

  if (!shipper) {
    return GROUP_KEYS.ALL_SHIPPERS
  }

  return shipper.name || ''
}

const groupByShipper = (margins: MarginResult[]) => {
  const groups = margins.reduce(
    (acc, margin) => {
      const key = getMarginGroupKey(margin)

      return {
        ...acc,
        [key]: [...(acc[key] || []), margin],
      }
    },
    {
      [GROUP_KEYS.ALL_SHIPPERS]: [] as MarginResult[],
    },
  )

  return Object.entries(groups)
    .map(([key, margins]) => ({ key, margins }))
    .sort((a, b) => {
      /**
       * Here we order our lane margin groups according to how we want it to be
       * shown in the list.
       * - First, we want to keep All Shippers lane margins at the top of the list.
       * - Disabled lane margin group must be pushed to the bottom of the list.
       * - All remaining groups (Shipper-specific lane margins) are sorted alphabetically.
       */
      if (a.key === GROUP_KEYS.ALL_SHIPPERS) return -1
      if (b.key === GROUP_KEYS.ALL_SHIPPERS) return 1
      // if (a.key === GROUP_KEYS.DISABLED) return 1
      // if (b.key === GROUP_KEYS.DISABLED) return -1

      return a.key < b.key ? -1 : 1
    })
}

const getLocationKeysWithValue = (location: Location) =>
  Object.keys(location).filter(
    (key) => key !== 'country' && Boolean(location[key as keyof Location]),
  )

const includesKey = (location: Location, key: string) =>
  getLocationKeysWithValue(location).includes(key)

const isOnlyKey = (location: Location, key: string) => {
  const keys = getLocationKeysWithValue(location)
  return keys.length === 1 && keys.includes(key)
}

const isLocationEmpty = (location: Location) => getLocationKeysWithValue(location).length === 0

function isAllToAll({ origin, destination }: MarginResult): boolean {
  return isLocationEmpty(origin) && isLocationEmpty(destination)
}

export function getSortScore(margin: MarginResult): number {
  function isLocationToAll({ origin, destination }: MarginResult, location: string) {
    return (
      (includesKey(origin, location) && isLocationEmpty(destination)) ||
      (includesKey(destination, location) && isLocationEmpty(origin))
    )
  }

  function isLocationToLocation(
    { origin, destination }: MarginResult,
    location: string,
    strict = false,
  ) {
    return (
      (includesKey(origin, location) && (!strict || isOnlyKey(origin, location))) ||
      (includesKey(destination, location) && (!strict || isOnlyKey(destination, location)))
    )
  }

  if (isAllToAll(margin)) {
    return SORT_SCORE.ALL_ALL
  }

  for (const { criterion, strict } of SORT_CRITERIA) {
    const uppercaseCriterion = criterion.toUpperCase()

    if (isLocationToAll(margin, criterion)) {
      return SORT_SCORE[`${uppercaseCriterion}_ALL`]
    }

    if (isLocationToLocation(margin, criterion, strict))
      return SORT_SCORE[`${uppercaseCriterion}_${uppercaseCriterion}`]
  }

  return SORT_SCORE.OTHER
}

const sortMarginsByLocation = (margins: MarginResult[]) =>
  margins
    .map((margin) => ({
      ...margin,
      $sort_score: getSortScore(margin),
    }))
    .sort((a, b) => (a.$sort_score < b.$sort_score ? -1 : 1))

const FILTER_METHODS = [
  function filterByDateRange(margin: MarginResult, { date_range }: MarginsFilterFields) {
    const safeDateRange = date_range || {}
    const filterDateStart = DateHelper(safeDateRange.date_start?.toString())
    const filterDateEnd = DateHelper(safeDateRange.date_end?.toString())

    return (
      (safeDateRange.date_start == null ||
        DateHelper(margin.pickup_date_start)?.is.sameOrAfter(filterDateStart, 'day')) &&
      (safeDateRange.date_end == null ||
        DateHelper(margin.pickup_date_end)?.is.sameOrBefore(filterDateEnd, 'day'))
    )
  },
  function filterByEquipmentType(margin: MarginResult, { equipment_types }: MarginsFilterFields) {
    return isEmpty(equipment_types) || equipment_types?.includes(margin.equipment_type)
  },
  // function filterByMode(margin: MarginResult, { modes }: MarginsFilterFields) {
  //   return (
  //     isEmpty(modes) ||
  //     (margin.modes || []).some((mode) => modes.includes(mode))
  //   )
  // },
  function filterByMargin(margin: MarginResult, { margin_min, margin_max }: MarginsFilterFields) {
    const marginValue = (margin.margin_value || 0) * 100
    const safeMarginMin =
      isEmpty(margin_min) || Number.isNaN(margin_min) ? -100 : Number(margin_min)
    const safeMarginMax = isEmpty(margin_max) || Number.isNaN(margin_max) ? 100 : Number(margin_max)

    return marginValue >= safeMarginMin && marginValue <= safeMarginMax
  },
  function filterByReason(margin: MarginResult, { reasons }: MarginsFilterFields) {
    return isEmpty(reasons) || reasons?.includes(margin.reason?.code)
  },
  function filterByExpiryDate(margin: MarginResult, { status }: MarginsFilterFields) {
    const safeStatus = status || null

    if (!margin.pickup_date_end) {
      return true
    }

    if (!safeStatus?.includes('EXPIRED')) {
      return DateHelper(margin.pickup_date_end)?.is.sameOrAfter(DateHelper.today(), 'day')
    }

    return DateHelper(margin.pickup_date_end)?.is.before(DateHelper.today(), 'day')
  },
  function filterByStatus(margin: MarginResult, { status }: MarginsFilterFields) {
    return isEmpty(status) || status?.includes(margin.status)
  },
  function filterByInbound(margin: MarginResult, { inbound }: MarginsFilterFields) {
    const safeInbound = inbound || []

    return (
      isEmpty(safeInbound) ||
      safeInbound.some((location: any) => {
        return LocationHelper(margin.destination).matches(location)
      })
    )
  },
  function filterByOutbound(margin: MarginResult, { outbound }: MarginsFilterFields) {
    const safeOutbound = outbound || []

    return (
      isEmpty(safeOutbound) ||
      safeOutbound.some((location: any) => {
        return LocationHelper(margin.origin).matches(location)
      })
    )
  },
  function filterByShipper(margin: MarginResult, { shippers }: MarginsFilterFields) {
    const safeShippers = shippers || []
    const safeMarginShipper = margin.shipper || ({} as MarginResult['shipper'])

    return (
      isEmpty(safeShippers) ||
      isEmpty(safeMarginShipper) ||
      safeShippers.some((filterShipper: any) => filterShipper.id === safeMarginShipper?.id)
    )
  },
  function filterByCreator(margin: MarginResult, { created_by }: MarginsFilterFields) {
    const safeCreatedBy = created_by || []
    const safeMarginCreatedBy = margin.created_by || {}

    // TODO: Check by eiter username or id. Backend needs to align the returns
    return (
      isEmpty(safeCreatedBy) ||
      safeCreatedBy.some((filterCreatedBy: any) => filterCreatedBy.id === safeMarginCreatedBy.id)
    )
  },
]

export const filterMargins = (margins: MarginResult[], filters: MarginsFilterFields) =>
  margins.filter((margin) => {
    return FILTER_METHODS.every(function applyFilter(filterMethod) {
      return filterMethod(margin, filters)
    })
  })

export function getFilteredLaneMarginGroups(margins: MarginResult[], filters: MarginsFilterFields) {
  return groupByShipper(sortMarginsByLocation(filterMargins(margins, filters)))
}
