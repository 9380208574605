import { createContext } from 'react'

interface TabContextValue {
  id: string
  activeTab: string
  setActiveTab: (name: string) => void
  variant?: 'default' | 'secondary'
}

export const TabContext = createContext<TabContextValue>({} as TabContextValue)
