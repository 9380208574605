import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import ReactSelect, {
  components,
  FocusEventHandler,
  IndicatorProps,
  OptionTypeBase,
} from 'react-select'
import clsx from 'clsx'

import type EventLike from 'common/types/EventLike'
import type ColorScheme from 'common/types/ColorScheme'
import {
  getStyles,
  getMapOfOptionsByValue,
  getReactSelectValue,
  getOutputValue,
} from './Select.helpers'
import Icon from '../Icon'
import Status from 'common/types/Status'
import useFingerprint from '../../hooks/useFingerprint'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from '../../theming'
export interface SelectOption {
  label: string
  value: string | number
  _type?: string
}

export type OptionCandidate = Omit<SelectOption, '_type'> & {
  data: SelectOption
}

interface CommonProps {
  id?: string
  name?: string
  className?: string
  clearable?: boolean
  disabled?: boolean
  options: SelectOption[]
  placeholder?: string
  scheme?: ColorScheme
  status?: Status
  disableDropdownIndicator?: boolean
  // wrap for react-select  specific props
  query?: string
  onQueryChange?: (newValue: string) => void
  filterOption?: (candidate: OptionCandidate, input?: string | null) => boolean
  onFocus?: FocusEventHandler
  customStylesCallbacks?: {
    options?: (params: any) => CSSProperties
  }
}

export type SingleSelectValue = string | number | Object | null
export type MultipleSelectValue = string[] | number[] | Object[] | null

type ConditionalProps =
  | {
      multiple?: false
      value?: SingleSelectValue
      onChange?: (event: EventLike<SingleSelectValue>) => void
    }
  | {
      multiple: true
      value?: MultipleSelectValue
      onChange?: (event: EventLike<MultipleSelectValue>) => void
    }

export type SelectProps = CommonProps & ConditionalProps

const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, true>) => (
  <components.DropdownIndicator {...props}>
    <Icon name="caret-down" size={12} />
  </components.DropdownIndicator>
)

const NilDropdownIndicator = (_: IndicatorProps<OptionTypeBase, true>) => null

const ClearIndicator = (props: IndicatorProps<OptionTypeBase, true>) => (
  <components.ClearIndicator {...props}>
    <Icon name="close" size={12} />
  </components.ClearIndicator>
)

const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <Icon name="close" size={10} />
  </components.MultiValueRemove>
)

const STATIC_COMPONENTS = {
  ClearIndicator,
  IndicatorSeparator: () => null,
  MultiValueRemove,
}

const THEME = (theme: any) => ({
  ...theme,
  borderRadius: 4,
})

function Select(props: SelectProps) {
  const {
    className,
    clearable = false,
    disabled = false,
    id,
    multiple = false,
    name,
    onChange,
    onQueryChange,
    options,
    placeholder = '',
    query,
    scheme = 'light',
    status = Status.Neutral,
    value,
    disableDropdownIndicator = false,
    customStylesCallbacks,
    ...others
  } = props
  const theme = useTheme() as RateGuideThemeInterface
  const { resetFingerprint, hasSameFingerprint } = useFingerprint<SelectOption>(
    (item) => String(item.value),
    options,
  )
  const [optionsByValue, setOptionsByValue] = useState(() => getMapOfOptionsByValue(options))

  const CustomDropdownIndicator = useMemo(() => {
    if (disableDropdownIndicator) return NilDropdownIndicator
    return DropdownIndicator
  }, [disableDropdownIndicator])

  const handleChange = useCallback(
    function handleChange(value: any) {
      onChange?.({
        target: { id, name, value: getOutputValue(multiple, value) },
      })
    },
    [id, multiple, name, onChange],
  )

  useEffect(
    function updateOptionsByValue() {
      if (hasSameFingerprint(options)) {
        return
      }

      resetFingerprint(options)

      setOptionsByValue(getMapOfOptionsByValue(options))
    },
    [hasSameFingerprint, options, resetFingerprint],
  )

  return (
    <div
      className={clsx(
        'relative w-full',
        {
          'opacity-40': disabled,
        },
        className,
      )}
    >
      <ReactSelect
        {...others}
        className="w-full rg-select"
        classNamePrefix="rg-select"
        menuPlacement="auto"
        components={{ ...STATIC_COMPONENTS, DropdownIndicator: CustomDropdownIndicator }}
        theme={THEME}
        inputId={id}
        isClearable={multiple || clearable}
        inputValue={query}
        isDisabled={disabled}
        isLoading={status === Status.Busy}
        isMulti={multiple || undefined} // yep... ¯\_(ツ)_/¯
        name={name}
        onChange={handleChange}
        onInputChange={onQueryChange}
        options={options}
        placeholder={placeholder}
        styles={getStyles({ scheme, status }, theme, customStylesCallbacks)}
        value={getReactSelectValue(optionsByValue, multiple, value)}
      />
    </div>
  )
}

export default Select
