import { WhiteLabelConfiguration } from 'common/types/WhiteLabelConfiguration'
import { RateProviderIdentifiers } from 'common/types/RateProvider'
import { Banner } from 'components/Banner'
import Text from 'components/Text'
import AdditionalRateProvider from './AdvancedOptions/AdditionalRateProvider'

interface AdvancedConfigurationProps {
  rateProviders: RateProviderIdentifiers[]
  configuration: WhiteLabelConfiguration
  onUpdateConfiguration: (config: Partial<WhiteLabelConfiguration>) => void
  onChangeTab: (tabName: string) => void
  setHasRules: (hasRules: boolean) => void
}

function AdvancedConfiguration({
  configuration,
  rateProviders,
  onUpdateConfiguration,
  onChangeTab,
  setHasRules,
}: AdvancedConfigurationProps) {
  const secondaryRateProvider = rateProviders.filter(
    (item) => item !== configuration.default_rate_provider,
  )[0]
  const margin = configuration.margins ? configuration.margins[secondaryRateProvider] : undefined

  const handleUpdateMargin = (updatedMargin: string | number) => {
    onUpdateConfiguration({
      margins: {
        ...configuration.margins,
        [secondaryRateProvider]: updatedMargin,
      },
    })
  }

  return (
    <>
      <Banner
        variant="neutral"
        scale="large"
        className="mb-6"
        title="Smart rules helps you to have more business"
        description="By using smart rules you can decide which requested quote you will handle and which one the Loadsmart will handle. To start using it is simple, just add a new rate provider and then add smart rules to the ones that you prefer the other rate provider to handle. You can add as many rules as you need."
      />
      <Text variant="heading-sm-bold">Additional rate providers</Text>
      <div className="mt-6">
        <AdditionalRateProvider
          rateProvider={secondaryRateProvider}
          margin={margin}
          onAddMarginActionClick={() => onChangeTab('general')}
          onUpdateMargin={handleUpdateMargin}
          setHasRules={setHasRules}
        />
      </div>
    </>
  )
}

export default AdvancedConfiguration
