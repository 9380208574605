import { clone, setWith, isString, isNumber, isEmpty } from 'lodash'

const setIn = (path: string, state: any, valueToSet: any): any => {
  if (isEmpty(path)) return valueToSet
  return setWith({ ...state }, path, valueToSet, (nsValue: any, key: any) => {
    const nextKey = path[path.lastIndexOf(key) + 1]
    const isStringNumber = isString(nextKey) && isNumber(parseInt(nextKey as string, 10))
    const result = isStringNumber ? Object(nsValue) : nsValue
    return clone(result)
  })
}

export default setIn
