import * as React from 'react'
import { Field, FieldProps, getIn } from 'formik'
import { LabelProps } from '@loadsmart/loadsmart-ui'
import { BaseFieldProps } from './BaseField'

export interface CustomFormikFieldCallableChildrenProps extends FieldProps {
  error?: string | any[]
  baseFieldProps: Omit<BaseFieldProps, 'children'>
  baseProps: any
}

export interface CustomFormikFieldProps {
  label: string
  name: string
  required?: boolean
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  id?: string
  className?: string
  children: (params: CustomFormikFieldCallableChildrenProps) => React.ReactNode
  innerComponentClassName?: string
}

export function CustomFormikField({ children, ...props }: CustomFormikFieldProps) {
  const {
    label,
    name,
    required,
    labelProps,
    id,
    className,
    innerComponentClassName,
    ...rest
  } = props

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { touched, errors } = form
        const error = getIn(errors, name)
        const touch = getIn(touched, name)
        const showError = !!error && !!touch

        const baseProps = {
          disabled: form.isSubmitting,
          className: innerComponentClassName,
          name,
          required,
          id,
          ...rest,
        }

        const baseFieldProps = {
          label,
          error: showError ? error : undefined,
          id,
          required,
          labelProps,
          className,
        }

        return children({
          field,
          form,
          meta,
          baseFieldProps,
          baseProps,
        })
      }}
    </Field>
  )
}
