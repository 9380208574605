import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Column, usePagination, useTable } from 'react-table'
import clsx from 'clsx'
import { toast } from 'components/Toast'
import { IconButton } from 'components/Button'
import Empty from 'components/Empty'
import get from 'common/helpers/get'
import isEmpty from 'common/helpers/isEmpty'
import Loading from 'components/Loading'
import Table from 'components/Table'
import useCancelToken from 'hooks/useCancelToken'
import Pagination from 'components/Pagination'
import { UserEntity } from 'common/types/UserEntity'
import { listUsers, editUser } from 'services/usersManagement/usersManagement.service'
import Section from 'components/Section'
import Icon from 'components/Icon'
import Switch from 'components/Switch'
import DateHelper from 'common/Date.helpers'
import CurrentUserHelper from 'common/CurrentUser.helper'

interface UsersListProps {
  className?: string
  filters?: any
  onEditUser: (user: UserEntity) => void
  onDeleteUser: (user: UserEntity) => void
}

const COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'first_name',
    style: { width: '10%', wordBreak: 'break-all' },
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
    style: { width: '12%', wordBreak: 'break-all' },
  },
  {
    Header: 'Username',
    accessor: 'username',
    style: { width: '15%', wordBreak: 'break-all' },
  },
  {
    Header: 'Email',
    accessor: 'email',
    style: { width: '22%', wordBreak: 'break-all' },
  },
  {
    Header: 'Admin',
    accessor: 'is_admin',
    style: { width: '5%', textAlign: 'center' },
    Cell({
      row: {
        original: { is_admin },
      },
    }: {
      row: { original: UserEntity }
    }) {
      return (
        <div>
          {is_admin && <Icon className="block mx-auto" name="check" size={16} title="Admin user" />}
        </div>
      )
    },
  },
  {
    Header: 'Last Login',
    accessor: 'last_login',
    style: { width: '15%' },
    Cell: ({
      row: {
        original: { last_login },
      },
    }: {
      row: { original: UserEntity }
    }) => {
      return <span>{DateHelper(last_login)?.format('MM/DD/YYYY hh:mmA')}</span>
    },
  },
  {
    Header: 'Status',
    accessor: 'is_active',
    Cell({
      row: { original },
      onEditUser,
      onDeleteUser,
      onToggleUserActive,
      isCurrentUser,
    }: {
      row: { original: UserEntity }
      onEditUser: (user: UserEntity) => void
      onDeleteUser: (user: UserEntity) => void
      onToggleUserActive: (user: UserEntity) => void
      isCurrentUser: boolean
    }) {
      return (
        <div className="flex flex-row items-center h-full">
          {
            <div className="inline-flex w-auto h-8 ml-auto">
              <Switch
                title={isCurrentUser ? "You can't deactivate yourself" : 'Toggle User Active'}
                aria-label="Toggle User Active"
                onToggle={() => {
                  if (!isCurrentUser) {
                    onToggleUserActive(original)
                  }
                }}
                scale="default"
                active={original.is_active}
                disabled={isCurrentUser}
              />
            </div>
          }
          <div className="inline-flex w-auto h-8 ml-auto">
            <IconButton className="ml-4" scale="small" onClick={() => onEditUser(original)}>
              <Icon name="edit" title="Edit User" size={16} />
            </IconButton>
            <IconButton className="ml-4" scale="small" onClick={() => onDeleteUser(original)}>
              <Icon name="remove" title="Delete User" size={16} />
            </IconButton>
          </div>
        </div>
      )
    },
  },
]

const UsersList = forwardRef(({ filters, onEditUser, onDeleteUser }: UsersListProps, ref) => {
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const currentUserHelper = CurrentUserHelper()

  const [data, setData] = useState<UserEntity[]>([])
  const [loading, setLoading] = useState(false)
  const [controlledPageCount, setControlledPageCount] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: COLUMNS as Column[],
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
  )

  const filtersRef = useRef(filters)
  const pageSizeRef = useRef(pageSize)

  const fetchUserList = useCallback(
    ({ pageSize, pageIndex, filters }) => {
      async function fetch() {
        cancelPending()

        setLoading(true)

        const source = getSource()

        const usersData: Record<string, unknown> = {
          limit: pageSize,
          offset: pageSize * pageIndex,
        }

        if (!isEmpty(filters)) {
          usersData.filters = filters
        }

        const [error, response] = await listUsers(usersData, {
          cancelToken: source.token,
        })

        clearSource()

        if (error) {
          toast.error('We could not retrieve the users.')
          setData([])
          setControlledPageCount(0)
          setTotalUsers(0)
        } else if (response) {
          const total = get(response, 'data.count', 0)
          const newData = get(response, 'data.results', [])
          setData(newData)
          setControlledPageCount(Math.ceil(total / pageSize))
          setTotalUsers(total)
        }

        setLoading(false)
      }
      fetch()
    },
    [cancelPending, clearSource, getSource],
  )

  async function handleUserActive(original: UserEntity) {
    const [error] = await editUser({
      ...original,
      is_active: !original.is_active,
    })

    if (error) {
      toast.error('We could not save this user.')
    } else {
      toast.success('User updated with sucess.')
      fetchUserList({ pageIndex, pageSize, filters })
    }
  }

  useEffect(() => {
    if (filters !== filtersRef.current || pageSize !== pageSizeRef.current) {
      filtersRef.current = filters
      pageSizeRef.current = pageSize

      if (pageIndex !== 0) {
        gotoPage(0)
        return
      }
    }

    fetchUserList({ pageIndex, pageSize, filters })

    return function cancelFetchQuotes() {
      cancelPending()
    }
  }, [fetchUserList, pageIndex, pageSize, filters, gotoPage, cancelPending])

  useImperativeHandle(ref, () => ({
    fetchUserList: () => fetchUserList({ pageIndex, pageSize, filters }),
  }))

  return (
    <>
      {loading && (
        <Loading className="absolute inset-x-0 bottom-0 justify-center px-3 py-6 opacity-75 top-15 bg-background" />
      )}
      <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
        <Section title={`${totalUsers || 0} users`}>
          <Table className="w-full" {...getTableProps()}>
            <Table.Header>
              {headerGroups.map((headerGroup) => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Table.Cell header {...column.getHeaderProps()} style={column.style}>
                      {column.render('Header')}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row)
                return (
                  <Table.Row {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell: any) => {
                      return (
                        <Table.Cell
                          key={cell.column.id}
                          className={clsx('text-sm')}
                          style={cell.column.style}
                        >
                          {cell.render('Cell', {
                            onEditUser,
                            onDeleteUser,
                            onToggleUserActive: handleUserActive,
                            isCurrentUser:
                              row.original.id === currentUserHelper.getCurrentUser()?.user_id,
                          })}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
            {isEmpty(data) && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={COLUMNS.length}>
                    <Empty>No users to display.</Empty>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
          {!isEmpty(data) && (
            <Pagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              className="w-full px-4 pt-6"
              currentPage={pageIndex}
              disabled={loading}
              onPageChange={(page) => {
                gotoPage(page)
              }}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize)
              }}
              pageSize={pageSize}
              totalPages={pageOptions.length}
            />
          )}
        </Section>
      </div>
    </>
  )
})

export default UsersList
