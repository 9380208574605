import { CustomFormikField } from 'packages/formik-miranda/src/CustomFormikField'
import { LTLStop } from 'pages/Quotes/QuoteForm/QuoteAdd/LTLQuote/LTLStop/LTLStop'

import type { LTLStopProps } from 'pages/Quotes/QuoteForm/QuoteAdd/LTLQuote/LTLStop/LTLStop'
import { Accessorial } from '../../../common/types/Accessorial'

export interface LTLStopFormikProps extends Omit<LTLStopProps, 'stop' | 'onChange' | 'errors'> {
  name: string,
  accessorials: Accessorial[]
}

function LTLStopFormik(props: LTLStopFormikProps) {
  return (
    <CustomFormikField label="Stop" {...props}>
      {({ field, baseFieldProps, form }) => (
        <LTLStop
          errors={baseFieldProps.error}
          type={props.type}
          onChange={(val) => form.setFieldValue(field.name, val)}
          stop={field.value}
          accessorials={props.accessorials}
        />
      )}
    </CustomFormikField>
  )
}

export default LTLStopFormik
