import React from 'react'
import { useHistory } from 'react-router-dom'

import passwordResetIcon from './password-reset-icon.png'
import Button from 'components/Button'


interface ResetPasswordProps {
  isNewUser?: boolean
}

const PasswordReset = ({ isNewUser }: ResetPasswordProps) => {
  const history = useHistory()

  return (
    <>
      <div className="flex justify-center mb-6">
        <img src={passwordResetIcon} alt="" className="w-20" />
      </div>
      <h1 className="mb-2 text-2xl font-bold text-neutral-darker text-center">
        {isNewUser ? 'Password created' : 'Password reset'}
      </h1>
      <p className="px-8 text-sm text-center">
        {isNewUser
          ? 'Your account has been successfully created, click below to log in to the platform.'
          : 'Your password has been successfully reset, click below to log in to the platform.'}
      </p>

      {isNewUser ? (
        <div className="flex flex-col px-8">
          <Button
            type="button"
            variant="primary"
            className="mt-4"
            onClick={() => history.push('/login')}
          >
            Go to Login
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default PasswordReset
