export type RateProviderIdentifiers = typeof RATE_GUIDE | typeof LOADSMART

export const RATE_GUIDE = 'rate_guide'

export const LOADSMART = 'loadsmart'

export const RateProviders: Record<RateProviderIdentifiers, { name: string }> = {
  rate_guide: {
    name: 'Broker Rate',
  },
  loadsmart: {
    name: 'Loadsmart',
  },
}
