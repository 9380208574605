import React from 'react'
import checkEmailIcon from './check-email-icon.png'
import { EmailSentStatus } from './ForgotPassword'

interface CheckYourEmailProps {
  emailStatus: EmailSentStatus
  email: string
  sendForgetPasswordRequest: () => void
}

const CheckYourEmail = ({ emailStatus, email, sendForgetPasswordRequest }: CheckYourEmailProps) => {
  return (
    <>
      <div className="flex justify-center mb-6">
        <img src={checkEmailIcon} alt="" className="w-20" />
      </div>
      <h1 className="mb-2 text-2xl font-bold text-neutral-darker text-center">Check your email</h1>
      <p className="px-8 text-sm text-center">We sent a password reset link to {email}</p>
      {emailStatus === 'sent' && (
        <p className="px-8 text-sm text-center pt-8 pb-4">
          Didn't receive the email?{' '}
          <span
            className="underline text-primary-500 cursor-pointer font-bold"
            onClick={sendForgetPasswordRequest}
          >
            Click to resend
          </span>
        </p>
      )}
    </>
  )
}

export default CheckYourEmail
