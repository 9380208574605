import { IconName } from '../../../../components/Icon'

export const ICON_BY_EQUIPMENT_TYPE: Record<string, IconName> = {
  dryvan: 'truck-dryvan',
  reefer: 'truck-reefer',
  flatbed: 'truck-flatbed',
  DRV: 'truck-dryvan',
  RFR: 'truck-reefer',
  FBE: 'truck-flatbed',
}
