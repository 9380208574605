import parseISO from './parseISO'
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'

export default function differenceInBusinessDaysText(laterDateISO: string, earlierDateISO: string) {
  const dateLeft = parseISO(laterDateISO)
  const dateRight = parseISO(earlierDateISO)

  const diff = differenceInBusinessDays(dateLeft, dateRight)

  if (diff === 0) return 'same business day'
  if (diff === 1) return '1 business day'
  return `${diff} business days`
}
