import { HAZMAT_CLASSES } from './Commodity.constants'
import { HazmatClass } from './Commodity.types'

const HAZMAT_BY_VALUE: Record<string, HazmatClass> = HAZMAT_CLASSES.reduce((map, hazmat_class) => {
  return {
    ...map,
    [hazmat_class.value]: hazmat_class,
  }
}, {})

export function getHazmatByValue(value: string): HazmatClass | null {
  return HAZMAT_BY_VALUE[value] || null
}
