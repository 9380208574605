import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { get, post, patch } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { MarginResult, MarginStatus, MarginErrorDetail, MarginReason } from 'common/types/Margin'

export interface GetMarginsPayload {
  results: MarginResult[]
}

export interface GetReasonsPayload {
  results: MarginReason[]
}

export interface MarginFilterBody {
  pickup_date_end_gte?: string
}

export async function getMargins(
  data?: MarginFilterBody,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetMarginsPayload> | undefined]> {
  let error = null,
    result

  try {
    ;[error, result] = await to(post('api/margin-configurations/list', data, config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}

export type AddMarginRequest = Omit<MarginResult, 'id' | 'status' | 'created_by'>

export interface AddMarginResponse {
  results: {
    success: MarginResult[]
    errors: MarginResult[]
    errors_details: MarginErrorDetail[]
  }
}

export async function addMargin(
  margin: AddMarginRequest,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<AddMarginResponse> | undefined]> {
  let error = null,
    result

  try {
    ;[error, result] = await to(post('api/margin-configurations', margin, config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}

export async function getReasons(
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetReasonsPayload> | undefined]> {
  let error = null,
    result
  try {
    ;[error, result] = await to(get('api/margin-configurations/reasons', config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}

export async function setStatus(
  id: string,
  status: MarginStatus,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetMarginsPayload> | undefined]> {
  let error = null,
    result
  try {
    ;[error, result] = await to(
      patch(
        `api/margin-configurations/${id}`,
        {
          status,
        },
        config,
      ),
    )

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}
