import { useCallback, useEffect, useRef } from 'react'

const useMounted = () => {
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const isMounted = useCallback(() => {
    return isMountedRef.current
  }, [])

  return isMounted
}

export default useMounted
