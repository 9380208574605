import React, { FunctionComponent } from 'react'
import Layout from 'components/Layout'
import Text from 'components/Text'
import Button from 'components/Button'

interface AdditionalRateProviderMessageProps {
  illustration: FunctionComponent
  onClickButton: () => void
  title: string
  description: string
  buttonText: string
}

const AdditionalRateProviderMessage = ({
  illustration: IllustrationComponent,
  title,
  description,
  buttonText,
  onClickButton,
}: AdditionalRateProviderMessageProps) => {
  return (
    <Layout.Box className="justify-center">
      <Layout.Stack className="items-center">
        <div className="w-32">
          <IllustrationComponent />
        </div>
        <Layout.Stack className="w-60">
          <Text variant="caption-bold" as="p" className="text-center">
            {title}
          </Text>
          <Text variant="caption" as="span" className="text-center">
            {description}
          </Text>
        </Layout.Stack>
        <div className="w-auto">
          <Button variant="primary" scale="small" onClick={onClickButton}>
            {buttonText}
          </Button>
        </div>
      </Layout.Stack>
    </Layout.Box>
  )
}

export default AdditionalRateProviderMessage
