import { Quoteline } from 'common/types/Quote'
import { QuoteRate } from 'common/types/QuoteRate'
import useCollection, { CollectionItemAdapter } from 'hooks/useCollection'
import { useCallback, useEffect, useState } from 'react'
import { sumQuotelines } from '../../PriceBreakdown'
import useQuotelines from '../PriceAdjustments/PriceAdjustmentsSingle/useQuotelines'

export interface useQuoteRatesProps {
  quoteID: string | undefined
  quoteRates: QuoteRate[]
  rateQuotelines: Quoteline[]
  selectedQuoteRate: { id: string } | null
  onQuotelinesChanged?: (items: Quoteline[]) => void
}

const adapter: CollectionItemAdapter = {
  accessor: 'id',
}

function useQuoteRates({
  quoteID,
  quoteRates,
  rateQuotelines,
  onQuotelinesChanged,
  selectedQuoteRate,
}: useQuoteRatesProps) {
  const { items, updateItem } = useCollection<QuoteRate>({
    items: quoteRates,
    options: {
      adapter,
    },
  })
  const [selectedRate, setSelectedRate] = useState<QuoteRate>()

  useEffect(() => {
    setSelectedRate(items.find((item: QuoteRate) => item.id === selectedQuoteRate?.id))
  }, [items, selectedQuoteRate, setSelectedRate])

  const handleQuotelinesChange = useCallback(
    function handleQuotelinesChange(quotelines: Quoteline[]) {
      if (selectedRate) {
        updateItem({
          ...selectedRate,
          price: String(sumQuotelines(quotelines)),
        })
      }
      onQuotelinesChanged?.(quotelines)
    },
    [selectedRate, updateItem, onQuotelinesChanged],
  )

  const { isBusy, quotelines, addQuoteline, updateQuoteline, removeQuoteline } = useQuotelines({
    quoteID: quoteID,
    quotelines: rateQuotelines,
    onChange: handleQuotelinesChange,
  })

  return {
    isBusy,
    rates: items,
    quotelines,
    addQuoteline,
    updateQuoteline,
    removeQuoteline,
  }
}

export default useQuoteRates
