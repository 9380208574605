import React from 'react'

import { CustomFormikField } from 'packages/formik-miranda/src/CustomFormikField'
import { BaseField } from 'packages/formik-miranda/src/BaseField'
import { LabelProps } from '@loadsmart/loadsmart-ui'
import isEmpty from 'common/helpers/isEmpty'
import ShipperSuggestion from '../../../pages/Quotes/QuoteForm/Shipper'
import { ShipperSuggestionProps } from '../../../pages/Quotes/QuoteForm/Shipper/ShipperSuggestion'

export interface ShipperSuggestionFormikProps
  extends Omit<ShipperSuggestionProps, 'name' | 'value' | 'form'> {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
  required?: boolean
  innerComponentClassName?: string
}

const ShipperSuggestionFormik = ({ ...props }: ShipperSuggestionFormikProps) => {
  return (
    <CustomFormikField {...props}>
      {({ field: { value, ...field }, baseFieldProps, baseProps }) => {
        let currentValue = null

        if (!isEmpty(value)) {
          currentValue = value
        }

        const mappedProps = {
          ...baseProps,
          status:
            props.status ??
            ((baseFieldProps.error ? 'danger' : undefined) as ShipperSuggestionProps['status']),
          value: currentValue,
          ...field,
          ...props,
          className: props.innerComponentClassName
        }

        return (
          <BaseField {...baseFieldProps}>
            <ShipperSuggestion {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

export default ShipperSuggestionFormik
