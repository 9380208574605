import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { post } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import type { KMA } from 'common/types/KMA'

interface PaginationData {
  limit: number
  offset: number
}

export interface GetPayload {
  count: number
  next: PaginationData
  previous: PaginationData
  results: KMA[]
}

export async function getKMAs(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetPayload> | undefined]> {
  let error = null,
    result
  try {
    ;[error, result] = await to(post('api/kma/list', data, config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}
