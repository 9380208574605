import { Accessorial } from 'common/types/Accessorial'
import { DELIVERY_TYPE, PICKUP_TYPE, Stop, StopLabels } from 'common/types/Stop'
import Text from 'components/Text'
import Layout from 'components/Layout'
import { Tag } from 'components/Tag'
import isEmpty from 'lodash.isempty'
import { Fragment, useMemo } from 'react'
import Tooltip from 'components/Tooltip'
import keyBy from 'common/helpers/keyBy'

export interface AccessorialsListProps {
  stops: Stop[]
  accessorialsList: Accessorial[]
  className?: string
}

function AccessorialsList({ stops, accessorialsList, className }: AccessorialsListProps) {
  const accessorialsDict = useMemo(() => {
    return keyBy(accessorialsList, 'code')
  }, [accessorialsList])
  return (
    <Layout.Stack className="my-6">
      {stops.map((stop, index) => {
        const stopType = index === 0 ? PICKUP_TYPE : DELIVERY_TYPE
        return (
          <Fragment key={`${stopType}-${stop.id}`}>
            <Text variant="body">{StopLabels[stopType]} accessorials</Text>
            <Layout.Group>
              {isEmpty(stop.accessorials) ? (
                <Text variant="body" color="color-neutral">
                  No accessorials required
                </Text>
              ) : (
                stop.accessorials?.map((code) => {
                  const AccessorialTag = (
                    <Tag key={code} variant="default">
                      {accessorialsDict[code]?.name || code}
                    </Tag>
                  )
                  return accessorialsDict[code]?.description ? (
                    <Tooltip key={code} message={accessorialsDict[code].description}>
                      {AccessorialTag}
                    </Tooltip>
                  ) : (
                    AccessorialTag
                  )
                })
              )}
            </Layout.Group>
          </Fragment>
        )
      })}
    </Layout.Stack>
  )
}

export default AccessorialsList
