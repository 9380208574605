import { Commodity, PackageType, PACKAGE_TYPE_LABELS } from 'common/types/Commodity'
import { Table } from 'components/Table'
import Text from 'components/Text'
import { Column, useTable } from 'react-table'
import isEmpty from 'common/helpers/isEmpty'
import Empty from 'components/Empty'
import NumberHelpers from 'common/Number.helpers'
import { getHazmatByValue } from './Commodity.helpers'

export interface LTLCommoditiesTableProps {
  className?: string
  commodities?: Array<Commodity>
  variant?: 'full' | 'reduced'
}

const FULL_COLUMNS = [
  {
    Header: 'COMMODITY',
    accessor: 'description',
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{value}</Text>
    },
  },
  {
    Header: 'PCKG TYPE',
    id: 'package_type',
    accessor: (row: Commodity) => {
      return row.ltl_item?.packageType
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{PACKAGE_TYPE_LABELS[value as PackageType] || value}</Text>
    },
  },
  {
    Header: 'QUANTITY',
    id: 'quantity',
    accessor: (row: Commodity) => {
      return row.ltl_item?.count
    },
    style: {},
    Cell({ value }: { value: number }) {
      return <Text variant="body">{value}</Text>
    },
  },
  {
    Header: 'WEIGHT',
    id: 'weight',
    accessor: (row: Commodity) => {
      return row.ltl_item?.weight
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${NumberHelpers(value).format()} lbs`}</Text>
    },
  },
  {
    Header: 'LENGTH',
    id: 'length',
    accessor: (row: Commodity) => {
      return row.ltl_item?.length
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${NumberHelpers(value).format()}"`}</Text>
    },
  },
  {
    Header: 'WIDTH',
    id: 'width',
    accessor: (row: Commodity) => {
      return row.ltl_item?.width
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${NumberHelpers(value).format()}"`}</Text>
    },
  },
  {
    Header: 'HEIGHT',
    id: 'height',
    accessor: (row: Commodity) => {
      return row.ltl_item?.height
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${NumberHelpers(value).format()}"`}</Text>
    },
  },
  {
    Header: 'FREIGHT CLASS',
    accessor: (row: Commodity) => {
      return row.ltl_item?.classification
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${value}`}</Text>
    },
  },
  {
    Header: 'NMFC CODE',
    accessor: (row: Commodity) => {
      return row.ltl_item?.nmfcCode
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${value || '-'}`}</Text>
    },
  },
  {
    Header: 'STACKABLE',
    accessor: (row: Commodity) => {
      return row.ltl_item?.stackable
    },
    style: {},
    Cell({ value }: { value: boolean }) {
      return <Text variant="body">{`${value ? 'Yes' : 'No'}`}</Text>
    },
  },
  {
    Header: 'HAZMAT CLASS',
    accessor: 'hazmat_class',
    style: {},
    Cell({ value }: { value: string }) {
      const hazmatClass = getHazmatByValue(value)
      return <Text variant="body">{`${hazmatClass?.label || '-'}`}</Text>
    },
  },
]

const REDUCED_COLUMNS = [
  {
    Header: 'COMMODITY',
    accessor: 'description',
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{value}</Text>
    },
  },
  {
    Header: 'QUANTITY',
    id: 'quantity',
    accessor: (row: Commodity) => {
      return row.ltl_item?.count
    },
    style: {},
    Cell({ value }: { value: number }) {
      return <Text variant="body">{value}</Text>
    },
  },
  {
    Header: 'WEIGHT',
    id: 'weight',
    accessor: (row: Commodity) => {
      return row.ltl_item?.weight
    },
    style: {},
    Cell({ value }: { value: string }) {
      return <Text variant="body">{`${NumberHelpers(value).format()} lbs`}</Text>
    },
  },
  {
    Header: 'HAZMAT',
    accessor: 'hazmat',
    style: {},
    Cell({ value }: { value: boolean }) {
      return <Text variant="body">{value ? 'Yes' : 'No'}</Text>
    },
  },
]

function LTLCommoditiesTable({
  className,
  commodities = [],
  variant = 'full',
}: LTLCommoditiesTableProps) {
  const COLUMNS = variant === 'full' ? FULL_COLUMNS : REDUCED_COLUMNS
  const { rows, getTableBodyProps, headerGroups, prepareRow } = useTable({
    columns: COLUMNS as Column[],
    data: commodities,
  })
  return (
    <Table className="w-full">
      <Table.Head>
        {headerGroups.map((headerGroup) => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <Table.Cell header {...column.getHeaderProps()} style={column.style}>
                {column.render('Header')}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Head>
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row)
          return (
            <Table.Row {...row.getRowProps()} key={row.id}>
              {row.cells.map((cell: any) => {
                return (
                  <Table.Cell key={cell.column.id} style={cell.column.style}>
                    {cell.render('Cell')}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          )
        })}
      </Table.Body>
      {isEmpty(rows) && (
        <Table.Foot>
          <Table.Row>
            {/* @ts-ignore */}
            <Table.Cell colSpan={COLUMNS.length}>
              <Empty>No items to display.</Empty>
            </Table.Cell>
          </Table.Row>
        </Table.Foot>
      )}
    </Table>
  )
}

export default LTLCommoditiesTable
