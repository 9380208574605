import { useCallback, useEffect, useState, useRef } from 'react'
import { isBlank } from '@loadsmart/utils-string'

import { SuggestionProps, SuggestionOption, SuggestionQueryStatus } from './Suggestion.types'

import EventLike from '../../common/types/EventLike'
import Select, { getMapOfOptionsByValue, getOptionsFromValidValue } from '../Select'
import Status from '../../common/types/Status'
import useID from '../../hooks/useID'
import useSuggestion from './useSuggestion'
import toArray from '../../common/helpers/toArray'
import isEmpty from '../../common/helpers/isEmpty'

function Suggestion(props: SuggestionProps) {
  const {
    datasources,
    delay = 450,
    minQueryLength = 3,
    placeholder = '',
    disableDropdownIndicator = false,
    name,
    onChange,
    loadDataOnFocus = false,
    ...others
  } = props
  const id = useID(props.id)
  const [query, setQuery] = useState('')
  const { clear, fetch, options, status } = useSuggestion(delay, minQueryLength, ...datasources)
  const optionsByValue = useRef(
    getMapOfOptionsByValue([...toArray(options), ...getOptionsFromValidValue(props.value)]),
  )

  useEffect(
    function updateOptionsByValue() {
      // for async options, current value should still be an option
      const newOptions = toArray(others.value).reduce((array, option) => {
        const newOption = getOption(option?.value)
        if (!newOption) {
          return array
        }
        return [...array, newOption]
      }, [] as SuggestionOption[])

      optionsByValue.current = getMapOfOptionsByValue([...options, ...newOptions])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  )

  const handleQueryChange = useCallback(
    function handleQueryChange(query: string) {
      setQuery(query)

      if (!isBlank(query)) {
        fetch({ query })
      } else {
        clear()
      }
    },
    [fetch, clear],
  )

  const handleChange = useCallback(
    function handleChange(event: EventLike<unknown>) {
      function getValue(multiple = false, value: unknown) {
        if (!value || isEmpty(value)) {
          return null
        }

        if (multiple) {
          return toArray(value).reduce((array, entry) => {
            return [...(array as number[] | string[]), getOption(entry as string | number)]
          }, [] as SuggestionOption[])
        }

        return getOption(value as number | string)
      }

      const value = getValue(others.multiple, event.target.value)

      onChange?.({ target: { id, name, value } })

      const newOptions = toArray(value).map(({ value }) => getOption(value))

      clear({ options: newOptions })
    },
    [id, others.multiple, name, onChange, clear],
  )

  function getStatus() {
    if ([SuggestionQueryStatus.Querying, SuggestionQueryStatus.PartiallyDone].includes(status)) {
      return Status.Busy
    }

    return others.status || Status.Neutral
  }

  function getOption(value: string | number) {
    return optionsByValue.current[value] || null
  }

  const handleLoadDataOnFocus = () => {
    fetch({ query: '', allowEmptyQuery: true })
  }

  return (
    <Select
      {...others}
      id={id}
      query={query}
      onQueryChange={handleQueryChange}
      status={getStatus()}
      options={options}
      placeholder={placeholder}
      onChange={handleChange}
      disableDropdownIndicator={disableDropdownIndicator}
      onFocus={() => loadDataOnFocus && handleLoadDataOnFocus()}
    />
  )
}

Suggestion.defaultProps = {
  status: Status.Neutral,
  scheme: 'light',
}

export default Suggestion
