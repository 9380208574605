import { Fragment, useState, useCallback, useRef, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

import type { PartialMarginsFilter } from './MarginsList/MarginsList.types'
import { AddMarginRequest } from 'services/margins/margins.service'
import { useModal } from 'components/Modal'
import Breadcrumbs from 'components/Breadcumbs'
import Button from 'components/Button'
import Header from 'components/Header'
import Icon from 'components/Icon'
import MarginConflicts from './MarginConflicts'
import MarginCreationModal from './MarginCreationModal'
import MarginsFilter from './MarginsFilter'
import MarginsList, { MarginsListOperations } from './MarginsList'
import useAddMargin from './hooks/useAddMargin'
import isEmpty from 'common/helpers/isEmpty'
import DateHelper from 'common/Date.helpers'
import { isNil } from '@loadsmart/utils-object'

const TODAY = DateHelper.today()

function MarginsDashboard() {
  let { url } = useRouteMatch()
  const marginsListRef = useRef<MarginsListOperations>(null)
  const { id: modalID, open, show, hide } = useModal({ open: false })
  const { save, conflicts, clearConflicts } = useAddMargin()

  const [filters, setFilters] = useState<PartialMarginsFilter | undefined>()
  const [fetchedFrom, setFetchedFrom] = useState(TODAY)

  const handleFilterChange = useCallback(
    function handleFilterChange(fields: PartialMarginsFilter) {
      setFilters(isEmpty(fields) ? undefined : fields)
    },
    [setFilters],
  )

  async function saveMargin(margin: AddMarginRequest) {
    const { hasCreatedMargins } = await save(margin)

    if (hasCreatedMargins) {
      getMarginsList()?.refresh()
    }
  }

  function getMarginsList() {
    return marginsListRef.current
  }

  useEffect(() => {
    if (!filters?.date_range?.date_start) {
      if (fetchedFrom) {
        setFetchedFrom(null)
        getMarginsList()?.refresh()
      }

      return
    }

    const dateRangeStart = DateHelper(filters?.date_range?.date_start)
    if (
      (isNil(fetchedFrom) || !dateRangeStart?.is.same(fetchedFrom)) &&
      dateRangeStart?.is.before(TODAY)
    ) {
      setFetchedFrom(dateRangeStart)
      getMarginsList()?.refresh({
        pickup_date_end_gte: filters?.date_range?.date_start as string,
      })
    }
  }, [filters, fetchedFrom])

  return (
    <Fragment>
      <Header>
        <Breadcrumbs
          entries={[
            {
              label: 'Margin Manager',
              active: true,
              url,
            },
          ]}
        />
      </Header>
      <MarginsFilter onChange={handleFilterChange}>
        <Button
          leading={<Icon name="add" title="Add margin" size={16} />}
          variant="primary"
          onClick={show}
          data-testid="button-modal-margin"
        >
          New Margin
        </Button>
      </MarginsFilter>
      <MarginConflicts conflicts={conflicts} clearConflicts={clearConflicts} />
      <MarginCreationModal id={modalID} open={open} onSave={saveMargin} onClose={hide} />
      <MarginsList ref={marginsListRef} filters={filters} className="w-full mt-8" />
    </Fragment>
  )
}

export default MarginsDashboard
