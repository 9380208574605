import { PropsWithChildren, ReactNode, MouseEvent } from 'react'
import clsx from 'clsx'

import './Section.css'

const IGNORE_CLICK = (e: MouseEvent) => {
  // the default bevior is to function like an accordion; we don't want that yet.
  e.preventDefault()
}

function Section({
  className,
  children,
  title,
  trailing,
}: PropsWithChildren<{ className?: string; title: ReactNode; trailing?: ReactNode }>) {
  return (
    <details open className={clsx('', className)}>
      <summary onClick={IGNORE_CLICK} className="flex pb-1" tabIndex={-1}>
        <h3 className="inline-flex flex-row items-center flex-1 text-base font-bold outline-none text-neutral-darker">
          {title}
        </h3>
        {trailing}
      </summary>
      {children}
    </details>
  )
}

export default Section
