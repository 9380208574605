import Section from 'components/Section'
import ToggleGroup from 'components/ToggleGroup'
import Field from 'components/Field'
import Label from 'components/Label'
import useFilter from 'hooks/useFilter'
import { UsersFiltersFields } from './UsersList.types'
import TextField from '../../components/TextField'

export interface UsersFiltersProps {
  onChange: (fields: any) => void
}

function UsersFilters({ onChange }: UsersFiltersProps) {
  const { fields, setField } = useFilter<Partial<UsersFiltersFields>>({
    fields: { first_name: [''], last_name: [''], email: [''], username: [''] },
    onChange,
    options: {
      debounce: 500,
    },
  })

  return (
    <>
      <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
        <Section title="Filter users">
          <div className="flex flex-wrap mt-4">
            <Field className="flex-1 mr-7 w-36">
              <Label htmlFor="first_name">First Name</Label>
              <TextField
                name="first_name"
                id="first_name"
                placeholder="Type a first name"
                value={fields.first_name![0]}
                onChange={(e) => setField('first_name', [e.target.value])}
              />
            </Field>
            <Field className="flex-1 mr-7 w-36">
              <Label htmlFor="last_name">Last Name</Label>
              <TextField
                name="last_name"
                id="last_name"
                placeholder="Type a last name"
                value={fields.last_name![0]}
                onChange={(e) => setField('last_name', [e.target.value])}
              />
            </Field>
            <Field className="flex-1 mr-7 w-36">
              <Label htmlFor="username">Username</Label>
              <TextField
                name="username"
                id="username"
                placeholder="Type a username"
                value={fields.username![0]}
                onChange={(e) => setField('username', [e.target.value])}
              />
            </Field>
            <Field className="flex-1 mr-7 w-36">
              <Label htmlFor="email">E-mail</Label>
              <TextField
                name="email"
                id="email"
                placeholder="Type an e-mail"
                value={fields.email![0]}
                onChange={(e) => setField('email', [e.target.value])}
              />
            </Field>
            <Field className="w-max  mr-7">
              <Label htmlFor="status">Status</Label>
              <ToggleGroup
                id="status"
                name="status"
                className="w-max"
                multiple
                value=""
                onChange={(e) => {
                  const values = (e.target.value as string[]).filter(Boolean)
                  let statusFilter = null
                  if (values.indexOf('active') !== -1) {
                    statusFilter = true
                  }

                  if (values.indexOf('inactive') !== -1) {
                    statusFilter = statusFilter ? null : false
                  }

                  setField('is_active', statusFilter)
                }}
                options={[
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' },
                ]}
              />
            </Field>
          </div>
        </Section>
      </div>
    </>
  )
}

export default UsersFilters
