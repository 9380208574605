import { forwardRef, useMemo } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import parsePhoneNumber from 'libphonenumber-js'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import type { ButtonProps } from '@loadsmart/loadsmart-ui'
import { conditional, whenProps } from '@loadsmart/loadsmart-ui/dist/tools'

import Icon from 'components/Icon'

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  padding: ${conditional(
    {
      'button-padding-y': whenProps({ scale: 'default' }),
      'button-small-padding-y': whenProps({ scale: 'small' }),
      'button-large-padding-y': whenProps({ scale: 'large' }),
    },
    '0',
  )};
`

const Leading = styled(StyledSpan)<ButtonProps>``

const Trailing = styled(StyledSpan)<ButtonProps>``

const Children = styled.span<ButtonProps>`
  pointer-events: none;
  padding: ${conditional(
    {
      'button-padding-y': whenProps({ scale: 'default' }),
      'button-small-padding-y': whenProps({ scale: 'small' }),
      'button-large-padding-y': whenProps({ scale: 'large' }),
    },
    '0',
  )};

  ${({ children }: ButtonProps) =>
    typeof children === 'string'
      ? `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
      : ''}
`

const StyledButton = styled.button<ButtonProps>`
  font-family: ${token('font-family-default')};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: ${token('button-font-weight')};
  line-height: ${token('button-font-height')};

  transition-property: background, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  box-sizing: border-box;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  text-align: center;

  cursor: pointer;

  border-radius: ${token('button-border-radius')};
  border-width: ${token('button-border-width')};
  border-style: solid;
  border-color: ${token('color-neutral-darkest')};

  font-size: ${conditional({
    'button-font-size': whenProps({ scale: 'default' }),
    'button-small-font-size': whenProps({ scale: 'small' }),
  })};

  height: ${conditional({
    'button-height': whenProps({ scale: 'default' }),
    'button-small-height': whenProps({ scale: 'small' }),
  })};

  width: ${token('button-width')};

  padding: 0 8px;

  background: ${token('color-transparent')};
  color: ${token('color-neutral-darkest')};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    user-select: none;

    &:active {
      pointer-events: none;
    }
  }

  outline: none;

  &:enabled {
    &:hover {
      background: ${token('color-transparent')};
      border-color: ${token('color-neutral-dark')};
      color: ${token('color-neutral-dark')};
    }

    &:focus {
      outline: none;

      background: ${token('color-transparent')};
      border-color: ${token('color-neutral-dark')};
      color: ${token('color-neutral-dark')};

      box-shadow: ${token('shadow-glow-primary')};
    }

    &:active {
      background: ${token('color-transparent')};
      border-color: ${token('color-neutral-light')};
      color: ${token('color-neutral-light')};
    }
  }

  ${Children} {
    margin: 0 ${token('button-spacing-x')};
  }

  ${Leading} {
    margin: 0 0 0 ${token('button-spacing-x')};
  }

  ${Trailing} {
    margin: 0 ${token('button-spacing-x')} 0 0;
  }
`

export const BaseButton = forwardRef(function BaseButton(
  {
    children,
    className,
    leading,
    trailing,
    type = 'button',
    scale = 'default',
    ...others
  }: ButtonProps,
  ref: any,
) {
  return (
    <StyledButton ref={ref} {...others} className={clsx(className)} scale={scale} type={type}>
      {leading && <Leading scale={scale}>{leading}</Leading>}
      <Children scale={scale}>{children}</Children>
      {trailing && <Trailing scale={scale}>{trailing}</Trailing>}
    </StyledButton>
  )
})

const PhoneButtonContainer = styled.a`
  display: flex;
  height: 20px;
  color: ${token('color-neutral-darker')};
  border: 1px solid ${token('color-accent')};
  border-radius: 4px;
  padding-left: 4px;
  font-weight: 500;
  font-size: 14px;
  align-self: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  &:focus,
  &:focus-visible {
    box-shadow: 0px 0px 4px 0px ${token('color-accent')};
    outline: none;
    &:hover {
      box-shadow: 0px 0px 4px 0px ${token('color-neutral')};
      outline: none;
    }
  }

  i {
    background: ${token('color-accent')};
    border-radius: 0 4px 4px 0;
    color: #fff;
    padding: 3px;
    margin-left: 4px;
    margin-right: -1px;
  }
  &:hover {
    i {
      background: ${token('color-neutral')};
    }
    border: 1px solid ${token('color-neutral')};
  }
  &:active {
    i {
      background: ${token('color-neutral-light')};
    }
    border: 1px solid ${token('color-neutral-light')};
  }
`
const MissingPhoneInfo = styled.span`
  color: ${token('color-neutral')};
  white-space: nowrap;
`

export interface PhoneNumberButtonProps {
  className?: string
  phone?: string | null
  extension?: string | null
}

export const PhoneNumberButton = function PhoneNumberButton({
  className,
  phone,
  extension,
}: PhoneNumberButtonProps) {
  const [formattedPhoneNumber, phoneNumberURI] = useMemo(() => {
    const parsedPhoneNumber = parsePhoneNumber(`${phone};${extension}`)
    return [parsedPhoneNumber?.formatInternational(), parsedPhoneNumber?.getURI()]
  }, [phone, extension])
  return (
    <PhoneButtonContainer
      className={className}
      onClick={() => {}}
      tabIndex={0}
      href={phoneNumberURI}
    >
      {formattedPhoneNumber || <MissingPhoneInfo>No phone provided</MissingPhoneInfo>}
      <i>
        <Icon name="phone" size={13} />
      </i>
    </PhoneButtonContainer>
  )
}

export const LinkButton = styled.button`
  color: ${token('color-accent')};
  font-size: ${token('font-size-4')};
  cursor: pointer;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${token('color-accent')};
  outline: none;
  &:focus {
    outline: 0;
  }
`
