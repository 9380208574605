import React from 'react'

import { CustomFormikField } from 'packages/formik-miranda/src/CustomFormikField'
import { BaseField } from 'packages/formik-miranda/src/BaseField'
import { LabelProps } from '@loadsmart/loadsmart-ui'
import LocationSuggestion, {
  LocationSuggestionProps,
} from 'pages/Quotes/QuoteForm/Location/LocationSuggestion'
import isEmpty from 'common/helpers/isEmpty'
import LocationHelper from 'common/Location.helpers'
import { Location } from 'common/types/Location'

export interface LocationSuggestionFormikProps
  extends Omit<LocationSuggestionProps, 'name' | 'value' | 'form'> {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
}

const LocationSuggestionFormik = ({ ...props }: LocationSuggestionFormikProps) => {
  return (
    <CustomFormikField {...props}>
      {({ field: { value, ...field }, baseFieldProps, baseProps }) => {
        let currentValue = null

        if (!isEmpty(value)) {
          currentValue = value.map((item: Location) => {
            if (item.address) {
              return item
            }

            return {
              ...item,
              address: LocationHelper(item).formatWithoutEmptyFields(),
            }
          })
        }

        const mappedProps = {
          ...baseProps,
          status:
            props.status ??
            ((baseFieldProps.error ? 'danger' : undefined) as LocationSuggestionProps['status']),
          value: currentValue,
          ...field,
          ...props,
        }

        return (
          <BaseField {...baseFieldProps}>
            <LocationSuggestion {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

export default LocationSuggestionFormik
