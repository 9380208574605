import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'

import { Card } from 'components/Card'
import { ReactComponent as AddMarginIllustration } from './add-margin.svg'
import { ReactComponent as SmartRulesIllustration } from './smart-rules.svg'
import AdditionalRateProviderMessage from './AdditionalRateProviderMessage'
import RulesList from './RulesList'
import { RateProviderIdentifiers } from 'common/types/RateProvider'
import Logo from 'components/Logo'
import { Tag } from 'components/Tag'
import Icon from 'components/Icon'
import { IconButton } from 'components/Button'
import Text from 'components/Text'
import cond from 'common/helpers/cond'
import matches from 'common/helpers/matches'
import { Dialog, useDialog } from 'components/Dialog'
import RuleForm from './RuleForm'
import { RateProviderRule } from 'common/types/RateProviderRule'
import RuleDelete from './RuleDelete'
import ProviderMarginModal from '../ProviderMarginModal'

interface AdditionalRateProviderProps {
  rateProvider: RateProviderIdentifiers
  onAddMarginActionClick: () => void
  margin?: number
  onUpdateMargin: (margin: string | number) => void
  setHasRules: (hasRules: boolean) => void
}

export const RATE_PROVIDERS: Record<RateProviderIdentifiers, { title: string | ReactNode }> = {
  rate_guide: {
    title: 'Broker rate',
  },
  loadsmart: {
    title: <Logo className="fill-current text-primary-500" />,
  },
}

const VerticalSeparator = styled.span`
  width: 1px;
  margin: 0 20px 0;
  background: ${token('color-neutral-light')};
`

const StyledTag = styled(Tag)`
  background: ${token('color-neutral-lighter')};
`

type AdditionalRateProviderRulesActions = 'ADD' | 'EDIT' | 'DELETE'

const AdditionalRateProvider = ({
  rateProvider,
  margin,
  onAddMarginActionClick,
  onUpdateMargin,
  setHasRules,
}: AdditionalRateProviderProps) => {
  const [showDialog, setShowDialog] = useState<AdditionalRateProviderRulesActions>()
  const [selectedRule, setSelectedRule] = useState<RateProviderRule>()
  const rulesListRef = useRef<{ fetchRulesList: () => void }>()
  const [step, setStep] = useState<'add-margin' | 'add-smart-rule' | 'rules'>(
    margin ? 'rules' : 'add-margin',
  )
  const { open, show, hide } = useDialog({ open: false })

  useEffect(() => {
    setHasRules(step === 'rules')
  }, [step, setHasRules])

  const handleEmptyRules = useCallback(() => {
    setStep('add-smart-rule')
  }, [setStep])

  const handleRulesAction = (
    action: AdditionalRateProviderRulesActions,
    rule?: RateProviderRule,
  ) => {
    setShowDialog(action)
    setSelectedRule(rule)
  }

  const handleClose = (refetch?: boolean) => {
    if (refetch) {
      rulesListRef.current?.fetchRulesList()
    }

    setSelectedRule(undefined)
    setShowDialog(undefined)
  }

  const handleUpdateMargin = (margin: string | number) => {
    onUpdateMargin(margin)
    hide()
  }

  return (
    <>
      {open && (
        <ProviderMarginModal
          onSubmit={handleUpdateMargin}
          onCancel={() => hide()}
          open={open}
          initialMargin={margin}
        />
      )}
      <Card>
        <Card.Title>
          <div className="flex">
            {React.isValidElement(RATE_PROVIDERS[rateProvider].title) ? (
              RATE_PROVIDERS[rateProvider].title
            ) : (
              <Text variant="heading-md-bold">{RATE_PROVIDERS[rateProvider].title}</Text>
            )}
            {margin && (
              <>
                <VerticalSeparator />
                <StyledTag variant="default" size="default">
                  Margin: {margin} %
                </StyledTag>
                <IconButton className="ml-4" scale="small" onClick={show}>
                  <Icon name="edit" size={16} />
                </IconButton>
              </>
            )}
          </div>
        </Card.Title>
        <Card.Separator />
        <Card.Body>
          {step === 'add-margin' && (
            <AdditionalRateProviderMessage
              illustration={AddMarginIllustration}
              title="Add a margin to use this provider"
              description="To start using this rate provider, first you need to add a margin to enable it to receive the smart rules."
              buttonText="Add margin"
              onClickButton={onAddMarginActionClick}
            />
          )}
          {step === 'add-smart-rule' && (
            <AdditionalRateProviderMessage
              illustration={SmartRulesIllustration}
              title="It's time to add smart rules"
              description="Add rules to specify when the other rate provider should handle, otherwise, the default one will handle all the requested quotes."
              buttonText="add new rule"
              onClickButton={() => handleRulesAction('ADD')}
            />
          )}
          {step === 'rules' && (
            <RulesList
              onAddRule={() => handleRulesAction('ADD')}
              onEditRule={(rule) => handleRulesAction('EDIT', rule)}
              onDeleteRule={(rule) => handleRulesAction('DELETE', rule)}
              onEmptyRules={handleEmptyRules}
              rateProvider={rateProvider}
              ref={rulesListRef}
            />
          )}
          <Dialog open={Boolean(showDialog)} onOverlayClick={() => handleClose(false)}>
            {cond([
              [
                matches('ADD'),
                () => <RuleForm onClose={handleClose} rateProvider={rateProvider} />,
              ],
              [
                matches('EDIT'),
                () => (
                  <RuleForm rule={selectedRule} onClose={handleClose} rateProvider={rateProvider} />
                ),
              ],
              [matches('DEL'), () => <RuleDelete rule={selectedRule!} onClose={handleClose} />],
            ])(showDialog)}
          </Dialog>
        </Card.Body>
      </Card>
    </>
  )
}

export default AdditionalRateProvider
