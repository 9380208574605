import { memo } from 'react'
import isEqual from 'lodash.isequal'
import { QuotesFilterFields } from '../QuotesList/QuotesList.types'
import OverallPerformance from './OverallPerformance'
import QuotesSummaryLoader from './QuotesSummary/QuotesSummaryLoader'

interface AnalyticsProps {
  className?: string
  filters?: Partial<QuotesFilterFields>
}

const QuoteAnalytics = ({ className, filters }: AnalyticsProps) => {
  if (!filters || !filters.created_at) {
    return null
  }

  return (
    <div className={className}>
      <div className="flex flex-row flex-wrap w-full pt-4">
        <QuotesSummaryLoader filters={filters} />
      </div>
      <div className="pt-4 min-w-min">
        <OverallPerformance filters={filters} className="pb-8" />
      </div>
    </div>
  )
}

export default memo(QuoteAnalytics, (prev, next) => isEqual(prev.filters, next.filters))
