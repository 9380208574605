/* eslint-disable no-template-curly-in-string */
import { Location } from './types/Location'
import { isBlank } from '@loadsmart/utils-string'
import template from './helpers/template'
import get from './helpers/get'

const ADDRESS = '${address}'
const CITY = '${city}'
const COUNTRY = '${country}'
const STATE = '${state}'
const ZIPCODE = '${zipcode}'
const KMA = '${kma}'

const DEFAULT_FORMAT = '${city}, ${state} ${zipcode}, ${country}'
const formats: Record<string, any> = {}

function getCompiledFormat(format: string): (...args: any[]) => string {
  if (isBlank(format)) {
    return getCompiledFormat(DEFAULT_FORMAT)
  }

  if (!formats[format]) {
    formats[format] = template(format)
  }

  return formats[format]
}

function compareCaseInsensitive(a?: string, b?: string) {
  return a && b && a.toLowerCase() === b.toLowerCase()
}
function LSLocation(locationArg: Location) {
  const location = {
    address: '',
    city: '',
    country: '',
    state: '',
    zipcode: '',
    ...locationArg,
  }

  return {
    /**
     * Get location formatted with the default or the provided format.
     */
    format(format = DEFAULT_FORMAT) {
      const compiled = getCompiledFormat(format)
      return compiled(location)
    },
    /**
     *
     * @param {Location} otherLocationArg - Location to be compared with.
     * @param {boolean} strict - `true` if all location properties must match. Default is `false`, which means
     * if any property matches, we return `true`.
     */
    matches(otherLocationArg: Location, strict = false) {
      function propertyMatches(property: string) {
        if (get(otherLocationArg, property)) {
          return compareCaseInsensitive(get(otherLocationArg, property), get(location, property))
        }

        return false
      }

      function runLooseMatch() {
        if (['kma', 'zipcode', 'city', 'state'].some(propertyMatches)) {
          return true
        }

        return (
          !location.country || compareCaseInsensitive(location.country, otherLocationArg.country)
        )
      }

      function runStrictMatch() {
        return ['kma', 'zipcode', 'city', 'state', 'country'].every(propertyMatches)
      }

      if (strict) {
        return runStrictMatch()
      }

      return runLooseMatch()
    },
    formatWithoutEmptyFields() {
      let currentFormat = ''

      currentFormat += location.city ? '${city}, ' : 'All Cities, '
      currentFormat += location.state ? '${state} ${zipcode}, ' : 'All, '
      currentFormat += location.country ? '${country}' : 'All'

      return this.format(currentFormat)
    },
  }
}

LSLocation.ADDRESS = ADDRESS
LSLocation.CITY = CITY
LSLocation.COUNTRY = COUNTRY
LSLocation.STATE = STATE
LSLocation.ZIPCODE = ZIPCODE
LSLocation.KMA = KMA

export default LSLocation
