// import analytics from 'utils/analytics'
import tokenManager from '../../common/tokenManager'
import { httpClient, post } from '../../common/HTTPClient'

import store from '../../app/store'
import { logout as logoutAction } from '../../features/auth/auth.actions'
import sessionManager from 'common/sessionManager'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { to } from 'common/helpers/awaitTo'

/**
 * Given user credentials, request access and refresh token to the Auth API.
 * @param username
 * @param password
 */
export async function requestTokens(username: string, password: string) {
  try {
    const { data } = await httpClient.post('/api/auth/token', {
      username,
      password,
    })

    return {
      accessToken: data.access,
      refreshToken: data.refresh,
    }
  } catch (error) {
    console.error(error)
  }

  return {
    accessToken: '',
    refreshToken: '',
  }
}

export async function refreshToken(): Promise<string> {
  const { data } = await httpClient.post('/api/auth/token/refresh', {
    refresh: tokenManager.getRefreshToken(),
  })

  tokenManager.setToken(data.access)
  tokenManager.setRefreshToken(data.refresh)

  sessionManager.setSession(data.access)

  return data.access
}

export function logout() {
  store.dispatch(
    logoutAction(() => {
      if (window.location.pathname !== '/login') {
        window.location.replace(`/login?next=${window.location.pathname}`)
      }
    }),
  )
}

export async function forgotPassword(
  email: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse | undefined]> {
  try {
    return await to(post('/api/auth/password_reset', { email }, config))
  } catch (error) {
    console.error(error)
  }

  return [null, undefined]
}

export async function resetPassword(
  token: string,
  password: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse | undefined ]> {
  try {
    return await to(post('/api/auth/password_reset/confirm', { password, token }, config))
  } catch (error) {
    console.error(error)
  }

  return [null, undefined]
}
