import { AppThunk } from '../../app/store'
import slice from './settings.slice'

import { getSettings, GetSettingsPayload} from '../../services/settings/settings.service'

export const { setSettings, setSettingsFailed} = slice.actions

export function loadSettings(callback?: () => void): AppThunk {
  return async (dispatch) => {
    const [error, data] = await getSettings()
    if (error) {
      dispatch(setSettingsFailed())
    } else {
      dispatch(setSettings(data?.data as GetSettingsPayload))
      callback?.()
    }
  }
}