import { Bar } from './index'
import React from 'react'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

export interface RangedBarProps {
  fromDataKey: string
  toDataKey: string
  isAnimationActive?: boolean
}

const RenderLine = ({ fromDataKey, toDataKey, isAnimationActive = false }: RangedBarProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  return (
    <>
      <Bar
        dataKey={fromDataKey}
        stackId="a"
        barSize={10}
        fill="transparent"
        isAnimationActive={isAnimationActive}
      />
      <Bar
        dataKey={toDataKey}
        stackId="a"
        barSize={10}
        fill={theme['color-neutral-lighter']}
        stroke="gray"
        isAnimationActive={isAnimationActive}
      />
    </>
  )
}

export default RenderLine
