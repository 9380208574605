import QuotesCreationOverTime, { QuotesCreationOverTimeProps } from 'components/BrokerInsights/QuotesCreationOverTime'
import { useEffect, useMemo, useState } from 'react'
import useCancelToken from 'hooks/useCancelToken'
import merge from 'common/helpers/merge'
import groupBy from 'common/helpers/groupBy'
import { getQuotesCreationOverTime, GetQuotesCreationOverTimePayload } from 'services/analytics/analytics.service'
import { toast } from 'components/Toast'
import Loading from 'components/Loading'
import { QuotesFilterFields } from '../../QuotesList/QuotesList.types'
import DateHelper from 'common/Date.helpers'
import QuoteFiltersHelper from 'common/QuoteFilters.helpers'

interface QuotesCreationOverTimeLoaderProps {
  filters: Partial<QuotesFilterFields>
  chartWidth?: number | string
  chartHeight?: number | string
  className?: string
}

const QuotesCreationOverTimeLoader = ({
  filters,
  chartHeight,
  chartWidth,
  className,
}: QuotesCreationOverTimeLoaderProps) => {
  const [data, setData] = useState<GetQuotesCreationOverTimePayload>(
    {} as GetQuotesCreationOverTimePayload,
  )
  const [loading, setLoading] = useState(false)
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const displayHourlyTricks = useMemo(() => {
    return QuoteFiltersHelper(filters).getCreatedAtDifference('days') < 3
  }, [filters])

  useEffect(() => {
    async function fetch() {
      cancelPending()
      setLoading(true)
      const source = getSource()

      const [, response] = await getQuotesCreationOverTime(
        { filters },
        {
          cancelToken: source.token,
        },
      )

      clearSource()

      if (response) {
        setData(response.data)
      } else {
        toast.error('We could not retrieve the chart data.')
        setData({} as GetQuotesCreationOverTimePayload)
      }

      setLoading(false)
    }
    fetch()
  }, [cancelPending, clearSource, filters, getSource])

  const formattedData: QuotesCreationOverTimeProps['data'] = useMemo(() => {
    if (!data || !data.series) {
      return {} as QuotesCreationOverTimeProps['data']
    }

    const allData = ([] as unknown[]).concat(
      ...data.series.map((item) => item.data.map((el) => ({ ...el, [item.id]: el.value }))),
    )

    const groupedByDate = groupBy(allData, 'timestamp')
    const formattedSeries = Object.keys(groupedByDate).map((key) => {
      const item = merge({}, ...groupedByDate[key])

      return {
        date: DateHelper(item.timestamp)?.format(displayHourlyTricks ? 'hh:mmA' : 'MM/DD') || '',
        drv: item['DRV'] ? Number(item['DRV']) : 0,
        rfr: item['RFR'] ? Number(item['RFR']) : 0,
        fbe: item['FBE'] ? Number(item['FBE']) : 0,
      }
    })

    return { series: formattedSeries }
  }, [data, displayHourlyTricks])

  if (loading) {
    return <Loading className="justify-center" />
  }

  if (!data.series) {
    return null
  }

  return (
    <div className={className}>
      <QuotesCreationOverTime
        data={formattedData}
        chartHeight={chartHeight}
        chartWidth={chartWidth}
      />
    </div>
  )
}

export default QuotesCreationOverTimeLoader
