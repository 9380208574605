interface ParsedJWT {
  [key: string]: string
}

function parseJWT(token: string): ParsedJWT | null {
  if (!token) return null

  const base64Url = token.split('.')[1]

  if (!base64Url) return null

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  } catch (e) {
    console.error(e)
    return null
  }
}

export default parseJWT
