import { isObject, isNil } from '@loadsmart/utils-object'
import { isBlank } from '@loadsmart/utils-string'

import Status from 'common/types/Status'
import toArray from 'common/helpers/toArray'
import isEmpty from 'common/helpers/isEmpty'
import ColorScheme from 'common/types/ColorScheme'
import { RateGuideThemeInterface } from '../../theming'
import { CSSProperties } from 'react'

function getBackgroundColor(
  {
    isDisabled,
    isSelected,
    isFocused,
    scheme,
  }: {
    isDisabled: boolean
    isSelected: boolean
    isFocused: boolean
    scheme: ColorScheme
  },
  theme: RateGuideThemeInterface,
) {
  if (isDisabled) {
    return null
  }

  if (isSelected || isFocused) {
    if (scheme === 'light') {
      return theme['color-neutral-lighter']
    }

    return theme['color-neutral-dark']
  }

  if (scheme === 'light') {
    return theme['color-neutral-lightest']
  }

  return theme['color-neutral-darker']
}

function getTexColor(
  {
    isDisabled,
    scheme,
  }: {
    isDisabled: boolean
    scheme: string
  },
  theme: RateGuideThemeInterface,
) {
  if (isDisabled) {
    if (scheme === 'light') {
      return theme['color-neutral-lighter']
    }

    return theme['color-neutral-darker']
  }

  if (scheme === 'light') {
    return theme['color-neutral-darker']
  }

  return theme['color-neutral-lightest']
}

export function getStyles(
  { scheme, status }: { scheme: ColorScheme; status: Status },
  theme: RateGuideThemeInterface,
  customStylesCallbacks?: { options?: (params: any) => CSSProperties },
) {
  return {
    control: (styles: Record<string, any>, { isFocused }: { isFocused: boolean }) => ({
      ...styles,
      backgroundColor:
        scheme === 'light' ? theme['color-neutral-lightest'] : theme['color-neutral-darker'],
      borderColor:
        status === Status.Danger
          ? theme['color-danger']
          : isFocused
          ? theme['color-primary']
          : scheme === 'light'
          ? theme['color-neutral-darker']
          : theme['color-neutral-light'],
      minHeight: 36,
      ...(isFocused && { boxShadow: 'none' }),
      ':hover': {
        ...styles[':hover'],
        borderColor: isFocused
          ? theme['color-primary']
          : scheme === 'light'
          ? theme['color-neutral-darker']
          : theme['color-neutral-light'],
      },
    }),
    option: (
      styles: Record<string, any>,
      {
        data,
        isDisabled,
        isSelected,
        isFocused,
        ...rest
      }: { data: any; isDisabled: boolean; isSelected: boolean; isFocused: boolean },
    ) => {
      const backgroundColor = getBackgroundColor(
        {
          isDisabled,
          isSelected,
          isFocused,
          scheme,
        },
        theme,
      )

      const textColor = getTexColor(
        {
          isDisabled,
          scheme,
        },
        theme,
      )

      return {
        ...styles,
        fontSize: 14,
        ...(backgroundColor && { backgroundColor }),
        ...(textColor && { color: textColor }),
        ...(isFocused && { fontWeight: 600 }),
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor:
            scheme === 'light' ? theme['color-neutral-light'] : theme['color-neutral-darker'],
        },
        ...(customStylesCallbacks &&
          customStylesCallbacks.options &&
          customStylesCallbacks.options({ data, isDisabled, isSelected, isFocused, ...rest })),
      }
    },
    menu: (styles: Record<string, any>) => {
      return {
        ...styles,
        backgroundColor:
          scheme === 'light' ? theme['color-neutral-lightest'] : theme['color-neutral-darker'],
        marginTop: 4,
      }
    },
    valueContainer: (styles: Record<string, any>) => {
      return {
        ...styles,
        padding: '2px 8px',
        borderRadius: 4,
        backgroundColor:
          scheme === 'light' ? theme['color-neutral-lightest'] : theme['color-neutral-darker'],
        borderColor:
          scheme === 'light' ? theme['color-neutral-darker'] : theme['color-neutral-light'],
      }
    },
    placeholder: (styles: Record<string, any>) => {
      return {
        ...styles,
        color: scheme === 'light' ? theme['color-neutral-darker'] : theme['color-neutral-lightest'],
        fontSize: 14,
        whiteSpace: 'nowrap' as CSSProperties['whiteSpace'],
      }
    },
    singleValue: (styles: Record<string, any>) => {
      return {
        ...styles,
        color: scheme === 'light' ? theme['color-neutral-darker'] : theme['color-neutral-lightest'],
        fontSize: 14,
      }
    },
    multiValue: (styles: Record<string, any>) => {
      return {
        ...styles,
        backgroundColor:
          scheme === 'light' ? theme['color-neutral-light'] : theme['color-neutral-dark'],
        color: scheme === 'light' ? theme['color-neutral-darker'] : theme['color-neutral-lighter'],
      }
    },
    multiValueLabel: (styles: Record<string, any>) => {
      return {
        ...styles,
        fontSize: 14,
        padding: '2px 4px',
        height: 24,
        color: scheme === 'light' ? theme['color-neutral-darker'] : theme['color-neutral-lighter'],
      }
    },
    multiValueRemove: (styles: Record<string, any>) => {
      return {
        ...styles,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        ':hover': {
          ...styles[':hover'],
          color: theme['color-danger-dark'],
          backgroundColor: theme['color-danger-light'],
        },
      }
    },
    container: (styles: Record<string, any>) => {
      return {
        ...styles,
        width: '100%',
        minWidth: 'calc(40px + 2ch)',
        backgroundColor: 'transparent',
      }
    },
    input: (styles: Record<string, any>) => {
      return {
        ...styles,
        fontSize: 14,
        padding: 0,
        color: scheme === 'light' ? theme['color-neutral-darker'] : theme['color-neutral-lightest'],
      }
    },
    dropdownIndicator: (styles: Record<string, any>) => {
      return {
        ...styles,
        ':hover': {
          ...styles[':hover'],
          color: theme['color-neutral-light'],
        },
      }
    },
    clearIndicator: (styles: Record<string, any>) => {
      return {
        ...styles,
        ':hover': {
          ...styles[':hover'],
          color: theme['color-neutral-light'],
        },
      }
    },
    noOptionsMessage: (styles: Record<string, any>) => {
      return {
        ...styles,
        fontSize: 14,
      }
    },
    loadingMessage: (styles: Record<string, any>) => {
      return {
        ...styles,
        fontSize: 14,
      }
    },
  }
}

export function getMapOfOptionsByValue(options: any[]) {
  return toArray(options).reduce((map, option) => {
    return { ...map, [option.value]: option }
  }, {})
}

function isValidOption(option: { value: null; label: null }) {
  return option.value != null && option.label != null
}

/**
 * Convert the provided value to the react-select equivalent.
 * @param {Object} optionsByValue - Object that maps option value to option.
 * @param {boolean} multiple - Is select multiple.
 * @param {string|number|Object|Object[]|null|undefined} value - prop value provided.
 * @returns {Object|Object[]|null}
 */
export function getReactSelectValue(optionsByValue: any, multiple: any, value: any) {
  const safeOptionsByValue = optionsByValue || {}

  if (multiple) {
    return toArray(value).reduce((array, entry) => {
      if (isObject(entry) && isValidOption(entry)) {
        return [...array, entry]
      } else if (!isNil(safeOptionsByValue[entry])) {
        return [...array, safeOptionsByValue[entry]]
      }

      return array
    }, [])
  }

  if (isObject(value) && isValidOption(value)) {
    return value
  } else if (!isNil(safeOptionsByValue[value])) {
    return safeOptionsByValue[value]
  }

  return null
}

/**
 * Handle the value react-select exposes on change and convert to the equivalent
 * of the native select value.
 * @param {boolean} multiple
 * @param {((string | number) & (number[] | string[])) | null} value
 * @returns {((string | number) & (number[] | string[])) | null}
 */
export function getOutputValue(multiple: any, value: any) {
  if (isEmpty(value) || isBlank(value)) {
    return null
  }

  if (multiple) {
    return toArray(value).reduce((array, entry) => {
      return [...array, entry.value]
    }, [])
  }

  return value.value
}

export function getOptionsFromValidValue(value: any) {
  if (isEmpty(value) || isBlank(value)) {
    return []
  }

  return toArray(value).reduce((array, entry) => {
    if (isObject(entry) && isValidOption(entry)) {
      return [...array, entry]
    }

    return array
  }, [])
}
