import { useLocation } from 'react-router'

import CurrentUserHelper from 'common/CurrentUser.helper'
import Logo from 'components/Logo'
import SideNavigation from 'components/SideNavigation'

function MenuItem(props: { label: string; url: string }) {
  const location = useLocation()

  return (
    <SideNavigation.Menu.Item
      label={props.label}
      url={props.url}
      active={location.pathname === props.url}
    />
  )
}

export function Sidebar() {
  const currentUserHelper = CurrentUserHelper()

  return (
    <SideNavigation>
      <SideNavigation.Logo url="/">
        <Logo />
      </SideNavigation.Logo>

      <SideNavigation.Menu label="Quote & Book">
        <MenuItem url="/quotes" label="Quotes Dashboard" />
        <MenuItem url="/margins" label="Margin Manager" />
      </SideNavigation.Menu>

      {currentUserHelper.hasAllGroups(['Account Manager']) && (
        <SideNavigation.Menu label="Settings">
          {currentUserHelper.hasAllGroups(['White Label Manager']) && (
            <MenuItem url="/white-label-settings" label="EasyQuote" />
          )}
          <MenuItem url="/settings/shippers" label="Shippers" />
          <MenuItem url="/settings/users" label="Users" />
        </SideNavigation.Menu>
      )}
    </SideNavigation>
  )
}
