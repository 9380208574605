import { toast } from 'components/Toast'
import { selectRate } from 'services/quotes/quotes.service'
import { selectRate as selectRateWhiteLabel } from 'pages/WhiteLabel/services/whiteLabel.service'
import LTLRateSelection, { LTLRateSelectionProps } from './LTLRateSelection'

interface LTLRateSelectionWrapperProps extends Omit<LTLRateSelectionProps, 'onRateSelection'> {
  isWhiteLabel?: boolean
}

function LTLRateSelectionWrapper({
  quote,
  setQuote,
  isWhiteLabel,
  ...rest
}: LTLRateSelectionWrapperProps) {
  const handleRateSelection = async (selectedRateId: string) => {
    const action = isWhiteLabel ? selectRateWhiteLabel : selectRate
    const [, result] = await action(quote.quote_id as string, selectedRateId)

    if (result) {
      setQuote(result.data)
      return true
    }
    toast.error('We could not select this provider.')
    return false
  }

  return (
    <LTLRateSelection
      quote={quote}
      setQuote={setQuote}
      onRateSelection={handleRateSelection}
      {...rest}
    />
  )
}

export default LTLRateSelectionWrapper
