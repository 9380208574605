import { useEffect, useMemo, useState } from 'react'
import { PriceOverTimeData } from 'components/BrokerInsights/OverallPrice/PriceOverTime'
import { QuotesFilterFields } from '../../QuotesList/QuotesList.types'
import { toast } from 'components/Toast'
import useCancelToken from 'hooks/useCancelToken'
import Loading from 'components/Loading'
import { getPricesOverTime, GetPricesOverTimePayload } from 'services/analytics/analytics.service'
import OverallPrice from 'components/BrokerInsights/OverallPrice'
import { OverallPriceProps } from 'components/BrokerInsights/OverallPrice/OverallPrice'
import QuoteFiltersHelper from 'common/QuoteFilters.helpers'
import DateHelper from 'common/Date.helpers'
import merge from 'common/helpers/merge'
import groupBy from 'common/helpers/groupBy'

interface PriceOverTimeLoaderProps {
  filters: Partial<QuotesFilterFields>
  chartHeight?: number | string
  chartWidth?: number | string
  className?: string
}

const PriceOverTimeLoader = ({
  filters,
  chartHeight,
  chartWidth,
  className,
}: PriceOverTimeLoaderProps) => {
  const [data, setData] = useState<GetPricesOverTimePayload>({} as GetPricesOverTimePayload)

  const [loading, setLoading] = useState(false)
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const displayHourlyTricks = useMemo(() => {
    return QuoteFiltersHelper(filters).getCreatedAtDifference('days') < 3
  }, [filters])

  useEffect(() => {
    async function fetch() {
      cancelPending()

      setLoading(true)

      const source = getSource()

      const [, response] = await getPricesOverTime(
        { filters },
        {
          cancelToken: source.token,
        },
      )

      clearSource()

      if (response) {
        setData(response.data)
      } else {
        toast.error('We could not retrieve the chart data.')
        setData({} as GetPricesOverTimePayload)
      }

      setLoading(false)
    }
    fetch()
  }, [cancelPending, clearSource, filters, getSource])

  const formattedData: OverallPriceProps['data'] = useMemo(() => {
    if (!data.series) {
      return {} as OverallPriceProps['data']
    }

    const allData = ([] as unknown[]).concat(
      ...data.series.map((item) => item.data.map((el) => ({ ...el, [item.id]: el.value }))),
    )

    const groupedByDate = groupBy(allData, 'timestamp')
    const formattedSeries = Object.keys(groupedByDate).map((key) => {
      const item = merge({}, ...groupedByDate[key])

      return {
        date: DateHelper(item.timestamp)?.format(displayHourlyTricks ? 'hh:mmA' : 'MM/DD') || '',
        'rate-guide': item['rate-guide'] && Number(item['rate-guide']),
        market: item.market && Number(item.market),
        min: item.min && Number(item.min),
        max: item.max && Number(item.max),
      } as PriceOverTimeData
    })

    return {
      series: formattedSeries,
      summary: data.summary,
    }
  }, [data.series, data.summary, displayHourlyTricks])

  if (loading) {
    return <Loading className="justify-center" />
  }

  if (!data.series) {
    return null
  }

  return (
    <div className={className}>
      <OverallPrice data={formattedData} chartHeight={chartHeight} chartWidth={chartWidth} />
    </div>
  )
}

export default PriceOverTimeLoader
