import toArray from './helpers/toArray'
import LoadsmartURL from './LoadsmartURL.helpers'
import { QuoteRate, RateProvider } from './types/QuoteRate'

function ProviderHelper(rate: QuoteRate | undefined) {
  return {
    is(provider: RateProvider | RateProvider[]) {
      return Boolean(rate && toArray(provider).includes(rate.rate_provider))
    },
    getBookingUrl() {
      switch (rate?.rate_provider) {
        case 'loadsmart':
          return LoadsmartURL().quote.checkout(String(rate.external_id))
        default:
          return
      }
    },
  }
}

export default ProviderHelper
