import { ReactNode, Ref, TextareaHTMLAttributes } from 'react'
import clsx from 'clsx'

import Status from '../../common/types/Status'
import ColorScheme from '../../common/types/ColorScheme'
import getConditionalClasses from '../../common/helpers/getConditionalClasses'

import './TextArea.css'

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string
  children?: ReactNode
  status?: Status
  scheme?: ColorScheme
  forwardedRef?: Ref<HTMLTextAreaElement>
}

const combine = getConditionalClasses({
  variant: ['primary', 'secondary', 'warning'],
  scheme: ['light', 'dark'],
  status: ['neutral', 'danger'],
})

function TextArea(props: TextAreaProps): JSX.Element {
  const { className, disabled = false, status, scheme, forwardedRef, ...others } = props
  const when = combine(props)

  return (
    <textarea
      ref={forwardedRef}
      data-testid="textarea"
      {...others}
      className={clsx(
        'rg-text-area text-sm outline-none px-2 py-1.5 rounded border transition-colors',
        {
          '-dark text-neutral-lightest bg-neutral-darker': when('dark'),
          'text-neutral-darker bg-neutral-lightest ': when(['light', 'neutral']),
        },
        {
          'border-neutral-darker': when(['light', 'neutral']),
          'border-neutral-light': when(['dark', 'neutral']),
          'border-danger': when(['light', 'danger'], ['dark', 'danger']),
        },
        {
          'active:border-primary-500 focus:border-primary-500': !disabled,
          'disabled:cursor-not-allowed disabled:opacity-40': disabled,
        },
        className,
      )}
      disabled={disabled}
    />
  )
}

TextArea.defaultProps = {
  status: Status.Neutral,
  scheme: 'light',
}

export default TextArea
