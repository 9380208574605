import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { post, patch, del } from '../../common/HTTPClient'
import { to } from '../../common/helpers/awaitTo'
import { Shipper } from '../../common/types/Shipper'
import { ShipperEntity } from 'common/types/ShipperEntity'

interface PaginationOptions {
  limit: number
  offset: number
}

export interface SearchShippersPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: Shipper[]
}

export async function searchShipper(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<SearchShippersPayload> | undefined]> {
  let error = null,
    result
  try {
    ;[error, result] = await to(post('/api/search/shippers', data, config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}

export interface ListShippersData {
  limit: number
  offset: number
  filters?: {
    name: ShipperEntity['name']
    mileage_provider: ShipperEntity['mileage_provider']
    status: ShipperEntity['status']
    id: ShipperEntity['id']
  }
}

export interface ListShippersPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: ShipperEntity[]
}

export async function listShippers(
  data?: ListShippersData,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | boolean | null, AxiosResponse<ListShippersPayload> | undefined]> {
  try {
    return await to(post('/api/shippers/list', data, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function createShipper(
  data?: Partial<ShipperEntity>,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | boolean | null, AxiosResponse<ShipperEntity> | undefined]> {
  try {
    return await to(post('/api/shippers', data, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function editShipper(
  data?: Partial<ShipperEntity>,
): Promise<[AxiosError | boolean | null, AxiosResponse<ShipperEntity> | undefined]> {
  try {
    return await to(patch(`/api/shippers/${data?.id}`, data))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function deleteShipper(
  userId: string,
): Promise<[AxiosError | boolean | null, AxiosResponse<ShipperEntity> | undefined]> {
  try {
    return await to(del(`/api/shippers/${userId}`))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}
