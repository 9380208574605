import { ChangeEvent } from 'react'
import EventLike from '../types/EventLike'

function getValueFromEvent<T>(e: ChangeEvent<HTMLInputElement> | EventLike<T>) {
  const {
    target,
    target: { type },
  } = e
  const value = type === 'checkbox' ? target.checked : target.value

  return value
}

export default getValueFromEvent
