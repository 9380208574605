import { Location } from '../../../common/types/Location'
import { MarginResult } from '../../../common/types/Margin'

function getCountry(location: Location) {
  return location.country || 'All'
}

function getState(location: Location) {
  if (location.kma) {
    return location.kma.split('_')[0]
  }

  return location.state || 'ALL'
}

function getCity(location: Location) {
  if (location.kma) {
    return location.kma
  }

  if (location.zipcode) {
    return location.zipcode
  }

  return location.city || 'All Cities'
}

function LocationRenderer({ location }: { location: Location }) {
  return (
    <div className="flex flex-row items-center justify-start px-3 space-x-4">
      <span>{getCountry(location)}</span>
      <span>{getState(location)}</span>
      <span>{getCity(location)}</span>
    </div>
  )
}

export function InboundRenderer({ margin }: { margin: MarginResult }) {
  return <LocationRenderer location={margin.destination} />
}

export function OutboundRenderer({ margin }: { margin: MarginResult }) {
  return <LocationRenderer location={margin.origin} />
}

export default LocationRenderer
