import React from 'react'
import SummaryCard, { SummaryCardProps } from './SummaryCard'
import { replaceWhiteSpaces } from 'common/utils'

interface SummaryHeaderProps {
  data: SummaryCardProps[]
}

const SummaryHeader = ({ data }: SummaryHeaderProps) => {
  return (
    <div className="flex flex-row w-full">
      {data.map((item) => (
        <SummaryCard {...item} key={replaceWhiteSpaces(item.title, '_')} />
      ))}
    </div>
  )
}

export default SummaryHeader
