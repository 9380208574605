import React from 'react'

import Checkbox from 'components/Checkbox'
import Icon from 'components/Icon'
import Layout from 'components/Layout'
import Text from 'components/Text'
import Tooltip from 'components/Tooltip'

import type { Accessorial } from 'common/types/Accessorial'
import type { StopType } from 'common/types/Stop'

type AccessorialOptionProps = {
  option: Accessorial
  checked: boolean
  onChange: () => void
}

function AccessorialOption(props: AccessorialOptionProps) {
  return (
    <Checkbox checked={props.checked} onChange={props.onChange}>
      <Layout.Group space="xs" align="center">
        <Text>{props.option.name}</Text>
        {props.option.description ? (
          <Tooltip message={props.option.description}>
            <Icon name="question-circle" size={16} title="" aria-hidden="true" />
          </Tooltip>
        ) : null}
      </Layout.Group>
    </Checkbox>
  )
}

function getLabel(type: StopType): string {
  return {
    pu: 'pickup',
    del: 'delivery',
  }[type]
}

export type AccessorialProps = {
  onChange: (code: string) => void
  type: StopType
  value: Record<string, boolean>
  accessorials: Accessorial[]
}

export function Accessorials({ type, accessorials, onChange, value }: AccessorialProps) {
  return (
    <Layout.Stack className="py-6" role="group" aria-labelledby={`${type}-accessorials`}>
      <Text as="h3" variant="body-bold" color="color-neutral-darker" id={`${type}-accessorials`}>
        Accessorials for {getLabel(type)}
      </Text>

      <Layout.Grid space="m" minColumnWidth="30%">
        {accessorials.map((option) => (
          <AccessorialOption
            key={option.code}
            option={option}
            onChange={() => onChange(option.code)}
            checked={value[option.code]}
          />
        ))}
      </Layout.Grid>
    </Layout.Stack>
  )
}
