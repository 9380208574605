import { CancelTokenSource } from 'axios'
import { useCallback, useRef } from 'react'

import { getCancelTokenSource } from '../../common/HTTPClient'

function useCancelToken() {
  const sourceRef = useRef<CancelTokenSource | null>(null)

  const cancelPending = useCallback(function cancelPending() {
    if (!sourceRef.current) {
      return
    }

    const source = sourceRef.current
    source.cancel('Request was cancelled.')
    console.log('[wms] Request was cancelled.')
  }, [])

  const getSource = useRef(() => {
    sourceRef.current = getCancelTokenSource()

    return sourceRef.current
  })

  const clearSource = useRef(() => {
    sourceRef.current = null
  })

  return {
    cancelPending,
    getSource: getSource.current,
    clearSource: clearSource.current,
  }
}

export default useCancelToken
