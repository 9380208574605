import React, { useMemo } from 'react'
import styled from 'styled-components'
import { FieldArray } from 'formik'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'

import Button from 'components/Button'
import Text from 'components/Text'
import CommodityItem from './CommodityItem'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import { calculateTotalDimensions } from 'common/LTLCommodity.helpers'
import { QuoteLTLFormValues } from 'common/types/LTLQuote'

const DEFAULT_ITEM = {
  description: '',
  hazmat: false,
  hazmat_class: '',
  ltl_item: {
    width: '',
    height: '',
    length: '',
    weight: '',
    density: '',
    stackable: false,
    count: '',
    package_type: '',
    classification: '50',
    ltl_excessive_length_class: '',
    nmfc_code: '',
  },
}

interface CommodityItemsProps {
  values: QuoteLTLFormValues
}

const StyledCommodityItemsContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${token('color-neutral-light')};
  padding: 24px;
  & .commodity-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }
  & .commodity-items-footer {
    background-color: ${token('color-neutral-lighter')};
    padding: 4px;
    border-radius: 4px;
    & .total-item {
      padding-left: 4px;
      padding-right: 8px;
    }
  }
`

const CommodityItems = ({ values }: CommodityItemsProps) => {
  const totals = useMemo(() => {
    return calculateTotalDimensions(values.items)
  }, [values.items])

  return (
    <FieldArray
      name="items"
      render={(arrayHelpers) => (
        <StyledCommodityItemsContainer>
          <div className="commodity-items-header">
            <Text variant="heading-sm-bold">
              {values.items.length} {values.items.length <= 1 ? 'item' : 'items'} added
            </Text>
            <Button
              variant="secondary"
              className="ml-4"
              leading={<Icon name="box" />}
              onClick={() => arrayHelpers.push(DEFAULT_ITEM)}
            >
              Add Commodity
            </Button>
          </div>
          <div>
            {values.items.map((item: any, index: number) => (
              <div className="pb-4" key={index}>
                <CommodityItem
                  onDuplicate={() => arrayHelpers.push(item)}
                  index={index}
                  onRemoveItem={() => arrayHelpers.remove(index)}
                  item={item}
                />
              </div>
            ))}
          </div>
          <div className="commodity-items-footer">
            <span className="total-item">
              <Text variant="caption-bold">Total linear feet</Text>
              <Text variant="caption" className="pl-2">
                {totals.totalLinearFeet} ft
              </Text>
              <Tooltip message="This prediction is calculated through the dimensions of the items.">
                <span className="ml-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold rounded-full bg-neutral-darker text-neutral-lightest">
                  ?
                </span>
              </Tooltip>
            </span>
            <span className="total-item">
              <Text variant="caption-bold">Total Cubic Volume</Text>
              <Text variant="caption" className="pl-2">
                {totals.totalCubicVolume} ft&#179;
              </Text>
              <Tooltip message="Total cubic volume is the freight volume calculated by multiplying the items counts, width, height and length of commodity items.">
                <span className="ml-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold rounded-full bg-neutral-darker text-neutral-lightest">
                  ?
                </span>
              </Tooltip>
            </span>
            <span className="total-item">
              <Text variant="caption-bold">Total Cubic Density</Text>
              <Text variant="caption" className="pl-2">
                {totals.totalCubicDensity} PCF
              </Text>
              <Tooltip message="Total cubic density is the total cargo weight divided by its total cubic volume.">
                <span className="ml-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold rounded-full bg-neutral-darker text-neutral-lightest">
                  ?
                </span>
              </Tooltip>
            </span>
          </div>
        </StyledCommodityItemsContainer>
      )}
    />
  )
}

export default CommodityItems
