import * as React from 'react'
import { Label, LabelProps, Layout } from '@loadsmart/loadsmart-ui'
import StyledErrorMessage from './StyledErrorMessage'
import { ReactNode } from 'react'

export interface BaseFieldProps {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  error?: string | any[]
  id?: string
  required?: boolean
  className?: string
  children: ReactNode
}

export function BaseField({ error, id, label, labelProps, required, children, className }: BaseFieldProps) {
  return (
    <Layout.Stack className={className}>
      <Label htmlFor={id} required={required} {...labelProps}>
        {label}
      </Label>
      {children}
      {error ? <StyledErrorMessage error={error} /> : null}
    </Layout.Stack>
  )
}
