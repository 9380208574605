import React from 'react'
import { QUOTE_TYPES } from './QuotesCreationOverTime'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

interface CustomTooltipProps {
  active?: boolean
  payload?: { value: string; name: 'drv' | 'rfr' | 'fbe' }[]
  label?: string
}

const RenderDot = ({ color }: { color: keyof RateGuideThemeInterface }) => {
  const theme = useTheme() as RateGuideThemeInterface

  return <span className="rounded-full w-2 h-2 inline-block" style={{ backgroundColor: String(theme[color]) }} />
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (!(active && payload && payload.length)) {
    return null
  }

  return (
    <div className="w-28 bg-neutral-lightest rounded-md p-2 text-dark">
      <p className="font-bold text-sm">Quotes</p>
      {payload.map((item, index) => (
        <p className="text-xs my-1" key={index}>
          {RenderDot({ color: QUOTE_TYPES[item.name].color })}
          <span className="font-bold inline-block mx-1">{item.value}</span>
          {QUOTE_TYPES[item.name].name}
        </p>
      ))}
    </div>
  )
}

export default CustomTooltip
