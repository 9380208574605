import {
  IconAlertCircle,
  IconArrowsExpandSide,
  IconAttention,
  IconCaretDown,
  IconClose,
  IconCog,
  IconCopy,
  IconDetails,
  IconDownload,
  IconEye,
  IconExport,
  IconFlagCheckered,
  IconMarkersPin,
  IconPencil,
  IconPhone,
  IconPlus,
  IconRetry,
  IconTeamDriver,
  IconTrash,
  IconTruckContainer,
  IconTruckDryvan,
  IconTruckFlatbed,
  IconTruckLtlDryvan,
  IconTruckPtlDryvan,
  IconTruckRailDrybox,
  IconTruckReefer,
  IconUpload,
  IconInfoCircle,
  IconStockOptions,
  IconDislike,
  IconLike,
  IconDolar,
  IconMinus,
  IconGraph,
  IconSettings,
  IconArrowsCornerUp,
  IconArrowsCornerDown,
  IconCheck,
  IconQuestionCircle,
  IconOpportunity,
  IconLogout,
  IconBox,
  IconWarehouse,
} from '@loadsmart/icons'

const mapping = {
  'alert-circle': IconAlertCircle,
  'caret-down': IconCaretDown,
  'chevron-right': IconArrowsExpandSide,
  'marker-pin': IconMarkersPin,
  'team-driver': IconTeamDriver,
  'truck-container': IconTruckContainer,
  'truck-dryvan': IconTruckDryvan,
  'truck-flatbed': IconTruckFlatbed,
  'truck-ltl-dryvan': IconTruckLtlDryvan,
  'truck-ptl-dryvan': IconTruckPtlDryvan,
  'truck-rail-drybox': IconTruckRailDrybox,
  'truck-reefer': IconTruckReefer,
  'stock-options': IconStockOptions,
  'arrow-corner-up': IconArrowsCornerUp,
  'arrow-corner-down': IconArrowsCornerDown,
  add: IconPlus,
  close: IconClose,
  cog: IconCog,
  copy: IconCopy,
  details: IconDetails,
  download: IconDownload,
  edit: IconPencil,
  export: IconExport,
  flag: IconFlagCheckered,
  peek: IconEye,
  remove: IconTrash,
  retry: IconRetry,
  warning: IconAttention,
  upload: IconUpload,
  info: IconInfoCircle,
  phone: IconPhone,
  dislike: IconDislike,
  like: IconLike,
  dollar: IconDolar,
  minus: IconMinus,
  settings: IconSettings,
  graph: IconGraph,
  check: IconCheck,
  'question-circle': IconQuestionCircle,
  opportunity: IconOpportunity,
  logout: IconLogout,
  warehouse: IconWarehouse,
  box: IconBox,
} as const

export type IconName = keyof typeof mapping

export default mapping
