export const COLORS = {
  'color-primary-charts-gradient': '#01CB2C',
  'color-charts-yellow': '#FFC852',
  'color-charts-purple': '#A066FF',
  'color-accent-transparent': 'rgba(12, 169, 51, 0.2)',
}

export const SHADOWS = {
  'shadow-glow-danger': '0px 2px 10px 0px rgba(232, 0, 69, 0.2)',
}

const rateGuide = {
  ...COLORS,
  ...SHADOWS,
}

export default rateGuide
