import clsx from 'clsx'
import { ReactNode, useState } from 'react'
import { IconButton } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import Icon, { IconName } from '../Icon'

export interface CollapsibleProps {
  open?: boolean
  title: string
  className?: string
  children: ReactNode
  iconName?: IconName
}

interface CollapsibleContentProps {
  duration?: number
  collapsed: boolean
}

const CollapsibleContent = styled.div<CollapsibleContentProps>`
  transition: height ${(props) => props.duration || 0.25}s ease-in-out;
  height: 100%;
  ${(props) =>
    props.collapsed &&
    `
      height: 0px;
      overflow: hidden;
    `}
`

function Collapsible({ className, open, children, title, iconName }: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState(Boolean(open))

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div
      className={clsx('flex items-center p-2 rounded-sm shadow border-neutral-light', className)}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <div className="flex-col">
            <div className="flex flex-row items-center">
              {iconName && (
                <span className="inline-block mr-1">
                  <Icon name={iconName} />
                </span>
              )}
              <span className={clsx({ 'font-bold': isOpen })}>{title}</span>
            </div>
          </div>
          <div className="flex-col">
            <IconButton onClick={handleOpen} title={isOpen ? 'Collapse' : 'Expand'} scale="small">
              <span>{isOpen ? <Icon name="minus" /> : <Icon name="add" />}</span>
            </IconButton>
          </div>
        </div>
        <div className="flex-row">
          <CollapsibleContent collapsed={!isOpen}>{children}</CollapsibleContent>
        </div>
      </div>
    </div>
  )
}

export default Collapsible
