interface ApplicationManager {
  isWhiteLabelApplication: () => boolean
}

const applicationManager: ApplicationManager = {
  isWhiteLabelApplication(): boolean {
    return window.location.pathname === '/white-label'
  }
}

export default applicationManager
