import { Fragment, ReactNode } from 'react'
import get from 'common/helpers/get'

import type { EquipmentType } from 'common/types/EquipmentType'
import type { PartialMarginsFilter, MarginsFilterFields } from '../MarginsList/MarginsList.types'

import { DateRangePicker } from 'components/DatePicker'
import { initialFields, getDateRangeParameters } from './MarginsFilter.helpers'
import { LANE_STATUS } from './MarginsFilter.helpers'
import DateHelper from 'common/Date.helpers'
import EquipmentTypeEntry from 'pages/Quotes/QuoteForm/EquipmentType'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import Field from 'components/Field'
import Label from 'components/Label'
import LoadTypeHelper from 'common/LoadType.helpers'
import LocationSuggestion from 'pages/Quotes/QuoteForm/Location'
import ReasonSelect from './ReasonSelect'
import Select from 'components/Select'
import ShipperSuggestion from 'pages/Quotes/QuoteForm/Shipper'
import TextField from 'components/TextField'
import toArray from 'common/helpers/toArray'
import ToggleGroup from 'components/ToggleGroup'
import useFetchKMAs from '../hooks/useFetchKMAs'
import useFilter, { generateUseQueryParamsPlugin } from 'hooks/useFilter'
import UserSuggestion from '../Users'

interface MarginFilterProps {
  children?: ReactNode
  onChange: (fields: PartialMarginsFilter) => void
}

const MODES = LoadTypeHelper.getAll()

const EQUIPMENT_TYPES = EquipmentTypeHelper.getAll(function getCustomProps(type: EquipmentType) {
  return {
    leading: <EquipmentTypeEntry mode="icon-only" name={type} />,
    label: <EquipmentTypeEntry mode="abbr-only" name={type} />,
  }
})

const useQueryParamsPlugin = generateUseQueryParamsPlugin<MarginsFilterFields>({
  outbound: {
    type: 'object-collection',
    mapping: {
      outbound_place_id: 'place_id',
      outbound_address: 'address',
      outbound_city: 'city',
      outbound_state: 'state',
      outbound_country: 'country',
      outbound_kma: 'kma',
      outbound_zipcode: 'zipcode',
    },
    toQueryParam: (e) => {
      return toArray(get(e, 'target.value'))
    },
  },
  inbound: {
    type: 'object-collection',
    mapping: {
      inbound_place_id: 'place_id',
      inbound_address: 'address',
      inbound_city: 'city',
      inbound_state: 'state',
      inbound_country: 'country',
      inbound_kma: 'kma',
      inbound_zipcode: 'zipcode',
    },
    toQueryParam: (e) => {
      return toArray(get(e, 'target.value'))
    },
  },
  shippers: {
    type: 'object-collection',
    mapping: {
      shippers_id: 'id',
      shippers_name: 'name',
    },
    toQueryParam: (e) => {
      return toArray(get(e, 'target.value'))
    },
  },
  status: {
    type: 'primitive',
    toQueryParam: (e) => {
      return get(e, 'target.value')
    },
  },
  date_range: {
    type: 'object',
    mapping: {
      date_range_start: 'date_start',
      date_range_end: 'date_end',
    },
    fromQueryParam: (_, value) => {
      return DateHelper(value as string)?.value() ?? null
    },
    toQueryParam: (e) => {
      const { startDate, endDate } = get(e, 'target.value')

      return getDateRangeParameters({
        date_start: startDate,
        date_end: endDate,
      })
    },
  },
  equipment_types: {
    type: 'primitive-collection',
    toQueryParam: (e) => toArray(get(e, 'target.value')),
  },
  margin_min: {
    type: 'primitive',
    fromQueryParam: (_, value) => value ?? -100,
    toQueryParam: (e) => get(e, 'target.value'),
  },
  margin_max: {
    type: 'primitive',
    fromQueryParam: (_, value) => value ?? 100,
    toQueryParam: (e) => get(e, 'target.value'),
  },
  reasons: {
    type: 'primitive-collection',
    toQueryParam: (e) => toArray(get(e, 'target.value')),
  },
  created_by: {
    type: 'object-collection',
    mapping: {
      created_by_id: 'id',
      created_by_first_name: 'first_name',
      created_by_last_name: 'last_name',
    },
    toQueryParam: (e) => {
      return toArray(get(e, 'target.value'))
    },
  },
})

function MarginFilter({ children, onChange }: MarginFilterProps) {
  const { fields, setField } = useFilter<MarginsFilterFields>(
    {
      fields: initialFields,
      onChange,
    },
    useQueryParamsPlugin,
  )

  return (
    <Fragment>
      <div
        className="flex flex-col items-end justify-between w-full mt-6 lg:flex-row"
        data-testid="margin-filter-row"
      >
        <Field className="flex-1 w-full pb-4 lg:pb-0 lg:pr-4" data-testid="rg-select__outbound">
          <Label>Outbound</Label>
          <LocationSuggestion
            multiple
            name="outbound"
            placeholder="All States"
            datasources={[useFetchKMAs]}
            options={{ types: 'regions' }}
            value={fields.outbound}
            onChange={setField('outbound')}
          />
        </Field>
        <Field className="flex-1 w-full pb-4 lg:pb-0 lg:pr-4" data-testid="rg-select__inbound">
          <Label>Inbound</Label>
          <LocationSuggestion
            multiple
            name="inbound"
            placeholder="All States"
            datasources={[useFetchKMAs]}
            options={{ types: 'regions' }}
            value={fields.inbound}
            onChange={setField('inbound')}
          />
        </Field>
        <Field className="flex-1 w-full pb-4 lg:pb-0 lg:pr-4" data-testid="rg-select__shipper">
          <Label>Shipper</Label>
          <ShipperSuggestion
            multiple
            name="shippers"
            data-testid="input-shippers"
            placeholder="All Shippers"
            onChange={setField('shippers')}
            value={fields.shippers}
            clearable
          />
        </Field>
        <Field className="flex-1 w-full pb-4 lg:pr-4 lg:pb-0" data-testid="rg-select__lane-status">
          <Label>Lane Status</Label>
          <Select
            name="status"
            onChange={setField('status')}
            placeholder="ALL"
            value={fields.status}
            options={LANE_STATUS}
            clearable
          />
        </Field>

        <Field className="w-full lg:w-auto" data-testid="date-picker-range">
          <Label>Date Range</Label>
          <DateRangePicker
            startDate={fields.date_range?.date_start || null}
            endDate={fields.date_range?.date_end || null}
            onChange={setField('date_range')}
            name="date_range"
          />
        </Field>
      </div>
      <div
        className="flex flex-col justify-between w-full mt-6 lg:items-end lg:flex-row"
        data-testid="margin-filter-row"
      >
        <div className="flex flex-col flex-1 space-y-4 lg:justify-start lg:items-end lg:space-y-0 lg:space-x-4 lg:flex-row">
          <Field
            className="w-full pb-4 lg:w-auto lg:pr-4 lg:pb-0"
            data-testid="equipment-type-toggle"
          >
            <Label>Equipment Type</Label>
            <ToggleGroup
              multiple
              options={EQUIPMENT_TYPES}
              name="equipment_types"
              onChange={setField('equipment_types')}
              value={fields.equipment_types}
            />
          </Field>
          <div className="flex flex-1 w-full pb-4 lg:pb-0 lg:pr-4">
            <Field className="hidden pr-4">
              <Label>Mode</Label>
              <ToggleGroup
                multiple
                options={MODES}
                name="modes"
                onChange={setField('modes')}
                value={fields.modes}
              />
            </Field>
            <Field>
              <Label>Margin Range (%)</Label>
              <div className="flex">
                <TextField
                  type="number"
                  style={{ minWidth: '70px' }}
                  className="w-1/2 mr-4"
                  name="margin_min"
                  onChange={setField('margin_min')}
                  value={fields.margin_min}
                  data-testid="margin-range-min"
                />
                <TextField
                  type="number"
                  style={{ minWidth: '70px' }}
                  className="w-1/2"
                  name="margin_max"
                  onChange={setField('margin_max')}
                  value={fields.margin_max}
                  data-testid="margin-range-max"
                />
              </div>
            </Field>
          </div>
          <Field className="flex-1 w-full pb-4 lg:pb-0 lg:pr-4" data-testid="rg-select__reason">
            <Label>Reason</Label>
            <ReasonSelect
              multiple
              placeholder="Select.."
              name="reasons"
              onChange={setField('reasons')}
              value={fields.reasons}
              clearable
            />
          </Field>

          <Field className="flex-1 w-full pb-4 lg:pr-4 lg:pb-0" data-testid="rg-select__user">
            <Label>Created By</Label>
            <UserSuggestion
              multiple
              placeholder="All Users"
              name="created_by"
              onChange={setField('created_by')}
              value={fields.created_by}
              clearable
            />
          </Field>
        </div>
        <div className="flex items-center justify-start ">{children}</div>
      </div>
    </Fragment>
  )
}

export default MarginFilter
