export interface Commodity {
  id: string
  po_number: string
  description: string
  hazmat?: boolean
  hazmat_class?: string
  ltl_item: LTLItem | null
}

export interface LTLItem {
  count: number
  height: string
  length: string
  width: string
  weight: string
  density: string
  stackable: boolean
  nmfcCode: boolean | null
  classification: FreightClass
  packageType: PackageType
  ltl_excessive_length_class: ExcessiveLengthClassification | null
}

export type PackageType =
  | 'std_pallets'
  | 'pallets_non_std'
  | 'bags'
  | 'bales'
  | 'boxes'
  | 'bunches'
  | 'carpets'
  | 'coils'
  | 'crates'
  | 'cylinders'
  | 'drums'
  | 'pails'
  | 'reels'
  | 'rolls'
  | 'tubes_pipes'
  | 'loose'

export const PACKAGE_TYPE_LABELS: Record<PackageType, string> = {
  std_pallets: 'Standard Pallets (40x48)',
  pallets_non_std: 'Pallets Non Standard',
  bags: 'Bags',
  bales: 'Bales',
  boxes: 'Boxes',
  bunches: 'Bunches',
  carpets: 'Carpets',
  coils: 'Coils',
  crates: 'Crates',
  cylinders: 'Cylinders',
  drums: 'Drums',
  pails: 'Pails',
  reels: 'Reels',
  rolls: 'Rolls',
  tubes_pipes: 'Tubes and Pipes',
  loose: 'Loose',
}

export type ExcessiveLengthClassification =
  | 'excessive_length_8ft'
  | 'excessive_length_12ft'
  | 'excessive_length_16ft'
  | 'excessive_length_20ft'

export type FreightClass =
  | '50'
  | '55'
  | '60'
  | '65'
  | '70'
  | '77.5'
  | '85'
  | '92.5'
  | '100'
  | '110'
  | '125'
  | '150'
  | '175'
  | '200'
  | '250'
  | '300'
  | '400'
  | '500'
