import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { prop } from '@loadsmart/loadsmart-ui/dist/tools'

import { ReactComponent as LogoSvg } from './logo.svg'

interface LogoProps extends HTMLAttributes<HTMLDivElement> {
  height?: string
  width?: string
}

const StyledLogo = styled.div<LogoProps>`
  width: ${prop('width', '150px')};
  height: ${prop('height', '25px')};
`

const Logo = ({ height, width, ...props }: LogoProps) => {
  return (
    <StyledLogo width={width} height={height} {...props}>
      <LogoSvg />
    </StyledLogo>
  )
}

export default Logo
