import React from 'react'

import { Dialog } from 'components/Dialog'
import Text from 'components/Text'
import Checkbox from 'components/Checkbox'
import Layout from 'components/Layout'
import Icon from 'components/Icon'

export interface LTLOnboardingProps {
  keyName: string
  message: string
}

export function LTLOnboarding(props: LTLOnboardingProps) {
  const keyName = `LTLOnboarding:${props.keyName}`
  const [open, setOpen] = React.useState(!Boolean(localStorage.getItem(keyName)))
  const [preventNext, setPreventNext] = React.useState(false)

  function onClose() {
    setOpen(false)
    if (preventNext) {
      localStorage.setItem(keyName, 'yes')
    }
  }

  if (!open) return null

  return (
    <Dialog open scale="small">
      <Dialog.Header>How LTL works</Dialog.Header>

      <Dialog.Body>
        <Layout.Stack space="xl">
          <Text>
            <Layout.Group justify="center">
              <Icon name="info" size={36} />
            </Layout.Group>
            {props.message}
          </Text>
          <Checkbox checked={preventNext} onChange={() => setPreventNext(!preventNext)}>
            Don't show me this again
          </Checkbox>
        </Layout.Stack>
      </Dialog.Body>

      <Dialog.Actions>
        <Dialog.ActionCancel onCancel={onClose}>Close</Dialog.ActionCancel>
      </Dialog.Actions>
    </Dialog>
  )
}
