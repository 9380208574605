import React, { useMemo } from 'react'

import PriceOverTime, { PriceOverTimeData } from './PriceOverTime'
import SummaryHeader from './SummaryHeader'
import { HighlightedDataFooter, HighlightedDataFooterProps } from 'components/Charts'
import CurrencyHelper from 'common/Currency.helpers'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

export interface OverallPriceProps {
  data: {
    series: PriceOverTimeData[]
    summary: {
      highest: { value: number }
      lowest: { value: number }
      average: { value: number }
    }
  }
  chartWidth?: string | number
  chartHeight?: string | number
  isAnimationActive?: boolean
}

const OverallPrice = ({
  data,
  chartWidth = '100%',
  chartHeight = 400,
  isAnimationActive = false,
}: OverallPriceProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  const highlightedFooterData = useMemo((): HighlightedDataFooterProps['data'] => {
    let footerRows: HighlightedDataFooterProps['data'] = [
      {
        identifier: 'rate-guide',
        values: data.series.map((item) =>
          item['rate-guide'] ? CurrencyHelper(item['rate-guide']).integer() : '-',
        ),
        color: theme['color-primary'],
      },
    ]

    if (data.series.some((item) => item.market)) {
      footerRows = [
        ...footerRows,
        {
          identifier: 'market',
          values: data.series.map((item) =>
            item.market ? CurrencyHelper(item.market).integer() : '-',
          ),
        },
      ]
    }

    return footerRows
  }, [data, theme])

  return (
    <>
      <SummaryHeader
        data={[
          { title: 'Average price', value: data.summary.average.value },
          { title: 'Highest price', value: data.summary.highest.value },
          { title: 'Lowest price', value: data.summary.lowest.value },
        ]}
      />
      <p className="font-bold mt-4 text-lg">Price variation</p>
      <PriceOverTime
        data={data.series}
        width={chartWidth}
        height={chartHeight}
        isAnimationActive={isAnimationActive}
      />
      <HighlightedDataFooter
        title="Rate"
        data={highlightedFooterData}
        titleWidth="8%"
        className="pr-6"
      />
    </>
  )
}

export default OverallPrice
