import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { post } from '../../common/HTTPClient'
import { to } from '../../common/helpers/awaitTo'
import { User } from '../../common/types/User'

interface PaginationOptions {
  limit: number
  offset: number
}

export interface SearchShippersPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: User[]
}

export async function searchUser(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<SearchShippersPayload> | undefined]> {
  let error = null,
    result
  try {
    ;[error, result] = await to(post('/api/search/users', data, config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}
