import { useSelector, useDispatch } from 'react-redux'

import { logout } from '../../features/auth/auth.actions'
import { RootState } from '../reducers'
import CurrentUserHelper from 'common/CurrentUser.helper'
import TopNavigation from 'components/TopNavigation'
import Icon from 'components/Icon'

function Header() {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: RootState) => state.auth?.accessToken)
  const currentUserHelper = CurrentUserHelper()

  if (!isAuthenticated) {
    return null
  }

  return (
    <TopNavigation>
      <TopNavigation.Menu align="right">
        <TopNavigation.Menu.Item
          label={currentUserHelper.getCurrentUser()?.user_first_name ?? 'My Account'}
        >
          <TopNavigation.Menu.SubItem
            url="#"
            onClick={(e) => {
              e.preventDefault()
              dispatch(
                logout(() => {
                  window.location.replace('/login')
                }),
              )
            }}
            icon={<Icon name="logout" />}
            label="Log Out"
          />
        </TopNavigation.Menu.Item>
      </TopNavigation.Menu>
    </TopNavigation>
  )
}

export default Header
