import isEmpty from 'common/helpers/isEmpty'

export const isValidPrice = (value: string, allowNegative: boolean = false) => {
  let regex = '\\d{1,6}(\\.\\d{1,2})?'
  if (allowNegative) {
    regex = `-?${regex}`
  }

  return new RegExp(`^${regex}$`).test(value)
}

export const isValidPartialPrice = (value: string, allowNegative: boolean = false) => {
  let regex = '[0-9]*[.]{0,1}[0-9]{0,2}'

  if (allowNegative) {
    regex = `-?${regex}`
  }

  return new RegExp(`^${regex}$`).test(value)
}

export const replaceEmptyValuesByNull = (formObject: any) => {
  if (typeof formObject !== 'object' || formObject === null) {
    return null
  }

  const emptyValues: Record<string, null> = {}
  Object.keys(formObject).forEach((key) => {
    if (isEmpty(formObject[key])) {
      emptyValues[key] = null
    }
  })

  return {
    ...formObject,
    ...emptyValues,
  }
}
