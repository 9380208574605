import { AppThunk } from '../../app/store'
import slice from './auth.slice'
import tokenManager from '../../common/tokenManager'
import sessionManager from '../../common/sessionManager'

import { requestTokens } from '../../services/auth/auth.service'
import analytics from 'common/analytics'

export const { loginStart, loginSuccess, loginFailed, logoutStart, logoutSuccess } = slice.actions

export function login(username: string, password: string, callback: () => void): AppThunk {
  return async (dispatch) => {
    dispatch(loginStart())
    try {
      const data = await requestTokens(username, password)

      tokenManager.setToken(data.accessToken)
      tokenManager.setRefreshToken(data.refreshToken)

      sessionManager.setSession(data.accessToken)

      analytics.identify()
      analytics.track('Sign In')
      dispatch(loginSuccess(data))
      callback?.()
    } catch (err) {
      dispatch(loginFailed(err))
    }
  }
}

export function logout(callback?: () => void): AppThunk {
  return async (dispatch) => {
    dispatch(logoutStart())
    tokenManager.clearTokens()
    sessionManager.clearSession()
    dispatch(logoutSuccess())
    callback?.()
  }
}
