import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { isBlank } from '@loadsmart/utils-string'

import { CarrierContact, CarrierSuggestion } from 'common/types/Carrier'
import { del, get, patch, post } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { Quote, Quoteline, QuoteResult } from 'common/types/Quote'
import { RateFeedback } from 'common/types/RateFeedback'
import { QuoteLTLFormValues } from '../../common/types/LTLQuote'

interface PaginationOptions {
  limit: number
  offset: number
}

export interface GetQuotesPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: QuoteResult[]
}

export interface QuoteRequest {
  id: string
  status: string
  status_message: string
  error_details: string
  quote: Quote
}

export type QuoteSendingMethods = 'manual' | 'quote_response_integration' | 'email'

type QuoteAccessUser = {
  id: string
  first_name: string
  last_name: string
}

export interface QuoteEventLog {
  created_at: string
  type: 'created' | 'sent' | 'send-started' | 'send-failed' | 'selected-rate-updated'
  data: {
    created_by?: QuoteAccessUser
    sent_by?: QuoteAccessUser
    sending_method?: QuoteSendingMethods
    error_description?: string
    updated_by?: QuoteAccessUser
  }
}

export interface GetCarrierContactsPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: CarrierContact[]
}

export type ContactFeedbackType = 'positive' | 'negative' | null

export async function getQuotes(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<GetQuotesPayload> | undefined]> {
  try {
    return await to(post('/api/quote/list', data, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function getQuote(
  id: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quote> | undefined]> {
  if (isBlank(id)) {
    return [true, undefined]
  }

  try {
    return await to(get(`/api/quote/${id}`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function addQuote(
  quote: Quote,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quote> | undefined]> {
  try {
    return await to(post('/api/quote/', quote, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function sendQuote(
  quoteID: string,
  payload: {
    sending_method: QuoteSendingMethods
    email?: string
  },
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quote> | undefined]> {
  try {
    return await to(post(`/api/quote/${quoteID}/send`, payload, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

type QuoteEventLogsPayload = {
  results: QuoteEventLog[]
}

export async function getQuoteEventLogs(
  quoteID: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<QuoteEventLogsPayload> | undefined]> {
  try {
    return await to(get(`/api/quote/${quoteID}/events`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function addQuoteline(
  quoteID: string,
  payload: { quoteline: Quoteline },
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quoteline> | undefined]> {
  try {
    return await to(post(`/api/quote/${quoteID}/quoteline`, payload, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function removeQuoteline(
  quoteID: string,
  quotelineID: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quoteline> | undefined]> {
  try {
    return await to(del(`/api/quote/${quoteID}/quoteline/${quotelineID}`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function selectRate(
  quoteID: string,
  rateID: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<Quote> | undefined]> {
  try {
    return await to(patch(`/api/quote/${quoteID}`, { selected_rate: { id: rateID } }, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function getCarrierContacts(
  quoteId: string,
  carrierUuid: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<GetCarrierContactsPayload> | undefined]> {
  try {
    return await to(get(`/api/quote/${quoteId}/suggested-carriers/${carrierUuid}/contacts`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function getSuggestedCarriers(
  quoteId: string,
  config?: AxiosRequestConfig,
): Promise<
  [AxiosError | null | boolean, AxiosResponse<{ results: CarrierSuggestion[] }> | undefined]
> {
  try {
    return await to(get(`/api/quote/${quoteId}/suggested-carriers`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function setCarrierContactFeedback(
  quoteId: string,
  carriedId: string,
  contactId: string,
  payload: {
    feedback: ContactFeedbackType
  },
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse | undefined]> {
  try {
    return await to(
      post(
        `/api/quote/${quoteId}/suggested-carriers/${carriedId}/contacts/${contactId}`,
        payload,
        config,
      ),
    )
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function setRateFeedback(
  quoteId: string,
  rateId: string,
  payload: RateFeedback,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse | undefined]> {
  try {
    return await to(post(`/api/quote/${quoteId}/quote-rates/${rateId}/feedback`, payload, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function createLTLQuoteRequest(
  quoteRequestParams: Partial<QuoteLTLFormValues> & { mode: 'LTL', channel: string },
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<{ id: string }> | undefined]> {
  try {
    return await to(post('/api/quote-requests', quoteRequestParams, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}

export async function fetchLTLQuoteRequest(
  quoteRequestId: string,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<QuoteRequest> | undefined]> {
  try {
    return await to(get(`/api/quote-requests/${quoteRequestId}`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}
