import { ReactNode, TableHTMLAttributes, TdHTMLAttributes, useRef } from 'react'
import clsx from 'clsx'

import './Table.css'

export interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  className?: string
  limitMinSize?: boolean
  children: ReactNode
  stripped?: boolean
}

function Table({ className, limitMinSize, children, stripped, ...others }: TableProps) {
  return (
    <div
      className={clsx('w-full', {
        'min-w-min': limitMinSize,
        'overflow-x-auto overflow-y-hidden': !limitMinSize,
      })}
    >
      <table
        className={clsx('rg-table', { 'rg-table--striped': stripped }, className)}
        role="table"
        {...others}
      >
        {children}
      </table>
    </div>
  )
}

export interface TableHeaderProps {
  children: ReactNode
}

Table.Header = function TableHeader({ children, ...others }: TableHeaderProps) {
  return <thead {...others}>{children}</thead>
}

export interface TableBodyProps {
  children: ReactNode
}

Table.Body = function TableBody({ children, ...others }: TableBodyProps) {
  return <tbody {...others}>{children}</tbody>
}

export interface TableRowProps {
  children: ReactNode
}

Table.Row = function TableRow({ children, ...others }: TableRowProps) {
  return (
    <tr role="row" {...others}>
      {children}
    </tr>
  )
}

export interface TableCellProps extends TdHTMLAttributes<HTMLTableDataCellElement> {
  className?: string
  children?: ReactNode
  header?: boolean
}

Table.Cell = function TableCell({
  className,
  children,
  header = false,
  ...others
}: TableCellProps) {
  const Type = useRef(header ? 'th' : 'td')
  const Wrapper = Type.current as any

  return (
    <Wrapper
      className={clsx(className, {
        'px-4 py-1 font-normal text-left uppercase truncate': header,
        'text-sm bg-neutral-lightest text-neutral-darkest': !header,
      })}
      role={header ? 'columnheader' : 'cell'}
      {...others}
    >
      {children}
    </Wrapper>
  )
}

export interface TableFooterProps {
  children: ReactNode
}

Table.Footer = function TableFooter({ children, ...others }: TableFooterProps) {
  return <tfoot {...others}>{children}</tfoot>
}

export default Table
