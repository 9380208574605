import { isNil } from '@loadsmart/utils-object'
import clsx from 'clsx'

import { BaseLink as Link } from '../Link'

export interface BreadcrumbProps {
  active?: boolean
  className?: string
  label: string
  url?: string
}

function getWrappedLabel(label: string, url: string | undefined) {
  if (isNil(url)) {
    return label
  }

  return (
    <Link href={url} target="_self" className="text-xl">
      {label}
    </Link>
  )
}

function Breadcrumb({ className, active, label, url }: BreadcrumbProps) {
  return (
    <li
      className={clsx(
        'inline-flex items-center font-neutral-darkest relative p-1 m-0 text-xl rg-breadcrumb',
        {
          'font-semibold': active,
        },
      )}
      key={label}
      data-testid="breadcrumb"
    >
      {getWrappedLabel(label, url)}
    </li>
  )
}

export default Breadcrumb
