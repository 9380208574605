import { useRef, useEffect, useReducer, useCallback, MutableRefObject } from 'react'

import { identity, isFunction } from '@loadsmart/utils-function'
import { isBlank } from '@loadsmart/utils-string'
import debounce from 'common/helpers/debounce'
import PluginsRunner from './plugins/PluginsRunner'

import type { FilterPluginHook, State, Action } from './useFilter.types'

/**
 * Gets the handlers for the given `field` from `handlersRef`.
 * @param {MutableRefObject} handlersRef - Handlers ref object.
 * @param {string} field - Field name.
 * @returns {function}
 */
function getHandler<T>(
  handlersRef: MutableRefObject<Record<keyof T, (e: any, ...args: any[]) => unknown>>,
  field: keyof T,
) {
  return handlersRef.current[field] || identity
}

/**
 * useFilter's onChange callback type.
 * @callback OnChangeCallback
 * @param {object} fields Non-blank fields
 */

type SimpleField = unknown
type ComplexField = {
  value: unknown
  set?: (value: Event) => void
  get?: (options: any) => unknown
  clear?: () => void
}

interface UseFilterOptions {
  debounce?: number
}

export interface UseFilterProps<T> {
  fields: {
    [key in keyof T]: ComplexField | SimpleField
  }
  onChange?: (field: Partial<T>) => void
  options?: UseFilterOptions
}

/**
 * Custom hook to generically manage multiple filter fields
 * @param {object} fieldsProp - Object with filter fields as key (field name)/value (field value).
 * Check README for more complex fields setup.
 * @param {OnChangeCallback} onChange - Callback to be called when fields change.
 */
function useFilter<T>(
  { fields: fieldsProp, onChange, options }: UseFilterProps<T>,
  ...plugins: FilterPluginHook<T>[]
) {
  const settersRef = useRef({} as Record<keyof T, (value: unknown, ...args: any[]) => unknown>)
  const setHandlersRef = useRef({} as Record<keyof T, (e: any, ...args: any[]) => unknown>)
  const getHandlersRef = useRef({} as Record<keyof T, (e: any, ...args: any[]) => unknown>)
  const clearHandlersRef = useRef({} as Record<keyof T, () => unknown>)

  const pluginsRunnerRef = useRef(PluginsRunner(plugins.map((plugin) => plugin())))

  function getPluginsRunner() {
    return pluginsRunnerRef.current
  }

  const [fields, dispatch] = useReducer(
    function reducer(state: State, action: Action<T>) {
      switch (action.type) {
        case 'set-field': {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          }
        }
        case 'clear-field': {
          const cleaner = getHandler<T>(clearHandlersRef, action.payload.field)
          const newValue = cleaner(action.payload.value)

          return {
            ...state,
            [action.payload.field]: newValue,
          }
        }
        case 'bulk-set': {
          return {
            ...state,
            ...action.payload.fields,
          }
        }
        default:
          return state
      }
    },
    fieldsProp,
    function init(fieldsProp) {
      const fieldsNames = Object.keys(fieldsProp) as (keyof T)[]
      let fields = {}

      for (let i = 0; i < fieldsNames.length; i++) {
        const fieldName = fieldsNames[i]
        const fieldValue = fieldsProp[fieldName]
        if (typeof fieldValue == 'object' && !Array.isArray(fieldValue)) {
          // grab field specific handlers
          const { value, set, get, clear } = fieldValue as ComplexField

          if (set && isFunction(set)) {
            setHandlersRef.current[fieldName] = set
          }

          if (get && isFunction(get)) {
            getHandlersRef.current[fieldName] = get
          }

          if (clear && isFunction(clear)) {
            clearHandlersRef.current[fieldName] = clear
          }

          fields = {
            ...fields,
            [fieldName]: value,
          }
        } else {
          fields = {
            ...fields,
            [fieldName]: fieldValue,
          }
        }
      }

      fields = getPluginsRunner().onInit(fields as T)

      return fields
    },
  )

  function getPublishChanges(options?: UseFilterOptions) {
    function publishChanges(
      fields: Record<string, unknown>,
      onChange: UseFilterProps<T>['onChange'],
    ) {
      if (!onChange) {
        return
      }

      let fieldsToPublish: Partial<T> = {}
      const fieldsNames = Object.keys(fields)

      for (let i = 0; i < fieldsNames.length; i++) {
        const fieldName = fieldsNames[i]
        const getter = getHandler<T>(getHandlersRef, fieldName as keyof T)
        const fieldValue = getter(fields[fieldName], fields)

        if (fieldValue != null && !isBlank(String(fieldValue))) {
          fieldsToPublish = {
            ...fieldsToPublish,
            [fieldName]: fieldValue,
          }
        }
      }
      onChange(fieldsToPublish)
    }
    if (options?.debounce) {
      return debounce(publishChanges, options.debounce)
    }
    return publishChanges
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publishChanges = useCallback(getPublishChanges(options), [])

  useEffect(() => {
    publishChanges(fields, onChange)
  }, [fields, onChange, publishChanges])

  async function setFieldWithHandler(field: keyof T, value: unknown, ...args: any[]) {
    getPluginsRunner().onChange(field, value)

    const setter = getHandler<T>(setHandlersRef, field)
    let newValue = await setter(value, ...args)

    dispatch({
      type: 'set-field',
      payload: {
        field,
        value: newValue,
      },
    })
  }

  const setField = function generateFieldSetter(field: keyof T, ...args: any[]) {
    if (args.length > 0) {
      // setter was called with field and value, i.e. `setField('field', 'value'))`
      const [value, ...others] = args
      setFieldWithHandler(field, value, ...others)
    }

    if (!settersRef.current[field]) {
      /**
       * setter was called using partial application i.e. `setField('field'))`, so we need to generate the
       * function to be called with its value and any additional arguments.
       */
      settersRef.current[field] = function (value: unknown, ...additionalArgs: any) {
        setFieldWithHandler(field, value, ...additionalArgs)
      }
    }

    return settersRef.current[field]
  }

  const clearField = function generateFieldCleaner(field: string, value: unknown) {
    dispatch({
      type: 'clear-field',
      payload: {
        field: field as keyof T,
        value,
      },
    })
  }

  const setBulkFields = (fields: Partial<T>) => {
    dispatch({
      type: 'bulk-set',
      payload: { fields },
    })
  }

  return { fields: fields as T, setField, clearField, setBulkFields }
}

export default useFilter
