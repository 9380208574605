import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Column, usePagination, useTable } from 'react-table'
import clsx from 'clsx'
import { toast } from 'components/Toast'
import { IconButton } from 'components/Button'
import Empty from 'components/Empty'
import get from 'common/helpers/get'
import isEmpty from 'common/helpers/isEmpty'
import Loading from 'components/Loading'
import { Table } from '@loadsmart/loadsmart-ui'
import useCancelToken from 'hooks/useCancelToken'
import Pagination from 'components/Pagination'
import { listShippers, editShipper, ListShippersData } from 'services/shippers/shippers.service'
import Section from 'components/Section'
import Icon from 'components/Icon'
import Switch from 'components/Switch'

import type { ShipperEntity } from 'common/types/ShipperEntity'
import CurrencyHelper from 'common/Currency.helpers'

interface ShippersListProps {
  className?: string
  filters?: any
  onEditShipper: (shipper: ShipperEntity) => void
  onDeleteShipper: (shipper: ShipperEntity) => void
}

const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    style: { width: '50%', wordBreak: 'break-all' },
  },
  {
    Header: 'Fuel Rate',
    accessor: 'fuel_rate',
    style: { width: '15%', wordBreak: 'break-all' },
    Cell({ row: { original } }: { row: { original: ShipperEntity } }) {
      return CurrencyHelper(Number(original.fuel_rate)).format()
    },
  },
  {
    Header: 'Mileage Provider',
    accessor: 'mileage_provider',
    style: { width: '22%', wordBreak: 'break-all' },
  },
  {
    Header: 'Status',
    accessor: 'is_active',
    Cell({
      row: { original },
      onEditShipper,
      onDeleteShipper,
      onToggleShipperStatus,
    }: {
      row: { original: ShipperEntity }
      onEditShipper: (shipper: ShipperEntity) => void
      onDeleteShipper: (shipper: ShipperEntity) => void
      onToggleShipperStatus: (shipper: ShipperEntity) => void
    }) {
      return (
        <div className="flex flex-row items-center h-full">
          {
            <div className="inline-flex w-auto h-8 ml-auto">
              <Switch
                aria-label="Toggle Shipper Active"
                onToggle={() => onToggleShipperStatus(original)}
                scale="default"
                active={original.status === 'active'}
              />
            </div>
          }
          <div className="inline-flex w-auto h-8 ml-auto">
            <IconButton className="ml-4" scale="small" onClick={() => onEditShipper(original)}>
              <Icon name="edit" title="Edit Shipper" size={16} />
            </IconButton>
            <IconButton className="ml-4" scale="small" onClick={() => onDeleteShipper(original)}>
              <Icon name="remove" title="Delete Shipper" size={16} />
            </IconButton>
          </div>
        </div>
      )
    },
  },
]

const ShippersList = forwardRef(
  ({ filters, onEditShipper, onDeleteShipper }: ShippersListProps, ref) => {
    const { getSource, clearSource, cancelPending } = useCancelToken()

    const [data, setData] = useState<ShipperEntity[]>([])
    const [loading, setLoading] = useState(false)
    const [controlledPageCount, setControlledPageCount] = useState(0)
    const [totalShippers, setTotalShippers] = useState(0)

    const {
      canNextPage,
      canPreviousPage,
      getTableBodyProps,
      getTableProps,
      gotoPage,
      headerGroups,
      page,
      pageOptions,
      prepareRow,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns: COLUMNS as Column[],
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
        manualPagination: true,
        pageCount: controlledPageCount,
      },
      usePagination,
    )

    const filtersRef = useRef(filters)
    const pageSizeRef = useRef(pageSize)

    const fetchShippersList = useCallback(
      ({ pageSize, pageIndex, filters }) => {
        async function fetch() {
          cancelPending()

          setLoading(true)

          const source = getSource()

          const shippersData: ListShippersData = {
            limit: pageSize,
            offset: pageSize * pageIndex,
          }

          if (!isEmpty(filters)) {
            shippersData.filters = filters
          }

          const [error, response] = await listShippers(shippersData, {
            cancelToken: source.token,
          })

          clearSource()

          if (error) {
            toast.error('We could not retrieve the shippers.')
            setData([])
            setControlledPageCount(0)
            setTotalShippers(0)
          } else if (response) {
            const total = get(response, 'data.count', 0)
            const newData = get(response, 'data.results', [])
            setData(newData)
            setControlledPageCount(Math.ceil(total / pageSize))
            setTotalShippers(total)
          }

          setLoading(false)
        }
        fetch()
      },
      [cancelPending, clearSource, getSource],
    )

    useEffect(() => {
      if (filters !== filtersRef.current || pageSize !== pageSizeRef.current) {
        filtersRef.current = filters
        pageSizeRef.current = pageSize

        if (pageIndex !== 0) {
          gotoPage(0)
          return
        }
      }

      fetchShippersList({ pageIndex, pageSize, filters })

      return function cancelFetchQuotes() {
        cancelPending()
      }
    }, [fetchShippersList, pageIndex, pageSize, filters, gotoPage, cancelPending])

    async function handleShipperStatusToggle(original: ShipperEntity) {
      const [error] = await editShipper({
        ...original,
        status: original.status === 'active' ? 'inactive' : 'active',
      })

      if (error) {
        toast.error('We could not save this shipper.')
      } else {
        toast.success('Shipper updated with sucess.')
        fetchShippersList({ pageIndex, pageSize, filters })
      }
    }

    useImperativeHandle(ref, () => ({
      fetchShippersList: () => fetchShippersList({ pageIndex, pageSize, filters }),
    }))

    return (
      <>
        {loading && (
          <Loading className="absolute inset-x-0 bottom-0 justify-center px-3 py-6 opacity-75 top-15 bg-background" />
        )}
        <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
          <Section title={`${totalShippers || 0} shippers`}>
            <Table className="w-full" {...getTableProps()}>
              <Table.Head>
                {headerGroups.map((headerGroup) => (
                  <Table.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <Table.Cell header {...column.getHeaderProps()} style={column.style}>
                        {column.render('Header')}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body {...getTableBodyProps()}>
                {page.map((row: any) => {
                  prepareRow(row)
                  return (
                    <Table.Row {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell: any) => {
                        return (
                          <Table.Cell
                            key={cell.column.id}
                            className={clsx('text-sm')}
                            style={cell.column.style}
                          >
                            {cell.render('Cell', {
                              onEditShipper,
                              onDeleteShipper,
                              onToggleShipperStatus: handleShipperStatusToggle,
                            })}
                          </Table.Cell>
                        )
                      })}
                    </Table.Row>
                  )
                })}
              </Table.Body>
              {isEmpty(data) && (
                <Table.Foot>
                  <Table.Row>
                    {/* @ts-ignore */}
                    <Table.Cell colSpan={COLUMNS.length}>
                      <Empty>No shippers to display.</Empty>
                    </Table.Cell>
                  </Table.Row>
                </Table.Foot>
              )}
            </Table>
            {!isEmpty(data) && (
              <Pagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                className="w-full px-4 pt-6"
                currentPage={pageIndex}
                disabled={loading}
                onPageChange={(page) => {
                  gotoPage(page)
                }}
                onPageSizeChange={(pageSize) => {
                  setPageSize(pageSize)
                }}
                pageSize={pageSize}
                totalPages={pageOptions.length}
              />
            )}
          </Section>
        </div>
      </>
    )
  },
)

export default ShippersList
