import {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
  ForwardedRef,
  forwardRef,
  useRef,
} from 'react'
import clsx from 'clsx'

import { InboundRenderer, OutboundRenderer } from './LocationRenderer'
import type { MarginGroup, MarginResult, MarginStatus } from 'common/types/Margin'
import DateHelper from 'common/Date.helpers'
import Empty from 'components/Empty'
import EquipmentType from '../../Quotes/QuoteForm/EquipmentType'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import EventLike from 'common/types/EventLike'
import Icon from 'components/Icon'
import isEmpty from 'common/helpers/isEmpty'
import Link from 'components/Link'
import Loading from 'components/Loading'
import NumberHelper from 'common/Number.helpers'
import StatusSwitch from './StatusSwitch'
import Table from 'components/Table'
import { getFilteredLaneMarginGroups } from './MarginsList.helpers'
import { PartialMarginsFilter } from './MarginsList.types'
import type { MarginFilterBody } from 'services/margins/margins.service'
import useFetchMargins from '../hooks/useFetchMargins'
import CurrencyHelper from '../../../common/Currency.helpers'
import { replaceWhiteSpaces } from 'common/utils'

import './MarginList.css'

interface MarginsListProps {
  filters?: PartialMarginsFilter
  className?: string
}

interface ColumnRenderProps {
  margin: MarginResult
  onChange?: (margin: MarginResult) => void
}

export function generateColumns({ editable = true } = {}) {
  return [
    {
      Header: 'Outbound',
      id: 'outbound',
      style: {},
      render({ margin }: ColumnRenderProps) {
        return <OutboundRenderer margin={margin} />
      },
    },
    {
      Header: 'Inbound',
      id: 'inbound',
      style: {},
      render({ margin }: ColumnRenderProps) {
        return <InboundRenderer margin={margin} />
      },
    },
    {
      Header: 'Channel',
      id: 'channel',
      style: { width: '10%' },
      render() {
        return <span className="h-full px-4 truncate">All channels</span>
      },
    },
    {
      Header: 'Date Range',
      id: 'date_range',
      style: { width: '220px' },
      render({ margin }: ColumnRenderProps) {
        function renderDate(date?: string | null) {
          return DateHelper(date)?.format() || '-'
        }

        return (
          <span className="flex flex-row items-center px-4 space-x-4">
            <span>{renderDate(margin.pickup_date_start)}</span>
            <span>{renderDate(margin.pickup_date_end)}</span>
          </span>
        )
      },
    },
    {
      Header: 'Equipment Type',
      id: 'equipment_type',
      style: { width: '140px' },
      render({ margin }: ColumnRenderProps) {
        return (
          <span className="flex flex-row items-center justify-center px-4 space-x-6">
            <EquipmentType
              className={clsx({
                'text-neutral-lighter': !EquipmentTypeHelper(margin.equipment_type).is('DRV'),
              })}
              mode="icon-only"
              name="DRV"
            />
            <EquipmentType
              className={clsx({
                'text-neutral-lighter': !EquipmentTypeHelper(margin.equipment_type).is('RFR'),
              })}
              mode="icon-only"
              name="RFR"
            />
            <EquipmentType
              className={clsx({
                'text-neutral-lighter': !EquipmentTypeHelper(margin.equipment_type).is('FBE'),
              })}
              mode="icon-only"
              name="FBE"
            />
          </span>
        )
      },
    },
    {
      Header: 'Shipper',
      id: 'shipper',
      style: { width: '13%' },
      render({ margin }: ColumnRenderProps) {
        return (
          <span className="h-full px-4 truncate">{margin.shipper?.name || 'All Shippers'}</span>
        )
      },
    },

    {
      Header: 'Margin',
      id: 'margin',
      style: { width: editable ? '20%' : '80px' },
      render({ margin, onChange }: ColumnRenderProps) {
        function handleToggle(e: EventLike<MarginStatus>) {
          const newStatus = e.target.value
          onChange?.({ ...margin, status: newStatus })
        }

        return (
          <div className="flex flex-row items-center h-full">
            <span
              className={clsx('font-bold truncate ml-4', {
                'text-primary-500': margin.margin_value > 0,
                'text-danger': margin.margin_value < 0,
              })}
            >
              {NumberHelper(margin.margin_value * 100).value()}
              %
            </span>
            {margin.max_margin_value && (
              <span className="text-neutral ml-1 text-sm">
                (max {CurrencyHelper(Number(margin.max_margin_value)).format()})
              </span>
            )}
            {editable && (
              <div className="relative inline-flex items-center ml-auto mr-4">
                <StatusSwitch margin={margin} onToggle={handleToggle} />
                <Link
                  href={`/margins/${margin.id}`}
                  className="items-center justify-center hidden w-8 h-8 mx-2 text-neutral-darkest"
                >
                  <Icon name="edit" title="Edit margin" size={16} />
                </Link>
              </div>
            )}
          </div>
        )
      },
    },
  ]
}

const COLUMNS = generateColumns()

export type MarginsListOperations = ReturnType<typeof generateMarginsListOperations>

function generateMarginsListOperations(fetchMargins: (body?: MarginFilterBody) => void) {
  return {
    refresh(body?: MarginFilterBody) {
      fetchMargins(body)
    },
  }
}

function MarginsList(
  { className, filters }: MarginsListProps,
  ref: ForwardedRef<MarginsListOperations>,
) {
  const [groups, setGroups] = useState<MarginGroup[]>([])
  const { loading, margins, fetchMargins, patchMargin } = useFetchMargins()

  const operationsRef = useRef(generateMarginsListOperations(fetchMargins))
  useImperativeHandle(ref, () => operationsRef.current)

  useEffect(() => {
    setGroups(getFilteredLaneMarginGroups(margins, filters || {}))
  }, [filters, margins])

  useEffect(
    function updateOperations() {
      operationsRef.current = generateMarginsListOperations(fetchMargins)
    },
    [fetchMargins],
  )

  return (
    <div className={clsx('relative', className)}>
      {loading && (
        <Loading className="absolute inset-x-0 bottom-0 z-10 justify-center px-3 py-6 opacity-75 top-6 bg-background" />
      )}
      {!isEmpty(margins) &&
        (groups || []).map((group: MarginGroup) => (
          <Fragment key={group.key}>
            <h2 className="flex flex-row items-center mb-2 text-base font-bold text-neutral-darkest">
              {group.key}
              {Boolean(group.margins.length) && (
                <span className="pl-2 text-sm font-normal text-neutral">
                  {group.margins.length} margins
                </span>
              )}
            </h2>
            <Table
              className="w-full mb-8"
              data-testid={`margins-list-${replaceWhiteSpaces(group.key)}`}
            >
              <Table.Header>
                <Table.Row>
                  {COLUMNS.map((column) => {
                    return (
                      <Table.Cell key={column.id} header>
                        {column.Header}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {(group.margins || []).map(function renderCommodity(margin: MarginResult) {
                  return (
                    <Table.Row key={margin.id}>
                      {COLUMNS.map((column) => {
                        return (
                          <Table.Cell key={column.id} style={column.style}>
                            {column.render({ margin, onChange: patchMargin })}
                          </Table.Cell>
                        )
                      })}
                    </Table.Row>
                  )
                })}
              </Table.Body>
              {isEmpty(group.margins) && (
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan={COLUMNS.length}>
                      <Empty>No margins to display.</Empty>
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Fragment>
        ))}
      {!loading && isEmpty(margins) && <Empty>No margins to be shown.</Empty>}
    </div>
  )
}

export default forwardRef(MarginsList)
