import { isNil } from '@loadsmart/utils-object'
import type { Quote } from 'common/types/Quote'
import get from 'common/helpers/get'
import set from 'common/helpers/set'
import isEmpty from 'common/helpers/isEmpty'
import { MultiQuoteResult } from 'common/types/MultiQuote'

/**
 * Updates a quote part by segment name
 * @param segment - part of the Quote state to be updated
 * @param quotePiece - actual payload with the update
 * @param quote - previous quote
 * @returns {Quote} - the updated quote
 */
export function updateQuoteBySegment(segment = '', quotePiece: any, quote: Quote) {
  const oldStatePiece = get({ ...quote }, segment)
  const transformedPiece = (() => {
    if (isNil(quotePiece)) {
      return quotePiece
    }

    if (typeof quotePiece === 'object' && !Array.isArray(quotePiece)) {
      return { ...oldStatePiece, ...quotePiece }
    }

    return quotePiece
  })()

  const newQuote = set(
    {
      ...quote,
      stops: [...quote.stops],
      items: [...quote.items],
      breakdown: [...quote.breakdown],
    },
    segment,
    transformedPiece,
  )
  return newQuote
}

/**
 * Sanitizes quote data before creating
 */
export function sanitizeQuote(quote: Quote) {
  const stops = quote.stops.map(({ zipcode, ...stop }) => ({
    ...stop,
    ...(!isEmpty(zipcode) && { zipcode }),
  }))

  return {
    ...quote,
    customer_reference: isEmpty(quote.customer_reference) ? null : quote.customer_reference,
    stops,
  }
}

/**
 * Returns initial quote value
 */
export function getDefaultQuote(): Quote {
  return {
    mode: 'FTL',
    equipment_type: undefined,
    weight: '0',
    channel: 'frontend',
    customer_reference: null,
    requirements: {
      team_driver: false,
    },
    shipper: {
      id: '',
      email: null,
    },
    stops: [
      {
        id: '0',
        type: 'pu',
        address: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        window: {
          start: null,
          end: null,
        },
        drop_trailer: false,
        place_id: '',
      },
      {
        id: '1',
        type: 'del',
        address: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        drop_trailer: false,
        place_id: '',
      },
    ],
    items: [],
    breakdown: [],
    price: 0,
    mileage: 0,
    selected_rate: null,
    rates: [],
  }
}

/**
 * Returns initial multi-quote value
 */
export function getDefaultMultiQuote(): MultiQuoteResult {
  return {
    id: '',
    channel: '',
    requests: [],
  }
}
