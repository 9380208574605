import { useEffect } from 'react'

function useBodyScrollLock(lock: boolean) {
  useEffect(
    function handleBodyOverflow() {
      if (lock) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }

      return function resetBodyOverflow() {
        document.body.classList.remove('overflow-hidden')
      }
    },
    [lock],
  )
}

export default useBodyScrollLock
