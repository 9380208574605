import { Quoteline } from '../../../../common/types/Quote'

export function getButtonLabelByMode(mode: 'add' | 'update') {
  if (mode === 'add') {
    return 'Create Price Adjustment'
  }

  return 'Save price adjustment'
}

export function getMode(adjustment?: Quoteline) {
  return adjustment?.quoteline_id ? 'update' : 'add'
}
