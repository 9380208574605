import mixpanel, { Dict } from 'mixpanel-browser'

import getEnvVariable from './helpers/getEnvVariable'
import jwtParse from './helpers/jwtParse'
import tokenManager from './tokenManager'
import getEnv from './helpers/getEnv'
import Environment from './types/Environment'
import { CurrentUser } from './types/CurrentUser'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const initMixPanel = () => {
  mixpanel.init(getEnvVariable('MIXPANEL_KEY'))
}

const identifyMixPanel = () => {
  try {
    const token = tokenManager.getToken()

    if (!token) {
      mixpanel.people.set({
        $name: '',
        $email: '',
        groups: [],
        id: '',
      })

      return
    }

    const user = (jwtParse(token) as unknown) as CurrentUser

    mixpanel.identify(user.user_id)
    if (mixpanel.people) {
      mixpanel.people.set({
        $name: `${user.user_first_name} ${user.user_last_name}`,
        $email: user.user_email,
        groups: user.group_names,
        id: user.user_id,
        account_name: user.account_name,
        test_account: user.test_account,
      })
    }
  } catch (e) {
    console.error(e)
    console.error('Error when identifying user on mixpanel')
  }
}

let analytics = {
  init() {
    if (getEnv() !== 'dev') {
      Sentry.init({
        dsn: getEnvVariable('SENTRY_DSN'),
        integrations: [new BrowserTracing()],
        environment: getEnv(),
        tracesSampleRate: 1.0,
      })
    }
  },
  identify() {},
  track(label: string, obj: Dict | undefined = undefined) {},
}

if (getEnv() === Environment.Production) {
  analytics = {
    init() {
      Sentry.init({
        dsn: getEnvVariable('SENTRY_DSN'),
        integrations: [new BrowserTracing()],
        environment: getEnv(),
      })
      initMixPanel()
      identifyMixPanel()
    },
    identify() {
      identifyMixPanel()
    },
    track(label: string, obj: Dict | undefined = undefined) {
      mixpanel.track(label, obj)
    },
  }
}

export default analytics
