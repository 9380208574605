import { useCallback } from 'react'

import { CommodityProps } from './Commodity'
import useCollection from '../../../../hooks/useCollection'

export interface useCommoditiesProps {
  commodities?: Array<CommodityProps>
  onChange?: (items: Array<CommodityProps>) => void
}

function useCommodities({ commodities, onChange }: useCommoditiesProps) {
  const { items, addItem, removeItem, updateItem } = useCollection<CommodityProps>({
    items: commodities,
    onChange,
  })

  const addCommodity = useCallback(
    function addCommodity(commodity?: CommodityProps) {
      addItem(commodity || DEFAULT_COMMODITY)
    },
    [addItem],
  )

  return {
    commodities: items,
    addCommodity,
    removeCommodity: removeItem,
    updateCommodity: updateItem,
  }
}

const DEFAULT_COMMODITY = {
  po_number: '',
  description: '',
  hazmat: false,
} as CommodityProps

export default useCommodities
