import tokenManager from './tokenManager'
import jwtParse from './helpers/jwtParse'
import { CurrentUser } from './types/CurrentUser'

function CurrentUserHelper() {
  const token = tokenManager.getToken()
  const refreshToken = tokenManager.getRefreshToken()

  let user: CurrentUser | null = null
  let refreshTokenUser: CurrentUser | null = null

  if (token) {
    user = (jwtParse(token) as unknown) as CurrentUser
  }
  if (refreshToken) {
    refreshTokenUser = (jwtParse(refreshToken) as unknown) as CurrentUser
  }

  return {
    getCurrentUser(): CurrentUser | null {
      return user
    },
    sessionExpired(): boolean {
      if (refreshTokenUser && refreshTokenUser.exp) {
        const expDate = new Date(refreshTokenUser.exp * 1000)
        return new Date() > expDate
      }
      return false
    },
    hasAllGroups: (groupNames: string[]) => {
      if (!user || !user.group_names) {
        return false
      }

      return groupNames.every((groupName) => {
        return user!.group_names.indexOf(groupName) > -1
      })
    },
  }
}

export default CurrentUserHelper
