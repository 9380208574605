import { useCallback } from 'react'

import { StopProps } from './Stop'
import useCollection, { CollectionCustomizer } from '../../../../hooks/useCollection'

const collectionCustomizer: CollectionCustomizer<StopProps> = {
  order(order, stopID) {
    // PU should always stay as first; final DEL should always stay as last
    return [...order.slice(0, order.length - 1), stopID, ...order.slice(order.length - 1)]
  },
  enhancer(stop: StopProps, index: number) {
    /**
     * As Backend does not save stop type currently, we need to keep and reapply
     * stop type whenever we change our quote.
     */
    if (stop.type) {
      return stop
    }

    stop.type = index === 0 ? 'pu' : 'del'

    return stop
  },
}
export interface useStopsProps {
  stops?: Array<StopProps>
  onChange?: (items: Array<StopProps>) => void
}

function useStops({ stops, onChange }: useStopsProps) {
  const { items, addItem, removeItem, updateItem } = useCollection<StopProps>({
    items: stops || DEFAULT_INITIAL_STOPS,
    onChange,
    options: { customizer: collectionCustomizer },
  })

  const addStop = useCallback(
    function addStop(stop?: StopProps) {
      addItem(stop || DEFAULT_STOP)
    },
    [addItem],
  )

  return {
    stops: items,
    addStop,
    removeStop: removeItem,
    updateStop: updateItem,
  }
}

export const DEFAULT_INITIAL_STOPS = [
  {
    type: 'pu',
    intermediary: false,
  },
  {
    type: 'del',
    intermediary: false,
  },
] as Array<StopProps>

export const DEFAULT_STOP = {
  type: 'del',
  intermediary: true,
} as StopProps

export default useStops
