import { ChangeEvent, useCallback } from 'react'
import clsx from 'clsx'

import { Commodity } from 'common/types/Commodity'
import { HAZMAT_CLASSES } from '../../components/Commodities/Commodity.constants'
import { IconButton } from 'components/Button'
import Checkbox from 'components/Checkbox'
import EventLike from 'common/types/EventLike'
import Field from 'components/Field'
import getValueFromEvent from 'common/helpers/getValueFromEvent'
import Icon from 'components/Icon'
import Label from 'components/Label'
import Select from 'components/Select'
import TextField from 'components/TextField'
import set from 'common/helpers/set'

export interface CommodityProps extends Commodity {}

function CommodityEntry({
  commodity,
  onChange,
  onRemove,
}: {
  commodity: CommodityProps
  onChange?: (stop: CommodityProps) => void
  onRemove?: (stopID: string) => void
}) {
  const handleChange = useCallback(
    function handleChange<CommodityProps>(
      e: ChangeEvent<HTMLInputElement> | EventLike<CommodityProps>,
    ) {
      const {
        target: { name },
      } = e
      const value = getValueFromEvent(e)

      const newCommodity = {
        ...commodity,
      }

      set(newCommodity, String(name), value)

      onChange?.(newCommodity)
    },
    [onChange, commodity],
  )

  return (
    <div
      className="flex flex-col space-y-2 lg:space-x-4 lg:items-end lg:flex-row rg-commodity"
      data-testid="commodity"
    >
      <div className="flex flex-col items-start space-y-2 lg:items-end lg:space-y-0 lg:space-x-4 lg:flex-row">
        <Field className="w-full lg:w-40">
          <Label htmlFor="po_number">PO number</Label>
          <TextField
            name="po_number"
            id="po_number"
            value={commodity.po_number || ''}
            onChange={handleChange}
            maxLength={255}
          />
        </Field>
        <Field className="w-full lg:w-80">
          <Label htmlFor="commodity_description">Description/Commodity</Label>
          <TextField
            name="description"
            id="commodity_description"
            value={commodity.description || ''}
            onChange={handleChange}
            maxLength={255}
          />
        </Field>
      </div>

      <div
        className={clsx(
          'flex flex-col items-start space-y-2 lg:items-end lg:space-y-0 lg:space-x-4 lg:flex-row lg:flex-1',
        )}
      >
        <Checkbox
          name="hazmat"
          onChange={handleChange}
          defaultChecked={commodity.hazmat}
          data-testid="is-hazmat"
        >
          Hazmat
        </Checkbox>

        <Field className="w-full">
          <Label htmlFor="hazmat_class">Hazmat class</Label>
          <Select
            id="hazmat_class"
            name="hazmat_class"
            onChange={handleChange}
            disabled={!commodity.hazmat}
            options={HAZMAT_CLASSES}
            value={commodity.hazmat_class}
            data-testid="hazmat-class"
          />
        </Field>
      </div>

      <IconButton
        onClick={() => {
          onRemove?.(commodity.id)
        }}
        data-testid="delete-commodity"
        className="self-start lg:self-end"
      >
        <Icon name="remove" title="Delete commodity" size={16} />
      </IconButton>
    </div>
  )
}

CommodityEntry.defaultProps = {
  hazmat: false,
}

export default CommodityEntry
