import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import Breadcrumb, { BreadcrumbProps } from './Breadcrumb'

import './Breadcrumbs.css'

export interface BreadcrumbsProps {
  className?: string
  entries: BreadcrumbProps[]
}

function Breadcrumbs({ className, children, entries }: PropsWithChildren<BreadcrumbsProps>) {
  return (
    <ul
      className={clsx('flex flex-row py-0 m-0 list-none space-x-4', className)}
      data-testid="breadcrumbs"
    >
      {(entries || []).map((entry: BreadcrumbProps) => {
        return <Breadcrumb key={entry.label} {...entry} />
      })}
    </ul>
  )
}
export default Breadcrumbs
