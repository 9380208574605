import React from 'react'

import Stop from 'pages/Quotes/QuoteForm/Stops/Stop'
import Layout from 'components/Layout'

import type { Stop as StopProps, StopType } from 'common/types/Stop'
import { Accessorial } from '../../../../../../common/types/Accessorial'
import { Accessorials } from '../Accessorials/Accessorials'

export interface StopAccessorials extends StopProps {
  accessorials: string[]
}

function getDefaultStop(type: StopType): StopProps {
  return {
    type,
    id: type === 'pu' ? '0' : '1',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    window: {
      start: null,
      end: null,
    },
    drop_trailer: false,
    place_id: '',
  }
}

export type LTLStopProps = {
  errors?: any
  stop: StopAccessorials
  type: StopType
  onChange: (val: StopAccessorials) => void
  accessorials: Accessorial[]
}

export function LTLStop(props: LTLStopProps) {
  const { accessorials: initialAccessorials = [], ...initialStop } = props.stop || {}

  const [stop, setStop] = React.useState<StopProps>({
    ...getDefaultStop(props.type),
    ...initialStop,
  })

  const [selectedAccessorials, setSelectedAccessorials] = React.useState<Record<string, boolean>>(
    initialAccessorials.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
  )

  React.useEffect(() => {
    props.onChange({
      ...stop,
      accessorials: Object.entries(selectedAccessorials)
        .filter(([, val]) => val)
        .map(([key]) => key),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stop, selectedAccessorials])

  return (
    <Layout.Stack space="m" className="w-full">
      <Stop
        errors={props.errors}
        label="Stop"
        stop={stop}
        id={props.type}
        showDate={props.type === 'pu'}
        onChange={(val) => setStop((prev) => ({ ...prev, ...val }))}
      />
      <Accessorials
        type={props.type}
        value={selectedAccessorials}
        onChange={(code) => {
          setSelectedAccessorials((prev) => ({ ...prev, [code]: !prev[code] }))
        }}
        accessorials={props.accessorials}
      />
    </Layout.Stack>
  )
}
