import PriceFeedback from './PriceFeedback'
import { QuoteRate } from 'common/types/QuoteRate'
import { RateFeedback, RateFeedbackOption } from 'common/types/RateFeedback'
import { setRateFeedback } from 'services/quotes/quotes.service'
import { toast } from '../../../../components/Toast'
import { useEffect, useState } from 'react'

export interface PriceFeedbackWrapperProps {
  quoteId: string
  rate: QuoteRate
}

const PriceFeedbackWrapper = ({ quoteId, rate }: PriceFeedbackWrapperProps) => {
  const [feedback, setFeedback] = useState<RateFeedbackOption>(
    rate.feedback ? rate.feedback.feedback : null,
  )

  const setPriceFeedback = async (rateFeedback: RateFeedback) => {
    const [, response] = await setRateFeedback(quoteId, rate.id, rateFeedback)

    if (response) {
      setFeedback(rateFeedback.feedback)
      toast.success('Thank you for your feedback')
    } else {
      toast.error('We could not set the rate feedback.')
    }

    return
  }

  useEffect(() => {
    setFeedback(rate.feedback ? rate.feedback.feedback : null)
  }, [rate])

  return <PriceFeedback feedback={feedback} onSubmit={setPriceFeedback} />
}

export default PriceFeedbackWrapper
