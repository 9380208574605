import clsx from 'clsx'

import { Commodity } from 'common/types/Commodity'
import isEmpty from 'common/helpers/isEmpty'
import Table from 'components/Table'
import { getHazmatByValue } from './Commodity.helpers'

export interface CommoditiesTableProps {
  className?: string
  commodities?: Array<Commodity>
}

interface ColumnRenderProps {
  commodity: Commodity
}

export const COLUMNS = [
  {
    Header: 'PO Number',
    id: 'po_number',
    style: { width: '10%', minWidth: '120px' },
    render({ commodity }: ColumnRenderProps) {
      return <span className="h-full px-2 truncate">{commodity.po_number}</span>
    },
  },
  {
    Header: 'Description',
    id: 'description',
    render({ commodity }: ColumnRenderProps) {
      return <span className="h-full px-2 truncate">{commodity.description}</span>
    },
  },
  {
    Header: 'Hazmat',
    id: 'hazmat',
    style: { width: '10%' },
    render({ commodity }: ColumnRenderProps) {
      return <span className="h-full px-2 truncate">{commodity.hazmat ? 'Yes' : 'No'}</span>
    },
  },
  {
    Header: 'Hazmat class',
    id: 'hazmat_class',
    style: { width: '20%' },
    render({ commodity }: ColumnRenderProps) {
      const hazmatClass = getHazmatByValue(String(commodity.hazmat_class))

      return <span className="h-full px-2 truncate">{hazmatClass?.label || '-'}</span>
    },
  },
]

function FTLCommoditiesTable({ className, commodities }: CommoditiesTableProps) {
  return (
    <Table data-testid="commodities-table" className={clsx('w-full', className)}>
      <Table.Header>
        <Table.Row>
          {COLUMNS.map((column) => {
            return (
              <Table.Cell key={column.id} header>
                {column.Header}
              </Table.Cell>
            )
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(commodities || []).map(function renderCommodity(commodity: Commodity, index: number) {
          return (
            <Table.Row key={`${index}-${commodity.description}`}>
              {COLUMNS.map((column) => {
                return (
                  <Table.Cell key={column.id} className="" style={column.style}>
                    {column.render({ commodity })}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          )
        })}

        {isEmpty(commodities) && (
          <Table.Row key="empty">
            <Table.Cell className="text-center" colSpan={COLUMNS.length}>
              No items to be shown.
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export default FTLCommoditiesTable
