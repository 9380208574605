import { useRef, useState } from 'react'
import { Quote } from 'common/types/Quote'
import usePolling, { PollingConfig } from 'hooks/usePolling'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { QuoteRequest } from 'services/quotes/quotes.service'
import { toast } from 'components/Toast'

interface useLTLQuoteProps {
  setQuote: (quote: Quote) => void
  fetchStatus: (
    quoteRequestId: string,
    config?: AxiosRequestConfig | undefined,
  ) => Promise<[boolean | AxiosError<any> | null, AxiosResponse<QuoteRequest> | undefined]>
  onGetQuoteSuccess: () => void
  getQuote?: (
    quoteId: string,
    config?: AxiosRequestConfig | undefined,
  ) => Promise<[boolean | AxiosError<any> | null, AxiosResponse<Quote> | undefined]>
}

const useLTLQuote = ({ setQuote, fetchStatus, onGetQuoteSuccess, getQuote }: useLTLQuoteProps) => {
  const DEFAULT_POLLING_CONFIG: Partial<PollingConfig> = {
    minInterval: 3000,
    growthFactor: 1,
    startImmediately: true,
  }
  const [isCalculatingPrice, setIsCalculatingPrice] = useState(false)
  const quoteRequestId = useRef<string>()

  const fetchLTLQuote = (quoteId: string) => {
    async function fetchQuote(quoteId: string) {
      const [, response] = await getQuote!(quoteId)
      if (response) {
        setQuote(response.data)
        onGetQuoteSuccess()
      } else {
        toast.error('We could not retrieve the quote.')
      }
      setIsCalculatingPrice(false)
    }
    fetchQuote(quoteId)
  }

  const onFetchLTLQuoteRequestStatusSuccess = (response: AxiosResponse<QuoteRequest>) => {
    if (response.data.status === 'pending') {
      return true
    }

    if (response.data.status === 'ready' && response.data.quote.quote_id) {
      if (getQuote) {
        fetchLTLQuote(response.data.quote.quote_id)
      } else {
        setQuote(response.data.quote)
        onGetQuoteSuccess()
        setIsCalculatingPrice(false)
      }
    } else {
      setIsCalculatingPrice(false)
      toast.error('We could not retrieve the prices.')
    }

    return false
  }

  const onFetchLTLQuoteRequestStatusFails = (_: any, retriesCount: number) => {
    if (retriesCount < 3) {
      return true
    }
    setIsCalculatingPrice(false)
    toast.error('We could not retrieve the prices.')
    return false
  }

  const pollingConfig = {
    ...DEFAULT_POLLING_CONFIG,
    onFail: onFetchLTLQuoteRequestStatusFails,
    onSuccess: onFetchLTLQuoteRequestStatusSuccess,
  }

  const fetchLTLQuoteRequestStatus = () => {
    return fetchStatus(quoteRequestId.current!)
  }

  const { startPolling } = usePolling(fetchLTLQuoteRequestStatus, pollingConfig)

  const getLTLQuote = (requestId: string) => {
    setIsCalculatingPrice(true)
    quoteRequestId.current = requestId
    startPolling()
  }

  return {
    isCalculatingPrice,
    getLTLQuote,
  }
}

export default useLTLQuote
