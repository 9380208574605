import { Layout } from '@loadsmart/loadsmart-ui'
import { Dialog } from 'components/Dialog'
import Text from 'components/Text'

export interface ProviderChangeMessageProps {
  open: boolean
  secondaryProviderName: string
  onConfirm: () => void
  onCancel: () => void
}

function ProviderChangeMessage({
  open,
  secondaryProviderName,
  onConfirm,
  onCancel,
}: ProviderChangeMessageProps) {
  return (
    <Dialog open={open} onOverlayClick={onCancel} scale="small">
      <Dialog.Header>Switching providers</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack>
          <Text variant="body">
            You already have smart rules created, so by changing the default rate provider, we will
            automatically switch the secondary rate provider to <b>{secondaryProviderName}</b>.
          </Text>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.ActionConfirm title="Confirm save with provider change" onConfirm={onConfirm}>
        confirm
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onCancel}></Dialog.ActionCancel>
    </Dialog>
  )
}

export default ProviderChangeMessage
