import { useCallback } from 'react'
import Suggestion, { SuggestionDatasource, SuggestionValue } from 'components/Suggestion'
import type ColorScheme from 'common/types/ColorScheme'
import type EventLike from 'common/types/EventLike'
import type Status from 'common/types/Status'
import type { User } from 'common/types/User'
import useFetchUsers, { UserAdapter } from './useFetchUsers'
import isEmpty from 'common/helpers/isEmpty'

export interface UserSuggestionProps {
  datasources?: (() => SuggestionDatasource<any>)[]
  className?: string
  delay?: number
  multiple?: boolean
  clearable?: boolean
  disabled?: boolean
  id?: string
  name: string
  placeholder?: string
  scheme?: ColorScheme
  status?: Status
  query?: string
  value?: User | User[] | null
  onChange?: (event: EventLike<User | User[] | null>) => void
}

const DATASOURCES = [useFetchUsers]

function UserSuggestion({
  name = 'user-suggestion',
  id,
  onChange,
  ...others
}: UserSuggestionProps) {
  const handleChange = useCallback(
    function handleChange(e: EventLike<SuggestionValue>) {
      const {
        target: { value },
      } = e

      if (Array.isArray(value)) {
        const users = [] as User[]

        for (let i = 0; i < value.length; i++) {
          const entry = value[i]
          const [first_name, last_name] = entry?.label.split(' ') ?? []
          users.push({
            id: String(entry.value),
            first_name,
            last_name,
          })
        }

        onChange?.({
          target: { id, name, value: isEmpty(users) ? null : users },
        })
      } else {
        const [first_name, last_name] = value?.label.split(' ') ?? []

        onChange?.({
          target: {
            id,
            name,
            value: value
              ? ({
                  id: value.value,
                  first_name,
                  last_name,
                } as User)
              : null,
          },
        })
      }
    },
    [id, name, onChange],
  )

  function getValue() {
    if (!others.value) {
      return null
    }

    if (Array.isArray(others.value)) {
      return (others.value as User[]).map((location) => ({
        label: UserAdapter.getLabel(location),
        value: UserAdapter.getValue(location),
      }))
    }

    return {
      label: UserAdapter.getLabel(others.value as User),
      value: UserAdapter.getValue(others.value as User),
    }
  }

  return (
    <Suggestion
      {...others}
      value={getValue() as any}
      id={id}
      name={name}
      onChange={handleChange}
      delay={450}
      minQueryLength={1}
      datasources={DATASOURCES}
    />
  )
}

export default UserSuggestion
