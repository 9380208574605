import { HTMLAttributes, useContext, useState, useEffect, ChangeEvent } from 'react'
import clsx from 'clsx'
import { TabContext } from './TabContext'
import Styled from 'styled-components'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import useID from 'hooks/useID'
import { WithVariantProps } from './Tab.types'
import { conditional, whenProps } from '@loadsmart/loadsmart-ui/dist/tools'

const StyledUL = Styled.ul<WithVariantProps>`
  display: flex;
  padding-left: ${conditional({
    'space-m': whenProps({ variant: 'default' }),
  })};
  padding-right: ${conditional({
    'space-m': whenProps({ variant: 'default' }),
  })};
  border-bottom-width: ${conditional({
    '2px': whenProps({ variant: 'default' }),
    '1px': whenProps({ variant: 'secondary' }),
  })};
  border-bottom-color: ${token('color-neutral-light')};
`

const StyledLabel = Styled.label<WithVariantProps>`
  font-size: ${token('font-size-4')};
  padding-left: ${conditional({
    'space-m': whenProps({ variant: 'default' }),
    'space-l': whenProps({ variant: 'secondary' }),
  })};
  padding-right: ${conditional({
    'space-m': whenProps({ variant: 'default' }),
    'space-l': whenProps({ variant: 'secondary' }),
  })};
  padding-bottom: ${conditional({
    'space-s': whenProps({ variant: 'default' }),
    'space-m': whenProps({ variant: 'secondary' }),
  })};
  padding-top: ${conditional({
    'space-s': whenProps({ variant: 'default' }),
    'space-m': whenProps({ variant: 'secondary' }),
  })};
  display: inline-block;
  cursor: pointer;
  position: relative;

  bottom: ${conditional({
    '-2px': whenProps({ variant: 'default' }),
    '-1px': whenProps({ variant: 'secondary' }),
  })};
  border-bottom-width: ${conditional({
    '2px': whenProps({ variant: 'default' }),
    '1px': whenProps({ variant: 'secondary' }),
  })};
`

interface NavProps extends HTMLAttributes<HTMLUListElement> {}

function Nav({ children, className, ...props }: NavProps) {
  const { variant } = useContext(TabContext)
  return (
    <div className={className}>
      <StyledUL variant={variant} {...props} role="tablist">
        {children}
      </StyledUL>
    </div>
  )
}

interface NavItemProps extends HTMLAttributes<HTMLInputElement> {
  name: string
  isDefault?: boolean
}

function NavItem({ children, name, isDefault, className, onChange, ...props }: NavItemProps) {
  const { id: tabId, setActiveTab, activeTab, variant } = useContext(TabContext)

  function handleSelect(event: ChangeEvent<HTMLInputElement>) {
    setActiveTab(name)
    onChange?.(event)
  }

  useEffect(() => {
    if (isDefault) {
      setActiveTab(name)
    }
  }, [name, setActiveTab, isDefault])

  return (
    <li role="tab">
      <StyledLabel
        htmlFor={`${tabId}_${name}`}
        variant={variant}
        className={clsx(
          'border-transparent',
          {
            'font-extrabold rounded-t-md border-neutral-light': variant === 'secondary',
          },
          {
            'text-success border-success': variant === 'default' && activeTab === name,
          },
          {
            'bg-neutral-lighter text-neutral-dark': variant === 'secondary' && activeTab !== name,
          },
          {
            'bg-neutral-lightest': variant === 'secondary' && activeTab === name,
          },
          className,
        )}
      >
        <input
          {...props}
          type="radio"
          name={tabId}
          id={`${tabId}_${name}`}
          onChange={handleSelect}
          hidden
        />
        {children}
      </StyledLabel>
    </li>
  )
}

interface PanelsProps extends HTMLAttributes<HTMLDivElement> {}

function Panels({ children, ...props }: PanelsProps) {
  return <div {...props}>{children}</div>
}

interface PanelItemProps extends HTMLAttributes<HTMLElement> {
  name: string
}

function PanelItem({ children, name, ...props }: PanelItemProps) {
  const { activeTab } = useContext(TabContext)

  const hidden = activeTab !== name

  return (
    <article
      {...props}
      role="tabpanel"
      className={clsx({ hidden })}
      aria-hidden={hidden ? 'true' : 'false'}
    >
      {children}
    </article>
  )
}

interface TabProps extends HTMLAttributes<HTMLElement>, WithVariantProps {}

function Tab({ children, variant = 'default', ...props }: TabProps) {
  const [activeTab, setActiveTab] = useState('')
  const id = useID()

  return (
    <div {...props}>
      <TabContext.Provider value={{ activeTab, setActiveTab, id, variant }}>
        {children}
      </TabContext.Provider>
    </div>
  )
}
Tab.Items = Nav
Tab.Item = NavItem
Tab.Panels = Panels
Tab.Panel = PanelItem

export default Tab
