import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Outlet } from '../../common/types/Outlet'

interface OutletState {
  results: Outlet[] | null
}

const initialState: OutletState = {
  results: null,
}

const slice = createSlice({
  name: 'outlets',
  initialState,
  reducers: {
    setOutlets(_, action: PayloadAction<OutletState>) {
      return action.payload
    },
    setOutletsFailed() {
      return initialState
    },
  },
})

export default slice
