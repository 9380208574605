import clsx from 'clsx'
import { ReactNode } from 'react'

export interface EmptyProps {
  className?: string
  children: ReactNode
}

function Empty({ className, children }: EmptyProps) {
  return (
    <section
      className={clsx(
        'w-full p-4 text-center bg-neutral-lightest text-neutral-darkest text-sm',
        className,
      )}
    >
      {children}
    </section>
  )
}

export default Empty
