import React from 'react'
import { CartesianGrid } from './index'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

const RenderGrid = () => {
  const theme = useTheme() as RateGuideThemeInterface

  return <CartesianGrid stroke={theme['color-neutral-light']} strokeDasharray="5 5" />
}

export default RenderGrid
