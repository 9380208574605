import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en' // locale-data for en
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en' // locale-data for en

import LSNumber from './Number.helpers'

const LOCALE = 'en-US' // TODO: parameterize locale

const getCurrencyFormatter = ({ ...options } = {}) => {
  return new Intl.NumberFormat(LOCALE, { style: 'currency', currency: 'USD', ...options })
}

export function formatCurrency(value: number) {
  return getCurrencyFormatter()
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return `${value} ` // aplying space to comply with design specification
        default:
          return value
      }
    })
    .join('')
}

function Currency(value: number | string) {
  const currency = LSNumber(value)

  return {
    /**
     * Get the provided currency value as `number`.
     */
    value() {
      return currency.value()
    },
    /**
     * Get the provided currency value as `string`, with 2 digits after the decimal point.
     */
    string() {
      return currency.format()
    },
    /**
     * Get the provided value formatted as currency (including currency symbol).
     */
    format() {
      return formatCurrency(currency.value())
    },
    integer() {
      return getCurrencyFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
        currency.value(),
      )
    },
  }
}

export default Currency
