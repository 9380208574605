import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { post } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'

export interface GetPricesOverTimePayload {
  summary: {
    highest: { value: number }
    lowest: { value: number }
    average: { value: number }
  }
  series: {
    id: string
    legend: string
    data: {
      timestamp: string
      value?: number
      min?: number
      max?: number
    }[]
  }[]
}

export interface GetQuotesCreationOverTimePayload {
  series: {
    id: string
    legend: string
    data: [
      {
        timestamp: string
        value: number
      },
    ]
  }[]
}

export interface GetQuotesSummaryPayload {
  total: number
  total_variation: number
}

export async function getPricesOverTime(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetPricesOverTimePayload> | undefined]> {
  try {
    return await to(post('/api/analytics/pricing-summary', data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function getQuotesCreationOverTime(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetQuotesCreationOverTimePayload> | undefined]> {
  try {
    return await to(post('/api/analytics/quotes-types', data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function getQuotesSummary(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetQuotesSummaryPayload> | undefined]> {
  try {
    return await to(post('/api/analytics/quotes-summary', data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}
