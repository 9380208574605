import type { QuoteStatus } from './types/Quote'
import Status from 'common/types/Status'
import toArray from 'common/helpers/toArray'

// TODO: rename to represent conversion to form status
function getFormStatus(status?: QuoteStatus) {
  switch (status) {
    case 'sending':
      return Status.Busy
    case 'sent':
      return Status.Warn
    case 'booked':
      return Status.Success
    case 'cancelled':
    case 'expired':
    case 'rejected':
      return Status.Danger
    case 'open':
    default:
      return Status.Neutral
  }
}

interface QuoteStatusData {
  label: string
  value: QuoteStatus
}

const OPEN: QuoteStatusData = {
  label: 'Open',
  value: 'open',
}

const SENT: QuoteStatusData = {
  label: 'Sent to shipper',
  value: 'sent',
}

const SENDING: QuoteStatusData = {
  label: 'Sending',
  value: 'sending',
}

const BOOKED: QuoteStatusData = {
  label: 'Booked',
  value: 'booked',
}

const CANCELLED: QuoteStatusData = {
  label: 'Cancelled',
  value: 'cancelled',
}

const EXPIRED: QuoteStatusData = {
  label: 'Expired',
  value: 'expired',
}

const REJECTED: QuoteStatusData = {
  label: 'Rejected',
  value: 'rejected',
}

const QUOTE_STATUS_BY_NAME: Record<QuoteStatus, QuoteStatusData> = {
  booked: BOOKED,
  Booked: BOOKED,
  BOOKED: BOOKED,
  cancelled: CANCELLED,
  Cancelled: CANCELLED,
  CANCELLED: CANCELLED,
  expired: EXPIRED,
  Expired: EXPIRED,
  EXPIRED: EXPIRED,
  open: OPEN,
  Open: OPEN,
  OPEN: OPEN,
  rejected: REJECTED,
  Rejected: REJECTED,
  REJECTED: REJECTED,
  sent: SENT,
  Sent: SENT,
  SENT: SENT,
  sending: SENDING,
  Sending: SENDING,
  SENDING: SENDING,
}

// const ALL_QUOTE_STATUS = [OPEN, SENT, BOOKED, CANCELLED, EXPIRED, REJECTED]
const ALL_QUOTE_STATUS = [OPEN, SENT, BOOKED, CANCELLED, REJECTED]

function getAllStatus(getCustomProps?: (status: QuoteStatus) => Record<string, any>) {
  if (!getCustomProps) {
    return ALL_QUOTE_STATUS
  }

  return ALL_QUOTE_STATUS.map((type) => ({
    ...type,
    ...getCustomProps(type.value),
  }))
}

function getByName(name: QuoteStatus, getCustomProps?: (type: QuoteStatus) => Record<string, any>) {
  if (!getCustomProps) {
    return QUOTE_STATUS_BY_NAME[name]
  }

  return {
    ...QUOTE_STATUS_BY_NAME[name],
    ...getCustomProps(name),
  }
}

export function QuoteStatusHelper(status: QuoteStatus) {
  const data: QuoteStatusData = getByName(status) || {}

  return {
    /**
     * Get the corresponding `Status` for quote status.
     */
    status() {
      return getFormStatus(status)
    },
    label() {
      return data.label
    },
    value() {
      return data.value
    },
    is(other: QuoteStatus | QuoteStatus[]) {
      return toArray(other).includes(status)
    },
  }
}

QuoteStatusHelper.getAll = getAllStatus
QuoteStatusHelper.getByName = getByName

export default QuoteStatusHelper
