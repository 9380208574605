import React from 'react'

import EventLike from 'common/types/EventLike'
import { RateProviderIdentifiers } from 'common/types/RateProvider'
import RateProviderSelectorItem from './RateProviderSelectorItem'
import { Layout } from '@loadsmart/loadsmart-ui'
import get from 'common/helpers/get'

interface RateProviderSelectorProps {
  onChange: (event: EventLike<any>) => void
  rateProviders: RateProviderIdentifiers[]
  name: string
  value?: RateProviderIdentifiers | null
  margins?: Record<RateProviderIdentifiers, number>
  defaultMarginError?: string
  onMarginEdit: (rateProvider: RateProviderIdentifiers) => void
}

const RateProviderSelector = ({
  onChange,
  onMarginEdit,
  rateProviders,
  name,
  value,
  margins,
  defaultMarginError,
}: RateProviderSelectorProps) => {
  return (
    <Layout.Group>
      {rateProviders.map((rateProvider) => (
        <div key={rateProvider}>
          <RateProviderSelectorItem
            onClick={() => onChange({ target: { name, value: rateProvider } })}
            selected={rateProvider === value}
            rateProvider={rateProvider}
            margin={get(margins, rateProvider, undefined)}
            error={rateProvider === value ? defaultMarginError : undefined}
            onMarginEdit={onMarginEdit}
          />
        </div>
      ))}
    </Layout.Group>
  )
}

export default RateProviderSelector
