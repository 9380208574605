import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { get } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { RateProviderIdentifiers } from 'common/types/RateProvider'

export async function getRateProviders(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null | boolean, AxiosResponse<RateProviderIdentifiers[]> | undefined]> {
  try {
    return await to(get(`/api/account/rate-providers`, config))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}
