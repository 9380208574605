import { HazmatClass } from './Commodity.types'

export const HAZMAT_CLASSES = [
  { label: 'Explosive Non Specified', value: 'EXPLOSIVE_NON_SPECIFIED' },
  { label: 'Mass Explosive', value: 'MASS_EXPLOSIVE' },
  { label: 'Projection Explosive', value: 'PROJECTION_EXPLOSIVE' },
  { label: 'Minor Fire Explosive', value: 'MINOR_FIRE_EXPLOSIVE' },
  { label: 'Minor Explosive', value: 'MINOR_EXPLOSIVE' },
  {
    label: 'Insensitive Mass Explosive',
    value: 'INSENSITIVE_MASS_EXPLOSIVE',
  },
  {
    label: 'Insensitive Minor Explosive',
    value: 'INSENSITIVE_MINOR_EXPLOSIVE',
  },
  { label: 'Gas Non Specified', value: 'GAS_NON_SPECIFIED' },
  { label: 'Flammable Gas', value: 'FLAMMABLE_GAS' },
  {
    label: 'Non Flammable Non Poisonous or Oxygen Gas',
    value: 'NON_FLAMMABLE_NON_POISONOUS_OR_OXYGEN_GAS',
  },
  { label: 'Poison Gas', value: 'POISON_GAS' },
  { label: 'Flammable Liquid', value: 'FLAMMABLE_LIQUID' },
  { label: 'Solid Non Specified', value: 'SOLID_NON_SPECIFIED' },
  { label: 'Flammable Solid', value: 'FLAMMABLE_SOLID' },
  {
    label: 'Spontaneously Combustible Solid',
    value: 'SPONTANEOUSLY_COMBUSTIBLE_SOLID',
  },
  { label: 'Dangerous when Wet', value: 'DANGEROUS_WHEN_WET' },
  { label: 'Oxidizer Non Specified', value: 'OXIDIZER_NON_SPECIFIED' },
  { label: 'Oxidizer', value: 'OXIDIZER' },
  { label: 'Organic Peroxide', value: 'ORGANIC_PEROXIDE' },
  { label: 'Poison', value: 'POISON' },
  { label: 'Radioactive', value: 'RADIOACTIVE' },
] as HazmatClass[]
