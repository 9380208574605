import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import CustomTooltip from './CustomTooltip'
import { RateGuideThemeInterface } from 'theming'
import {
  ComposedChart,
  RenderArea,
  RenderGrid,
  RenderLegend,
  RenderLine,
  RenderLinearGradient,
  RenderRangedBar,
  RenderTooltip,
  RenderXAxis,
  RenderYAxis,
  ResponsiveContainer,
} from 'components/Charts'
import { Payload } from 'recharts/types/component/DefaultLegendContent'

export type PriceOverTimeData = {
  date: string
  'rate-guide': number
  market?: number
  min: number
  max: number
}

export interface PriceOverTimeProps {
  data: PriceOverTimeData[]
  width?: number | string
  height?: number | string
  isAnimationActive?: boolean
}

const PriceOverTime = ({
  data,
  width = '100%',
  height,
  isAnimationActive = false,
}: PriceOverTimeProps) => {
  const theme = useTheme() as RateGuideThemeInterface
  let legendPayload: Payload[] = [
    { value: 'Your Rate', type: 'circle', id: 'rate-guide', color: theme['color-primary'] },
  ]

  const formatYTick = (value: number) => {
    return `$${(value / 1000).toFixed(1)}K`
  }

  const formattedData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      maxOffset: item.min ? item.max - item.min : null,
    }))
  }, [data])

  const minValue = useMemo(() => {
    const value = Math.min(...data.map((item) => item.min)) - 0.1
    return Math.max(0, value)
  }, [data])

  const hasMarketRate = useMemo(() => {
    return data.some((item) => item.market)
  }, [data])

  if (hasMarketRate) {
    legendPayload = [
      {
        value: 'Market Rate',
        type: 'circle',
        id: 'market',
        color: theme['color-neutral-darker'],
      },
      ...legendPayload,
    ]
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <ComposedChart data={formattedData}>
        {RenderLinearGradient({ id: 'areaGradient' })}
        {RenderGrid()}
        {RenderXAxis({ dataKey: 'date' })}
        {RenderYAxis({ domain: [minValue, 'auto'], formatter: formatYTick })}
        {RenderTooltip({ content: <CustomTooltip /> })}
        {RenderLegend({
          payload: legendPayload,
        })}
        {RenderRangedBar({ fromDataKey: 'min', toDataKey: 'maxOffset', isAnimationActive })}
        {RenderArea({
          dataKey: 'rate-guide',
          isAnimationActive,
          fill: 'url(#areaGradient)',
        })}
        {RenderLine({ dataKey: 'market', isAnimationActive })}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default PriceOverTime
