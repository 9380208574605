import React from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import TextField from 'components/TextField'
import forgotPasswordIcon from './forgot-password-icon.png'

interface ForgotPasswordFormProps {
  sendForgetPasswordRequest: () => void
  email: string
  setEmail: (email: string) => void
}

const ForgotPasswordForm = ({
  sendForgetPasswordRequest,
  email,
  setEmail,
}: ForgotPasswordFormProps) => {
  return (
    <>
      <div className="flex justify-center mb-6">
        <img src={forgotPasswordIcon} alt="" className="w-20" />
      </div>
      <h1 className="mb-2 text-2xl font-bold text-neutral-darker text-center">
        Forgot your password?
      </h1>
      <p className="px-8 text-sm text-center">
        No worries, we'll send instructions to reset your password.
      </p>
      <form
        className="flex flex-col px-8 mt-8"
        onSubmit={(event) => {
          event.preventDefault()
          sendForgetPasswordRequest()
        }}
      >
        <Field>
          <Label htmlFor="email">E-mail</Label>
          <TextField
            name="email"
            id="email"
            className="h-8"
            placeholder="E-mail"
            type="email"
            required
            value={email}
            autoComplete="off"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            autoFocus
          />
        </Field>
        <Button type="submit" variant="primary" className="mt-12">
          Reset Password
        </Button>
      </form>
    </>
  )
}

export default ForgotPasswordForm
