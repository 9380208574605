import mapping, { IconName } from './mapping'

function Icon({
  size = 16,
  role = 'img',
  name,
  width,
  height,
  ...others
}: {
  name: IconName
  size?: number
  width?: number
  height?: number
  role?: string
  title?: string | null
  className?: string
}) {
  const MappedIcon = mapping[name]

  if (!MappedIcon) {
    return <span className="inline-flex items-center justify-center w-8 h-8">?</span>
  }

  return <MappedIcon role={role} width={width || size} height={height || size} {...others} />
}

export default Icon
