import { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { PackageType, PACKAGE_TYPE_LABELS } from 'common/types/Commodity'
import { HAZMAT_CLASSES } from 'pages/Quotes/components/Commodities/Commodity.constants'

export const PACKAGE_TYPES: GenericOption[] = Object.keys(PACKAGE_TYPE_LABELS).map(
  (packageType) => ({
    value: packageType,
    label: PACKAGE_TYPE_LABELS[packageType as PackageType],
  }),
)

export const FREIGHT_CLASS_REFERENCE: Record<string, GenericOption> = {
  CLASS_50: { value: '50', label: 'Class 50' },
  CLASS_55: { value: '55', label: 'Class 55' },
  CLASS_60: { value: '60', label: 'Class 60' },
  CLASS_65: { value: '65', label: 'Class 65' },
  CLASS_70: { value: '70', label: 'Class 70' },
  CLASS_77: { value: '77.5', label: 'Class 77.5' },
  CLASS_85: { value: '85', label: 'Class 85' },
  CLASS_92: { value: '92.5', label: 'Class 92.5' },
  CLASS_100: { value: '100', label: 'Class 100' },
  CLASS_110: { value: '110', label: 'Class 110' },
  CLASS_125: { value: '125', label: 'Class 125' },
  CLASS_150: { value: '150', label: 'Class 150' },
  CLASS_175: { value: '175', label: 'Class 175' },
  CLASS_200: { value: '200', label: 'Class 200' },
  CLASS_250: { value: '250', label: 'Class 250' },
  CLASS_300: { value: '300', label: 'Class 300' },
  CLASS_400: { value: '400', label: 'Class 400' },
  CLASS_500: { value: '500', label: 'Class 500' },
}

export const LTL_FREIGHT_CLASSES = Object.keys(FREIGHT_CLASS_REFERENCE).map(
  (key) => FREIGHT_CLASS_REFERENCE[key],
)

export const ACCESSORIALS: GenericOption[] = [
  { value: 'hazmat', label: 'Hazmat' },
  { value: 'stackable', label: 'Stackable' },
]

export const HAZMAT_CLASS: GenericOption[] = HAZMAT_CLASSES

export const EXCESSIVE_LENGTH_CLASS: GenericOption[] = [
  { value: 'excessive_length_8ft', label: 'Excessive Length 8ft' },
  { value: 'excessive_length_12ft', label: 'Excessive Length 12ft' },
  { value: 'excessive_length_16ft', label: 'Excessive Length 16ft' },
  { value: 'excessive_length_20ft', label: 'Excessive Length 20ft' },
]
