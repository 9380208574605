import React from 'react'

import { CustomFormikField } from 'packages/formik-miranda/src/CustomFormikField'
import { BaseField } from 'packages/formik-miranda/src/BaseField'
import { LabelProps } from '@loadsmart/loadsmart-ui'

import { DateRangePicker, DateRangePickerProps } from 'components/DatePicker'
import EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import omit from 'common/helpers/omit'
import moment from 'moment/moment'

export interface DateRangePickerFormikProps
  extends Omit<
    DateRangePickerProps,
    'name' | 'value' | 'form' | 'startDate' | 'endDate' | 'onChange'
  > {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
}

const DateRangePickerFormik = ({ ...props }: DateRangePickerFormikProps) => {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: fieldOnChange, onBlur: fieldOnBlur, value: fieldValue, ...field },
        form,
        baseFieldProps,
        baseProps,
      }) => {
        const { setFieldTouched, setFieldValue } = form
        const properties = omit(props, 'label', 'labelProps')

        const mappedProps = {
          ...baseProps,
          ...(fieldValue && fieldValue.start && { startDate: moment(fieldValue.start) }),
          ...(fieldValue && fieldValue.end && { endDate: moment(fieldValue.end) }),
          onChange: ({
            target: {
              value: { startDate, endDate },
            },
          }: EventLike<{ startDate: string; endDate: string }>) => {
            if (!startDate && !endDate) {
              setFieldValue(field.name, null)
            } else {
              setFieldValue(field.name, { start: startDate, end: endDate })
            }

            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...properties,
        }

        return (
          <BaseField {...baseFieldProps}>
            <DateRangePicker {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

export default DateRangePickerFormik
