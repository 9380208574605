import { useRef, useCallback, useMemo, useEffect } from 'react'

export interface PollingConfig {
  minInterval: number
  maxInterval: number
  growthFactor: number
  startImmediately: boolean
  onFail?: (error: any, retriesCount: number) => boolean
  onSuccess?: (response: any) => boolean
}

type ActionGeneric<T> = (...args: any) => Promise<[any, T | undefined]>

const DEFAULT_CONFIG: PollingConfig = {
  minInterval: 1000,
  maxInterval: Infinity,
  growthFactor: 1.6,
  startImmediately: false,
}

function usePolling<T>(action: ActionGeneric<T>, config?: Partial<PollingConfig>) {
  const safeConfig = useMemo(() => ({ ...DEFAULT_CONFIG, ...config }), [config])
  const nextInterval = useRef<number>(safeConfig.startImmediately ? 0 : safeConfig.minInterval)
  const pollRef = useRef<number>()
  const isFirstExecution = useRef<boolean>(true)
  const isExecuting = useRef<boolean>(false)
  const retriesCount = useRef<number>(0)

  const updateNextInterval = useCallback(
    function updateNextInterval() {
      const { growthFactor, maxInterval, minInterval } = safeConfig
      const safeGrowthFactor = Math.max(growthFactor, 1)

      if (isFirstExecution.current && safeConfig.startImmediately) {
        nextInterval.current = Math.min(minInterval, maxInterval)
      } else {
        nextInterval.current = Math.min(nextInterval.current * safeGrowthFactor, maxInterval)
      }

      isFirstExecution.current = false
    },
    [safeConfig],
  )

  const resetNextInterval = useCallback(
    function resetNextInterval() {
      nextInterval.current = safeConfig.minInterval
    },
    [safeConfig.minInterval],
  )

  const cancelPolling = useCallback(
    function cancelPolling() {
      isExecuting.current = false

      window.clearTimeout(pollRef.current)
      resetNextInterval()
    },
    [resetNextInterval],
  )

  function resetRetriesCount() {
    retriesCount.current = 0
  }

  const executePolling = useCallback(
    function executePolling() {
      isExecuting.current = true

      async function exec() {
        const [error, response] = await action()

        if (error) {
          const shouldRetry = safeConfig.onFail?.(error, retriesCount.current)

          if (shouldRetry) {
            retriesCount.current += 1
            executePolling()
          } else {
            cancelPolling()
            resetRetriesCount()
            resetNextInterval()
          }
        } else {
          const shouldContinue = safeConfig.onSuccess?.(response)
          resetRetriesCount()

          if (shouldContinue) {
            executePolling()
          } else {
            cancelPolling()
            resetNextInterval()
          }
        }
      }

      window.clearTimeout(pollRef.current)
      pollRef.current = window.setTimeout(exec, nextInterval.current)
      updateNextInterval()
    },
    [updateNextInterval, action, safeConfig, cancelPolling, resetNextInterval],
  )

  const startPolling = useCallback(
    function start() {
      if (!isExecuting.current) {
        executePolling()
      }
    },
    [executePolling],
  )

  useEffect(() => {
    return function onUnmount() {
      cancelPolling()
    }
  }, [cancelPolling])

  return { startPolling, cancelPolling }
}

export default usePolling
