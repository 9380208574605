import isProduction from 'common/helpers/isProduction'

export function GTMNoScript() {
  if (!isProduction()) {
    return null
  }

  return (
    <noscript>
      <iframe
        title="gtm"
        src="https://www.googletagmanager.com/ns.html?id=GTM-PKQPXZG"
        height="0"
        width="0"
        className="invisible hidden"
      />
    </noscript>
  )
}
