import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { post, del, patch } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'
import { UserEntity } from 'common/types/UserEntity'

interface PaginationOptions {
  limit: number
  offset: number
}

export interface UsersPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: UserEntity[]
}

export async function listUsers(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<UsersPayload> | undefined]> {
  try {
    return await to(post('/api/users/list', data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function createUser(
  data?: any,
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<UserEntity> | undefined]> {
  try {
    return await to(post('/api/users', data, config))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function deleteUser(
  userId: string,
): Promise<[AxiosError | null, AxiosResponse<UsersPayload> | undefined]> {
  try {
    return await to(del(`/api/users/${userId}`))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}

export async function editUser(
  data?: Partial<UserEntity>,
): Promise<[AxiosError | null, AxiosResponse<UsersPayload> | undefined]> {
  try {
    return await to(patch(`/api/users/${data?.id}`, data))
  } catch (error) {
    console.error(error)
    return [error, undefined]
  }
}
