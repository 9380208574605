import * as React from 'react'
import { TextField as MTextField, TextFieldProps as MTextFieldProps } from '@loadsmart/loadsmart-ui'

import Icon from 'components/Icon'
import { CustomFormikField, CustomFormikFieldProps } from './CustomFormikField'
import { BaseField } from './BaseField'

export interface TextFieldProps
  extends Omit<MTextFieldProps, 'name' | 'value' | 'form' | 'className'>,
    Omit<CustomFormikFieldProps, 'children' > {}

export function TextField({ children, ...props }: TextFieldProps) {
  return (
    <CustomFormikField {...props}>
      {({ field, baseFieldProps, baseProps }) => {
        const mappedProps = {
          status:
            props.status ??
            ((baseFieldProps.error ? 'danger' : undefined) as MTextFieldProps['status']),
          trailing: props.trailing ?? (baseFieldProps.error ? <Icon name="warning" /> : undefined),
          ...field,
          ...baseProps,
        }

        return (
          <BaseField {...baseFieldProps}>
            <MTextField {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}
