import React from 'react'
import { Helmet } from 'react-helmet'

import getEnvVariable from '../../../../common/helpers/getEnvVariable'

declare global {
  interface Window {
    google: any
  }
}

function GoogleMapsAPI() {
  if (window.google) {
    return null
  }

  return (
    <Helmet>
      <script
        defer
        async
        charSet="utf-8"
        src={`//maps.googleapis.com/maps/api/js?key=${getEnvVariable(
          'GOOGLE_MAPS_KEY',
        )}&libraries=places&language=en`}
      />
    </Helmet>
  )
}

export default GoogleMapsAPI
