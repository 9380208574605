import { Area } from './index'
import React from 'react'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

export interface RenderAreaProps {
  dataKey: string
  isAnimationActive?: boolean
  fill?: string,
  color?: keyof RateGuideThemeInterface
}

const RenderArea = ({
  dataKey,
  isAnimationActive = false,
  fill = 'transparent',
  color = 'color-primary'
}: RenderAreaProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  return (
    <Area
      type="linear"
      dataKey={dataKey}
      fill={fill}
      stroke={String(theme[color])}
      dot={{ stroke: String(theme[color]), strokeWidth: 6, fill: String(theme[color]) }}
      activeDot={{
        stroke: String(theme[color]),
        strokeWidth: 2,
        fill: theme['color-neutral-white'],
      }}
      isAnimationActive={isAnimationActive}
    />
  )
}

export default RenderArea
