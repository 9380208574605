import { Dialog } from 'components/Dialog'
import * as yup from 'yup'
import { ChangeEvent, useState } from 'react'
import ErrorHelper, { ErrorHelperType } from 'common/Error.helpers'
import Field from 'components/Field'
import Label from 'components/Label'
import TextField from 'components/TextField'
import EventLike from 'common/types/EventLike'
import Feedback from 'components/Feedback'
import Status from 'common/types/Status'
import { setInterest } from '../../services/whiteLabel.service'
import { toast } from 'components/Toast'

const schema = yup.object().shape({
  email: yup.string().email().required(),
  phone_number: yup.string().required(),
  name: yup.string().required(),
  company_name: yup.string().required(),
})

export interface IContactForm {
  email: string
  phone_number: string
  company_name: string
  name: string
}

export interface ContactFormModalProps {
  open: boolean
  onSubmit: (email: string) => void
  onCancel: () => void
  quoteId: string
}

function ContactFormModal({ open, onSubmit, onCancel, quoteId }: ContactFormModalProps) {
  const [errors, setErrors] = useState<ErrorHelperType>()
  const [form, setForm] = useState<IContactForm>({} as IContactForm)
  const [isSendingContactInformation, setIsSendingContactInformation] = useState(false)

  const handleSubmit = async () => {
    setErrors(undefined)
    try {
      schema.validateSync(form, {
        abortEarly: false,
      })
    } catch (e) {
      setErrors(ErrorHelper(e))
      return
    }

    setIsSendingContactInformation(true)
    const [errors] = await setInterest(quoteId, form)
    setIsSendingContactInformation(false)

    if (errors) {
      toast.error('We could not save your contact information.')

      return
    }

    onSubmit(form.email)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement> | EventLike<any>) => {
    const { name, value } = event.target

    setForm((form) => {
      return { ...form, [name as keyof IContactForm]: value }
    })
  }

  return (
    <Dialog open={open} scale="small">
      <Dialog.Header>Informing my interest</Dialog.Header>
      <Dialog.Body>
        <>
          <span>
            We are very happy with your interest, just fill in the details below so that we can
            contact with you.
          </span>
          <Field className="mt-6">
            <Label required htmlFor="email">
              E-mail for contact
            </Label>
            <TextField
              type="email"
              id="email"
              name="email"
              required
              onChange={handleChange}
              placeholder="Inform the e-mail"
            />
            {errors?.has('email') && (
              <Feedback status={Status.Danger}>{errors.get('email')}</Feedback>
            )}
          </Field>
          <Field className="mt-6">
            <Label required htmlFor="phone_number">
              Phone number
            </Label>
            <TextField
              type="text"
              id="phone_number"
              name="phone_number"
              required
              onChange={handleChange}
              placeholder="Inform the number"
            />
            {errors?.has('phone_number') && (
              <Feedback status={Status.Danger}>{errors.get('phone_number')}</Feedback>
            )}
          </Field>
          <Field className="mt-6">
            <Label required htmlFor="company_name">
              Company name
            </Label>
            <TextField
              type="text"
              id="company_name"
              name="company_name"
              required
              onChange={handleChange}
              placeholder="Inform the company"
            />
            {errors?.has('company_name') && (
              <Feedback status={Status.Danger}>{errors.get('company_name')}</Feedback>
            )}
          </Field>
          <Field className="mt-6">
            <Label required htmlFor="name">
              Name for contact
            </Label>
            <TextField
              type="text"
              id="name"
              name="name"
              required
              onChange={handleChange}
              placeholder="Inform the name"
            />
            {errors?.has('name') && (
              <Feedback status={Status.Danger}>{errors.get('name')}</Feedback>
            )}
          </Field>
        </>
      </Dialog.Body>
      <Dialog.ActionConfirm disabled={isSendingContactInformation} onConfirm={handleSubmit}>
        confirm sending
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel
        disabled={isSendingContactInformation}
        onCancel={() => {
          setForm({} as IContactForm)
          onCancel()
        }}
      />
    </Dialog>
  )
}

export default ContactFormModal
