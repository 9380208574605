import React, { LabelHTMLAttributes, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { isNil } from '@loadsmart/utils-object'

import Tooltip from '../Tooltip'
import ColorScheme from '../../common/types/ColorScheme'
import Text from 'components/Text'

type AcceptedType = 'label' | 'div' | 'span'
export interface LabelProps
  extends LabelHTMLAttributes<HTMLLabelElement | HTMLDivElement | HTMLSpanElement> {
  as?: AcceptedType
  required?: boolean
  className?: string
  scheme?: ColorScheme
  tip?: string
}

function Label({
  as,
  className,
  children,
  required,
  scheme,
  tip,
  ...others
}: PropsWithChildren<LabelProps>): JSX.Element {
  const Tag = String(as || 'label') as AcceptedType

  return (
    <Tag
      data-testid="label"
      {...others}
      className={clsx('inline-flex items-center justify-start tracking-normal', className)}
    >
      <Text
        className={clsx('inline-block text-sm font-normal truncate', {
          'text-neutral-darker': scheme === 'light',
          'text-neutral-lightest': scheme === 'dark',
        })}
      >
        {children}
      </Text>
      {required && <span className="inline-block text-xs text-danger">*</span>}
      {!isNil(tip) && (
        <Tooltip message={tip || ''}>
          <span
            className={clsx(
              'ml-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold rounded-full ',
              {
                'bg-neutral text-neutral-lightest': scheme === 'light',
                'bg-neutral-darker text-neutral-light': scheme === 'dark',
              },
            )}
          >
            ?
          </span>
        </Tooltip>
      )}
    </Tag>
  )
}

Label.defaultProps = {
  scheme: 'light',
}

export default Label
