import { deleteUser } from 'services/usersManagement/usersManagement.service'
import { toast } from 'components/Toast'
import { Dialog } from 'components/Dialog'
import Icon from 'components/Icon'
import Layout from 'components/Layout'
import Text from 'components/Text'
import { UserEntity } from 'common/types/UserEntity'

type UserFormProps = {
  user: UserEntity
  onClose: (refetch?: boolean) => void
}

export default function UserDelete({ user, onClose }: UserFormProps) {
  async function handleSubmit() {
    const [error] = await deleteUser(user.id)

    if (error) {
      toast.error('We could not delete this user.')
    } else {
      toast.success('User deleted with success.')
      onClose(true)
    }
  }

  return (
    <>
      <Dialog.Header>Delete user?</Dialog.Header>

      <Dialog.Body>
        <Layout.Stack space="s">
          <Layout.Group justify="center">
            <Icon name="warning" size={38} />
          </Layout.Group>

          <Text>Are you sure you want to delete this user? The action cannot be undone.</Text>
        </Layout.Stack>
      </Dialog.Body>

      <Dialog.ActionConfirm onConfirm={handleSubmit}>Yes, delete user</Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={() => onClose(false)} />
    </>
  )
}
