import Layout from 'components/Layout'
import { Dialog } from 'components/Dialog'
import Icon from 'components/Icon'
import Text from 'components/Text'

export interface ConfirmRateChangeModalProps {
  onClose: (confirm: boolean) => void
  open: boolean
}

function ConfirmRateChangeModal({ open, onClose }: ConfirmRateChangeModalProps) {
  return (
    <Dialog scale="small" open={open}>
      <Dialog.Header>Change selected rate</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack>
          <Icon name="warning" size={38} className="mx-auto" />
          <Text variant="body">
            Are you sure you want to select another rate for this lane? By changing the rate, the
            adjustments won't be copied, so you need to add new adjustments.
          </Text>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionConfirm onConfirm={() => onClose(true)}>
          confirm changing
        </Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={() => onClose(false)} />
      </Dialog.Actions>
    </Dialog>
  )
}

export default ConfirmRateChangeModal
