import clsx from 'clsx'

import { getType } from './Stop.helpers'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import { Stop } from 'common/types/Stop'
import DateHelper from 'common/Date.helpers'
import LocationHelper from 'common/Location.helpers'
import NumberHelper from 'common/Number.helpers'
import styled from 'styled-components'

import { conditional, whenProps } from '@loadsmart/loadsmart-ui/dist/tools'

interface TimelineEventProps {
  variant: 'horizontal' | 'vertical'
}

const TimelineEvent = styled.div<TimelineEventProps>`
  flex: 1;
  display: flex;
  position: relative;

  flex-direction: ${conditional({
    column: whenProps({ variant: 'vertical' }),
    row: whenProps({ variant: 'horizontal' }),
  })};

  &:last-of-type {
    justify-content: ${conditional({
      'flex-end': whenProps({ variant: 'vertical' }),
      'flex-start': whenProps({ variant: 'horizontal' }),
    })};
  }

  &::before {
    position: absolute;
    content: '';
    display: block;

    height: 16px;
    width: 16px;
    border-radius: ${token('border-radius-circle')};
    top: -1px;

    z-index: 1;
    background-color: ${token('color-primary')};
  }
  &:last-of-type::before {
    bottom: ${conditional({
      '0px': whenProps({ variant: 'vertical' }),
      auto: whenProps({ variant: 'horizontal' }),
    })};
    top: ${conditional({
      auto: whenProps({ variant: 'vertical' }),
      '-1px': whenProps({ variant: 'horizontal' }),
    })};
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    z-index: 0;
    background-color: ${token('color-neutral-light')};
    top: 0;
    height: ${conditional({
      '100%': whenProps({ variant: 'vertical' }),
      '14px': whenProps({ variant: 'horizontal' }),
    })};
    width: ${conditional({
      '100%': whenProps({ variant: 'horizontal' }),
      '14px': whenProps({ variant: 'vertical' }),
    })};
    left: ${conditional({
      '1px': whenProps({ variant: 'vertical' }),
      '8px': whenProps({ variant: 'horizontal' }),
    })};
    right: ${conditional({
      auto: whenProps({ variant: 'vertical' }),
      '8px': whenProps({ variant: 'horizontal' }),
    })};

    border-radius: ${conditional({
      '8px': whenProps({ variant: 'horizontal' }),
      '8px 8px 0 0': whenProps({ variant: 'vertical' }),
    })};
  }
  &:last-of-type::after {
    border-radius: ${conditional({
      '8px': whenProps({ variant: 'horizontal' }),
      '0 0 8px 8px': whenProps({ variant: 'vertical' }),
    })};
    visibility: ${conditional({
      visible: whenProps({ variant: 'vertical' }),
      hidden: whenProps({ variant: 'horizontal' }),
    })};
  }
`

export interface StopsTimelineProps {
  className?: string
  variant?: 'horizontal' | 'vertical'
  mode?: 'default' | 'condensed'
  stops?: Stop[]
  mileage?: string | number
}

function renderLocationByMode(stop: Stop, mode?: 'default' | 'condensed') {
  if (mode === 'condensed') {
    return (
      <span className="text-sm font-bold md:text-xl">
        {LocationHelper(stop).format(`${LocationHelper.CITY}, ${LocationHelper.STATE}`)} <br />
        {LocationHelper(stop).format(`${LocationHelper.ZIPCODE}, ${LocationHelper.COUNTRY}`)}
      </span>
    )
  }

  return <span className="text-sm font-bold md:text-xl">{LocationHelper(stop).format()}</span>
}
function StopsTimeline({
  className,
  stops,
  mileage,
  mode,
  variant = 'horizontal',
}: StopsTimelineProps) {
  return (
    <div
      className={clsx(
        'flex relative mx-0 my-auto',
        variant === 'vertical' ? 'flex-col h-full' : 'flex-row',
        className,
      )}
    >
      {(stops || []).map(function renderStop(stop, index, stops) {
        const date = DateHelper(stop.window?.start) // TODO: keep reference

        return (
          <TimelineEvent key={stop.id || stop.zipcode || stop.address} variant={variant}>
            <div
              className={clsx('flex flex-col relative', {
                'pt-6': variant === 'horizontal',
                'pl-6': variant === 'vertical',
              })}
            >
              <span className="inline-flex text-xs uppercase md:text-sm">
                {getType(stop, index)}
              </span>
              {renderLocationByMode(stop, mode)}
              <span className="text-xs md:text-sm">{date?.format('ddd, MM/DD/YY')}</span>
            </div>
          </TimelineEvent>
        )
      })}
      {mileage && (
        <span className="absolute top-0 right-0 rg-stop-timeline-distance">
          <b className="font-bold">{NumberHelper(mileage).format()}</b> miles
        </span>
      )}
    </div>
  )
}

StopsTimeline.defaultProps = {
  mode: 'default',
  variant: 'horizontal',
}

export default StopsTimeline
