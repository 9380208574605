import { Dialog } from 'components/Dialog'
import Text from 'components/Text'
import { Layout } from '@loadsmart/loadsmart-ui'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import Button from 'components/Button'

import { TextField } from 'packages/formik-miranda/src'

export interface ProviderMarginModalProps {
  onSubmit: (margin: number | string) => void
  onCancel: () => void
  open: boolean
  initialMargin?: number | string
}

const schema = yup.object().shape({
  margin: yup
    .number()
    .notOneOf([0], 'Margin cannot be zero')
    .min(-100, 'Margin cannot be less than -100%')
    .required('Margin is required'),
})

type FormValues = {
  margin: number | string
}

function ProviderMarginModal({
  open,
  initialMargin,
  onSubmit,
  onCancel,
}: ProviderMarginModalProps) {
  return (
    <Dialog open={open} scale="small">
      <Dialog.Header>Add margin</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack space="l">
          <Formik<FormValues>
            initialValues={{ margin: initialMargin || 0 }}
            validationSchema={schema}
            onSubmit={(form) => onSubmit(form.margin)}
          >
            {({ submitForm, isSubmitting, isValid, dirty }) => (
              <Form>
                <Layout.Stack space="l">
                  <Text variant="body">
                    By adding a margin you can make sure that you will be profiting on the requested
                    quotes.
                  </Text>
                  <TextField
                    label="Profit Margin (%)"
                    id="margin"
                    name="margin"
                    placeholder="Inform the margin"
                    type="text"
                    required
                    autoComplete="off"
                    labelProps={{
                      tip:
                        'To avoid offering nonreliable quotes, use this margin to define how much do you want to increase quoted lanes on EasyQuote.',
                    }}
                  />
                  <Layout.Stack space="s">
                    <Button
                      variant="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                      onClick={submitForm}
                      title="Confirm margin value"
                    >
                      add margin
                    </Button>
                    <Button variant="secondary" onClick={() => onCancel()}>
                      Cancel
                    </Button>
                  </Layout.Stack>
                </Layout.Stack>
              </Form>
            )}
          </Formik>
        </Layout.Stack>
      </Dialog.Body>
    </Dialog>
  )
}

export default ProviderMarginModal
