import { Quoteline } from '../../../../common/types/Quote'
import partition from '../../../../common/helpers/partition'

export function sumQuotelines(quotelines: Quoteline[]) {
  return (quotelines || []).reduce((acc: number, quoteline: Quoteline) => {
    return acc + Number(quoteline.quantity) * Number(quoteline.unit_price)
  }, 0)
}

/**
 * Group by type margin, type/subtype: extras/manual_charge, others
 * @param quotelines
 */
export function partitionByType(quotelines: Quoteline[]) {
  return partition(quotelines, (item) => {
    if (item.type === 'margin') {
      return 'margins'
    } else if (item.type === 'fsc' && item.subtype === 'stop') {
      return 'fsc'
    } else if (item.type === 'extras' && item.subtype === 'manual_charge') {
      return 'extras'
    }

    return 'others'
  })
}
