import styled from 'styled-components'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import { Text } from '@loadsmart/loadsmart-ui'

const StyledErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem 0.5rem;
  background-color: ${token('color-danger-light')};
`

export interface StyledErrorMessageProps {
  error: string | any[]
}

const StyledErrorMessage = ({ error }: StyledErrorMessageProps) => {
  const renderErrorsList = (errors: string[]) => {
    return (
      <ul>
        {errors.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    )
  }

  return (
    <StyledErrorMessageWrapper role="alert">
      <Text color="color-danger-dark" variant="caption">
        {Array.isArray(error) ? renderErrorsList(error) : error}
      </Text>
    </StyledErrorMessageWrapper>
  )
}

export default StyledErrorMessage
