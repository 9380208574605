import { Tooltip } from './index'
import React, { ReactElement } from 'react'

export interface RenderTooltipProps {
  content: ReactElement
}

const RenderTooltip= ({
  content
}: RenderTooltipProps) => {

  return (
    <Tooltip content={content} />
  )
}

export default RenderTooltip
