import { IconButton, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import Icon from '../Icon'

export type VoteStatus = 'up' | 'down' | 'none'

export interface VoteProps {
  status: VoteStatus
  onVote: (status: VoteStatus) => void
  upMessage: string
  downMessage: string
  scale?: 'large' | 'default' | 'small'
  tooltipPosition?: TooltipPosition
  editable?: boolean
}

const statusColors = {
  up: {
    normal: '#48C881',
    light: '#C0ECD3',
  },
  down: {
    normal: '#E80045',
    light: '#FFD6E2',
  },
  neutral: {
    normal: '#687887',
    light: '#E9EBEC',
  },
}

const getColor = (variant: 'light' | 'normal') => ({
  active,
  direction,
}: {
  active: boolean
  direction: 'up' | 'down'
}) => (active ? statusColors[direction][variant] : statusColors.neutral[variant])

const lightColor = getColor('light')
const normalColor = getColor('normal')

const StyledVoteIconButton = styled(IconButton)<{ active: boolean; direction: 'up' | 'down' }>`
  margin: 0 4px;
  background: ${lightColor};
  color: ${normalColor};
  &:not(.is-disabled):not(:disabled):hover {
    background: ${lightColor};
    color: ${normalColor};
  }
  &:focus,
  :focus-within {
    border-color: ${lightColor};
    background: ${lightColor};
    color: ${normalColor};
    box-shadow: 0 0 4px 0 ${({ direction }) => statusColors[direction].light};
  }
  &:enabled:not(.is-disabled):active {
    border-color: ${lightColor};
    background: ${lightColor};
    color: ${normalColor};
  }
`

function Vote({
  status = 'none',
  onVote,
  upMessage,
  downMessage,
  scale = 'default',
  editable = true,
  tooltipPosition = TooltipPosition.Left
}: VoteProps) {
  return (
    <>
      <Tooltip message={upMessage} position={tooltipPosition}>
        <StyledVoteIconButton
          disabled={status === 'down' && editable}
          scale={scale}
          aria-label="Vote Up"
          onClick={() => onVote('up')}
          active={status === 'up'}
          direction="up"
        >
          <Icon name="like" title="thumbs up" />
        </StyledVoteIconButton>
      </Tooltip>
      <Tooltip message={downMessage} position={tooltipPosition}>
        <StyledVoteIconButton
          disabled={status === 'up' && editable}
          scale={scale}
          aria-label="Vote Down"
          onClick={() => onVote('down')}
          active={status === 'down'}
          direction="down"
        >
          <Icon name="dislike" title="thumbs down" />
        </StyledVoteIconButton>
      </Tooltip>
    </>
  )
}

export default Vote
