import { useState, useMemo, ChangeEvent } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import { createUser, editUser } from 'services/usersManagement/usersManagement.service'
import ErrorHelper from 'common/Error.helpers'
import getValueFromEvent from 'common/helpers/getValueFromEvent'
import { genericTo as to } from 'common/helpers/awaitTo'
import { toast } from 'components/Toast'
import { Dialog } from 'components/Dialog'
import Layout from 'components/Layout'
import Field from 'components/Field'
import Label from 'components/Label'
import TextField from 'components/TextField'
import Checkbox from 'components/Checkbox'
import Text from 'components/Text'

import type { UserEntity } from 'common/types/UserEntity'

const ScrollableDialogBody = styled(Dialog.Body)`
  max-height: calc(100vh - 250px);
  overflow: auto;
`

const schema = yup.object().shape({
  first_name: yup.string().required('Missing the first name'),
  last_name: yup.string().required('Missing the last name'),
  username: yup.string().required('Missing the username'),
  email: yup.string().email('Provide a valid e-mail').required('Missing the e-mail'),
})

type UserFormProps = {
  user?: UserEntity
  onClose: (refetch?: boolean) => void
}

export default function UserForm({ user, onClose }: UserFormProps) {
  const [values, setValues] = useState<Partial<UserEntity>>({ ...user })
  const [errors, setErrors] = useState({})

  const errorData = useMemo(() => ErrorHelper(errors), [errors])

  const isEditing = Boolean(user)

  function handleChange(evt: ChangeEvent<HTMLInputElement>) {
    const {
      target: { name },
    } = evt
    const value = getValueFromEvent(evt)
    setValues((prev) => ({ ...prev, [name]: value }))
  }

  async function handleSubmit() {
    setErrors({})

    const [invalid, valid] = await to(
      schema.validate(values, {
        abortEarly: false,
      }),
    )

    if (valid) {
      await save()
    } else {
      setErrors(invalid)
    }
  }

  async function save() {
    const action = isEditing ? editUser : createUser
    const [error] = await action(values)

    if (error) {
      toast.error('We could not save this user.')
    } else {
      const message = isEditing ? 'User updated with sucess.' : 'User created with sucess.'
      toast.success(message)
      onClose(true)
    }
  }

  return (
    <>
      <Dialog.Header>{isEditing ? 'Edit user' : 'New user'}</Dialog.Header>

      <ScrollableDialogBody>
        <Layout.Stack space="l">
          {!isEditing ? (
            <Text>
              After creating the new user, a welcome e-mail will be sent for him/her to guide how to
              set the password.
            </Text>
          ) : null}

          <Field error={errorData.get('first_name')}>
            <Label htmlFor="mgt_dialog.first_name" required>
              First name
            </Label>
            <TextField
              id="mgt_dialog.first_name"
              name="first_name"
              placeholder="Inform the first name"
              type="text"
              required
              value={values.first_name}
              autoComplete="off"
              onChange={handleChange}
            />
          </Field>

          <Field error={errorData.get('last_name')}>
            <Label htmlFor="mgt_dialog.last_name" required>
              Last name
            </Label>
            <TextField
              id="mgt_dialog.last_name"
              name="last_name"
              placeholder="Inform the last name"
              type="text"
              required
              value={values.last_name}
              autoComplete="off"
              onChange={handleChange}
            />
          </Field>

          <Field error={errorData.get('username')}>
            <Label htmlFor="mgt_dialog.username" required>
              Username
            </Label>
            <TextField
              id="mgt_dialog.username"
              name="username"
              placeholder="Inform the username"
              type="text"
              required
              value={values.username}
              autoComplete="off"
              onChange={handleChange}
            />
          </Field>

          <Field error={errorData.get('email')}>
            <Label htmlFor="mgt_dialog.email" required>
              E-mail
            </Label>
            <TextField
              id="mgt_dialog.email"
              name="email"
              placeholder="Inform the e-mail"
              type="text"
              required
              value={values.email}
              autoComplete="off"
              onChange={handleChange}
            />
          </Field>

          <Field>
            <Checkbox onChange={handleChange} name="is_admin" checked={values.is_admin}>
              Set as administrator
            </Checkbox>
          </Field>
        </Layout.Stack>
      </ScrollableDialogBody>

      <Dialog.ActionConfirm onConfirm={handleSubmit}>
        {isEditing ? 'Save changes' : 'Create user'}
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={() => onClose(false)} />
    </>
  )
}
