import { Commodity } from './types/Commodity'
import { Quote as QuoteType } from './types/Quote'
import get from './helpers/get'
import toArray from 'common/helpers/toArray'
import { QuoteRate } from './types/QuoteRate'
import QuoteRateHelper from './QuoteRate.helper'

function hasHazmat(quote: QuoteType) {
  const items = get(quote, 'items') || []

  return items.some((item: Commodity) => item.hazmat)
}

function hasDropTrailer(quote: QuoteType) {
  const stops = get(quote, 'stops') || []

  return stops.some((stop: any) => stop.drop_trailer)
}

function hasTMSUrl(shipper: QuoteType['shipper']) {
  const tmsUrl = get(shipper, 'tms_url', false)
  return Boolean(tmsUrl)
}

function hasUIIntegration(shipper: QuoteType['shipper']) {
  const connectedToLIN = get(shipper, 'has_quote_response_integration', false)
  return Boolean(connectedToLIN)
}

function Quote(quote: QuoteType) {
  return {
    get selectedRate() {
      return quote.rates?.find((rate: QuoteRate) => rate.id === quote.selected_rate?.id)
    },
    getSelectedRateHelper() {
      const selectedRate = quote.rates?.find(
        (rate: QuoteRate) => rate.id === quote.selected_rate?.id,
      )
      return QuoteRateHelper(selectedRate)
    },
    hasMargins() {
      return (
        quote.breakdown?.filter((quoteline) => !['haul', 'fsc', 'rate'].includes(quoteline.type))
          .length > 0
      )
    },
    status: {
      is(status: QuoteType['status'] | QuoteType['status'][]) {
        return toArray(status).includes(quote.status)
      },
    },
    shipper: {
      has(parameter: 'tms_url' | 'ui_integration') {
        switch (parameter) {
          case 'tms_url':
            return hasTMSUrl(quote.shipper)
          case 'ui_integration':
            return hasUIIntegration(quote.shipper)
          default:
            return false
        }
      },
    },
    get delivery() {
      return quote.stops.find((stop, index) => index === quote.stops.length - 1)
    },
    get pickup() {
      return quote.stops.find((stop, index) => index === 0)
    },
    /**
     * Summarizes some nested or complex quote characteristics.
     * @param parameter
     */
    has(parameter: 'drop_trailer' | 'hazmat') {
      switch (parameter) {
        case 'drop_trailer':
          return hasDropTrailer(quote)
        case 'hazmat':
          return hasHazmat(quote)
        default:
          return false
      }
    },
    get<TKey extends keyof QuoteType>(key: TKey): QuoteType[TKey] {
      return quote[key]
    },
    mode: {
      is(status: QuoteType['mode']) {
        return quote.mode === status
      },
    },
  }
}

export default Quote
