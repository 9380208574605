import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { Router } from '../routing'
import store from './store'
import ToastHost from 'components/Toast'
import { rateGuideTheme } from 'theming'

import './App.css'
import analytics from 'common/analytics'
import applicationManager from 'common/ApplicationManager'
import WhiteLabel from '../pages/WhiteLabel/WhiteLabel'
import * as Sentry from '@sentry/react'

function App() {
  analytics.init()

  if (applicationManager.isWhiteLabelApplication()) {
    return <WhiteLabel />
  }

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={rateGuideTheme}>
            <ToastHost />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

export default App
