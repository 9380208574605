import React, { ChangeEvent, CSSProperties, SyntheticEvent, useEffect, useState } from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import Text from 'components/Text'
import Field from 'components/Field'
import Label from 'components/Label'
import TextField from 'components/TextField'
import Feedback from 'components/Feedback'
import Status from 'common/types/Status'
import ErrorHelper, { CombinedError, ErrorHelperType } from 'common/Error.helpers'
import EventLike from 'common/types/EventLike'
import Button from 'components/Button'
import getValueFromEvent from 'common/helpers/getValueFromEvent'
import TextArea from 'components/TextArea'
import { toast } from 'components/Toast'
import Loading from 'components/Loading'
import setIn from 'common/helpers/setIn'
import get from 'common/helpers/get'
import getSnippetCode from './Snippet.helper'
import shadeColor from 'common/helpers/shadeColor'
import Select from 'components/Select'
import RateProviderSelector from './RateProviderSelector'
import Preview from './Preview'
import Layout from 'components/Layout'
import Divider from 'components/Divider'
import { WhiteLabelConfiguration } from 'common/types/WhiteLabelConfiguration'
import { BannerActionLarge } from '@loadsmart/loadsmart-ui'
import { RATE_GUIDE, RateProviderIdentifiers, RateProviders } from 'common/types/RateProvider'
import ProviderMarginModal from './ProviderMarginModal'
import { useDialog } from 'components/Dialog'
import ProviderChangeMessage from './ProviderChangeMessageModal'
import ToggleGroup from 'components/ToggleGroup'
import Icon from 'components/Icon'

const SHIPMENT_TYPES = [
  {
    leading: <Icon name="truck-dryvan" />,
    label: 'FTL',
    value: 'FTL',
  },
  {
    leading: <Icon name="truck-ltl-dryvan" />,
    label: 'LTL',
    value: 'LTL',
  },
]

const initialConfiguration: WhiteLabelConfiguration = {
  active: true,
  app_id: '',
  domain: '',
  customization: {
    success_text: '',
  },
  theme: {
    primary_color: '',
    secondary_color: '',
    font_family: '',
  },
  user: {
    email: '',
  },
  margin: 0,
  margins: {} as Record<RateProviderIdentifiers, number>,
  default_rate_provider: RATE_GUIDE,
  allowed_modes: ['FTL'],
}

const schema = yup.object().shape({
  domain: yup
    .string()
    .required()
    .matches(
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'Invalid Url',
    ),
  email: yup.string().email(),
  default_rate_provider: yup.string().required('You need to specify a Rate Provider'),
  margin: yup
    .number()
    .notOneOf([0], 'Margin cannot be zero')
    .min(-100, 'Margin cannot be less than -100%')
    .required('Margin required for this rate provider'),
  theme: yup
    .object()
    .shape({
      primary_color: yup
        .string()
        .nullable()
        .notRequired()
        .matches(/(^$)|(^#(?:[0-9a-fA-F]{3}){1,2}$)/, 'Invalid hexadecimal color'),
      secondary_color: yup
        .string()
        .nullable()
        .matches(/(^$)|(^#(?:[0-9a-fA-F]{3}){1,2}$)/, 'Invalid hexadecimal color'),
    })
    .nullable(),
})

interface GeneralConfigurationProps {
  savedConfiguration?: WhiteLabelConfiguration
  onCreateConfiguration: (config: WhiteLabelConfiguration) => void
  onUpdateConfiguration: (config: WhiteLabelConfiguration) => void
  onAdvancedBannerClick: () => void
  showAdvancedTabBanner: boolean
  rateProviders: RateProviderIdentifiers[]
  isBusy: boolean
  hasRules: boolean
}

function GeneralConfiguration({
  savedConfiguration,
  onCreateConfiguration,
  onUpdateConfiguration,
  onAdvancedBannerClick,
  showAdvancedTabBanner,
  rateProviders,
  isBusy,
  hasRules = false,
}: GeneralConfigurationProps) {
  const [errors, setErrors] = useState<ErrorHelperType>()
  const [code, setCode] = useState<string>('')
  const [configForm, setConfigForm] = useState(initialConfiguration)
  const [mode, setMode] = useState<'create' | 'edit'>('create')
  const [editProviderMargin, setEditProviderMargin] = useState<RateProviderIdentifiers>(
    rateProviders[0],
  )
  const providerMarginModal = useDialog({
    open: false,
  })
  const providerChangeWarning = useDialog({ open: false })

  useEffect(() => {
    if (savedConfiguration?.app_id) {
      setCode(getSnippetCode(savedConfiguration.app_id))
      setConfigForm(savedConfiguration)
      setMode('edit')
    }
  }, [savedConfiguration])

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    setErrors(undefined)
    try {
      schema.validateSync(configForm, {
        abortEarly: false,
      })
    } catch (e) {
      setErrors(ErrorHelper(e as CombinedError))
      return
    }

    const isSwitchingProviders =
      mode === 'edit' &&
      configForm.default_rate_provider !== savedConfiguration?.default_rate_provider

    if (isSwitchingProviders && hasRules) {
      providerChangeWarning.show()
    } else if (mode === 'edit') {
      onUpdateConfiguration(configForm)
    } else {
      onCreateConfiguration(configForm)
    }
  }

  const onConfirmProviderChange = () => {
    onUpdateConfiguration(configForm)
    providerChangeWarning.hide()
  }

  const handlePrimaryColorChange = (event: ChangeEvent<HTMLInputElement> | EventLike<any>) => {
    const value = getValueFromEvent(event)
    handleChange({ target: { name: 'theme.secondary_color', value: shadeColor(value, -20) } })
    handleChange(event)
  }

  useEffect(() => {
    setConfigForm((config) => {
      return setIn(
        'margin',
        config,
        configForm.margins && configForm.default_rate_provider
          ? configForm.margins[configForm.default_rate_provider]
          : undefined,
      )
    })
  }, [configForm.default_rate_provider, configForm.margins])

  const handleChange = (event: ChangeEvent<HTMLInputElement> | EventLike<any>) => {
    const { name } = event.target
    const value = getValueFromEvent(event)

    setConfigForm((config) => {
      return setIn(String(name), config, value)
    })
  }

  const handleMarginEdit = (rateProvider: RateProviderIdentifiers) => {
    setEditProviderMargin(rateProvider)
    providerMarginModal.show()
  }

  const handleSubmitMargin = (margin: number | string) => {
    setConfigForm({
      ...configForm,
      margins: {
        ...configForm.margins,
        [editProviderMargin]: margin as number,
      } as Record<RateProviderIdentifiers, number>,
    })
    providerMarginModal.hide()
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code)
      toast.success('Code copied!')
    } catch (e) {
      toast.error("Couldn't copy code")
    }
  }

  if (isBusy) {
    return <Loading className="justify-center" />
  }
  return (
    <Layout.Stack>
      {providerMarginModal.open && (
        <ProviderMarginModal
          onSubmit={handleSubmitMargin}
          onCancel={() => providerMarginModal.hide()}
          open={providerMarginModal.open}
          initialMargin={
            editProviderMargin && configForm.margins ? configForm.margins[editProviderMargin] : 0
          }
        />
      )}
      {providerChangeWarning.open && savedConfiguration && (
        <ProviderChangeMessage
          open={providerChangeWarning.open}
          secondaryProviderName={RateProviders[savedConfiguration.default_rate_provider].name}
          onConfirm={onConfirmProviderChange}
          onCancel={() => providerChangeWarning.hide()}
        />
      )}
      <form onSubmit={handleSubmit}>
        <Layout.Switcher space="m" limit={2} className="mb-8">
          <div>
            <Field className="flex-col w-full">
              <Label
                required
                htmlFor="domain"
                tip="Inform your website URL to configure it inside the page."
              >
                URL of your website
              </Label>
              <TextField
                type="text"
                id="domain"
                name="domain"
                value={configForm.domain}
                required
                onChange={handleChange}
                placeholder="Type your URL, Ex: website-gold.com"
              />
              {errors?.has('domain') && (
                <Feedback status={Status.Danger}>{errors.get('domain')}</Feedback>
              )}
            </Field>
            <Field className="flex-col mt-8 mb-8">
              <Label
                required
                htmlFor="email"
                tip="When a shipper is interested in a quote, an e-mail will be sent. so define the receiver."
              >
                E-mail to receive notifications
              </Label>
              <TextField
                type="text"
                id="email"
                name="user.email"
                value={get(configForm, 'user.email')}
                required
                onChange={handleChange}
                placeholder="broker@gmail.com"
              />
              {errors?.has('user.email') && (
                <Feedback status={Status.Danger}>{errors.get('user.email')}</Feedback>
              )}
            </Field>
            <Text variant="heading-sm-bold">Website theme and branding</Text>
            <Divider className="my-2" />
            <div className="flex space-x-4 my-6">
              <Field className="flex-col flex-1 w-full">
                <Label
                  htmlFor="primary_color"
                  tip="This color will be used to all the buttons and primary elements."
                >
                  Primary color
                </Label>
                <div className="flex flex-row">
                  <TextField
                    className="flex-1"
                    type="text"
                    id="primary_color"
                    name="theme.primary_color"
                    value={get(configForm, 'theme.primary_color')}
                    onChange={handlePrimaryColorChange}
                    placeholder="Ex: #42A7DF"
                  />
                  <input
                    className="rounded border bg-neutral-lightest h-full ml-1"
                    type="color"
                    id="primary_color"
                    name="theme.primary_color"
                    value={get(configForm, 'theme.primary_color')}
                    onChange={handlePrimaryColorChange}
                  />
                </div>
                {errors?.has('theme.primary_color') && (
                  <Feedback status={Status.Danger}>{errors.get('theme.primary_color')}</Feedback>
                )}
              </Field>
              <Field className="flex-col flex-1">
                <Label
                  htmlFor="font_family"
                  tip="Define the typography used in your website to keep consistent."
                >
                  Font family
                </Label>
                <Select
                  name="theme.font_family"
                  id="font_family"
                  onChange={handleChange}
                  placeholder="Select the font to be used"
                  value={get(configForm, 'theme.font_family')}
                  options={[
                    { label: 'Serif', value: 'serif' },
                    { label: 'Sans Serif', value: 'sans-serif' },
                    { label: 'Monospace', value: 'monospace' },
                    { label: 'Cursive', value: 'cursive' },
                    { label: 'Fantasy', value: 'fantasy' },
                  ]}
                  clearable
                  customStylesCallbacks={{
                    options: ({ data }): CSSProperties => ({
                      fontFamily: data.value,
                    }),
                  }}
                />
                {errors?.has('theme.font_family') && (
                  <Feedback status={Status.Danger}>{errors.get('theme.font_family')}</Feedback>
                )}
              </Field>
            </div>
            <div className="flex space-x-4 mt-6">
              <Field className="flex-col flex-1">
                <Label
                  htmlFor="success_text"
                  tip="This message will be displayed after the shipper quote and in the emails."
                >
                  Thank you message to the interested shippers
                </Label>
                <TextArea
                  id="success_text"
                  name="customization.success_text"
                  rows={3}
                  value={get(configForm, 'customization.success_text', '')}
                  onChange={handleChange}
                  placeholder="Your quote interest was sent to the broker, and soon you will be contacted by him to proceed with the next steps. Take a look at the quote details below, a copy was sent to your e-mail. "
                />
                {errors?.has('customization.success_text') && (
                  <Feedback status={Status.Danger}>
                    {errors.get('customization.success_text')}
                  </Feedback>
                )}
              </Field>
            </div>
          </div>
          <div className="p-6 bg-neutral-lighter">
            <Preview
              primaryColor={get(configForm, 'theme.primary_color')}
              fontFamily={get(configForm, 'theme.font_family')}
            />
          </div>
        </Layout.Switcher>
        <Text variant="heading-sm-bold">Rate provider and profit margin</Text>
        <Divider className="my-2" />
        <div className="mt-4">
          <Text variant="body">
            Now it's time to define who will handle the requested quotes for default, choose between
            Loadsmart your Broker rate provider, and you can also add smart rules to use the other
            rate provider in situations that you don't want the default one to handle.
          </Text>
          <div className="mt-6">
            <Label
              htmlFor="allowed_modes"
              tip="You can offer both FTL and LTL modes
            to your customers, Loadsmart will 
            handle all the LTL all for you."
            >
              Select the shipment modes you want to offer
            </Label>
            <ToggleGroup
              multiple
              id="allowed_modes"
              name="allowed_modes"
              className="w-max"
              value={configForm.allowed_modes}
              onChange={handleChange}
              options={SHIPMENT_TYPES}
              scale="default"
            />
          </div>
          {rateProviders.length > 0 && (
            <div className="flex space-x-4 my-6">
              <Field className="flex-col flex-1">
                <Label
                  htmlFor="default_rate_provider"
                  tip="The rate provider is the source of the price for your quotes and how the quote will be moved."
                >
                  Choose the rate provider
                </Label>
                <RateProviderSelector
                  value={get(configForm, 'default_rate_provider')}
                  onChange={handleChange}
                  rateProviders={rateProviders}
                  onMarginEdit={handleMarginEdit}
                  margins={configForm.margins}
                  defaultMarginError={errors?.get('margin')}
                  name="default_rate_provider"
                />
                {errors?.has('default_rate_provider') && (
                  <Feedback status={Status.Danger}>{errors.get('default_rate_provider')}</Feedback>
                )}
              </Field>
            </div>
          )}

          {showAdvancedTabBanner && (
            <BannerActionLarge
              variant="neutral"
              scale="large"
              className="mb-6"
              title="Now you can use Smart rules to have more than only one rate provider"
              description="By using smart rules you can decide which requested quote you will handle and which one the Loadsmart will handle. To start using it is simple, just open the advanced options tab  and add smart rules to the ones that you prefer the other rate provider to handle. You can add as many rules as you need."
              action="Open the advanced options"
              onActionButtonClick={onAdvancedBannerClick}
              dismissible
            />
          )}

          <div
            className={clsx('flex', {
              'justify-end': mode === 'create',
              'justify-between': mode === 'edit',
            })}
          >
            {mode === 'edit' && (
              <Button type="button" variant="secondary" onClick={copyToClipboard}>
                copy to clipboard
              </Button>
            )}
            <Button type="submit" variant="primary">
              {mode === 'create' ? 'Generate code' : 'Save Changes'}
            </Button>
          </div>
          {mode === 'edit' && (
            <div className="mt-4">
              <Text variant="caption">
                This is code that should be pasted into your website, at the page where you'd like
                EasyQuote to show up.
              </Text>
              <div className="bg-neutral-lighter font-mono rounded mt-1 p-6">{code}</div>
            </div>
          )}
        </div>
      </form>
    </Layout.Stack>
  )
}

export default GeneralConfiguration
