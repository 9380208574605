import qs from 'querystring'
import { identity } from '@loadsmart/utils-function'

import get from 'common/helpers/get'
import invert from 'common/helpers/invert'
import isEmpty from 'common/helpers/isEmpty'
import toArray from 'common/helpers/toArray'
import set from 'common/helpers/set'

export interface QueryParamsParser {
  parse: (fields: any) => any
  onChange?: (fields: any) => any
}

export type ParsedQueryParams = qs.ParsedUrlQuery
type QueryParamToPropertyHandler = (param: string, value: string | string[] | null) => unknown
type PropertyToQueryParamHandler = (value: unknown) => unknown | unknown[] | null | undefined

type QueryParamType = 'primitive' | 'primitive-collection' | 'object' | 'object-collection'

interface QueryParamsPluginConfig {
  type: QueryParamType
  /**
   * Map query param name to property.
   */
  mapping?: Record<string, string>
  fromQueryParam?: QueryParamToPropertyHandler
  toQueryParam?: PropertyToQueryParamHandler
}

/**
 * Generate a plugin hook to be used with `useFilter` with the given
 * @param config {Object}
 * @returns
 */
export function generateUseQueryParamsPlugin<T>(
  config: Partial<Record<keyof T, QueryParamsPluginConfig>>,
) {
  function getFromQueryParamHandler(key: string): QueryParamToPropertyHandler {
    return get(config, [key, 'fromQueryParam'], (_: any, value: unknown) => value)
  }

  function getToQueryParamHandler(key: string): PropertyToQueryParamHandler {
    return get(config, [key, 'toQueryParam'], identity)
  }

  function getManagedFields() {
    return Object.keys(config)
  }

  function getType(field: string) {
    return get(config, [field, 'type'], '')
  }

  function isCollection(type: QueryParamType) {
    return ['primitive-collection', 'object-collection'].includes(type)
  }

  function getExpectedQueryParams(field: string) {
    return get(config, [field, 'mapping'], { [field]: field })
  }

  function getExpectedProperties(field: string) {
    return invert(get(config, [field, 'mapping'], { [field]: field }))
  }

  return function useQueryParamsPlugin() {
    return {
      _name: 'useQueryParamsPlugin',
      onInit: (fields: Record<keyof T, unknown>) => {
        let params = new URLSearchParams(window.location.search)

        function getValue(field: string, queryParam: string) {
          const type = getType(field)
          const rawValue = (function getRawValue() {
            if (isCollection(type)) {
              return params.getAll(queryParam)
            }

            return params.get(queryParam)
          })()

          const handler = getFromQueryParamHandler(field)

          if (isCollection(type)) {
            return toArray(rawValue).map((v) => handler(queryParam, v))
          }

          return handler(queryParam, rawValue)
        }

        let result: Record<keyof T, unknown> = { ...fields }

        getManagedFields().forEach((field) => {
          const expectedQueryParamToProperty = getExpectedQueryParams(field)
          const type = getType(field)

          for (let expectedQueryParam in expectedQueryParamToProperty) {
            const property = expectedQueryParamToProperty[expectedQueryParam]
            const value = getValue(field, expectedQueryParam)

            switch (type) {
              case 'primitive':
              case 'primitive-collection':
                set(result, [property], value)
                break
              case 'object':
                set(result, [field, property], value)
                break
              case 'object-collection':
                toArray(value).forEach((v, index) => {
                  set(result, [field, index, property], v)
                })
                break
            }
          }
        })

        return result
      },
      onChange: (fieldArg: keyof T, valueArg: unknown | unknown[]) => {
        const url = new URL(window.location.pathname, window.location.origin)
        const params = new URLSearchParams(window.location.search)
        const field = String(fieldArg)
        const value = getToQueryParamHandler(field)(valueArg)

        const expectedPropertyToQueryParam = getExpectedProperties(field)
        const type = getType(field)

        for (let expectedProperty in expectedPropertyToQueryParam) {
          const queryParam = expectedPropertyToQueryParam[expectedProperty]
          params.delete(queryParam)

          if (value == null || isEmpty(value)) {
            continue
          }

          switch (type) {
            case 'primitive':
              params.set(queryParam, String(value))
              break
            case 'primitive-collection':
              toArray(value).forEach((v) => {
                params.append(queryParam, String(v))
              })
              break
            case 'object':
              params.set(
                queryParam,
                String((value as Record<string, unknown>)[expectedProperty] || ''),
              )
              break
            case 'object-collection':
              toArray(value).forEach((v) => {
                params.append(
                  queryParam,
                  String((v as Record<string, unknown>)[expectedProperty] || ''),
                )
              })
              break
          }
        }

        url.search = `?${params.toString()}`
        window.history.pushState({}, '', String(url))
      },
    }
  }
}
