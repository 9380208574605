import { Carrier } from './Carrier'
import { QuoteStatusExpired, QuoteStatusOpen, QuoteStatusRejected } from './Quote'
import { RateFeedback } from './RateFeedback'

export type QuoteRateStatus = QuoteStatusOpen | QuoteStatusRejected | QuoteStatusExpired

export type RateProvider = 'loadsmart' | 'rate_guide' | 'market'

export interface SelectedQuoteRate {
  id: string
}

export interface QuoteRate {
  id: string
  price: string
  feedback: RateFeedback
  reject_reason: string | null
  status: QuoteRateStatus
  expires_at: string
  rate_provider: RateProvider
  external_id: string | null
  carrier: Carrier | null
  service_level: string | null
  lane_type: LaneType | null
  est_delivery_date: string | null
}

export type LaneType = typeof DIRECT | typeof INTERLINE | typeof UNSPECIFIED

export const DIRECT = 'DIRECT'
export const INTERLINE = 'INTERLINE'
export const UNSPECIFIED = 'UNSPECIFIED'

export const LaneTypes: Record<LaneType, { name: string; description: string }> = {
  DIRECT: {
    name: 'Direct',
    description:
      'Direct shipments are handled directly by the carrier, are usually faster, and have better service',
  },
  INTERLINE: {
    name: 'Interline',
    description:
      'Jorney is handled by direct carrier and additional partner carrier. Longer transit times.',
  },
  UNSPECIFIED: { name: 'Unspecified', description: '' },
}
