import { AnchorHTMLAttributes } from 'react'
import clsx from 'clsx'

import useSafeLink from './useSafeLink'

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  disabled?: boolean
}

export function BaseLink({
  href = '/',
  children,
  className,
  disabled = false,
  target = '_self',
  ...others
}: LinkProps) {
  return (
    <a
      className={clsx(
        'inline-block overflow-ellipsis overflow-hidden text-center  transition-colors focus:outline-none',
        {
          'disabled:cursor-not-allowed pointer-events-none opacity-40': disabled,
          'focus:ring focus:ring-opacity-40 focus:ring-primary-400': !disabled,
        },
        className,
      )}
      href={href}
      target={target}
      {...others}
      data-testid="link"
    >
      {children}
    </a>
  )
}

function Link({ className, disabled, ...others }: LinkProps) {
  return (
    <BaseLink
      className={clsx(
        'text-primary-accent text-sm font-normal uppercase',
        {
          'pointer-events-none opacity-40': disabled,
          'focus:ring-primary-400': !disabled,
        },
        className,
      )}
      disabled={disabled}
      {...others}
    />
  )
}

export function SafeLink(props: LinkProps) {
  const safeProps = useSafeLink(props)

  return <Link {...safeProps} />
}

export default Link
