import type { TimeWindow } from './Time'
import type { Location } from './Location'
import type { Accessorial } from './Accessorial'

export interface Stop extends Location {
  id: string
  type: StopType
  time?: string
  window?: TimeWindow
  drop_trailer?: boolean
  intermediary?: boolean
  accessorials?: Array<Accessorial['code']>
}

export const PICKUP_TYPE = 'pu'
export const DELIVERY_TYPE = 'del'

export type StopType = typeof PICKUP_TYPE | typeof DELIVERY_TYPE

export const StopLabels = {
  pu: 'Pickup',
  del: 'Delivery',
}
