import { QuotesFilterFields } from '../pages/Quotes/QuotesList/QuotesList.types'
import moment, { unitOfTime } from 'moment'

function QuoteFiltersHelper(filters: Partial<QuotesFilterFields>) {
  const { created_at } = filters

  return {
    getCreatedAtDifference(unitOfTime: unitOfTime.Diff) {
      if (!created_at) {
        return 0
      }

      const createdAtStart = moment(created_at.start)
      const createdAtEnd = moment(created_at.end)

      return createdAtEnd.diff(createdAtStart, unitOfTime)
    },
  }
}

export default QuoteFiltersHelper
