import * as React from 'react'
import {
  Checkbox as MCheckbox,
  CheckboxProps as MCheckboxProps,
  Label,
  LabelProps,
} from '@loadsmart/loadsmart-ui'

import StyledErrorMessage from './StyledErrorMessage'
import { CustomFormikField } from './CustomFormikField'
import EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

export interface CheckboxProps extends Omit<MCheckboxProps, 'name' | 'value' | 'form'> {
  label: string
  name: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
}

export function Checkbox({ children, ...props }: CheckboxProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { value: fieldValue, onChange: fieldOnChange, ...field },
        error,
        baseProps,
        form,
      }) => {
        const { setFieldTouched, setFieldValue } = form
        const mappedProps = {
          ...baseProps,
          checked: fieldValue,
          onChange: ({ target: { checked } }: EventLike<string>) => {
            setFieldValue(field.name, checked)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...props,
        }

        return (
          <>
            <MCheckbox {...mappedProps}>
              <Label htmlFor={props.id} required={props.required} {...props.labelProps}>
                {props.label}
              </Label>
            </MCheckbox>
            {error && <StyledErrorMessage error={error} />}
          </>
        )
      }}
    </CustomFormikField>
  )
}
