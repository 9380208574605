import { Quote } from 'common/types/Quote'
import Layout from 'components/Layout'
import Text from 'components/Text'
import EquipmentType from 'pages/Quotes/QuoteForm/EquipmentType'
import { Tag } from 'components/Tag'
import QuoteHelper from 'common/Quote.helpers'
import { StopsTimeline } from 'pages/Quotes/QuoteForm/Stops'

export interface QuoteSummaryProps {
  quote: Quote
  variant?: 'full' | 'reduced'
  showShipper?: boolean
}

const getCheckedInfo = (quote: Quote, variant: 'full' | 'reduced') => {
  const quoteHelper = QuoteHelper(quote)
  if (variant === 'full') {
    return (
      <Layout.Switcher>
        <Layout.Stack space="xs">
          <Text variant="body">Drop at the delivery?</Text>
          <Tag className="self-start" variant="default">
            {quoteHelper.has('drop_trailer') ? 'YES' : 'NO'}
          </Tag>
        </Layout.Stack>
        <Layout.Stack space="xs">
          <Text variant="body">Hazard material?</Text>
          <Tag className="self-start" variant="default">
            {quoteHelper.has('hazmat') ? 'YES' : 'NO'}
          </Tag>
        </Layout.Stack>
      </Layout.Switcher>
    )
  } else {
    return (
      <>
        <Layout.Switcher>
          <Text variant="body">Drop at the delivery?</Text>
          <Tag className="self-start" variant="default">
            {quoteHelper.has('drop_trailer') ? 'YES' : 'NO'}
          </Tag>
        </Layout.Switcher>
        <Layout.Switcher>
          <Text variant="body">Hazard material?</Text>
          <Tag className="self-start" variant="default">
            {quoteHelper.has('hazmat') ? 'YES' : 'NO'}
          </Tag>
        </Layout.Switcher>
      </>
    )
  }
}

function QuoteSummary({ quote, showShipper, variant = 'full' }: QuoteSummaryProps) {
  return (
    <div className={'flex flex-row rounded-sm shadow border-neutral-light '}>
      <div className="p-6 bg-neutral-lightest space-y-4 w-1/3">
        <Layout.Stack space="l">
          {showShipper && variant === 'full' && (
            <Layout.Stack space="xs">
              <Text variant="body">Shipper</Text>
              <b className="text-base font-bold">{quote.shipper?.name}</b>
            </Layout.Stack>
          )}

          <Layout.Switcher space="l">
            <Layout.Stack space="xs">
              <Text variant="body">Mode</Text>
              <Text variant={variant === 'full' ? 'heading-sm-bold' : 'heading-md-bold'}>
                {quote.mode}
              </Text>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Text variant="body">Equipment type</Text>
              <EquipmentType
                name={quote.equipment_type}
                mode={variant === 'full' ? 'full' : 'label-only'}
                className={variant === 'full' ? 'text-base' : 'text-xl'}
              />
            </Layout.Stack>
            {variant === 'full' && (
              <Layout.Stack space="xs">
                <Text variant="body">Customer reference</Text>
                <Text variant="heading-sm-bold">{quote.customer_reference || '-'}</Text>
              </Layout.Stack>
            )}
          </Layout.Switcher>
          {getCheckedInfo(quote, variant)}
        </Layout.Stack>
      </div>
      <div className="flex flex-col px-8 py-6 bg-neutral-lighter w-2/3">
        <StopsTimeline mileage={quote.mileage} stops={quote.stops} />
      </div>
    </div>
  )
}

export default QuoteSummary
