import { QuotesFilterFields } from '../../QuotesList/QuotesList.types'
import { useEffect, useState } from 'react'
import { toast } from 'components/Toast'
import useCancelToken from 'hooks/useCancelToken'
import isEmpty from 'common/helpers/isEmpty'
import Loading from 'components/Loading'
import { getQuotesSummary, GetQuotesSummaryPayload } from 'services/analytics/analytics.service'
import QuotesSummary from './QuotesSummary'

interface QuotesSummaryLoaderProps {
  filters: Partial<QuotesFilterFields>
  className?: string
}

const QuotesSummaryLoader = ({ filters, className }: QuotesSummaryLoaderProps) => {
  const [data, setData] = useState<GetQuotesSummaryPayload>({} as GetQuotesSummaryPayload)
  const [loading, setLoading] = useState(false)
  const { getSource, clearSource, cancelPending } = useCancelToken()

  useEffect(() => {
    async function fetch() {
      cancelPending()

      setLoading(true)

      const source = getSource()

      const [, response] = await getQuotesSummary(
        { filters },
        {
          cancelToken: source.token,
        },
      )

      clearSource()

      if (response) {
        setData(response.data)
      } else {
        toast.error('We could not retrieve the chart data.')
        setData({} as GetQuotesSummaryPayload)
      }

      setLoading(false)
    }

    if (!isEmpty(filters)) {
      fetch()
    }
  }, [cancelPending, clearSource, filters, getSource])

  if (loading) {
    return <Loading className="justify-center" />
  }

  return (
    <div className={className}>
      {filters && filters.created_at && !isEmpty(data) && (
        <QuotesSummary
          createdAtDates={filters.created_at}
          total={data.total}
          totalVariation={data.total_variation}
        />
      )}
    </div>
  )
}

export default QuotesSummaryLoader
