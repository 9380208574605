import React from 'react'

import Layout from 'components/Layout'
import Field from 'components/Field'
import Label from 'components/Label'
import ToggleGroup from 'components/ToggleGroup'
import Divider from 'components/Divider'
import Section from 'components/Section'
import { ShipmentModeType } from 'common/types/Quote'
import Tooltip from 'components/Tooltip'

interface ShipmentModeSelectorProps {
  shipmentMode: ShipmentModeType
  setShipmentMode: (mode: ShipmentModeType) => void
  fblEnabled?: boolean
}

function ShipmentModeSelector({
  shipmentMode,
  setShipmentMode,
  fblEnabled = true,
}: ShipmentModeSelectorProps) {
  const Wrapper = fblEnabled ? React.Fragment : Tooltip

  return (
    <Section title="Shipment mode">
      <p className="mb-8 text-sm">
        First you need to define what is the shipment mode you want to quote
      </p>
      <Wrapper message="You must enable Fulfillment By Loadsmart to access LTL mode">
        <Layout.Stack space="s">
          <Field>
            <Label htmlFor="shipment_mode">Shipment mode</Label>
            <ToggleGroup
              id="shipment_mode"
              name="shipment_mode"
              className="w-max"
              onChange={(e) => setShipmentMode(e.target.value as ShipmentModeType)}
              value={shipmentMode}
              disabled={!fblEnabled}
              options={[
                {
                  label: 'FTL',
                  value: 'ftl',
                },
                {
                  label: 'LTL',
                  value: 'ltl',
                },
              ]}
              scale="default"
            />
          </Field>
        </Layout.Stack>
      </Wrapper>
      <Divider className="mt-6" />
    </Section>
  )
}

export default ShipmentModeSelector
