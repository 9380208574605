import * as yup from 'yup'
import { Form, Formik } from 'formik'

import { TextField, Select } from 'packages/formik-miranda/src'
import { createShipper, editShipper } from 'services/shippers/shippers.service'
import { toast } from 'components/Toast'
import { Dialog } from 'components/Dialog'
import Button from 'components/Button'
import Icon from 'components/Icon'

import { MileageProvider, MILEAGE_PROVIDERS, ShipperEntity } from 'common/types/ShipperEntity'
import Layout from 'components/Layout'

const schema = yup.object().shape({
  name: yup.string().required('Missing the name'),
  fuel_rate: yup.number().required('Missing the rate'),
  mileage_provider: yup.string().required('Select a provider'),
})

type FormValues = {
  name?: string
  fuel_rate?: number
  mileage_provider?: MileageProvider
}

type ShipperFormProps = {
  shipper?: ShipperEntity
  onClose: (refetch?: boolean) => void
}

export default function ShipperForm({ shipper, onClose }: ShipperFormProps) {
  const isEditing = Boolean(shipper)

  async function handleSubmit(values: FormValues) {
    const action = isEditing ? editShipper : createShipper
    const [error] = await action(values)

    if (error) {
      toast.error('We could not save this shipper.')
    } else {
      const message = isEditing ? 'Shipper updated with sucess.' : 'Shipper created with sucess.'
      toast.success(message)
      onClose(true)
    }
  }

  const mileageProviderOptions = MILEAGE_PROVIDERS.map((provider) => ({
    label: provider,
    value: provider,
  }))

  return (
    <>
      <Dialog.Header>{isEditing ? 'Edit shipper' : 'New shipper'}</Dialog.Header>

      <Dialog.Body>
        <Layout.Stack space="l">
          <Formik<FormValues>
            initialValues={{ ...shipper }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting, isValid, dirty }) => (
              <Form>
                <Layout.Stack space="l">
                  <TextField
                    label="Shipper Name"
                    id="shipper_name"
                    name="name"
                    placeholder="Inform the name"
                    type="text"
                    required
                    autoComplete="off"
                  />
                  <TextField
                    label="Fuel rate"
                    id="fuel_rate"
                    name="fuel_rate"
                    placeholder="Inform the rate"
                    type="text"
                    required
                    autoComplete="off"
                    leading={<Icon name="dollar" size={16} />}
                  />
                  <Select
                    label="Mileage provider"
                    id="mileage_provider"
                    name="mileage_provider"
                    placeholder="Choose provider"
                    required
                    options={mileageProviderOptions}
                  />
                  <Layout.Stack space="s">
                    <Button
                      variant="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                      onClick={submitForm}
                    >
                      {isEditing ? 'Save Changes' : 'Create Shipper'}
                    </Button>
                    <Button variant="secondary" onClick={() => onClose(false)}>
                      Cancel
                    </Button>
                  </Layout.Stack>
                </Layout.Stack>
              </Form>
            )}
          </Formik>
        </Layout.Stack>
      </Dialog.Body>
    </>
  )
}
