import { Legend } from './index'
import React from 'react'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'
import { Payload } from 'recharts/types/component/DefaultLegendContent'


export interface RenderLegendProps {
  payload: Array<Payload>
}

const RenderLegend = ({
  payload
}: RenderLegendProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  return (
    <Legend
      layout="horizontal"
      verticalAlign="top"
      align="right"
      iconSize={12}
      wrapperStyle={{
        paddingBottom: '15px',
      }}
      formatter={(value) => (
        <span style={{ color: theme['color-neutral-dark'], fontSize: '12px' }}>{value}</span>
      )}
      payload={payload}
    />
  )
}

export default RenderLegend
