import clsx from 'clsx'

export interface HighlightedDataFooterProps {
  data: { values: string[] | number[]; identifier: string; color?: string }[]
  title: string
  className?: string
  titleWidth?: string
}

const HighlightedDataFooter = ({ title, data, className, titleWidth = '20%' }: HighlightedDataFooterProps) => {
  return (
    <div className={clsx('flex text-xs font-bold', className)}>
      <div className="flex flex-col justify-center items-center" style={{ width: titleWidth }}>
        {title}
      </div>
      <div className="flex flex-col w-full">
        <table className="w-full table-fixed">
          <tbody>
            {data.map((item) => (
              <tr
                key={item.identifier}
                style={{ color: item.color }}
                className="bg-neutral-lighter border-neutral-lightest border-b-4 border-t-4"
              >
                {item.values.map((value: string | number, index: number) => (
                  <td className="text-center" key={index}>
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HighlightedDataFooter
