import { combineReducers } from '@reduxjs/toolkit'
import authReducer from 'features/auth/auth.reducer'
import settingsReducer from 'features/settings/settings.reducer'
import outletsReducer from 'features/outlets/outlets.reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  outlets: outletsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
