import { useState, useCallback } from 'react'
import useCancelToken from '../../../hooks/useCancelToken'
import {
  addMargin,
  AddMarginRequest,
  AddMarginResponse,
} from '../../../services/margins/margins.service'
import { toast } from '../../../components/Toast'
import { MarginResult, MarginErrorDetail } from 'common/types/Margin'
import { isEmpty } from 'lodash'

export type MarginConflicts = {
  conflict: MarginResult
  details: MarginErrorDetail
}

interface MarginSuccess {
  createdMargins: AddMarginResponse['results']['success']
  hasCreatedMargins: boolean
}

function groupConflictDetails(
  conflicts: MarginResult[],
  details: MarginErrorDetail[],
): MarginConflicts[] {
  return conflicts.map((conflict, index) => ({
    conflict,
    details: details[index],
  }))
}

function useAddMargin() {
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const [isBusy, setBusy] = useState(false)
  const [conflicts, setConflicts] = useState<MarginConflicts[]>([])

  const save = useCallback(
    function save(margin: AddMarginRequest) {
      return new Promise<MarginSuccess>(async (resolve, reject) => {
        cancelPending()

        setBusy(true)

        const source = getSource()

        const [error, response] = await addMargin(margin, {
          cancelToken: source.token,
        })

        clearSource()

        if (error) {
          toast.error('We could not create this margin.')
          console.error('save', error)

          reject({})
        } else if (response) {
          const { success, errors, errors_details } = response.data.results

          if (isEmpty(errors_details)) {
            toast.success('Lane margin created.')
          } else {
            toast.error('Some margins were not created.')
          }

          setConflicts(groupConflictDetails(errors, errors_details))

          resolve({
            createdMargins: success,
            hasCreatedMargins: success.length > 0,
          })
        }

        setBusy(false)
      })
    },
    [cancelPending, clearSource, getSource],
  )

  const clearConflicts = useCallback(function clearConflicts() {
    setConflicts([])
  }, [])

  return {
    save,
    conflicts,
    clearConflicts,
    isBusy,
  }
}

export default useAddMargin
