import { ChangeEvent, useEffect, useState } from 'react'
import { Quoteline } from '../../../../common/types/Quote'
import Button from '../../../../components/Button'
import EventLike from '../../../../common/types/EventLike'
import Field from '../../../../components/Field'
import getValueFromEvent from '../../../../common/helpers/getValueFromEvent'
import Label from '../../../../components/Label'
import Modal, { ModalProps } from '../../../../components/Modal'
import TextArea from '../../../../components/TextArea'
import TextField, { TextFieldProps } from '../../../../components/TextField'
import Feedback from '../../../../components/Feedback'
import ErrorHelper from '../../../../common/Error.helpers'
import Status from '../../../../common/types/Status'
import { genericTo as to } from '../../../../common/helpers/awaitTo'
import { getButtonLabelByMode, getMode } from './PriceAdjustment.helpers'
import * as yup from 'yup'
import { Banner } from 'components/Banner'
import { isValidPartialPrice, isValidPrice } from '../../../../common/Form.helpers'

function getInitialState(): Quoteline {
  return {
    quoteline_id: null,
    added_by: null,
    type: 'extras',
    subtype: 'manual_charge',
    label: 'Price adjustment',
    description: '',
    unit_price: '',
    quantity: 1,
  }
}

export interface PriceAdjustmentModalProps extends ModalProps {
  className?: string
  id: string
  open: boolean
  adjustment?: Quoteline
  infoText?: string
  onClose: (adjustment?: Quoteline) => void
  allowNegative?: boolean
}

function PriceAdjustmentModal({
  onClose,
  adjustment: adjustmentProp,
  infoText,
  allowNegative = false,
  ...others
}: PriceAdjustmentModalProps) {
  const [adjustment, setAdjustment] = useState(() => {
    if (adjustmentProp) {
      return { ...adjustmentProp, unit_price: parseFloat(adjustmentProp.unit_price as string) }
    }

    return getInitialState()
  })

  const [mode, setMode] = useState<'add' | 'update'>(() => getMode(adjustmentProp))
  const [errors, setErrors] = useState({})
  const schema = yup.object().shape({
    unit_price: yup
      .string()
      .test(
        'maxDigits',
        'Must have 6 digits or less before the decimal point and 2 digits or less after decimal point',
        (number) => {
          if (!number) return false
          return isValidPrice(number, allowNegative)
        },
      )
      .required('Required field'),
    description: yup.string().required('Required field'),
  })

  useEffect(
    function updateAdjustment() {
      if (adjustmentProp) {
        setAdjustment({
          ...adjustmentProp,
          unit_price: parseFloat(adjustmentProp.unit_price as string),
        })
        return
      }

      setAdjustment(getInitialState())
    },
    [adjustmentProp],
  )

  useEffect(
    function updateMode() {
      setMode(getMode(adjustmentProp))
    },
    [adjustmentProp],
  )

  function handleChange<T>(e: ChangeEvent<HTMLInputElement> | EventLike<T>) {
    const {
      target: { name },
    } = e
    const value = getValueFromEvent(e)

    setAdjustment((adjustment) => ({
      ...adjustment,
      [String(name)]: value,
    }))
  }

  const handleUnitPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value === '' || isValidPartialPrice(value, allowNegative)) {
      setAdjustment((adjustment) => ({
        ...adjustment,
        unit_price: value,
      }))
    }
  }

  function handleClose(adjustment?: Quoteline) {
    onClose(adjustment)
    setErrors({})
    setAdjustment(getInitialState())
  }

  async function handleSubmit() {
    const [errors, valid] = await to(
      schema.validate(adjustment, {
        abortEarly: false,
      }),
    )
    if (valid) {
      const temp = adjustment
      setAdjustment(getInitialState())
      handleClose(temp)
    } else {
      setErrors(errors)
    }
  }

  const errorData = ErrorHelper(errors)

  return (
    <Modal data-testid={`price-adjustment-modal`} {...others}>
      <Modal.Header>New Price Adjustment</Modal.Header>
      <Modal.Body className="space-y-6">
        {Boolean(infoText) && <Banner className="mb-2" variant={Status.Neutral} title={infoText} />}
        <Field>
          <Label htmlFor="unit_price" required>
            Value
          </Label>
          <TextField
            id="unit_price"
            name="unit_price"
            data-testid={`adjustment-value`}
            onChange={handleUnitPriceChange}
            value={adjustment.unit_price}
            status={errorData.status('unit_price') as TextFieldProps['status']}
            required
          />
          {errorData.has('unit_price') && (
            <Feedback status={Status.Danger}>{errorData.get('unit_price')}</Feedback>
          )}
        </Field>
        <Field>
          <Label htmlFor="description" required>
            Reason to add this adjustment
          </Label>
          <TextArea
            name="description"
            id="description"
            data-testid={`adjustment-description`}
            onChange={handleChange}
            value={adjustment.description}
            maxLength={255}
            status={errorData.status('description')}
            required
          />
          {errorData.has('description') && (
            <Feedback status={Status.Danger}>{errorData.get('description')}</Feedback>
          )}
        </Field>
      </Modal.Body>
      <Modal.Footer className="flex flex-col space-y-2">
        <Button
          variant="primary"
          className="justify-center w-full"
          data-testid={`button-save-adjustment`}
          onClick={handleSubmit}
        >
          {getButtonLabelByMode(mode)}
        </Button>

        <Button
          className="justify-center w-full"
          onClick={() => {
            handleClose()
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PriceAdjustmentModal
