import type { ReactNode } from 'react'
import { ReactComponent as Calculator } from './calculator.svg'

interface CalculatingQuoteProps {
  children?: ReactNode
}

function CalculatingQuote({ children }: CalculatingQuoteProps) {
  return (
    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center p-6 bg-neutral-lightest">
      <Calculator />
      <h2 className="mt-6 mb-2 text-xl font-bold text-neutral-darker">Calculating the prices</h2>

      <p className="mb-8 text-sm text-center">
        Just a moment, we are using your information
        <br />
        to calculate the price for this quote.
      </p>

      {children}
    </div>
  )
}

export default CalculatingQuote
