import get from 'common/helpers/get'

type VariableValue = string | number

type VariableMap = {
  [key: string]: VariableValue
}

export function generateColorGetter(
  colors: VariableMap,
): (name: string, opacity?: number) => VariableValue {
  /**
   * Gets color from color token, applying an optional opacity.
   * @param {string} name - Color token.
   * @param {number} [opacity] - Opacity value.
   */
  return function color(name: string, opacity?: number): VariableValue {
    const rawColor = get(colors, `color-${name}`)

    if (opacity == null) {
      return rawColor
    }

    const opacityAsHex = (opacity * 255).toString(16)
    return `${rawColor}${opacityAsHex}`
  }
}

export function generateSpacingGetter(spacings: VariableMap): (name: string) => VariableValue {
  return function spacing(name: string): VariableValue {
    return get(spacings, `space-${name}`)
  }
}

export function generateShadowGetter(shadows: VariableMap): (name: string) => VariableValue {
  return function shadow(name: string): VariableValue {
    return get(shadows, `shadow-${name}`)
  }
}

export function generateFontGetters({
  families,
  sizes,
  weights,
  heights,
}: {
  families: VariableMap
  sizes: VariableMap
  weights: VariableMap
  heights: VariableMap
}): { [key: string]: (name: string) => VariableValue } {
  return {
    family(name) {
      return get(families, `font-family-${name}`)
    },
    size(name) {
      return get(sizes, `font-size-${name}`)
    },
    weight(name) {
      return get(weights, `font-weight-${name}`)
    },
    height(name) {
      return get(heights, `font-height-${name}`)
    },
  }
}

export function generateOpacityGetter(opacities: VariableMap): (name: string) => VariableValue {
  return function opacity(name: string): VariableValue {
    return get(opacities, `opacity-${name}`)
  }
}

export function generateBorderGetters({
  radiuses,
  widths,
}: {
  radiuses: VariableMap
  widths: VariableMap
}): Record<string, (name: string) => VariableValue> {
  return {
    radius(name) {
      return get(radiuses, `border-radius-${name}`)
    },
    width(name) {
      return get(widths, `border-width-${name}`)
    },
  }
}
