import Section from 'components/Section'
import ToggleGroup from 'components/ToggleGroup'
import Field from 'components/Field'
import Label from 'components/Label'
import useFilter from 'hooks/useFilter'
import TextField from 'components/TextField'
import Select, { SingleSelectValue } from 'components/Select'

import { ShippersFiltersFields } from './ShippersList.types'
import { MILEAGE_PROVIDERS } from 'common/types/ShipperEntity'
import EventLike from 'common/types/EventLike'

export interface ShippersFiltersProps {
  onChange: (fields: any) => void
}

function ShippersFilters({ onChange }: ShippersFiltersProps) {
  const { fields, setField } = useFilter<Partial<ShippersFiltersFields>>({
    fields: { name: [''], mileage_provider: [''], status: [] },
    onChange,
    options: {
      debounce: 500,
    },
  })

  const mileageProviderOptions = [
    {
      value: '',
      label: 'All the providers',
    },
    ...MILEAGE_PROVIDERS.map((provider) => ({
      label: provider,
      value: provider,
    })),
  ]

  return (
    <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
      <Section title="Filter shippers">
        <div className="flex flex-wrap mt-4">
          <Field className="flex-1 mr-7 w-36">
            <Label htmlFor="name">Name</Label>
            <TextField
              name="name"
              id="name"
              placeholder="Type a name"
              value={fields.name![0]}
              onChange={(e) => setField('name', [e.target.value])}
            />
          </Field>
          <Field className="flex-1 mr-7 w-36">
            <Label htmlFor="mileage_provider">Mileage Provider</Label>
            <Select
              name="mileage_provider"
              id="mileage_provider"
              placeholder="Choose a Mileage Provider"
              value={fields.mileage_provider![0]}
              options={mileageProviderOptions}
              onChange={(e: EventLike<SingleSelectValue>) => {
                setField('mileage_provider', [e.target.value])
              }}
            />
          </Field>
          <Field className="w-max mr-7">
            <Label htmlFor="status">Status</Label>
            <ToggleGroup
              id="status"
              name="status"
              className="w-max"
              value=""
              onChange={(e) => setField('status', [e.target.value])}
              options={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
              ]}
            />
          </Field>
        </div>
      </Section>
    </div>
  )
}

export default ShippersFilters
