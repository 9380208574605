import moment, { MomentSetObject, unitOfTime } from 'moment'
import { isNil } from '@loadsmart/utils-object'

function LSDate(dateArg?: string | null) {
  if (isNil(dateArg)) {
    return null
  }

  const date = moment(dateArg)

  return {
    /**
     * Get the provided value as `moment`.
     */
    value() {
      return date
    },
    format(format = 'MM/DD/YYYY') {
      if (format === 'UTC') {
        return moment.utc(date).format()
      }

      return date.format(format)
    },
    set(objectLiteral: MomentSetObject) {
      date.set(objectLiteral)

      return this
    },
    get(unit: unitOfTime.All) {
      return date.get(unit)
    },
    add(amount: moment.DurationInputArg1, unit: unitOfTime.DurationConstructor) {
      date.add(amount, unit)

      return this
    },
    subtract(amount: moment.DurationInputArg1, unit: unitOfTime.DurationConstructor) {
      date.subtract(amount, unit)

      return this
    },
    startOfDay() {
      date.utc().startOf('day')
      return this
    },
    endOfDay() {
      date.utc().endOf('day')
      return this
    },
    is: {
      today() {
        // It matches unit equal or higher: 'day', 'month' and 'year'
        return moment().isSame(date, 'day')
      },
      tomorrow() {
        const tomorrow = moment().add(1, 'day')
        return date.isSame(tomorrow, 'day')
      },
      yesterday() {
        const yesterday = moment().subtract(1, 'day')
        return date.isSame(yesterday, 'day')
      },
      same(dateToCompare: any, granularity?: unitOfTime.StartOf) {
        return date.isSame(dateToCompare.value(), granularity)
      },
      sameOrAfter(dateToCompare: any, granularity?: unitOfTime.StartOf) {
        return date.isSameOrAfter(dateToCompare.value(), granularity)
      },
      sameOrBefore(dateToCompare: any, granularity?: unitOfTime.StartOf) {
        return date.isSameOrBefore(dateToCompare.value(), granularity)
      },
      before(dateToCompare: any, granularity?: unitOfTime.StartOf) {
        return date.isBefore(dateToCompare.value(), granularity)
      },
    },
  }
}

/**
 * Returns all current time data
 */
LSDate.now = function now() {
  return LSDate(moment().toISOString())
}

/**
 * Returns today. While `LSDate.now` only retrieves the current time, today is customizable.
 * If no options are provided, it'll consider the current day, month and year, at 00:00:00 time
 */
LSDate.today = function today(opts?: moment.MomentInput) {
  const defaultOptions = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  }
  return LSDate(moment(opts ?? defaultOptions).toString())
}

export default LSDate
