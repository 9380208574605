import { QuoteRate as QuoteRateType, QuoteRateStatus, SelectedQuoteRate } from './types/QuoteRate'
import toArray from 'common/helpers/toArray'
import RateProviderHelper from './RateProvider.helper'

export function loadsmartRateSelected(selectedRate: SelectedQuoteRate, rates: QuoteRateType[]) {
  return (
    rates.find((rate: QuoteRateType) => rate.id === selectedRate.id)?.rate_provider === 'loadsmart'
  )
}

function QuoteRate(quoteRate: QuoteRateType | undefined) {
  return {
    status: {
      is(status: QuoteRateStatus | QuoteRateStatus[]) {
        return Boolean(quoteRate && toArray(status).includes(quoteRate.status))
      },
    },
    provider: {
      ...RateProviderHelper(quoteRate),
    },
  }
}

export default QuoteRate
