function UrlSearchParamsHelpers() {
  let searchParams = new URLSearchParams(window.location.search)

  return {
    get(param: string) {
      return searchParams.get(param) !== 'null' ? searchParams.get(param) : null
    },
  }
}

export default UrlSearchParamsHelpers
