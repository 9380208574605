import { useDialog } from 'components/Dialog'
import Vote, { VoteStatus } from 'components/Vote/Vote'
import { useState } from 'react'
import { RateFeedback, RateFeedbackOption } from 'common/types/RateFeedback'
import FeedbackModal from './FeedbackModal'
import { TooltipPosition } from '@loadsmart/loadsmart-ui'

export interface PriceFeedbackProps {
  feedback: RateFeedbackOption
  onSubmit: (feedback: RateFeedback) => void
}

const mapFeedbackToStatus = (feedback: RateFeedbackOption) => {
  switch (feedback) {
    case null:
      return 'none'
    case 'low':
    case 'high':
      return 'down'
    case 'fair':
      return 'up'
  }
}

function PriceFeedback({ feedback, onSubmit }: PriceFeedbackProps) {
  const { open, show, hide } = useDialog({
    open: false,
  })
  const [showFeedbackForm, setShowFeedbackForm] = useState(false)

  const handleVote = (status: VoteStatus) => {
    if (feedback === null) {
      setShowFeedbackForm(status === 'down')
      show()
    }
  }

  return (
    <>
      <FeedbackModal
        open={open}
        withForm={showFeedbackForm}
        onSubmit={(feedback) => {
          onSubmit(feedback)
          hide()
        }}
        onCancel={hide}
      />
      <div className="flex items-center">
        <span className="mr-4 text-sm font-bold text-black align-middle">
          Is this rate accurate?
        </span>
        <Vote
          status={mapFeedbackToStatus(feedback)}
          scale="small"
          onVote={handleVote}
          upMessage="We're satisfied with the estimated rate"
          downMessage="The estimated rate is not good"
          tooltipPosition={TooltipPosition.Top}
        />
      </div>
    </>
  )
}

export default PriceFeedback
