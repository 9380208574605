import get from 'common/helpers/get'

function parseAddress(result: any) {
  const addressComponents = get(result, 'address_components', [])

  let city = ''
  let state = ''
  let country = ''
  let zipcode = ''

  for (let i = 0; i < addressComponents.length; i++) {
    const types = get(addressComponents, [i, 'types'], [])

    if (types.includes('locality') || types.includes('sublocality')) {
      city = addressComponents[i].long_name
    }

    if (types.includes('administrative_area_level_1')) {
      state = addressComponents[i].short_name
    }

    if (types.includes('country')) {
      country = addressComponents[i].short_name
    }

    if (types.includes('postal_code')) {
      zipcode = addressComponents[i].short_name
    }
  }

  return { city, state, country, zipcode }
}

function parseLocation(result: any) {
  const coordinates = get(result, 'geometry.location') as google.maps.LatLng

  return {
    lat: coordinates?.lat() ?? 0,
    lng: coordinates?.lng() ?? 0,
  }
}

export function parseResult(result: any) {
  const place_id = get(result, 'place_id')

  return {
    ...parseAddress(result),
    ...parseLocation(result),
    place_id,
  }
}
