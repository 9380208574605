import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { Quote, ShipmentModeType } from 'common/types/Quote'
import { toast } from 'components/Toast'
import omit from 'common/helpers/omit'
import { QuoteLTLFormValues } from 'common/types/LTLQuote'
import useLTLQuote from 'pages/Quotes/QuoteForm/QuoteAdd/hooks/useLTLQuote'
import CalculatingQuote from '../Quotes/QuoteForm/QuoteAdd/CalculatingQuote'
import { LTLQuoteForm, LTLQuoteReview } from '../Quotes/QuoteForm/QuoteAdd/LTLQuote'
import ShipmentModeSelector from '../Quotes/QuoteForm/QuoteAdd/ShipmentModeSelector'
import { createLTLQuoteRequest, fetchLTLQuoteRequest } from './services/whiteLabel.service'
import { FormikHelpers } from 'formik/dist/types'
import ContactFormModal from './components/ContactInformation/ContactFormModal'
import { useDialog } from '../../components/Dialog'
import useAccessorials from '../Quotes/components/Accessorials/useAccessorials'
import SuccessMessage from './components/SuccessMessage'

interface NewLTLQuoteProps {
  setShipmentMode: (mode: ShipmentModeType) => void
  step: number
  successText: string | null
  handlePrevious: () => void
  handleNext: () => void
  reset: () => void
  showShipmentModeSelector: boolean
  onLoaderIsOpen?: (isOpen: boolean) => void
}

const NewLTLQuote = ({
  setShipmentMode,
  showShipmentModeSelector,
  handleNext,
  reset,
  step,
  successText,
  onLoaderIsOpen,
}: NewLTLQuoteProps) => {
  const [quote, setQuote] = useState<Quote>()
  const { accessorials } = useAccessorials({ isWhiteLabel: true })
  const formikHelpersRef = useRef<FormikHelpers<QuoteLTLFormValues>>()
  const [email, setEmail] = useState('')
  const { isCalculatingPrice, getLTLQuote } = useLTLQuote({
    setQuote,
    fetchStatus: fetchLTLQuoteRequest,
    onGetQuoteSuccess: () => {
      formikHelpersRef.current?.resetForm()
      handleNext()
    },
  })

  useEffect(() => {
    if (onLoaderIsOpen) {
      onLoaderIsOpen(isCalculatingPrice)
    }
  }, [isCalculatingPrice, onLoaderIsOpen])

  const contactFormDialogHandler = useDialog({
    open: false,
  })

  const onContactInformationFormSubmit = (email: string) => {
    setEmail(email)
    handleNext()
  }

  async function handleSubmit(
    values: QuoteLTLFormValues,
    formikHelpers: FormikHelpers<QuoteLTLFormValues>,
  ) {
    formikHelpersRef.current = formikHelpers
    const [error, result] = await createLTLQuoteRequest({
      mode: 'LTL',
      channel: 'frontend',
      ...values,
      items: values.items.map((item) => ({
        ...item,
        po_number: 'no value',
        hazmat_class: item.hazmat_class || null,
        ltl_item: {
          ...item.ltl_item,
          ltl_excessive_length_class: item.ltl_item.ltl_excessive_length_class || null,
          nmfc_code: item.ltl_item.nmfc_code || null,
        },
      })),
      customer_reference: values.customer_reference || null,
      stops: values.stops?.map((stop) => {
        if (stop.type === 'del') {
          return omit(stop, 'window')
        }
        return {
          ...stop,
        }
      }) as QuoteLTLFormValues['stops'],
    })

    if (error) {
      toast.error('We could not create the quote.')
      return
    }

    getLTLQuote(result?.data.id!)
    return
  }

  return (
    <>
      {isCalculatingPrice && <CalculatingQuote />}
      {step === 0 && (
        <div
          className={clsx({
            hidden: isCalculatingPrice,
          })}
        >
          {showShipmentModeSelector && (
            <div className="px-8 pt-8 bg-neutral-lightest">
              <ShipmentModeSelector shipmentMode="ltl" setShipmentMode={setShipmentMode} />
            </div>
          )}

          <LTLQuoteForm onSubmit={handleSubmit} accessorials={accessorials} isWhiteLabel />
        </div>
      )}
      {step === 1 && quote && (
        <>
          <ContactFormModal
            onCancel={() => contactFormDialogHandler.hide()}
            onSubmit={onContactInformationFormSubmit}
            open={contactFormDialogHandler.open}
            quoteId={quote.quote_id!}
          />
          <LTLQuoteReview
            quote={quote}
            setQuote={setQuote}
            onSave={() => contactFormDialogHandler.show()}
            onCancel={reset}
            accessorials={accessorials}
            isWhiteLabel
          />
        </>
      )}
      {step === 2 && quote && (
        <SuccessMessage
          quote={quote}
          email={email!}
          onRequote={reset}
          accessorials={accessorials}
          message={successText}
        />
      )}
    </>
  )
}

export default NewLTLQuote
