import { MarginsFilterFields } from '../MarginsList/MarginsList.types'
import DateHelper from 'common/Date.helpers'
import get from 'common/helpers/get'
import { API_DATE_FORMAT } from 'common/constants'

export const LANE_MARGIN_STATUS = {
  ACTIVE: 'ENABLED',
  DISABLED: 'DISABLED',
  EXPIRED: 'EXPIRED',
}

export const LANE_STATUS = [
  {
    label: 'Active',
    value: LANE_MARGIN_STATUS.ACTIVE,
  },
  {
    label: 'Disabled',
    value: LANE_MARGIN_STATUS.DISABLED,
  },
  {
    label: 'Expired',
    value: LANE_MARGIN_STATUS.EXPIRED,
  },
]

export function getDateRangeParameters(dateRange: MarginsFilterFields['date_range']) {
  if (!dateRange?.date_start && !dateRange?.date_end) {
    return null
  }

  const obj = {
    ...(dateRange.date_start && {
      date_start: DateHelper(dateRange.date_start)?.format(API_DATE_FORMAT),
    }),
    ...(dateRange.date_end && {
      date_end: DateHelper(dateRange.date_end)?.format(API_DATE_FORMAT),
    }),
  }

  return obj
}

export function buildFilterFields(initialFields: MarginsFilterFields) {
  return {
    outbound: {
      value: initialFields.outbound,
      set(e: Event) {
        const outbound = get(e, 'target.value', [])

        return outbound
      },
      get(options: any) {
        return options || []
      },
    },
    inbound: {
      value: initialFields.inbound,
      set(e: Event) {
        const inbound = get(e, 'target.value', [])

        return inbound
      },
      get(options: any) {
        return options || []
      },
    },
    shippers: {
      value: initialFields.shippers,
      set(e: Event) {
        const shippers = get(e, 'target.value', [])

        return shippers
      },
      get(options: any) {
        return options || []
      },
    },
    status: {
      value: initialFields.status,
      set(e: Event) {
        const options = get(e, 'target.value', null)
        return options
      },
      get(option: any) {
        if (option != null) {
          if (option === LANE_MARGIN_STATUS['EXPIRED']) {
            return [LANE_MARGIN_STATUS['ACTIVE'], option]
          }
          return [option]
        }

        return [LANE_MARGIN_STATUS['ACTIVE'], LANE_MARGIN_STATUS['DISABLED']]
      },
    },
    date_range: {
      value: {
        date_start: DateHelper(initialFields.date_range?.date_start)?.value() ?? null,
        date_end: DateHelper(initialFields.date_range?.date_end)?.value() ?? null,
      },
      set(e: Event) {
        const { startDate, endDate } = get(e, 'target.value', {})
        return {
          date_start: startDate,
          date_end: endDate,
        }
      },
      clear() {
        return { date_start: null, date_end: null }
      },
      get: getDateRangeParameters,
    },
    equipment_types: {
      value: initialFields.equipment_types,
      set(e: Event) {
        const equipment_types = get(e, 'target.value', [])

        return equipment_types
      },
      get(options: any) {
        return options || []
      },
    },
    modes: {
      value: initialFields.modes,
      set(e: Event) {
        const modes = get(e, 'target.value', [])

        return modes
      },
      get(options: any) {
        return (options || []).map((option: any) => option.value)
      },
    },
    margin_min: {
      value: initialFields.margin_min,
      set(e: Event) {
        const margin_min = get(e, 'target.value', '')
        return margin_min
      },
    },
    margin_max: {
      value: initialFields.margin_max,
      set(e: Event) {
        const margin_max = get(e, 'target.value', '')
        return margin_max
      },
    },
    reasons: {
      value: initialFields.reasons,
      set(e: Event) {
        const reasons = get(e, 'target.value', [])
        return reasons
      },
      get(options: any) {
        return options || []
      },
    },
    created_by: {
      value: initialFields.created_by,
      set(e: Event) {
        const created_by = get(e, 'target.value', [])
        return created_by
      },
      get(options: any) {
        return options || []
      },
    },
    offset: initialFields.offset || 0,
    limit: initialFields.limit || 150,
  }
}

export const initialFields = buildFilterFields({
  date_range: { date_start: null, date_end: null },
  inbound: [],
  outbound: [],
  shippers: [],
  created_by: [],
  equipment_types: [],
  modes: [],
  margin_min: -100,
  margin_max: 100,
  reasons: [],
  status: null,
  offset: 0,
  limit: 150,
})
