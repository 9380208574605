import { useState, useCallback } from 'react'

import type { KMA } from '../../../common/types/KMA'
import type { SuggestionItemAdapter } from 'components/Suggestion'
import { toast } from '../../../components/Toast'
import { getKMAs } from '../../../services/KMAs/KMAs.service'
import useCancelToken from '../../../hooks/useCancelToken'

const KMAAdapter: SuggestionItemAdapter<KMA> = {
  getValue: (item: KMA) => item.id,
  getLabel: (item: KMA) => item.name,
}

let KMAs: KMA[] = []

function useFetchKMAs() {
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const [, setLoading] = useState(false)

  const fetch = useCallback(
    ({ pageSize = 1000, skip = 0 } = {}) => {
      async function fetch() {
        cancelPending()

        setLoading(true)

        const source = getSource()

        const [error, response] = await getKMAs(
          {
            limit: pageSize,
            offset: skip,
          },
          {
            cancelToken: source.token,
          },
        )

        clearSource()

        if (error) {
          toast.error('We could not retrieve the KMAs.')
          KMAs = []
        } else if (response) {
          const newData = response.data.results ?? []

          KMAs = newData
        }

        setLoading(false)

        return KMAs
      }

      if (KMAs.length > 0) {
        return Promise.resolve(KMAs)
      }

      return fetch()
    },
    [cancelPending, clearSource, getSource],
  )

  return {
    _type: 'KMA',
    fetch,
    adapter: KMAAdapter,
  }
}

export default useFetchKMAs
