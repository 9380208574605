import * as React from 'react'
import {
  LabelProps,
  ToggleGroup as MToggleGroup,
  ToggleGroupProps as MToggleGroupProps,
} from '@loadsmart/loadsmart-ui'

import { CustomFormikField } from './CustomFormikField'
import { BaseField } from './BaseField'

export interface ToggleGroupProps extends Omit<MToggleGroupProps, 'name' | 'value' | 'form'> {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
  required?: boolean
}

export function ToggleGroup({ children, ...props }: ToggleGroupProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: fieldOnChange, onBlur: fieldOnBlur, ...field },
        baseFieldProps,
        baseProps,
        form
      }) => {
        const {setFieldTouched} = form
        const mappedProps = {
          ...baseProps,
          ...field,
          ...props,
          onChange: (event: any) => {
            fieldOnChange(event)
            setFieldTouched(field.name, true, false)
          },
        }

        return (
          <BaseField {...baseFieldProps}>
            <MToggleGroup {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}
