import { useState, useEffect, useCallback } from 'react'

import type { MarginResult } from 'common/types/Margin'
import { toast } from 'components/Toast'
import { getMargins, MarginFilterBody } from 'services/margins/margins.service'
import useCancelToken from 'hooks/useCancelToken'

function useFetchMargins() {
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const [margins, setMargins] = useState<MarginResult[]>([])
  const [loading, setLoading] = useState(false)

  // update local margin list to avoid fetching margins again.
  const patchMargin = useCallback(
    function patchMargin(margin: MarginResult) {
      function findMarginIndexByID(id: string) {
        if (!id) {
          return -1
        }

        return (margins || []).findIndex((margin) => margin.id === id)
      }

      const index = findMarginIndexByID(margin.id)

      if (index === -1) {
        return
      }

      const newData = [...margins.slice(0, index), margin, ...margins.slice(index + 1)]
      setMargins(newData)
    },
    [margins],
  )

  const fetchMargins = useCallback(
    (body?: MarginFilterBody) => {
      async function fetch() {
        cancelPending()

        setLoading(true)

        const source = getSource()

        const [error, response] = await getMargins(body, {
          cancelToken: source.token,
        })

        clearSource()

        if (error) {
          toast.error('We could not retrieve the margins.')
          setMargins([])
        } else if (response) {
          const newData = response.data.results ?? []

          setMargins(newData)
        }

        setLoading(false)
      }

      fetch()
    },
    [cancelPending, clearSource, getSource],
  )

  useEffect(() => {
    return function cancelFetchMargins() {
      cancelPending()
    }
  }, [cancelPending])

  return { margins, fetchMargins, patchMargin, loading }
}

export default useFetchMargins
