import { conditional, whenProps } from '@loadsmart/loadsmart-ui/dist/tools'
import styled from 'styled-components'

interface OptionProps {
  option: 'low' | 'high'
  selected: boolean
}

const Option = styled.button<OptionProps>`
  cursor: pointer;
  :focus {
    outline: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 63px;
  background: ${conditional({
    'color-neutral-lightest': whenProps({ selected: false }),
    'color-accent-transparent': whenProps({ selected: true }),
  })};
  border-width: ${conditional({
    '1px 1px 1px 0px': whenProps({ option: 'high' }),
    '1px 0px 1px 1px': whenProps({ option: 'low' }),
  })};
  color: ${conditional({
    'color-accent': whenProps({
      selected: true,
    }),
  })};
  font-weight: ${conditional({
    'font-weight-bold': whenProps({
      selected: true,
    }),
    'font-weight-medium': whenProps({
      selected: false,
    }),
  })};
  border-style: solid;
  border-color: ${conditional({
    'color-neutral-lighter': whenProps({ selected: false }),
    'color-accent': whenProps({ selected: true }),
  })};
  box-sizing: border-box;
  border-radius: ${conditional({
    '0px 4px 4px 0px': whenProps({ option: 'high' }),
    '4px 0px 0px 4px': whenProps({ option: 'low' }),
  })};
`

const OptionSeparator = styled.div<{ selected: boolean }>`
  border-left: 1px solid;
  border-color: ${conditional({
    'color-neutral-lighter': whenProps({ selected: false }),
    'color-accent': whenProps({ selected: true }),
  })};
  height: 63px;
`

export interface PriceFeedbackSelectorProps {
  selected: 'low' | 'high' | null
  onSelect: (option: 'low' | 'high') => void
}

const PriceFeedbackSelector = ({ selected, onSelect }: PriceFeedbackSelectorProps) => {
  return (
    <div className="flex text-center">
      <Option onClick={() => onSelect('low')} option="low" selected={selected === 'low'}>
        <span className="text-xl leading-none">
          💰
          <br /> <span className="text-xs">Too low</span>
        </span>
      </Option>
      <OptionSeparator selected={Boolean(selected)} />
      <Option onClick={() => onSelect('high')} option="high" selected={selected === 'high'}>
        <span className="text-xl leading-none">
          💰💰💰
          <br /> <span className="text-xs">Too high</span>
        </span>
      </Option>
    </div>
  )
}

export default PriceFeedbackSelector
