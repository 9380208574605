import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Column, usePagination, useTable } from 'react-table'
import clsx from 'clsx'
import { toast } from 'components/Toast'
import Button, { IconButton } from 'components/Button'
import Empty from 'components/Empty'
import isEmpty from 'common/helpers/isEmpty'
import Loading from 'components/Loading'
import { Table } from 'components/Table'
import useCancelToken from 'hooks/useCancelToken'
import Section from 'components/Section'
import Icon from 'components/Icon'
import { RateProviderRule } from 'common/types/RateProviderRule'
import { listRateProviderRules } from 'services/rateProviderRules/rateProviderRules.service'
import Layout from 'components/Layout'
import EquipmentTypeEntry from '../../Quotes/QuoteForm/EquipmentType'
import LocationHelper from 'common/Location.helpers'
import { DISPLAY_DATE_FORMAT } from 'common/constants'
import get from 'common/helpers/get'
import Pagination from 'components/Pagination'
import { RateProviderIdentifiers, RateProviders } from 'common/types/RateProvider'
import DateHelper from 'common/Date.helpers'

interface RulesListProps {
  className?: string
  filters?: any
  onAddRule: () => void
  onEditRule: (rule: RateProviderRule) => void
  onDeleteRule: (rule: RateProviderRule) => void
  onEmptyRules: () => void
  rateProvider: RateProviderIdentifiers
}

const COLUMNS = [
  {
    Header: 'Rate Provider',
    accessor: 'rate_provider',
    style: { width: '12%', wordBreak: 'break-all', textTransform: 'capitalize' },
    Cell({ value }: { value: RateProviderRule['rate_provider'] }) {
      return RateProviders[value].name
    },
  },
  {
    Header: 'EG. Type',
    accessor: 'equipment_types',
    style: { width: '12%', wordBreak: 'break-all' },
    Cell({ value }: { value: RateProviderRule['equipment_types'] }) {
      if (!value) {
        return ''
      }

      return value.map((item) => EquipmentTypeEntry({ mode: 'icon-only', name: item }))
    },
  },
  {
    Header: 'Pickup',
    accessor: 'origins',
    style: { width: '22%', wordBreak: 'break-all' },
    Cell({ value }: { value: RateProviderRule['origins'] }) {
      if (value && value.length > 0) {
        return LocationHelper(value[0]).formatWithoutEmptyFields()
      }

      return 'All the locations'
    },
  },

  {
    Header: 'Delivery',
    accessor: 'destinations',
    style: { width: '22%', wordBreak: 'break-all' },
    Cell({ value }: { value: RateProviderRule['destinations'] }) {
      if (value && value.length > 0) {
        return LocationHelper(value[0]).formatWithoutEmptyFields()
      }

      return 'All the locations'
    },
  },
  {
    Header: 'Valid Thru',
    accessor: 'valid_through',
    Cell({
      row: { original },
      value,
      onEditRule,
      onDeleteRule,
    }: {
      row: { original: RateProviderRule }
      value: RateProviderRule['valid_through']
      onEditRule: (rule: RateProviderRule) => void
      onDeleteRule: (rule: RateProviderRule) => void
    }) {
      return (
        <div className="flex flex-row items-center h-full">
          <div className="inline-flex w-auto h-8 mr-auto">
            {value && value.start && value.end
              ? `${DateHelper(value.start)
                  ?.value()
                  .utc()
                  .format(DISPLAY_DATE_FORMAT)} - ${DateHelper(value.end)
                  ?.value()
                  .utc()
                  .format(DISPLAY_DATE_FORMAT)} `
              : '-'}
          </div>
          <div className="inline-flex w-auto h-8 ml-auto">
            <IconButton className="ml-4" scale="small" onClick={() => onEditRule(original)}>
              <Icon name="edit" title="Edit Rule" size={16} />
            </IconButton>
            <IconButton className="ml-4" scale="small" onClick={() => onDeleteRule(original)}>
              <Icon name="remove" title="Delete Rule" size={16} />
            </IconButton>
          </div>
        </div>
      )
    },
  },
]

const RulesList = forwardRef(
  (
    { filters, onEditRule, onDeleteRule, onAddRule, onEmptyRules, rateProvider }: RulesListProps,
    ref,
  ) => {
    const { getSource, clearSource, cancelPending } = useCancelToken()

    const [data, setData] = useState<RateProviderRule[]>([])
    const [loading, setLoading] = useState(false)
    const [controlledPageCount, setControlledPageCount] = useState(0)
    const [totalRules, setTotalRules] = useState(0)

    const {
      canNextPage,
      canPreviousPage,
      getTableBodyProps,
      getTableProps,
      gotoPage,
      headerGroups,
      page,
      pageOptions,
      prepareRow,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns: COLUMNS as Column[],
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
        manualPagination: true,
        pageCount: controlledPageCount,
      },
      usePagination,
    )

    const pageSizeRef = useRef(pageSize)

    const fetchRulesList = useCallback(
      ({ pageSize, pageIndex }) => {
        async function fetch() {
          cancelPending()

          setLoading(true)

          const source = getSource()

          const rulesData = {
            limit: pageSize,
            offset: pageSize * pageIndex,
            filters: {
              rate_provider: [rateProvider],
            },
          }

          const [error, response] = await listRateProviderRules(rulesData, {
            cancelToken: source.token,
          })

          setLoading(false)

          clearSource()

          if (error) {
            toast.error('We could not retrieve the rules.')
            setData([])
            setControlledPageCount(0)
            setTotalRules(0)
          } else if (response) {
            const total = get(response, 'data.count', 0)
            const newData = get(response, 'data.results', [])
            setData(newData)
            setControlledPageCount(Math.ceil(total / pageSize))
            setTotalRules(total)

            if (total === 0) {
              onEmptyRules()
            }
          }
        }
        fetch()
      },
      [cancelPending, clearSource, getSource, onEmptyRules, rateProvider],
    )

    useEffect(() => {
      if (pageSize !== pageSizeRef.current) {
        pageSizeRef.current = pageSize

        if (pageIndex !== 0) {
          gotoPage(0)
          return
        }
      }

      fetchRulesList({ pageIndex, pageSize })

      return function cancelFetchQuotes() {
        cancelPending()
      }
    }, [fetchRulesList, pageIndex, pageSize, gotoPage, cancelPending])

    useImperativeHandle(ref, () => ({
      fetchRulesList: () => fetchRulesList({ pageIndex, pageSize }),
    }))

    return (
      <>
        {loading && (
          <Loading className="absolute inset-x-0 bottom-0 z-10 justify-center px-3 py-6 opacity-75 top-6 bg-background" />
        )}
        <Section
          title={`${totalRules || 0} smart rules`}
          trailing={
            <Button variant="secondary" onClick={onAddRule}>
              <Layout.Group space="xs" align="center">
                <Icon name="add" />
                Add New Rule
              </Layout.Group>
            </Button>
          }
        >
          <Table className="w-full mt-2" {...getTableProps()}>
            <Table.Head>
              {headerGroups.map((headerGroup) => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Table.Cell header {...column.getHeaderProps()} style={column.style}>
                      {column.render('Header')}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row)
                return (
                  <Table.Row {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell: any) => {
                      return (
                        <Table.Cell
                          key={cell.column.id}
                          className={clsx('text-sm')}
                          style={cell.column.style}
                        >
                          {cell.render('Cell', {
                            onEditRule,
                            onDeleteRule,
                          })}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
            {isEmpty(data) && (
              <Table.Foot>
                <Table.Row>
                  {/* @ts-ignore */}
                  <Table.Cell colSpan={COLUMNS.length}>
                    <Empty>No smart rules to display.</Empty>
                  </Table.Cell>
                </Table.Row>
              </Table.Foot>
            )}
          </Table>
          {!isEmpty(data) && (
            <Pagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              className="w-full px-4 pt-6"
              currentPage={pageIndex}
              disabled={loading}
              onPageChange={(page) => {
                gotoPage(page)
              }}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize)
              }}
              pageSize={pageSize}
              totalPages={pageOptions.length}
            />
          )}
        </Section>
      </>
    )
  },
)

export default RulesList
