import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import tokenManager from '../../common/tokenManager'

interface AuthState {
  accessToken: string | null
  refreshToken: string | null
  meta: {
    loading: boolean
    error: string | null
  }
}

interface LoginSuccessPayload {
  accessToken: string
  refreshToken: string
}

const initialState: AuthState = {
  accessToken: tokenManager.getToken() || '',
  refreshToken: tokenManager.getRefreshToken() || '',
  meta: { loading: false, error: null },
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.meta.loading = true
      state.meta.error = null
    },
    loginSuccess(state, action: PayloadAction<LoginSuccessPayload>) {
      state.meta.loading = false
      state.meta.error = null

      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.meta.loading = false
      state.meta.error = action.payload
    },
    logoutStart(state) {
      state.meta.loading = true
      state.meta.error = null
    },
    logoutSuccess(state) {
      state.meta.loading = false
      state.meta.error = null

      state.accessToken = ''
      state.refreshToken = ''
    },
  },
})

export default slice
