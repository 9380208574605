import React from 'react'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

export interface RenderLinearGradientProps {
  id: string
  color?: keyof RateGuideThemeInterface
}

const RenderLinearGradient = ({ id, color = 'color-primary' }: RenderLinearGradientProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  return (
    <defs>
      <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
        <stop offset="20%" stopColor={String(theme[color])} stopOpacity={0.5} />
        <stop
          offset="80%"
          stopColor={String(theme[`${color}-charts-gradient` as keyof RateGuideThemeInterface])}
          stopOpacity={0.1}
        />
      </linearGradient>
    </defs>
  )
}

export default RenderLinearGradient
