import { Fragment, useCallback, useEffect, useState } from 'react'

import type { Location } from 'common/types/Location'
import type ColorScheme from 'common/types/ColorScheme'
import isEmpty from 'common/helpers/isEmpty'
import get from 'common/helpers/get'
import EventLike from 'common/types/EventLike'
import GoogleMapsAPI from './GoogleMapsAPI'
import Status from 'common/types/Status'
import Suggestion, { SuggestionDatasource, SuggestionValue } from 'components/Suggestion'
import {
  FetchLocationOptions,
  generateUseFetchLocations,
  LocationAdapter,
} from './useFetchLocations'
import { filterSelectOptions, getLocationByValueType } from './LocationSuggestion.helpers'

export interface LocationSuggestionProps {
  datasources?: (() => SuggestionDatasource<any>)[]
  className?: string
  delay?: number
  clearable?: boolean
  disabled?: boolean
  id?: string
  name: string
  placeholder?: string
  scheme?: ColorScheme
  status?: Status
  query?: string
  multiple?: boolean
  options?: FetchLocationOptions
  value?: Location | Location[] | null
  onChange?: (event: EventLike<Location | Location[] | null>) => void
}

function LocationSuggestion({
  id,
  name,
  placeholder = 'City or Zipcode',
  datasources = [],
  onChange,
  options,
  ...others
}: LocationSuggestionProps) {
  const [status, setStatus] = useState(Status.Neutral)
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(() => Boolean(window.google))

  useEffect(function startCheckGoogleMaps() {
    const interval = setInterval(() => {
      if (window.google) {
        setIsGoogleMapsLoaded(true)
        clearInterval(interval)
      }
    }, 500)

    return function stopCheckGoogleMaps() {
      clearInterval(interval)
    }
  }, [])

  const handleChange = useCallback(
    function handleChange(e: EventLike<SuggestionValue>) {
      let {
        target: { value },
      } = e

      async function updateValues() {
        if (!value) {
          onChange?.({ target: { id, name, value: null } })
          return
        }

        setStatus(Status.Busy)

        if (Array.isArray(value)) {
          const locations: Location[] = []

          for (let i = 0; i < value.length; i++) {
            let location
            if (!get(value[i], 'payload.location')) {
              location = await getLocationByValueType(value[i])
            } else {
              location = value[i].payload.location
            }
            if (location) {
              locations.push(location)
            }
          }

          onChange?.({
            target: {
              id,
              name,
              value: isEmpty(locations) ? null : locations,
            },
          })
        } else {
          const location = get(value, 'payload.location', null) ?? (await getLocationByValueType(value))

          onChange?.({ target: { id, name, value: location } })
        }

        setStatus(Status.Neutral)
      }

      updateValues()
    },
    [id, name, onChange],
  )

  function getStatus() {
    if (status === Status.Busy) {
      return Status.Busy
    }

    return others.status || Status.Neutral
  }

  function getValue() {
    if (!others.value) {
      return null
    }

    if (Array.isArray(others.value)) {
      return (others.value as Location[]).map((location) => ({
        label: LocationAdapter.getLabel(location),
        value: LocationAdapter.getValue(location),
        payload: {
          location,
        },
      }))
    }

    return {
      label: LocationAdapter.getLabel(others.value as Location),
      value: LocationAdapter.getValue(others.value as Location),
      payload: {
        location: others.value,
      },
    }
  }

  return (
    <Fragment>
      <GoogleMapsAPI />

      <Suggestion
        id={id}
        name={name}
        placeholder={placeholder}
        delay={750}
        datasources={[...datasources, generateUseFetchLocations(options)]}
        onChange={handleChange}
        disabled={!isGoogleMapsLoaded}
        filterOption={filterSelectOptions}
        {...others}
        value={getValue() as any}
        status={getStatus()}
      />
    </Fragment>
  )
}

export default LocationSuggestion
