import { AxiosError, AxiosResponse } from 'axios'

import { get } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'

import type { Accessorial } from 'common/types/Accessorial'
import type { StopType } from 'common/types/Stop'

const urlStopType: Record<StopType, string> = {
  pu: 'pickup',
  del: 'delivery',
}

export interface AccessorialsPayload {
  data: Accessorial[]
}

export async function getAccessorials(
  type: StopType,
): Promise<[AxiosError | null | boolean, AxiosResponse<AccessorialsPayload> | undefined]> {
  try {
    return await to(get(`/api/accessorials?stop_type=${urlStopType[type]}`))
  } catch (error) {
    console.error(error)
    return [true, undefined]
  }
}
