import { API_DATE_FORMAT } from 'common/constants'
import Icon from 'components/Icon'
import moment from 'moment'
import clsx from 'clsx'

export interface QuotesSummaryProps {
  createdAtDates: { start: string; end?: string }
  total: number
  totalVariation: number | null
}

const getDayRange = (start: string, end?: string) => {
  if (!end) {
    return moment().diff(moment(start, API_DATE_FORMAT), 'd')
  }
  return moment(end, API_DATE_FORMAT).diff(moment(start, API_DATE_FORMAT), 'd')
}

function QuotesSummary({ createdAtDates, total, totalVariation }: QuotesSummaryProps) {
  const dayRange = getDayRange(createdAtDates.start, createdAtDates.end)
  return (
    <div
      className="flex flex-col drop-shadow rounded p-6 shadow bg-neutral-lightest min-w-max"
      style={{ minWidth: '325px', height: '160px' }}
    >
      <h1 className="font-bold text-neutral-dark">Number of quotes</h1>
      <div className="mt-4 flex">
        <span className="text-4xl text-neutral-darker font-black">{total}</span>
        {totalVariation !== null && (
          <div className="flex flex-col ml-6">
            <span
              className={clsx('text-sm flex font-medium', {
                'text-brand-primary': (totalVariation as number) >= 0,
                'text-danger': (totalVariation as number) < 0,
              })}
            >
              <Icon
                className="my-auto mr-1.5"
                name={(totalVariation as number) >= 0 ? 'arrow-corner-up' : 'arrow-corner-down'}
                width={12}
                height={12}
              />
              {Math.abs(totalVariation as number).toFixed(2)}%
            </span>
            <span className="text-xs text-neutral-darker font-medium">
              from past {dayRange} days
            </span>
          </div>
        )}
      </div>
      <div className="flex mt-3">
        {dayRange > 7 && (
          <div className="mr-10">
            <span className="text-xl text-neutral-darker font-bold">{Math.floor(total / 7)}</span>
            <span className="text-xs text-neutral-darker font-medium"> per week</span>
          </div>
        )}
        {dayRange > 2 && (
          <div>
            <span className="text-xl text-neutral-darker font-bold">
              {Math.floor(total / dayRange)}
            </span>
            <span className="text-xs text-neutral-darker font-medium"> per day</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default QuotesSummary
