// import analytics from 'utils/analytics'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { get } from 'common/HTTPClient'
import { to } from 'common/helpers/awaitTo'


export interface GetSettingsPayload {
  flags: {
    [key: string]: boolean;
  },
  settings: {
    [key: string]: any;
  },
}

/**
 * Load the settings and FF for the logged user
 */
export async function getSettings(
  config?: AxiosRequestConfig,
): Promise<[AxiosError | null, AxiosResponse<GetSettingsPayload> | undefined]> {
  let error = null,
    result
  try {
    ;[error, result] = await to(get('/api/frontend-settings', config))

    return [error, result]
  } catch (error) {
    console.error(error)
  }

  return [error, result]
}
