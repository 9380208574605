import { ChangeEvent, KeyboardEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'

import Button from '../Button'
import EventLike from '../../common/types/EventLike'
import Icon from '../Icon'
import Select, { SingleSelectValue } from '../Select'
import TextField from '../TextField'

export interface PaginationProps {
  disabled?: boolean
  currentPage: number
  totalPages: number
  pageSize: number
  canPreviousPage?: boolean
  canNextPage?: boolean
  children?: ReactNode
  className?: string
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

const PAGE_SIZES = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
]
const TEXT_FIELD_STYLE = { width: 'calc(3ch + 24px)' }

function Pagination({
  children,
  canNextPage = true,
  canPreviousPage = true,
  className,
  currentPage: currentPageProp,
  disabled,
  onPageChange,
  onPageSizeChange,
  pageSize,
  totalPages,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(currentPageProp)

  const navigateToPreviousPage = useCallback(() => {
    if (canPreviousPage) {
      onPageChange(currentPage - 1)
    }
  }, [currentPage, onPageChange, canPreviousPage])

  const navigateToNextPage = useCallback(() => {
    if (canNextPage) {
      onPageChange(currentPage + 1)
    }
  }, [currentPage, onPageChange, canNextPage])

  const handlePageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0
    setCurrentPage(page)
  }, [])

  const handlePageSizeChange = useCallback(
    (e: EventLike<SingleSelectValue>) => {
      onPageSizeChange(Number(e.target.value))
    },
    [onPageSizeChange],
  )

  useEffect(
    function updateCurrentPageState() {
      setCurrentPage(currentPageProp)
    },
    [currentPageProp],
  )

  const publishPageChange = useCallback(() => {
    onPageChange(currentPage)
  }, [onPageChange, currentPage])

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        publishPageChange()
      }
    },
    [publishPageChange],
  )

  return (
    <div className={clsx('flex flex-row items-center text-sm justify-between', className)}>
      <div className="flex mr-2">
        <Button
          variant="secondary"
          onClick={navigateToPreviousPage}
          disabled={!canPreviousPage || disabled}
          className="justify-center hidden w-9 lg:flex lg:mr-6"
          data-testid="previous-page"
        >
          <Icon
            name="chevron-right"
            aria-label="Previous page"
            className="transform rotate-180"
            size={16}
          />
        </Button>
        <div>
          <span>Page</span>
          <TextField
            type="number"
            disabled={disabled}
            onChange={handlePageChange}
            onBlur={publishPageChange}
            onKeyUp={handleKeyUp}
            style={TEXT_FIELD_STYLE}
            className="mx-2"
            value={currentPage + 1}
            data-testid="page-input"
          />
          <span>of</span>
          <strong className="ml-2">{totalPages}</strong>
        </div>
        <Button
          variant="secondary"
          onClick={navigateToNextPage}
          disabled={!canNextPage || disabled}
          className="justify-center hidden w-9 lg:flex lg:ml-6"
          data-testid="next-page"
        >
          <Icon name="chevron-right" aria-label="Next page" size={16} />
        </Button>
      </div>
      {children}
      <div className="flex flex-row items-center ml-2">
        <label htmlFor="page-size" className="mr-2">
          Show
        </label>
        <Select
          id="page-size"
          name="page-size"
          className="z-10"
          value={String(pageSize)}
          onChange={handlePageSizeChange}
          disabled={disabled}
          options={PAGE_SIZES}
          data-testid="page-size"
        />
      </div>
    </div>
  )
}

export default Pagination
