import React, { useMemo } from 'react'
import {
  BarChart,
  RenderGrid,
  RenderLegend,
  RenderStackedBar,
  RenderTooltip,
  RenderXAxis,
  RenderYAxis,
  ResponsiveContainer,
} from 'components/Charts'

import { HighlightedDataFooter, HighlightedDataFooterProps } from 'components/Charts'
import CustomTooltip from './CustomTooltip'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { RateGuideThemeInterface } from 'theming'
import { useTheme } from 'styled-components'

export interface QuotesCreationOverTimeProps {
  data: {
    series: {
      date: string
      drv: number
      rfr: number
      fbe: number
    }[]
  }
  chartWidth?: number | string
  chartHeight?: number | string
  isAnimationActive?: boolean
}

export const QUOTE_TYPES: Record<string, { color: keyof RateGuideThemeInterface; name: string }> = {
  drv: { color: 'color-primary', name: 'Dry Van' },
  rfr: { color: 'color-charts-yellow', name: 'Reefer' },
  fbe: { color: 'color-charts-purple', name: 'Flatbed' },
}

const QuotesCreationOverTime = ({
  data,
  chartWidth = '100%',
  chartHeight = 400,
  isAnimationActive = false,
}: QuotesCreationOverTimeProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  const legendPayload: Payload[] = [
    {
      value: QUOTE_TYPES.drv.name,
      type: 'circle',
      id: 'drv',
      color: String(theme[QUOTE_TYPES.drv.color]),
    },
    {
      value: QUOTE_TYPES.rfr.name,
      type: 'circle',
      id: 'rfr',
      color: String(theme[QUOTE_TYPES.rfr.color]),
    },
    {
      value: QUOTE_TYPES.fbe.name,
      type: 'circle',
      id: 'fbe',
      color: String(theme[QUOTE_TYPES.fbe.color]),
    },
  ]

  const stackedBarItems = [
    { dataKey: 'drv', color: QUOTE_TYPES.drv.color },
    { dataKey: 'rfr', color: QUOTE_TYPES.rfr.color },
    { dataKey: 'fbe', color: QUOTE_TYPES.fbe.color },
  ]

  const highlightedFooterData = useMemo((): HighlightedDataFooterProps['data'] => {
    return [
      {
        identifier: 'total',
        values: data.series.map((item) => item.drv + item.fbe + item.rfr),
      },
    ]
  }, [data])

  return (
    <>
      <p className="font-bold text-lg">Quotes creation</p>
      <ResponsiveContainer width={chartWidth} height={chartHeight}>
        <BarChart data={data.series}>
          {RenderGrid()}
          {RenderXAxis({ dataKey: 'date' })}
          {RenderYAxis()}
          {RenderTooltip({ content: <CustomTooltip /> })}
          {RenderLegend({ payload: legendPayload })}
          {RenderStackedBar({ items: stackedBarItems, isAnimationActive })}
        </BarChart>
      </ResponsiveContainer>
      <HighlightedDataFooter
        title="Total"
        data={highlightedFooterData}
        titleWidth="8%"
        className="pr-6"
      />
    </>
  )
}

export default QuotesCreationOverTime
