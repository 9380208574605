import { ReactComponent as NotFoundIllustration } from './not-found-art.svg'
import Text from 'components/Text'

function NotFound() {
  return (
    <div className="h-full w-80 flex flex-col justify-center">
      <NotFoundIllustration />
      <Text className="mt-4 text-center" variant="heading-sm-bold">
        Oops, something went wrong
      </Text>
      <Text className="mt-1 text-center" variant="body">
        We're sorry, the page couldn't be loaded or doesn't exist. Please, try to load again and if
        the problem persists, contact the administrator.
      </Text>
    </div>
  )
}

export default NotFound
