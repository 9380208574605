import React from 'react'
import { YAxis } from './index'

export interface RenderYAxisProps {
  domain?: [number | string, string | number]
  formatter?: (value: any, index: number) => string
  type?: 'number' | 'category'
}

const RenderYAxis = ({
  domain = ['auto', 'auto'],
  formatter,
  type = 'number',
}: RenderYAxisProps = {}) => {
  return (
    <YAxis
      type={type}
      tickFormatter={formatter}
      domain={domain}
      allowDataOverflow
      fontSize="12px"
    />
  )
}

export default RenderYAxis
