import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GetSettingsPayload } from '../../services/settings/settings.service'


const initialState: GetSettingsPayload = {
  settings: {},
  flags: {}
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(_, action: PayloadAction<GetSettingsPayload>) {
      return action.payload
    },
    setSettingsFailed() {
      return initialState
    }
  },
})

export default slice
