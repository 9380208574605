import { ReactNode } from 'react'
import styled from 'styled-components'
import { conditional, whenProps } from '@loadsmart/loadsmart-ui/dist/tools'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'

export type CardSize = 'default' | 'small'

export interface CardProps {
  highlighted?: boolean
  error?: boolean
  children?: ReactNode
  disabled?: boolean
  size?: CardSize
}

const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  left: 0px;
  top: 0px;

  height: ${(props) => {
    switch (props.size) {
      case 'small':
        return '142px'
      case 'default':
      default:
        return '187px'
    }
  }};

  width: ${(props) => {
    switch (props.size) {
      case 'small':
        return '264px'
      case 'default':
      default:
        return '300px'
    }
  }};

  h2 {
    font-size: ${(props) => {
      switch (props.size) {
        case 'small':
          return token('font-size-2')
        case 'default':
        default:
          return token('font-size-1')
      }
    }};
  }
  p {
    font-size: ${token('font-size-3')};
  }

  background: ${token('color-neutral-white')};

  border-style: solid;

  border-color: ${conditional({
    'color-primary': whenProps({ highlighted: true, error: false }),
    'color-danger-dark': whenProps({ highlighted: true, error: true }),
    'color-neutral-light': whenProps({ highlighted: false }),
  })};

  border-width: ${(props) =>
    props.highlighted ? token('border-width-thin') : token('border-width-thin')};

  padding: ${(props) => (props.highlighted ? '23px' : '24px')};

  box-shadow: ${conditional({
    'shadow-glow-primary': whenProps({ highlighted: true, error: false }),
    'shadow-glow-danger': whenProps({ highlighted: true, error: true }),
    'shadow-none': whenProps({ highlighted: false }),
  })};

  &:focus {
    outline: 0;
  }

  box-sizing: border-box;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;

  opacity: ${conditional({
    '0.5': whenProps({ disabled: true }),
    '1': whenProps({ disabled: false }),
  })};

  &:hover {
    cursor: ${conditional({
      default: whenProps({ disabled: true }),
      pointer: whenProps({ disabled: false }),
    })};
  }
`

Card.defaultProps = {
  size: 'default',
  disabled: false,
}

export default Card
