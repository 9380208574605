import React, { useCallback, useEffect, useRef, useState, memo } from 'react'
import { useTable, usePagination } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import isEqual from 'lodash.isequal'

import { getQuotes } from 'services/quotes/quotes.service'
import { PickupRenderer, DeliveryRenderer } from './StopRenderer'
import { Quote, QuoteResult } from 'common/types/Quote'
import { toast } from 'components/Toast'
import Currency from 'common/Currency.helpers'
import Empty from 'components/Empty'
import get from 'common/helpers/get'
import Icon from 'components/Icon'
import isEmpty from 'common/helpers/isEmpty'
import Loading from 'components/Loading'
import Pagination from 'components/Pagination'
import Pill from 'components/Pill'
import QuoteStatusHelper from 'common/QuoteStatus.helpers'
import Table from 'components/Table'
import useCancelToken from 'hooks/useCancelToken'
import type { PartialQuotesFilter } from './QuotesList.types'
import styled from 'styled-components'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import EquipmentType from '../QuoteForm/EquipmentType'
import { IconButton } from '@loadsmart/loadsmart-ui'
import RefreshQuoteAlertModalHelper from './helpers/RefreshQuoteAlertModal.helpers'
import RefreshQuoteAlertModal from '../RefreshQuote/RefreshQuoteAlertModal'
import { useDialog } from 'components/Dialog'

interface QuotesListProps {
  className?: string
  filters?: PartialQuotesFilter
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const PRICE_COLUMN_STYLE = { minWidth: 160 }
const COLUMNS = [
  {
    Header: 'Quote',
    accessor: 'quote_number',
  },
  {
    Header: 'Pickup',
    accessor: 'pickup',
    Cell: PickupRenderer,
  },
  {
    Header: 'Delivery',
    accessor: 'delivery',
    Cell: DeliveryRenderer,
  },
  {
    Header: 'Shipper',
    accessor: 'shipper_name',
    Cell({ row }: { row: any }) {
      const quote = row.original as QuoteResult
      return <span className="h-full truncate">{quote.shipper_name}</span>
    },
  },
  {
    Header: 'Equip. Type',
    accessor: 'equipment_type',
    Cell({ row }: { row: any }) {
      const { equipment_type } = row.original as Quote

      const validEquipmentType = equipment_type ? EquipmentTypeHelper(equipment_type).value() : null

      return (
        <span className="flex">
          {validEquipmentType ? <EquipmentType mode="abbr" name={equipment_type} /> : '-'}
        </span>
      )
    },
  },
  {
    Header: 'Channel',
    accessor: 'channel',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell({ row }: { row: any }) {
      const quote = row.original as QuoteResult
      const statusHelper = QuoteStatusHelper(quote.status)
      return (
        <Pill className="truncate" status={statusHelper.status()}>
          {statusHelper.label()}
        </Pill>
      )
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    sticky: 'right',
    Cell({
      row,
      showRefreshQuoteAlertModal,
    }: {
      row: any
      showRefreshQuoteAlertModal: (quoteId: string) => void
    }) {
      const { original } = row
      const { price, quote_id } = original as QuoteResult
      return (
        <div className="flex flex-row items-center h-full" style={PRICE_COLUMN_STYLE}>
          {Currency(price).format()}
          <div className="inline-flex w-auto h-8 ml-auto">
            <IconButton onClick={() => showRefreshQuoteAlertModal(quote_id)} scale="small">
              <Icon name="retry" size={16} title="Refresh Quote" />
            </IconButton>
            <IconButton className="ml-4" scale="small">
              <Link to={`/quotes/${quote_id}`}>
                <Icon name="peek" size={16} title="See quote details" />
              </Link>
            </IconButton>
          </div>
        </div>
      )
    },
  },
]

const StickyColumnsStyleDiv = styled.div`
  @media (max-width: 1485px) {
    [data-sticky-first-right-td] {
      box-shadow: -5px 0 5px #ccc;
      z-index: 0 !important;
    }
  }
`

function QuotesList({ className, filters }: QuotesListProps) {
  const { getSource, clearSource, cancelPending } = useCancelToken()

  const [data, setData] = useState<QuoteResult[]>([])
  const [loading, setLoading] = useState(false)
  const [controlledPageCount, setControlledPageCount] = useState(0)
  const [totalQuotes, setTotalQuotes] = useState(0)
  const [selectedQuoteId, setSelectedQuoteId] = useState<string>()
  const history = useHistory()
  const refreshQuoteModalHandler = useDialog({
    open: false,
  })

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: COLUMNS,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    useSticky,
  )

  const filtersRef = useRef(filters)
  const pageSizeRef = useRef(pageSize)

  const fetchQuotes = useCallback(
    ({ pageSize, pageIndex, filters }) => {
      async function fetch() {
        cancelPending()

        setLoading(true)

        const source = getSource()

        const quoteData: Record<string, unknown> = {
          limit: pageSize,
          offset: pageSize * pageIndex,
        }

        if (!isEmpty(filters)) {
          quoteData.filters = filters
        }

        const [error, response] = await getQuotes(quoteData, {
          cancelToken: source.token,
        })

        clearSource()

        if (error) {
          toast.error('We could not retrieve the quotes.')
          setData([])
          setControlledPageCount(0)
          setTotalQuotes(0)
        } else if (response) {
          const totalQuotes = get(response, 'data.count', 0)
          const newData = get(response, 'data.results', [])
          setData(newData)
          setControlledPageCount(Math.ceil(totalQuotes / pageSize))
          setTotalQuotes(totalQuotes)
        }

        setLoading(false)
      }
      if (!isEmpty(filters)) {
        fetch()
      }
    },
    [cancelPending, clearSource, getSource],
  )

  useEffect(() => {
    if (filters !== filtersRef.current || pageSize !== pageSizeRef.current) {
      filtersRef.current = filters
      pageSizeRef.current = pageSize

      if (pageIndex !== 0) {
        gotoPage(0)
        return
      }
    }

    fetchQuotes({ pageIndex, pageSize, filters })

    return function cancelFetchQuotes() {
      cancelPending()
    }
  }, [fetchQuotes, pageIndex, pageSize, filters, gotoPage, cancelPending])

  const showRefreshQuoteAlertModal = (quoteId: string) => {
    if (RefreshQuoteAlertModalHelper().shouldOpenModal()) {
      setSelectedQuoteId(quoteId)
      refreshQuoteModalHandler.show()
      return
    }

    history.push(`/quotes/refresh/${quoteId}`)
  }

  const handleCloseRefreshQuoteAlertModal = (result: boolean = false) => {
    refreshQuoteModalHandler.hide()
    if (!result) {
      return
    }

    history.push(`/quotes/refresh/${selectedQuoteId}`)
  }

  return (
    <>
      <RefreshQuoteAlertModal
        open={refreshQuoteModalHandler.open}
        onClose={handleCloseRefreshQuoteAlertModal}
      />
      <StickyColumnsStyleDiv className={clsx('relative', className)}>
        {loading && (
          <Loading className="absolute inset-x-0 bottom-0 z-10 justify-center px-3 py-6 opacity-75 top-6 bg-background" />
        )}
        <h2 className="flex flex-row items-center mb-4 text-xl font-bold text-neutral-darkest">
          {totalQuotes || 0} quotes
        </h2>
        <Table className="w-full" data-testid="quotes-list" {...getTableProps()}>
          <Table.Header>
            {headerGroups.map((headerGroup) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Table.Cell header {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row)
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    const isStop = ['Pickup', 'Delivery'].includes(String(cell.column.Header))

                    return (
                      <Table.Cell
                        className={clsx('text-sm', {
                          'pl-0 lg:pl-4 py-0': isStop,
                          'px-4 py-1': !isStop,
                        })}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell', { showRefreshQuoteAlertModal })}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
          {isEmpty(data) && (
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={COLUMNS.length}>
                  <Empty>No quotes to display.</Empty>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
        {!isEmpty(data) && (
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            className="w-full px-4 pt-6"
            currentPage={pageIndex}
            disabled={loading}
            onPageChange={(page) => {
              gotoPage(page)
              scrollToTop()
            }}
            onPageSizeChange={(pageSize) => {
              setPageSize(pageSize)
              scrollToTop()
            }}
            pageSize={pageSize}
            totalPages={pageOptions.length}
          />
        )}
      </StickyColumnsStyleDiv>
    </>
  )
}

export default memo(QuotesList, (prev, next) => isEqual(prev.filters, next.filters))
