import type { QuotesFilterFields, QuotesExtraFilterFields } from '../QuotesList/QuotesList.types'
import ToggleGroup, { ToggleGroupOptionValue } from 'components/ToggleGroup'
import { EquipmentType } from 'common/types/EquipmentType'
import Field from 'components/Field'
import Label from 'components/Label'
import Button from 'components/Button'
import { useState, useRef } from 'react'
import clsx from 'clsx'
import { RGDatePicker } from 'components/DatePicker'
import EquipmentTypeEntry from 'pages/Quotes/QuoteForm/EquipmentType'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import Select, { SingleSelectValue } from 'components/Select'
import UserSuggestion from 'pages/Margins/Users'
import QuoteStatusHelper from 'common/QuoteStatus.helpers'
import useClickOutside from 'hooks/useClickOutside'
import Icon from 'components/Icon'
import Popover from 'components/Popover'
import EventLike from 'common/types/EventLike'
import { Moment } from 'moment'
import { User } from 'common/types/User'

export interface MoreFilterProps {
  fields: QuotesFilterFields
  setBulk: (fields: Partial<QuotesFilterFields>) => void
}

const EQUIPMENT_TYPES = EquipmentTypeHelper.getAll(function getCustomProps(type: EquipmentType) {
  return {
    leading: <EquipmentTypeEntry mode="abbr" name={type} />,
    label: null,
  }
})

const initialExtraFilters: QuotesExtraFilterFields = {
  pickup_date: null,
  delivery_date: null,
  equipment_type: [],
  quoted_by: [],
  status: null,
}

export default function MoreFilters({ fields, setBulk }: MoreFilterProps) {
  const [open, setOpen] = useState(false)

  const { pickup_date, delivery_date, equipment_type, quoted_by, status } = fields

  const [extraFilters, setExtraFilters] = useState<QuotesExtraFilterFields>({
    pickup_date,
    delivery_date,
    equipment_type,
    quoted_by,
    status,
  })

  const handleChange = (target: {
    name: string
    value:
      | SingleSelectValue
      | ToggleGroupOptionValue
      | ToggleGroupOptionValue[]
      | User
      | User[]
      | Moment
      | null
  }) => {
    setExtraFilters((filters) => {
      return {
        ...filters,
        [target.name]: target.value,
      }
    })
  }

  const handleApply = () => {
    setBulk(extraFilters)
    setOpen(false)
  }

  const handleClear = () => {
    setExtraFilters(initialExtraFilters)
    setBulk(initialExtraFilters)
    setOpen(false)
  }

  const wrapperRef = useRef(null)
  useClickOutside(wrapperRef, () => {
    setOpen(false)
  })
  return (
    <div ref={wrapperRef} className={clsx('mt-7 relative rounded', { overshadow: open })}>
      <Button
        className="rounded-sm"
        onClick={() => {
          setOpen(!open)
        }}
        leading={<Icon name="settings" />}
      >
        more filters
      </Button>
      <Popover
        id="more-filters"
        className={clsx('p-6 w-96 flex flex-col min-w-min', {
          hidden: !open,
        })}
      >
        <h2 className="inline-flex flex-row text-lg font-bold text-neutral-darker mx-auto">
          More filters
        </h2>
        <div className="flex flex-row mt-6">
          <Field className="flex-1">
            <Label htmlFor="pickup_date">Pickup Date</Label>
            <RGDatePicker
              name="pickup_date"
              id="pickup_date"
              date={extraFilters.pickup_date}
              onChange={(e: EventLike<Moment | null>) => {
                if (e.target.name) {
                  handleChange({ name: e.target.name, value: e.target.value })
                }
              }}
            />
          </Field>
          <Field className="ml-4 flex-1">
            <Label htmlFor="delivery_date">Delivery Date</Label>
            <RGDatePicker
              date={extraFilters.delivery_date}
              onChange={(e: EventLike<Moment | null>) => {
                if (e.target.name) {
                  handleChange({ name: e.target.name, value: e.target.value })
                }
              }}
              name="delivery_date"
              id="delivery_date"
            />
          </Field>
        </div>
        <Field className="mt-8">
          <Label htmlFor="equipment_type">Equipment Type</Label>
          <ToggleGroup
            multiple
            options={EQUIPMENT_TYPES}
            name="equipment_type"
            id="equipment_type"
            onChange={(e: EventLike<ToggleGroupOptionValue | ToggleGroupOptionValue[] | null>) => {
              if (e.target.name) {
                handleChange({ name: e.target.name, value: e.target.value })
              }
            }}
            value={extraFilters.equipment_type}
          />
        </Field>

        <Field className="flex-1 mt-8">
          <Label>Sales representative</Label>
          <UserSuggestion
            multiple
            placeholder="All Users"
            name="quoted_by"
            onChange={(e: EventLike<User | User[] | null>) => {
              if (e.target.name) {
                handleChange({ name: e.target.name, value: e.target.value })
              }
            }}
            value={extraFilters.quoted_by}
            clearable
          />
        </Field>

        <Field className="flex-1 mt-8 mb-8">
          <Label>Quote status</Label>
          <Select
            multiple
            name="status"
            onChange={(e: EventLike<SingleSelectValue>) => {
              if (e.target.name) {
                handleChange({ name: e.target.name, value: e.target.value })
              }
            }}
            placeholder="ALL"
            value={extraFilters.status}
            options={QuoteStatusHelper.getAll()}
            clearable
          />
        </Field>
        <hr className="w-full mt-2 mb-2" />
        <div className="flex justify-end">
          <span className="my-auto text-sm font-bold cursor-pointer" onClick={handleClear}>
            Clear Filters
          </span>
          <Button className="ml-4" variant="primary" onClick={handleApply}>
            apply filters
          </Button>
        </div>
      </Popover>
    </div>
  )
}
