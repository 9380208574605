import { deleteShipper } from 'services/shippers/shippers.service'
import { toast } from 'components/Toast'
import { Dialog } from 'components/Dialog'
import Icon from 'components/Icon'
import Layout from 'components/Layout'
import Text from 'components/Text'
import { ShipperEntity } from 'common/types/ShipperEntity'

type ShipperDelProps = {
  shipper: ShipperEntity
  onClose: (refetch?: boolean) => void
}

export default function ShipperDelete({ shipper, onClose }: ShipperDelProps) {
  async function handleSubmit() {
    const [error] = await deleteShipper(shipper.id)
    if (error) {
      toast.error('We could not delete this shipper.')
    } else {
      toast.success('Shipper deleted with success.')
      onClose(true)
    }
  }

  return (
    <>
      <Dialog.Header>Delete shipper?</Dialog.Header>

      <Dialog.Body>
        <Layout.Stack space="s">
          <Layout.Group justify="center">
            <Icon name="warning" size={38} />
          </Layout.Group>

          <Text>Are you sure you want to delete this shipper? The action cannot be undone.</Text>
        </Layout.Stack>
      </Dialog.Body>

      <Dialog.ActionConfirm onConfirm={handleSubmit}>Yes, delete shipper</Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={() => onClose(false)} />
    </>
  )
}
