import React, { ChangeEvent, useState } from 'react'

import clsx from 'clsx'
import * as yup from 'yup'

import type { EquipmentType } from 'common/types/EquipmentType'
import Button from 'components/Button'
import EventLike from 'common/types/EventLike'
import Field from 'components/Field'
import getValueFromEvent from 'common/helpers/getValueFromEvent'
import Label from 'components/Label'
import Modal, { ModalProps } from 'components/Modal'
import ShipperSuggestion from '../../Quotes/QuoteForm/Shipper'
import LocationSuggestion from '../../Quotes/QuoteForm/Location/LocationSuggestion'
import TextField, { TextFieldProps } from 'components/TextField'
import ToggleGroup from 'components/ToggleGroup'
import { transformers } from './MarginCreationModal.helper'
import { genericTo as to } from 'common/helpers/awaitTo'
import set from 'common/helpers/set'
import ErrorHelper from 'common/Error.helpers'
import DateHelper from 'common/Date.helpers'
import { AddMarginRequest } from 'services/margins/margins.service'
// import LoadTypeHelper from 'common/LoadType.helpers'
import EquipmentTypeEntry from '../../Quotes/QuoteForm/EquipmentType'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import useFetchKMAs from '../hooks/useFetchKMAs'
import ReasonSelect from '../MarginsFilter/ReasonSelect'
import { DateRangePicker } from 'components/DatePicker'
import Checkbox from 'components/Checkbox'
import Tooltip from 'components/Tooltip'
import { isValidPartialPrice, isValidPrice } from 'common/Form.helpers'
import omit from 'common/helpers/omit'

import './MarginCreationModal.css'

export interface MarginCreationModalProps extends ModalProps {
  className?: string
  id: string
  open: boolean
  onSave: (margin: AddMarginRequest) => void
  onClose: () => void
}

// const MODES = LoadTypeHelper.getAll()

const EQUIPMENT_TYPES = EquipmentTypeHelper.getAll(function getCustomProps(type: EquipmentType) {
  return { leading: <EquipmentTypeEntry mode="icon-only" name={type} /> }
})

const schema = yup.object().shape({
  origin: yup.array().nullable(),
  destination: yup.array().nullable(),
  equipment_types: yup.array(yup.string()).min(1, 'At least one equipment type must be selected'),
  pickup_date_start: yup
    .string()
    .nullable()
    .required('Start date is required')
    .test('future-date', 'Start date cannot be in the past', (value) =>
      Boolean(DateHelper(value)?.is.sameOrAfter(DateHelper.today())),
    ),
  pickup_date_end: yup.string().nullable(),
  shipper: yup
    .object()
    .shape({
      id: yup.string().nullable(),
    })
    .nullable(),
  margin_value: yup
    .number()
    .notOneOf([0], 'Margin cannot be zero')
    .min(-100, 'Margin cannot be less than -100%')
    .required('Margin is required'),
  enable_maximum_value: yup.boolean(),
  max_margin_value: yup
    .string()
    .nullable()
    .when('enable_maximum_value', {
      is: true,
      then: yup
        .string()
        .nullable()
        .test(
          'maxDigits',
          'Must have 6 digits or less before the decimal point and 2 digits or less after decimal point',
          (value) => {
            return value ? isValidPrice(value) : false
          },
        )
        .required('Maximum value is required'),
    }),
  reason: yup.object().shape({
    code: yup.string().required('Reason is required'),
  }),
})

function getDefaultValues() {
  return {
    origin: [],
    destination: [],
    equipment_types: [],
    modes: [],
    pickup_date_start: null,
    pickup_date_end: null,
    shipper: null,
    margin_value: 0,
    reason: {
      code: '',
    },
    enable_maximum_value: false,
    max_margin_value: null,
  }
}

function MarginCreationModal({ className, onClose, onSave, ...others }: MarginCreationModalProps) {
  const [values, setValues] = useState(getDefaultValues())
  const [errors, setErrors] = useState({})

  function handleChange<T>(e: ChangeEvent<HTMLInputElement> | EventLike<T>) {
    const {
      target: { name },
    } = e
    const value = getValueFromEvent(e)

    setValues((prev) => ({
      ...set(prev, String(name), value),
    }))
  }

  function handleClose() {
    onClose()
    setErrors({})
    setValues(getDefaultValues())
  }

  const changeLocation = (name: string, value: any) => {
    setValues((prev: any) => ({
      ...prev,
      [name]: !value || Array.isArray(value) ? value : [...prev[name], value],
    }))
  }

  const handleChangeEnableMaximumValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setValues((prev) => ({
        ...prev,
        max_margin_value: null,
        enable_maximum_value: false,
      }))

      return
    }

    handleChange(e)
  }

  const handleChangeMaxMarginValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value === '' || isValidPartialPrice(value, false)) {
      handleChange(e)
    }
  }

  const onSubmit = async () => {
    const [errors, valid] = await to(
      schema.validate(values, {
        abortEarly: false,
      }),
    )

    if (valid) {
      const newMargin = transformers.reduce((margin, transformer) => {
        return transformer(margin)
      }, omit(values, ['enable_maximum_value'])) as unknown

      onSave(newMargin as AddMarginRequest)
      handleClose()
    } else {
      setErrors(errors)
    }
  }

  const errorData = ErrorHelper(errors)

  return (
    <Modal
      {...others}
      className={clsx('margin-creation-modal', className)}
      data-testid="margin-creation-modal"
    >
      <Modal.Header>New Margin</Modal.Header>
      <Modal.Body className="space-y-6 overflow-visible margin-creation-modal__body">
        <Field>
          <Label htmlFor="outbound" tip="Leave blank for all states">
            Outbound
          </Label>
          <LocationSuggestion
            multiple
            name="outbound"
            id="outbound"
            status={errorData.status('address')}
            placeholder="All States"
            options={{ types: 'regions' }}
            datasources={[useFetchKMAs]}
            value={values.origin}
            onChange={(e) => {
              changeLocation('origin', e.target.value)
            }}
          />
        </Field>
        <Field>
          <Label htmlFor="inbound" tip="Leave blank for all states">
            Inbound
          </Label>
          <LocationSuggestion
            multiple
            name="inbound"
            id="inbound"
            status={errorData.status('address')}
            placeholder="All States"
            options={{ types: 'regions' }}
            datasources={[useFetchKMAs]}
            value={values.destination}
            onChange={(e) => {
              changeLocation('destination', e.target.value)
            }}
          />
        </Field>
        <Field>
          <Label htmlFor="shipper" tip="Leave blank for all shippers">
            Shipper
          </Label>
          <ShipperSuggestion
            name="shipper"
            id="shipper"
            data-testid="input-shipper"
            placeholder="All Shippers"
            onChange={handleChange}
            value={values.shipper}
            clearable
          />
        </Field>

        <div className="flex">
          {/* <Field className="flex-1 lg:pr-4">
            <Label>Mode</Label>
            <ToggleGroup
              options={MODES}
              name="modes"
              onChange={handleChange}
              multiple
              value={values.modes}
            />
          </Field> */}
          <Field
            className="margin-creation-modal__equipment-type"
            error={errorData.get('equipment_types')}
            data-testid="equipment-type-toggle"
          >
            <Label required>Equipment Type</Label>
            <ToggleGroup
              options={EQUIPMENT_TYPES}
              name="equipment_types"
              onChange={handleChange}
              multiple
              value={values.equipment_types}
            />
          </Field>
        </div>

        <div className="flex">
          <Field className="flex-1 lg:pr-4" error={errorData.get('pickup_date_start')}>
            <Label required>Date Range</Label>
            <DateRangePicker
              isDayBlocked={(day) => {
                return Boolean(DateHelper(day.toString())?.is.before(DateHelper.today()))
              }}
              startDate={values.pickup_date_start}
              endDate={values.pickup_date_end}
              onChange={(event: any) => {
                const { startDate, endDate } = event.target.value
                setValues((prev) => ({
                  ...prev,
                  pickup_date_start: startDate,
                  pickup_date_end: endDate,
                }))
              }}
              name="date-range"
            />
          </Field>
          <Field className="lg:max-w-1/2" error={errorData.get('margin_value')}>
            <Label htmlFor="margin_value" required>
              Margin (%)
            </Label>
            <TextField
              type="number"
              name="margin_value"
              id="margin_value"
              value={values.margin_value}
              onChange={handleChange}
              status={errorData.status('margin_value') as TextFieldProps['status']}
              data-testid="input-margin"
            />
          </Field>
        </div>

        <div className="flex">
          <Field className="flex-1 lg:pr-2" error={errorData.get('enable_maximum_value')}>
            <div className="flex flex-row h-full">
              <Checkbox
                name="enable_maximum_value"
                onChange={handleChangeEnableMaximumValue}
                checked={values.enable_maximum_value}
                data-testid="enable_maximum_value-checkbox"
              >
                Enable maximum value
                <Tooltip message="The maximum value allows you to set a dollar limit for this margin.">
                  <span className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold rounded-full bg-neutral text-neutral-lightest">
                    ?
                  </span>
                </Tooltip>
              </Checkbox>
            </div>
          </Field>
          {values.enable_maximum_value && (
            <Field error={errorData.get('max_margin_value')}>
              <Label
                htmlFor="max_margin_value"
                tip="Set the maximum dollar amount so that the margin never exceeds it."
                required
              >
                Maximum Value ($)
              </Label>
              <TextField
                name="max_margin_value"
                id="max_margin_value"
                value={values.max_margin_value || ''}
                onChange={handleChangeMaxMarginValue}
                status={errorData.status('max_margin_value') as TextFieldProps['status']}
              />
            </Field>
          )}
        </div>

        <Field error={errorData.get('reason.code')} data-testid="rg-select__reason">
          <Label htmlFor="reason" required>
            Reason
          </Label>
          <ReasonSelect
            name="reason.code"
            id="reason"
            onChange={handleChange}
            status={errorData.status('reason.code')}
            value={values.reason.code}
          />
        </Field>
      </Modal.Body>
      <Modal.Footer className="flex flex-col space-y-2">
        <Button
          variant="primary"
          className="justify-center w-full"
          onClick={onSubmit}
          data-testid="button-add-margin"
        >
          Add Margin
        </Button>

        <Button className="justify-center w-full" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MarginCreationModal
