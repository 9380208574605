import Section from 'components/Section'
import Icon from 'components/Icon'
import { Tabs } from 'components/Tab'
import GeneralConfiguration from './GeneralConfiguration'
import AdvancedConfiguration from './AdvancedConfiguration'
import { useEffect, useState } from 'react'
import { WhiteLabelConfiguration as ConfigurationType } from 'common/types/WhiteLabelConfiguration'
import { RateProviderIdentifiers } from 'common/types/RateProvider'
import {
  createConfiguration,
  getConfiguration,
  updateConfiguration,
} from 'services/whiteLabel/whiteLabel.service'
import isEmpty from 'common/helpers/isEmpty'
import get from 'common/helpers/get'
import { toast } from 'components/Toast'
import { getRateProviders } from 'services/account/account.service'
import { Dialog, useDialog } from 'components/Dialog'
import Layout from 'components/Layout'
import Text from 'components/Text'
import { replaceEmptyValuesByNull } from 'common/Form.helpers'

const getNormalizedConfigFormData = (data: ConfigurationType): ConfigurationType => {
  const margins = data.margins
    ? Object.keys(data.margins).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: data.margins ? data.margins[curr as RateProviderIdentifiers] * 100 : undefined,
        }
      }, {})
    : {}

  const defaultMargin =
    data.margins && data.default_rate_provider ? data.margins[data.default_rate_provider] * 100 : 0

  return {
    ...data,
    customization: {
      success_text: get(data, 'customization.success_text', ''),
    },
    theme: data.theme || {
      primary_color: '',
      secondary_color: '',
      font_family: '',
    },
    margin: defaultMargin,
    margins: margins as Record<RateProviderIdentifiers, number>,
  }
}

const getConfigData = (configForm: Partial<ConfigurationType>) => {
  const margins = configForm.margins
    ? Object.keys(configForm.margins).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: parseFloat(
            (configForm.margins![curr as RateProviderIdentifiers] / 100).toFixed(4),
          ),
        }
      }, {})
    : {}

  const { margin, ...config } = configForm

  return {
    ...replaceEmptyValuesByNull(config),
    active: true,
    ...(config.theme && { theme: replaceEmptyValuesByNull(config.theme) }),
    ...(config.customization && { customization: replaceEmptyValuesByNull(config.customization) }),
    margins,
  }
}

const WhiteLabelConfiguration = () => {
  const [config, setConfig] = useState<ConfigurationType | undefined>()
  const [rateProviders, setRateProviders] = useState<RateProviderIdentifiers[]>([])
  const [isBusy, setIsBusy] = useState(false)
  const [tab, setTab] = useState<string>()
  const [showAdvancedTabBanner, setShowAdvancedTabBanner] = useState(false)
  const advancedTabModal = useDialog({ open: false })
  const [hasRules, setHasRules] = useState(false)

  useEffect(() => {
    const getWhiteLabelConfiguration = async () => {
      setIsBusy(true)
      const [, response] = await getConfiguration()
      setIsBusy(false)

      if (response) {
        if (!isEmpty(response.data)) {
          setConfig(getNormalizedConfigFormData(response.data))
        }
      } else {
        toast.error('We could not retrieve the configuration.')
      }
    }

    const getAccountRateProviders = async () => {
      setIsBusy(true)
      const [error, response] = await getRateProviders()
      setIsBusy(false)

      if (error) {
        setRateProviders([])
        toast.error('We could not retrieve the configuration.')
      } else {
        if (response) {
          setRateProviders(response.data)
        }
      }
    }

    getWhiteLabelConfiguration()
    getAccountRateProviders()
  }, [])

  const handleCreateConfiguration = async (configData: ConfigurationType) => {
    setIsBusy(true)

    const [, response] = await createConfiguration(getConfigData(configData))
    setIsBusy(false)

    if (response) {
      setConfig(getNormalizedConfigFormData(response.data))
      toast.success('Configuration created successfully')
      advancedTabModal.show()
    } else {
      toast.error('We could not create the configuration')
    }
  }

  const handleUpdateConfiguration = async (configData: Partial<ConfigurationType>) => {
    setIsBusy(true)
    const [, response] = await updateConfiguration(getConfigData(configData))
    setIsBusy(false)

    if (response) {
      setConfig(getNormalizedConfigFormData(response.data))
      toast.success('Configuration updated successfully')
    } else {
      toast.error('We could not update the configuration')
    }
  }

  useEffect(() => {
    if (tab === 'advanced') {
      setShowAdvancedTabBanner(false)
    }
  }, [tab])

  return (
    <>
      <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
        <Section
          title={
            <>
              <Icon name="question-circle" size={20} className="mr-2" /> What is EasyQuote?
            </>
          }
        >
          EasyQuote is a tech solution that allows you to embed the quote form in your own website,
          where shippers can get instant rates by using our pricing API but under your branding
          without any investment necessary to develop it. To start using it's easy, you just need to
          define your branding style, generate the code and paste it on your own website page.
        </Section>
      </div>
      <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
        <Section title="Generate your snippet">
          Adjust the configuration below to match your website's look and feel, after this, generate
          the code and add it to the page where the quote form will be displayed. And to make it
          even easier, once you paste the code, the future changes on this page will be
          automatically applied after you save, so you don't need to paste again.
          <Tabs className="flex flex-col mt-10" activeTab={tab} onTabChange={(tab) => setTab(tab)}>
            <Tabs.Items>
              <Tabs.Item name="general" default>
                GENERAL
              </Tabs.Item>
              {Boolean(config) && <Tabs.Item name="advanced">ADVANCED OPTIONS</Tabs.Item>}
            </Tabs.Items>
            <Tabs.Panels>
              <Tabs.Panel name="general">
                <GeneralConfiguration
                  savedConfiguration={config}
                  onCreateConfiguration={handleCreateConfiguration}
                  onUpdateConfiguration={handleUpdateConfiguration}
                  rateProviders={rateProviders}
                  isBusy={isBusy}
                  showAdvancedTabBanner={showAdvancedTabBanner}
                  onAdvancedBannerClick={() => setTab('advanced')}
                  hasRules={hasRules}
                />
              </Tabs.Panel>
              <Tabs.Panel name="advanced">
                {config && rateProviders.length > 1 && (
                  <AdvancedConfiguration
                    configuration={config}
                    rateProviders={rateProviders}
                    onUpdateConfiguration={handleUpdateConfiguration}
                    onChangeTab={(tabName) => setTab(tabName)}
                    setHasRules={setHasRules}
                  />
                )}
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </Section>
      </div>
      <Dialog open={advancedTabModal.open} scale="small" onOverlayClick={advancedTabModal.hide}>
        <Dialog.Header>More providers available</Dialog.Header>
        <Dialog.Body>
          <Layout.Group justify="center">
            <Icon name="opportunity" size={48} />
            <Text variant="body">
              Now you can use more than only one rate provider to EasyQuote, by defining Smart rules
              you can decide when to use other rate providers, bringing more business to you.
            </Text>
          </Layout.Group>
        </Dialog.Body>
        <Dialog.Actions>
          <Dialog.ActionConfirm
            onConfirm={() => {
              advancedTabModal.hide()
              setTab('advanced')
            }}
          >
            See more
          </Dialog.ActionConfirm>
          <Dialog.ActionCancel
            onCancel={() => {
              setShowAdvancedTabBanner(true)
              advancedTabModal.hide()
            }}
          >
            Maybe later
          </Dialog.ActionCancel>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}

export default WhiteLabelConfiguration
