import { Line } from './index'
import React from 'react'
import { useTheme } from 'styled-components'
import { RateGuideThemeInterface } from 'theming'

export interface RenderLineProps {
  dataKey: string
  isAnimationActive?: boolean
  color?: keyof RateGuideThemeInterface
}

const RenderLine = ({
  dataKey,
  isAnimationActive = false,
  color = 'color-neutral-darker'
}: RenderLineProps) => {
  const theme = useTheme() as RateGuideThemeInterface

  return (
    <Line
      type="linear"
      dataKey={dataKey}
      stroke={String(theme[color])}
      dot={{
        stroke: String(theme[color]),
        strokeWidth: 6,
        fill: String(theme[color]),
      }}
      activeDot={{
        stroke: String(theme[color]),
        strokeWidth: 2,
        fill: theme['color-neutral-white'],
      }}
      isAnimationActive={isAnimationActive}
    />
  )
}

export default RenderLine
