import { ChangeEvent, useCallback } from 'react'

import { isNil } from '@loadsmart/utils-object'
import { PU_LEAD_TIME } from './Stop.constants'
import { isPU, isDEL, getLabel, normalizeDate, isDayBlocked } from './Stop.helpers'
import type { Location } from '../../../../common/types/Location'
import type { Stop } from '../../../../common/types/Stop'
import { toast } from '../../../../components/Toast'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import DateHelper from '../../../../common/Date.helpers'
import type EventLike from '../../../../common/types/EventLike'
import Field from '../../../../components/Field'
import getValueFromEvent from '../../../../common/helpers/getValueFromEvent'
import Icon from '../../../../components/Icon'
import Label from '../../../../components/Label'
import LocationSuggestion from '../Location'
import set from '../../../../common/helpers/set'
import TimeField from '../../../../components/TimeField'
import ToggleGroup from '../../../../components/ToggleGroup'
import Status from '../../../../common/types/Status'
import Feedback from '../../../../components/Feedback'
import ErrorHelper, { TranslatedError } from '../../../../common/Error.helpers'

/* this import needs to be after Checkbox to prevent
 * mini-css-extract-plugin conflicting order.
 */
import { RGDatePicker } from '../../../../components/DatePicker'

export interface StopProps extends Stop {}

export const STOP_TYPE_OPTIONS = [
  {
    label: 'PU',
    value: 'pu',
    leading: <Icon name="marker-pin" title="Pickup" size={16} />,
  },
  {
    label: 'DEL',
    value: 'del',
    leading: <Icon name="flag" title="Delivery" size={16} />,
  },
]

const EMPTY_LOCATION = {
  address: '',
  city: '',
  state: '',
  country: '',
  zipcode: '',
  place_id: '',
}

interface StopEntryProps {
  id?: string
  stop: StopProps
  onChange?: (stop: StopProps | Partial<StopProps>) => void
  onRemove?: (stopID: string) => void
  label: string
  minDate?: string | null
  showDate?: boolean
  errors: TranslatedError
}

function LocationError({ errors }: { errors: ReturnType<typeof ErrorHelper> }) {
  if (errors.has('address')) {
    return <Feedback status={Status.Danger}>{errors.get('address')}</Feedback>
  }
  if (errors.has('city')) {
    return <Feedback status={Status.Danger}>{errors.get('city')}</Feedback>
  }
  return null
}

function StopEntry({
  label,
  stop,
  onChange,
  onRemove,
  minDate,
  errors,
  showDate = true,
  id = 'single',
}: StopEntryProps) {
  const handleChange = useCallback(
    function handleChange<Location>(e: ChangeEvent<HTMLInputElement> | EventLike<Location>) {
      const {
        target: { name },
      } = e
      const value = getValueFromEvent(e)

      const newStop = {
        ...stop,
      }

      set(newStop, String(name), value)

      onChange?.(newStop)
    },
    [onChange, stop],
  )

  const clearStopDate = useCallback(
    function clearStopDate(stop: StopProps) {
      onChange?.({
        time: undefined,
        window: undefined,
      })
    },
    [onChange],
  )

  const errorData = ErrorHelper(errors)

  return (
    <div
      className="flex flex-col items-start space-y-2 lg:space-x-4 lg:items-end lg:flex-row rg-stop"
      data-testid="stop"
    >
      <Field className="w-full rg-stop-location lg:max-w-1/2" data-testid={`rg-select__stop-${id}`}>
        <Label required>{getLabel(stop, label)}</Label>
        <LocationSuggestion
          query={stop.address}
          name=""
          status={errorData.status(['address', 'city'])}
          value={stop}
          onChange={(e) => {
            const location = getValueFromEvent(e) as Location

            onChange?.({
              ...(location ?? EMPTY_LOCATION),
            })
          }}
        />
        <LocationError errors={errorData} />
      </Field>

      <div className="flex flex-row items-end justify-start space-x-4">
        {stop.intermediary && (
          <Field className="justify-end">
            <ToggleGroup
              value={stop.type}
              onChange={handleChange}
              options={STOP_TYPE_OPTIONS}
              name="type"
              data-testid="stop-type"
            />
          </Field>
        )}

        {showDate && (
          <Field>
            <Label htmlFor={isPU(stop) ? 'pickup_date' : 'delivery_date'} required={isPU(stop)}>
              {isPU(stop) ? 'Pickup' : 'Delivery'} date
            </Label>
            <RGDatePicker
              id={isPU(stop) ? 'pickup_date' : 'delivery_date'}
              isDayBlocked={(day) => {
                if (minDate) {
                  return day.isSameOrBefore(DateHelper(minDate)?.value())
                }
                return isDayBlocked(day.toString())
              }}
              name="window.start"
              date={DateHelper(stop.window?.start)?.value()}
              status={errorData.status('window.start')}
              onChange={(e) => {
                const dateFromPicker = DateHelper(getValueFromEvent(e))

                if (!dateFromPicker) {
                  clearStopDate(stop)
                  return
                }
                if (stop.time) {
                  const [hours, minutes] = stop.time.split(':') || []
                  dateFromPicker?.set({
                    hours: Number(hours),
                    minutes: Number(minutes),
                  })
                }
                const { date, fixed } = normalizeDate(dateFromPicker)
                onChange?.({
                  ...stop,
                  time: fixed || Boolean(stop.time) ? date?.format('HH:mm') : undefined,
                  window: {
                    start: date?.format('UTC'),
                    end: date?.format('UTC'),
                  },
                })
              }}
            />
            {errorData.has('window.start') && (
              <Feedback status={Status.Danger}>{errorData.get('window.start')}</Feedback>
            )}
          </Field>
        )}

        {isPU(stop) && (
          <Field>
            <Label>Select time</Label>
            <TimeField
              name="time"
              value={stop.time}
              onChange={(e) => {
                const time = getValueFromEvent(e) as string
                const [hour, minute] = time.split(':')
                const date = DateHelper(stop.window?.start)?.set({
                  hour: Number(hour),
                  minute: Number(minute),
                })
                onChange?.({
                  ...stop,
                  time,
                  window: {
                    start: date?.format('UTC'),
                    end: date?.format('UTC'),
                  },
                })
              }}
              disabled={isNil(stop.window?.start)}
              onBlur={() => {
                const { date, fixed } = normalizeDate(DateHelper(stop.window?.start))

                if (fixed) {
                  toast.warning(`The time you defined was not within ${PU_LEAD_TIME} hours.`)
                  onChange?.({
                    ...stop,
                    time: date?.format('HH:mm'),
                    window: {
                      start: date?.format('UTC'),
                      end: date?.format('UTC'),
                    },
                  })
                }
              }}
            />
          </Field>
        )}

        {isDEL(stop) && (
          <Field className="justify-end">
            <Checkbox
              name="drop_trailer"
              onChange={handleChange}
              checked={stop.drop_trailer}
              data-testid="flag-drop-trailer"
            >
              Drop trailer
            </Checkbox>
          </Field>
        )}
      </div>

      {stop.intermediary && (
        <Button
          variant="secondary"
          onClick={() => {
            onRemove?.(stop.id)
          }}
          data-testid="delete-stop"
        >
          <Icon name="remove" title="Delete stop" size={16} />
        </Button>
      )}
    </div>
  )
}

StopEntry.defaultProps = {
  intermediary: false,
  removable: false,
}

export default StopEntry
