import clsx from 'clsx'

import ColorScheme from '../../common/types/ColorScheme'

export interface DividerProps {
  className?: string
  scheme?: ColorScheme
}

function Divider({ className, scheme }: DividerProps) {
  return (
    <hr
      className={clsx(
        'w-full border-t ',
        {
          'border-neutral-light': scheme === 'light',
          'border-neutral-dark': scheme === 'dark',
        },
        className,
      )}
    />
  )
}

Divider.defaultProps = {
  scheme: 'light',
}

export default Divider
