import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { AuthLayout } from 'app/AuthLayout'
import ResetPasswordForm from './ResetPasswordForm'
import PasswordReset from './PasswordReset'

interface ResetPasswordProps {
  isNewUser?: boolean
}

const ResetPassword = ({ isNewUser }: ResetPasswordProps) => {
  const [isPasswordReset, setIsPasswordReset] = React.useState(false)
  const history = useHistory()

  return (
    <AuthLayout>
      {!isPasswordReset ? (
        <ResetPasswordForm setIsPasswordReset={setIsPasswordReset} isNewUser={isNewUser} />
      ) : (
        <PasswordReset isNewUser={isNewUser} />
      )}
      {!isNewUser ? (
        <div className="flex flex-col px-8">
          <Button
            type="button"
            variant="secondary"
            className="mt-4"
            onClick={() => history.push('/login')}
          >
            Back to Login
          </Button>
        </div>
      ) : null}
    </AuthLayout>
  )
}

export default ResetPassword
