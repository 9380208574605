function LSNumber(value: number | string) {
  const valueAsNumber = Number(value) || 0

  return {
    /**
     * Get the provided value as `number`.
     */
    value() {
      return valueAsNumber
    },
    /**
     * Get the provided value as `string`, with 2 (default) digits after the decimal point.
     */
    format({ fractionDigits = 2 } = {}) {
      return valueAsNumber.toFixed(fractionDigits)
    },
  }
}

export default LSNumber
