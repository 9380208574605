import { Fragment, useCallback, useEffect, useState, ChangeEvent } from 'react'

import { MarginResult, MarginStatus } from '../../../common/types/Margin'
import { setStatus } from '../../../services/margins/margins.service'
import { toast } from '../../../components/Toast'
import EventLike from '../../../common/types/EventLike'
import Loading from '../../../components/Loading'
import ToggleSwitch from '../../../components/ToggleSwitch'
import useCancelToken from '../../../hooks/useCancelToken'

export interface StatusSwitchProps {
  margin: MarginResult
  className?: string
  onToggle?: (event: EventLike<MarginStatus>) => void
}

function StatusSwitch({ margin, onToggle, ...others }: StatusSwitchProps) {
  const { getSource, clearSource, cancelPending } = useCancelToken()
  const [loading, setLoading] = useState(false)

  const toggleStatus = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      async function toggle() {
        cancelPending()

        setLoading(true)

        const source = getSource()
        const newStatus = e.target.checked ? 'ENABLED' : 'DISABLED'
        const performedOperation = newStatus.toLowerCase()

        const [error] = await setStatus(margin.id, newStatus, {
          cancelToken: source.token,
        })

        clearSource()

        setLoading(false)

        if (error) {
          toast.error(`We could not ${performedOperation} the margin.`)
        } else {
          toast.success(`Margin ${performedOperation} successfully.`)
          onToggle?.({ target: { value: newStatus } })
        }
      }

      toggle()
    },
    [cancelPending, clearSource, getSource, margin, onToggle],
  )

  useEffect(() => {
    return function cancelSetStatus() {
      cancelPending()
    }
  }, [cancelPending])

  return (
    <Fragment>
      {loading && (
        <Loading
          mode="dots"
          className="absolute inset-x-0 inset-y-0 z-10 items-center justify-center bg-neutral-lightest"
        />
      )}
      <ToggleSwitch
        data-testid={`margin-switch-${margin.id}`}
        name={`margin-switch-${margin.id}`}
        {...others}
        active={margin.status === 'ENABLED'}
        onToggle={toggleStatus}
        disabled={loading}
      />
    </Fragment>
  )
}

export default StatusSwitch
