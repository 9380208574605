import { ReactNode } from 'react'
import clsx from 'clsx'

import Status from '../../common/types/Status'
import getConditionalClasses from '../../common/helpers/getConditionalClasses'

export interface FeedbackProps {
  className?: string
  children?: ReactNode
  status?: Status
}

const combine = getConditionalClasses({
  scheme: ['light', 'dark'],
  status: ['neutral', 'danger', 'success'],
})

function Feedback(props: FeedbackProps) {
  const { status, className, children, ...others } = props
  const when = combine(props)

  return (
    <div
      {...others}
      role="status"
      aria-live="polite"
      className={clsx(
        'flex items-center justify-start text-xs px-2 py-1',
        {
          'text-dark bg-neutral-lighter': when('neutral'),
        },
        {
          'text-danger-dark bg-danger-light': when(['light', 'danger']),
          'text-danger-light bg-danger-dark': when(['dark', 'danger']),
        },
        {
          'text-success-dark bg-success-light': when(['light', 'success']),
          'text-success-light bg-success-dark': when(['dark', 'success']),
        },
        className,
      )}
      data-testid="feedback"
    >
      {children}
    </div>
  )
}

Feedback.defaultProps = {
  status: Status.Neutral,
  scheme: 'light',
}

export default Feedback
