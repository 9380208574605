import type { QuotesFilterFields, PartialQuotesFilter } from '../QuotesList/QuotesList.types'
import Section from 'components/Section'
import ToggleGroup from 'components/ToggleGroup'
import Field from 'components/Field'
import Label from 'components/Label'
import ShipperSuggestion from 'pages/Quotes/QuoteForm/Shipper'
import LocationSuggestion from '../QuoteForm/Location'
import useFilter from 'hooks/useFilter'
import { initialFields } from './QuotesFilter.helpers'
import { useState } from 'react'
import './QuotesFilter.css'
import { DateRangePickerPopup } from 'components/DatePicker'
import { API_DATE_FORMAT } from 'common/constants'
import moment from 'moment'
import MoreFilters from './MoreFilters'

export interface QuoteFilterProps {
  onChange: (fields: PartialQuotesFilter) => void
}

type CreationRanges = 'day' | 'week' | 'month' | 'months' | 'custom'

function Filters({ onChange }: QuoteFilterProps) {
  const { fields, setField, setBulkFields } = useFilter<QuotesFilterFields>({
    fields: initialFields,
    onChange,
    options: {
      debounce: 500,
    },
  })
  const [showCustomRangePicker, setShowCustomRangePicker] = useState(false)
  const [selectedRange, setSelectedRange] = useState<{
    toggle: CreationRanges
    filter: CreationRanges
  }>({ toggle: 'month', filter: 'month' })

  const setCreatedAt = setField('created_at')

  const handleRangeApply = (range: { start: string; end: string }) => {
    setCreatedAt(range)
    setShowCustomRangePicker(false)
  }

  const handleRangeChange = (range: CreationRanges) => {
    switch (range) {
      case 'day':
        setCreatedAt({ start: moment().format(API_DATE_FORMAT) })
        break
      case 'week':
        setCreatedAt({ start: moment().subtract(7, 'd').format(API_DATE_FORMAT) })
        break
      case 'month':
        setCreatedAt({ start: moment().subtract(1, 'months').format(API_DATE_FORMAT) })
        break
      case 'months':
        setCreatedAt({ start: moment().subtract(3, 'months').format(API_DATE_FORMAT) })
        break
    }
    setSelectedRange({
      toggle: range,
      filter: selectedRange.toggle,
    })
    setShowCustomRangePicker(range === 'custom')
  }

  return (
    <>
      <div className="flex flex-col mt-8 p-6 h-full rounded-sm shadow bg-neutral-lightest w-full">
        <Section title="Filter quotes">
          <div className="flex flex-wrap mt-4">
            <Field className="w-max  mr-7">
              <Label htmlFor="created">Quotes created</Label>
              <ToggleGroup
                id="created"
                name="created"
                aria-label="created"
                className="w-max"
                value={selectedRange.toggle}
                onChange={(e) => handleRangeChange(e.target.value as CreationRanges)}
              >
                <ToggleGroup.Option value="day">today</ToggleGroup.Option>
                <ToggleGroup.Option value="week">7 days</ToggleGroup.Option>
                <ToggleGroup.Option value="month">30 days</ToggleGroup.Option>
                <ToggleGroup.Option value="months">3 months</ToggleGroup.Option>
                <ToggleGroup.Option
                  value="custom"
                  leading={
                    <div data-testid="custom-range-picker">
                      {showCustomRangePicker && (
                        <DateRangePickerPopup
                          open={showCustomRangePicker}
                          onApply={handleRangeApply}
                          reset={!(selectedRange.toggle === 'custom')}
                          defaultRange={fields.created_at}
                          onCancel={() => {
                            handleRangeChange(selectedRange.filter)
                            setShowCustomRangePicker(false)
                          }}
                        />
                      )}
                    </div>
                  }
                >
                  custom
                </ToggleGroup.Option>
              </ToggleGroup>
            </Field>
            <Field className="flex-1 mr-7 w-36">
              <Label htmlFor="shipper">Shipper</Label>
              <ShipperSuggestion
                multiple
                name="shipper"
                id="shipper"
                data-testid="input-shippers"
                placeholder="All Shippers"
                onChange={setField('shipper')}
                value={fields.shipper}
                clearable
              />
            </Field>
            <Field className="flex-1 mr-7 w-36">
              <Label htmlFor="pickup">Pickup location</Label>
              <LocationSuggestion
                multiple
                name="pickup"
                id="pickup"
                placeholder="All Locations"
                options={{ types: 'cities' }}
                value={fields.pickup}
                onChange={setField('pickup')}
              />
            </Field>
            <Field className="flex-1  mr-7 w-36">
              <Label htmlFor="delivery">Delivery location</Label>
              <LocationSuggestion
                multiple
                name="delivery"
                id="delivery"
                placeholder="All Locations"
                options={{ types: 'cities' }}
                value={fields.delivery}
                onChange={setField('delivery')}
              />
            </Field>
            <MoreFilters fields={fields} setBulk={setBulkFields} />
          </div>
        </Section>
      </div>
    </>
  )
}

export default Filters
