import getEnvSubdomain from './helpers/getEnvSubdomain'

function LoadsmartURL() {
  const baseUrl = `https://${getEnvSubdomain()}loadsmart.com/`

  return {
    base: baseUrl,
    quote: {
      checkout: (id: string) => `${baseUrl}quote/#/checkout/${id}`,
    },
  }
}

export default LoadsmartURL
