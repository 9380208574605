import * as yup from 'yup'

export const LTLQuoteBaseValidationSchema = {
  equipment_type: yup.string().required(),
  shipper: yup.object().typeError('Invalid Shipper').shape({
    id: yup.string().required(),
  }),
  stops: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().oneOf(['pu', 'del']),
        address: yup.string().required('An address must be provided'),
        city: yup.string().required('The address must have a city'),
        window: yup
          .object()
          .shape({
            start: yup.string().notRequired().nullable(),
            end: yup.string().notRequired().nullable(),
          })
          .when('type', {
            is: 'pu',
            then: yup.object().shape({
              start: yup.string().required('Required field').nullable(),
              end: yup.string().required('Required field').nullable(),
            }),
          }),
      }),
    )
    .length(2),
  items: yup
    .array()
    .of(
      yup.object().shape({
        description: yup.string().required('Required'),
        hazmat: yup.boolean().required(),
        hazmat_class: yup.string().when('hazmat', {
          is: true,
          then: yup.string().required('Required'),
        }),
        ltl_item: yup.object().shape({
          width: yup.number().required('Required'),
          height: yup.number().required('Required'),
          length: yup.number().required('Required'),
          weight: yup.number().required('Required'),
          density: yup.number().required('Required'),
          stackable: yup.boolean().required('Required'),
          count: yup.number().required('Required'),
          package_type: yup.string().required('Required'),
          classification: yup.string().required('Required'),
          ltl_excessive_length_class: yup.string(),
          nmfc_code: yup.string(),
        }),
      }),
    )
    .required('Must have commodities') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'At least one commodity'),
}

export const LTLQuoteValidationSchema = yup.object().shape(LTLQuoteBaseValidationSchema)



