import { useCallback, useRef, useState } from 'react'

import cond from 'common/helpers/cond'
import matches from 'common/helpers/matches'
import isEmpty from 'common/helpers/isEmpty'
import Breadcrumbs from 'components/Breadcumbs'
import Header from 'components/Header'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Icon from 'components/Icon'
import UserForm from './UserForm'
import UserDelete from './UserDelete'
import UsersList from './UsersList'
import UsersFilters from './UsersFilters'
import { UsersFiltersFields } from './UsersList.types'
import { Dialog } from 'components/Dialog'

import type { UserEntity } from 'common/types/UserEntity'

type UserManagementAction = 'ADD' | 'EDIT' | 'DELETE'

const Users = () => {
  const [showDialog, setShowDialog] = useState<UserManagementAction>()
  const [selectedUser, setSelectedUser] = useState<UserEntity>()
  const [filters, setFilters] = useState<UsersFiltersFields>()
  const usersListRef = useRef<{ fetchUserList: () => void }>()

  const handleFilterChange = useCallback(
    (fields: UsersFiltersFields) => {
      setFilters(isEmpty(fields) ? undefined : fields)
    },
    [setFilters],
  )

  const handleUserAction = (action: UserManagementAction, user?: UserEntity) => {
    setShowDialog(action)
    setSelectedUser(user)
  }

  const handleClose = (refetch?: boolean) => {
    if (refetch) usersListRef.current?.fetchUserList()

    setSelectedUser(undefined)
    setShowDialog(undefined)
  }

  return (
    <>
      <Header>
        <Layout.Group justify="space-between" className="w-full">
          <Breadcrumbs
            entries={[
              {
                label: 'Quotes Dashboard',
                url: '/quotes',
              },
              {
                label: 'Users',
                active: true,
              },
            ]}
          />
          <Button variant="primary" onClick={() => handleUserAction('ADD')}>
            <Layout.Group space="xs" align="center">
              <Icon name="add" />
              Add User
            </Layout.Group>
          </Button>
        </Layout.Group>
      </Header>
      <UsersFilters onChange={handleFilterChange} />
      <UsersList
        filters={filters}
        onEditUser={(user) => handleUserAction('EDIT', user)}
        onDeleteUser={(user) => handleUserAction('DELETE', user)}
        ref={usersListRef}
      />
      <Dialog open={Boolean(showDialog)} onOverlayClick={() => handleClose(false)} scale="small">
        {cond([
          [matches('ADD'), () => <UserForm onClose={handleClose} />],
          [matches('EDIT'), () => <UserForm user={selectedUser} onClose={handleClose} />],
          [matches('DEL'), () => <UserDelete user={selectedUser!} onClose={handleClose} />],
        ])(showDialog)}
      </Dialog>
    </>
  )
}

export default Users
