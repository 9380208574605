import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import tokenManager from '../common/tokenManager'

import { loadSettings } from 'features/settings/settings.actions'
import CurrentUserHelper from 'common/CurrentUser.helper'
import { logout } from 'features/auth/auth.actions'

interface Props extends RouteProps {
  component?: React.ComponentType
  roles?: string[]
}

function PrivateRoute({ component, roles, render, children, ...rest }: Props) {
  const dispatch = useDispatch()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!tokenManager.getToken()) {
          return <Redirect to={`/login?next=${props.location.pathname || '/'}`} />
        }

        if (CurrentUserHelper().sessionExpired()) {
          dispatch(logout())
          return <Redirect to="/login" />
        }

        dispatch(loadSettings())

        if (component) {
          return React.createElement(component)
        }

        if (render) {
          return render(props)
        }

        if (children) {
          return children
        }
      }}
    />
  )
}

export default PrivateRoute
