import { useCallback, useRef, useState } from 'react'
import cond from 'common/helpers/cond'
import matches from 'common/helpers/matches'
import isEmpty from 'common/helpers/isEmpty'
import Breadcrumbs from 'components/Breadcumbs'
import Header from 'components/Header'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Icon from 'components/Icon'
import ShipperForm from './ShipperForm'
import ShipperDel from './ShipperDelete'
import ShippersList from './ShippersList'
import ShippersFilters from './ShippersFilters'
import { ShippersFiltersFields } from './ShippersList.types'
import { Dialog } from 'components/Dialog'

import { ShipperEntity } from 'common/types/ShipperEntity'

type ShipperManagementAction = 'ADD' | 'EDIT' | 'DELETE'

const Shippers = () => {
  const [showDialog, setShowDialog] = useState<ShipperManagementAction>()
  const [selectedShipper, setSelectedShipper] = useState<ShipperEntity>()
  const [filters, setFilters] = useState<ShippersFiltersFields>()
  const shippersListRef = useRef<{ fetchShippersList: () => void }>()

  const handleFilterChange = useCallback(
    (fields: ShippersFiltersFields) => {
      setFilters(isEmpty(fields) ? undefined : fields)
    },
    [setFilters],
  )

  const handleShipperAction = (action: ShipperManagementAction, shipper?: ShipperEntity) => {
    setShowDialog(action)
    setSelectedShipper(shipper)
  }

  const handleClose = (refetch?: boolean) => {
    if (refetch) shippersListRef.current?.fetchShippersList()

    setSelectedShipper(undefined)
    setShowDialog(undefined)
  }

  return (
    <>
      <Header>
        <Layout.Group justify="space-between" className="w-full">
          <Breadcrumbs
            entries={[
              {
                label: 'Quotes Dashboard',
                url: '/quotes',
              },
              {
                label: 'Shippers',
                active: true,
              },
            ]}
          />
          <Button variant="primary" onClick={() => handleShipperAction('ADD')}>
            <Layout.Group space="xs" align="center">
              <Icon name="add" />
              Add Shipper
            </Layout.Group>
          </Button>
        </Layout.Group>
      </Header>
      <ShippersFilters onChange={handleFilterChange} />
      <ShippersList
        filters={filters}
        onEditShipper={(shipper) => handleShipperAction('EDIT', shipper)}
        onDeleteShipper={(shipper) => handleShipperAction('DELETE', shipper)}
        ref={shippersListRef}
      />
      <Dialog open={Boolean(showDialog)} onOverlayClick={() => handleClose(false)} scale="small">
        {cond([
          [matches('ADD'), () => <ShipperForm onClose={handleClose} />],
          [matches('EDIT'), () => <ShipperForm shipper={selectedShipper} onClose={handleClose} />],
          [matches('DEL'), () => <ShipperDel shipper={selectedShipper!} onClose={handleClose} />],
        ])(showDialog)}
      </Dialog>
    </>
  )
}

export default Shippers
