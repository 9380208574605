import type { SuggestionOption } from 'components/Suggestion'
import type { OptionCandidate } from 'components/Select'
import { genericTo as to } from 'common/helpers/awaitTo'
import { toast } from 'components/Toast'
import { getLocationGeocode } from './useFetchLocations'
import GeocodingCache from './GeocodingCache'

const cache = GeocodingCache()

export async function getLocationByValueType(entry: SuggestionOption) {
  if (entry._type === 'KMA') {
    return {
      kma: entry.value as string,
      address: entry.label,
    }
  }

  const placeID = String(entry.value || '')
  const key = placeID || entry.label

  if (cache.has(key)) {
    return cache.get(key)
  }

  const [error, data] = await to(
    getLocationGeocode({
      place_id: placeID,
      address: entry.label,
    }),
  )

  if (error) {
    toast.error(`We could not retrieve the location details for ${entry.label}`)
  } else {
    cache.add(key, data)
    return data as Location
  }

  return null
}

export function filterSelectOptions(candidate: OptionCandidate, input?: string | null) {
  if (!input) return false

  if (!candidate.data._type) return true

  return (
    RegExp(input, 'gi').test(candidate.label) || RegExp(input, 'gi').test(String(candidate.value))
  )
}
