import {
  ForwardedRef,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'

import { Quote, Quoteline } from '../../../../common/types/Quote'
import { sumQuotelines, partitionByType } from './PriceBreakdown.helpers'
import { useModal } from '../../../../components/Modal'
import { IconButton } from '../../../../components/Button'
import CurrencyHelper from '../../../../common/Currency.helpers'
import Divider from '../../../../components/Divider'
import Icon from '../../../../components/Icon'
import isEmpty from '../../../../common/helpers/isEmpty'
import NumberHelper from '../../../../common/Number.helpers'
import PriceAdjustmentModal from './PriceAdjustmentModal'
import Table from '../../../../components/Table'
import toArray from '../../../../common/helpers/toArray'
import PriceFeedbackWrapper from '../PriceFeedback/PriceFeedbackWrapper'
import QuoteHelper from 'common/Quote.helpers'

export interface PriceBreakdownProps {
  className?: string
  editable?: boolean
  quotelines?: Quoteline[]
  mileage: number
  adjustmentInfo?: string
  onChange?: (quoteline: Quoteline) => void
  onRemove?: (quotelineID: string) => void
  quote?: Quote
  showPriceFeedback?: boolean
}

interface ColumnRenderProps {
  quoteline: Quoteline
}

export const COLUMNS = [
  {
    Header: 'Item',
    id: 'label',
    style: { width: '15%' },
    render({ quoteline }: ColumnRenderProps) {
      return <span className="h-full px-2 font-semibold truncate">{quoteline.label}</span>
    },
  },
  {
    Header: 'Description',
    id: 'description',
    render({ quoteline }: ColumnRenderProps) {
      return <span className="h-full px-2 truncate">{quoteline.description}</span>
    },
  },
  {
    Header: <span className="flex justify-end">Rate</span>,
    id: 'rate',
    style: { width: '10%', minWidth: '150px' },
    render({ quoteline }: ColumnRenderProps) {
      return (
        <span className="flex flex-row items-center justify-end h-full px-2">
          {CurrencyHelper(Number(quoteline.unit_price)).format()}
        </span>
      )
    },
  },
  {
    Header: <span className="flex justify-end">Quantity</span>,
    id: 'quantity',
    style: { width: '10%', minWidth: '80px' },
    render({ quoteline }: ColumnRenderProps) {
      return (
        <span className="flex flex-row items-center justify-end h-full px-2">
          {NumberHelper(quoteline.quantity).format()}
        </span>
      )
    },
  },
  {
    Header: <span className="flex justify-end">Line Total</span>,
    id: 'total',
    style: { width: '15%' },
    render({ quoteline }: ColumnRenderProps) {
      return (
        <span className="flex flex-row items-center justify-end h-full px-2 font-bold text-neutral-darkest">
          {CurrencyHelper(Number(quoteline.quantity) * Number(quoteline.unit_price)).format()}
        </span>
      )
    },
  },
]

interface AdjustmentColumnRenderProps extends ColumnRenderProps {
  editable?: boolean
  onChange?: (quoteline: Quoteline) => void
  onRemove?: (quotelineID: string) => void
}

export const ADJUSTMENT_COLUMNS = [
  {
    Header: 'Item',
    id: 'label',
    style: { width: '15%' },
    render({ quoteline }: AdjustmentColumnRenderProps) {
      return <span className="h-full px-2 font-semibold truncate">{quoteline.label}</span>
    },
  },
  {
    Header: 'Description',
    id: 'description',
    render({ quoteline }: AdjustmentColumnRenderProps) {
      return <span className="h-full px-2 truncate">{quoteline.description}</span>
    },
  },
  {
    Header: 'Total',
    id: 'value',
    style: { width: '15%', minWidth: '180px' },
    render({ quoteline, editable, onChange, onRemove }: AdjustmentColumnRenderProps) {
      const price = CurrencyHelper(Number(quoteline.unit_price))

      return (
        <div className="flex flex-row items-center justify-end h-full px-2">
          <span
            className={clsx('font-bold ', {
              'text-primary-500': price.value() > 0,
              'text-danger': price.value() < 0,
            })}
          >
            {price.format()}
          </span>

          {editable && (
            <div className="flex ml-auto mr-2 space-x-2">
              <IconButton
                onClick={() => {
                  onChange?.(quoteline)
                }}
              >
                <Icon name="edit" title="Edit adjustment" size={16} />
              </IconButton>
              <IconButton
                onClick={() => {
                  onRemove?.(String(quoteline.quoteline_id))
                }}
              >
                <Icon name="remove" title="Delete adjustment" size={16} />
              </IconButton>
            </div>
          )}
        </div>
      )
    },
  },
]

export function Total({
  className,
  colorful = false,
  size = 'default',
  direction = 'horizontal',
  label,
  value,
}: {
  className?: string
  colorful?: boolean
  direction?: 'horizontal' | 'vertical'
  size?: 'small' | 'default'
  label: string
  value: number
}) {
  return (
    <p
      className={clsx(
        'flex px-2',
        {
          'justify-end': direction === 'horizontal',
          'flex-col items-end justify-between': direction === 'vertical',
        },
        {
          'text-xs lg:text-sm': size === 'small',
          'text-sm lg:text-base': size === 'default',
        },
        className,
      )}
    >
      <span className="flex-1 inline-block truncate sm:flex-none">{label}</span>
      <b
        className={clsx(
          'text-right font-bold text-sm lg:text-base flex-1 sm:flex-none',
          {
            'text-neutral-darkest': !colorful || value === 0,
            'text-primary-500': colorful && value > 0,
            'text-danger': colorful && value < 0,
          },
          {
            'lg:ml-4': direction === 'horizontal',
          },
        )}
      >
        {CurrencyHelper(value).format()}
      </b>
    </p>
  )
}

function MarginPriceBreakdown({
  className,
  quotelines,
  ...others
}: {
  className?: string
  quotelines: Quoteline[]
}) {
  if (isEmpty(quotelines)) {
    return null
  }

  return (
    <Fragment>
      <Divider className="my-4" />

      <Table className={clsx('w-full', className)} {...others}>
        <Table.Body>
          {(quotelines || []).map(function renderQuoteline(quoteline: Quoteline) {
            return (
              <Table.Row key={quoteline.quoteline_id}>
                {COLUMNS.map((column) => {
                  return (
                    <Table.Cell
                      key={column.id}
                      className={clsx('text-sm', {})}
                      style={column.style}
                    >
                      {column.render({ quoteline })}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>

      <Total className="mt-4" label="Quote margins" value={sumQuotelines(quotelines || [])} />
    </Fragment>
  )
}

function ExtraPriceBreakdown({
  className,
  editable,
  quotelines,
  onChange,
  onRemove,
  ...others
}: {
  className?: string
  editable?: boolean
  quotelines: Quoteline[]
  onChange?: (quoteline: Quoteline) => void
  onRemove?: (quotelineID: string) => void
}) {
  if (isEmpty(quotelines)) {
    return null
  }

  return (
    <Fragment>
      <Divider className="my-4" />

      <Table className={clsx('w-full', className)} {...others}>
        <Table.Body>
          {(quotelines || []).map(function renderAdjustment(quoteline: Quoteline) {
            return (
              <Table.Row key={quoteline.quoteline_id}>
                {ADJUSTMENT_COLUMNS.map((column) => {
                  return (
                    <Table.Cell
                      key={column.id}
                      className={clsx('text-sm', {})}
                      style={column.style}
                    >
                      {column.render({ quoteline, editable, onChange, onRemove })}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>

      <Total
        colorful
        className="mt-4"
        label="Price adjustments"
        value={sumQuotelines(quotelines || [])}
      />
    </Fragment>
  )
}

export interface PriceBreakdownOperations {
  openCreateAdjustmentModal: () => void
  closeCreateAdjustmentModal: () => void
}

function PriceBreakdown(
  {
    className,
    editable = false,
    mileage,
    quotelines,
    adjustmentInfo,
    onChange,
    onRemove,
    quote,
    showPriceFeedback,
  }: PriceBreakdownProps,
  ref: ForwardedRef<PriceBreakdownOperations>,
) {
  const { id: modalID, open, show, hide } = useModal({ open: false })
  const [currentQuoteline, setCurrentQuoteline] = useState<Quoteline | undefined>()
  const [quotelinesByType, setQuotelinesByType] = useState(() => partitionByType(quotelines || []))
  const [total, setTotal] = useState(0)

  useEffect(
    function updateQuotelinesByType() {
      const quotelinesByType = partitionByType(quotelines || [])

      setQuotelinesByType(quotelinesByType)
      setTotal(sumQuotelines(quotelines || []))
    },
    [quotelines],
  )

  const openAdjustmentModal = useCallback(
    function openAdjustmentModal() {
      setCurrentQuoteline(undefined)
      show()
    },
    [show],
  )

  const closeAdjustmentModal = useCallback(
    function closeAdjustmentModal() {
      setCurrentQuoteline(undefined)
      hide()
    },
    [hide],
  )

  const openUpdateAdjustmentModal = useCallback(
    function openUpdateAdjustmentModal(quoteline: Quoteline) {
      setCurrentQuoteline(quoteline)
      show()
    },
    [show],
  )

  const operationsRef = useRef({
    openCreateAdjustmentModal: openAdjustmentModal,
    closeCreateAdjustmentModal: closeAdjustmentModal,
  })

  useImperativeHandle(ref, () => operationsRef.current)

  return (
    <div className={clsx('w-full flex flex-col', className)}>
      <PriceAdjustmentModal
        adjustment={currentQuoteline}
        infoText={adjustmentInfo}
        onClose={(quoteline) => {
          if (quoteline) {
            onChange?.(quoteline)
          }

          closeAdjustmentModal()
        }}
        id={modalID}
        open={open}
        className="w-96"
        allowNegative
      />

      <Table className={clsx('w-full', className)} data-testid="price-breakdown-others">
        <Table.Header>
          <Table.Row>
            {COLUMNS.map((column) => (
              <Table.Cell key={column.id} header style={column.style}>
                {column.Header}
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {[...toArray(quotelinesByType.others), ...toArray(quotelinesByType.fsc)].map(
            function renderQuoteline(quoteline: Quoteline) {
              return (
                <Table.Row key={quoteline.quoteline_id}>
                  {COLUMNS.map((column) => {
                    return (
                      <Table.Cell key={column.id} className="text-sm">
                        {column.render({ quoteline })}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            },
          )}
        </Table.Body>
      </Table>

      <Total
        className="mt-4"
        label=""
        value={sumQuotelines([
          ...toArray(quotelinesByType.others),
          ...toArray(quotelinesByType.fsc),
        ])}
      />

      <MarginPriceBreakdown
        className="w-full"
        data-testid="price-breakdown-margins"
        quotelines={quotelinesByType.margins || []}
      />

      <ExtraPriceBreakdown
        className="w-full"
        data-testid="price-breakdown-extra-manual"
        editable={editable}
        quotelines={quotelinesByType.extras || []}
        onChange={openUpdateAdjustmentModal}
        onRemove={onRemove}
      />

      <Divider className="my-4" scheme="dark" />

      <div className="flex flex-row justify-between">
        <div className="flex align-middle">
          {showPriceFeedback && quote && quote.quote_id && quote.selected_rate && (
            <PriceFeedbackWrapper
              quoteId={quote.quote_id}
              rate={QuoteHelper(quote).selectedRate!}
            />
          )}
        </div>
        <div className="flex flex-row justify-end space-x-2 lg:space-x-24">
          {mileage > 0 && (
            <Total
              direction="vertical"
              label="Rate per mile"
              size="small"
              value={total / mileage}
            />
          )}
          <Total direction="vertical" label="Consolidated price" value={total} />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(PriceBreakdown)
