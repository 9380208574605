import React, { useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import clsx from 'clsx'

import ToastHost from 'components/Toast'
import tokenManager from '../../common/tokenManager'
import UrlSearchParamsHelpers from './common/UrlSearchParams.helpers'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import { NotFound } from 'components/NotFound'
import getDynamicTheme from './theming/getDynamicTheme'
import { ShipmentModeType } from '../../common/types/Quote'
import NewFTLQuote from './NewFTLQuote'
import Text from '../../components/Text'
import NewLTLQuote from './NewLTLQuote'

const WhiteLabelMain = styled.div`
  font-family: ${token('font-family-default')};
`

const WhiteLabel = () => {
  const [step, setStep] = useState(0)
  const queryParams = UrlSearchParamsHelpers()
  const token = queryParams.get('token')
  const primaryColor = queryParams.get('primary_color')
  const secondaryColor = queryParams.get('secondary_color')
  const fontFamily = queryParams.get('font_family')
  const successText = queryParams.get('success_text')
  const isFTLAllowed = queryParams.get('is_ftl_allowed')
  const isLTLAllowed = queryParams.get('is_ltl_allowed')
  const showShipmentModeSelector = isFTLAllowed === 'true' && isLTLAllowed === 'true'
  const [shipmentMode, setShipmentMode] = useState<ShipmentModeType>(
    isLTLAllowed && !isFTLAllowed ? 'ltl' : 'ftl',
  )
  const [isLoaderOpen, setIsLoaderOpen] = useState(false)

  const theme = getDynamicTheme({
    COLORS: {
      ...(primaryColor ? { 'color-primary': primaryColor } : undefined),
      ...(secondaryColor ? { 'color-accent': secondaryColor } : undefined),
    },
    SHADOWS: {
      ...(primaryColor ? { 'shadow-glow-primary': `0px 0px 4px 0px ${primaryColor}` } : undefined),
    },
    FONT_FAMILIES: {
      ...(fontFamily ? { 'font-family-default': fontFamily } : undefined),
    },
  })

  useEffect(() => {
    if (token) {
      tokenManager.setWhiteLabelToken(token)
    }
  }, [token])

  const handlePrevious = () => {
    if (step === 0) {
      return
    }

    setStep((prevState) => prevState - 1)
  }

  const handleNext = () => {
    setStep((prevState) => prevState + 1)
  }

  const reset = () => {
    setStep(0)
  }

  const NewQuoteComponent = useMemo(() => {
    if (shipmentMode === 'ltl') {
      return NewLTLQuote
    }

    return NewFTLQuote
  }, [shipmentMode])

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ToastHost />
        <Switch>
          <Route exact path="/white-label">
            <main className="flex flex-col items-center justify-start py-6 white-label wrapper w-full h-auto">
              {token ? (
                <WhiteLabelMain className="App bg-neutral-white m-l w-full">
                  <div
                    className={clsx(
                      { 'flex flex-col w-full mt-8 relative': !isLoaderOpen },
                      {
                        'h-screen': isLoaderOpen,
                      },
                    )}
                  >
                    {step === 0 && (
                      <div className="px-8 pt-8 bg-neutral-lightest">
                        <div className="mb-2">
                          <Text variant="heading-lg" as="h2">
                            Let's quote
                          </Text>
                        </div>
                        <Text variant="body" color="color-neutral-dark" as="h2">
                          Fill all the fields that you have information to start quoting this lane.
                          <br />
                          In the next step you will see the prices for this quote and adjust if you
                          want.
                        </Text>
                      </div>
                    )}
                    <NewQuoteComponent
                      setShipmentMode={setShipmentMode}
                      step={step}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                      reset={reset}
                      successText={successText}
                      showShipmentModeSelector={showShipmentModeSelector}
                      onLoaderIsOpen={setIsLoaderOpen}
                    />
                  </div>
                </WhiteLabelMain>
              ) : (
                <NotFound />
              )}
            </main>
          </Route>
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default WhiteLabel
