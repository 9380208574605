import React, { useCallback, useRef } from 'react'

import { Quote, Quoteline } from 'common/types/Quote'
import Text from 'components/Text'
import Layout from 'components/Layout'
import { AccessorialsList } from 'pages/Quotes/components/Accessorials'
import QuoteSummary from 'pages/Quotes/components/QuoteSummary'
import { LTLOnboarding } from 'pages/Quotes/components/LTLOnboarding/LTLOnboarding'
import Button from 'components/Button'
import QuoteHelper from 'common/Quote.helpers'
import Collapsible from 'components/Collapsible'
import LTLCommoditiesTable from 'pages/Quotes/components/Commodities/LTLCommoditiesTable'
import Icon from 'components/Icon'
import PriceBreakdown, { PriceBreakdownOperations } from '../../PriceBreakdown'
import useQuoteRates from '../hooks/useQuoteRate'
import LTLRateSelectionWrapper from 'pages/Quotes/components/LTLRates/LTLRateSelectionWrapper'
import { GroupedAccessorials } from 'common/types/Accessorial'

export interface LTLQuoteReviewProps {
  quote: Quote
  setQuote: (quote: Quote) => void
  updateQuote?: (segment: any, quotePiece: any) => void
  onSave: (quoteId: string) => void
  onCancel?: () => void
  isWhiteLabel?: boolean
  accessorials: GroupedAccessorials
}

const LTLQuoteReview = ({
  quote,
  setQuote,
  updateQuote,
  onSave,
  onCancel,
  isWhiteLabel,
  accessorials,
}: LTLQuoteReviewProps) => {
  const operationsRef = useRef<PriceBreakdownOperations>(null)
  const handleChange = useCallback(
    function handleChange(quotelines: Quoteline[]) {
      if (updateQuote) {
        updateQuote('breakdown', quotelines)
      }
    },
    [updateQuote],
  )

  const { addQuoteline, updateQuoteline, removeQuoteline } = useQuoteRates({
    quoteID: quote.quote_id,
    rateQuotelines: quote.breakdown,
    quoteRates: quote.rates,
    selectedQuoteRate: quote.selected_rate,
    onQuotelinesChanged: handleChange,
  })

  return (
    <>
      <Layout.Stack space="xl" className="px-8 pb-8 pt-8 bg-neutral-lightest">
        <div className="mb-2">
          <Text variant="heading-lg" as="h2">
            Here is your quote result
          </Text>
        </div>
        <div className="mb-8">
          {isWhiteLabel ? (
            <Text variant="body">
              Take a look at the prices below and select the one you prefer. If you want to proceed{' '}
              <br />
              with the selected quote, click on the button below to start negotiating
            </Text>
          ) : (
            <Text variant="body">
              Your lane was quoted and you can take a look at the prices below, select the one you
              prefer,
              <br /> and if needed, you can also edit them, adding extra prices and giving
              discounts.
            </Text>
          )}
        </div>
        <Text variant="heading-md-bold">Quote Details</Text>
        <QuoteSummary quote={quote} showShipper={!isWhiteLabel} />
        <Collapsible
          title="Accessorials for pickup and delivery"
          iconName="warehouse"
          className="px-6 py-1 mt-2"
        >
          <AccessorialsList
            stops={quote.stops}
            accessorialsList={[...accessorials.pu, ...accessorials.del]}
          />
        </Collapsible>

        <Collapsible title="Commodity and items details" iconName="dollar" className="px-6 py-1">
          <div className="mt-2">
            <LTLCommoditiesTable commodities={quote.items} />
          </div>
        </Collapsible>
        {!isWhiteLabel && (
          <div className="flex">
            <Text variant="heading-md-bold" className="mr-auto">
              Price breakdown for the selected rate
            </Text>
            <Button
              className="ml-auto"
              leading={<Icon name="add" title="Add price adjustment" size={16} />}
              onClick={operationsRef.current?.openCreateAdjustmentModal}
            >
              Add Adjustment
            </Button>
          </div>
        )}

        {!isWhiteLabel && (
          <PriceBreakdown
            ref={operationsRef}
            mileage={Number(quote.mileage)}
            editable={true}
            quotelines={quote.breakdown}
            onChange={(adjustment) => {
              if (adjustment.quoteline_id) {
                updateQuoteline(adjustment)
              } else {
                addQuoteline(adjustment)
              }
            }}
            onRemove={removeQuoteline}
            className="mt-2"
            quote={quote}
          />
        )}
        {!QuoteHelper(quote).status.is(['rejected']) && (
          <>
            <Text variant="heading-md-bold">Rates available for this lane</Text>
            <LTLRateSelectionWrapper
              quote={quote}
              setQuote={setQuote}
              isWhiteLabel={isWhiteLabel}
            />
          </>
        )}
      </Layout.Stack>
      <div className="flex flex-col mt-6 space-y-4 lg:flex-row lg:items-center lg:justify-start lg:space-y-0">
        <div className="flex justify-between lg:justify-start">
          <Button
            variant="secondary"
            onClick={() => {
              onCancel?.()
            }}
          >
            {!isWhiteLabel ? 'back to dashboard' : 'quote a new lane'}
          </Button>
        </div>

        <Button
          disabled={quote ? QuoteHelper(quote).status.is(['rejected', 'expired']) : true}
          variant="primary"
          className="lg:ml-auto"
          data-testid="button-save-to-review"
          onClick={() => {
            if (quote) onSave(quote.quote_id as string)
          }}
        >
          {!isWhiteLabel ? 'go to quote details' : "I'm interested "}
        </Button>
      </div>
      {!isWhiteLabel && (
        <LTLOnboarding
          keyName="quoteReview"
          message="Hey, with LTL quotes you will be able to select between many rates available. Choose the one you prefer based on the conditions offered and proceed to the next steps."
        />
      )}
    </>
  )
}

export default LTLQuoteReview
