import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export type AcceptedType = 'header' | 'div' | 'h1' | 'h2' | 'h3' | 'h4'

export interface HeaderProps {
  as?: AcceptedType
  className?: string
}

function Header({ as, children, className, ...others }: PropsWithChildren<HeaderProps>) {
  const Tag = String(as || 'header') as AcceptedType

  return (
    <Tag
      data-testid="header"
      {...others}
      className={clsx(
        'w-full bg-neutral-lighter flex flex-col lg:flex-row lg:items-center px-4 py-2 flex-wrap space-y-2 lg:space-y-0',
        className,
      )}
    >
      {children}
    </Tag>
  )
}

Header.defaultProps = {
  as: 'header',
}

export default Header
