import toArray from 'common/helpers/toArray'
import clone from 'common/helpers/clone'

import type { FilterPlugin } from '../useFilter.types'

function PluginsRunner<T>(plugins?: FilterPlugin<T>[]) {
  const safePlugins = toArray(plugins)

  return {
    onInit(fields: Record<keyof T, unknown>) {
      let newFields = clone(fields)

      for (const plugin of safePlugins) {
        newFields = { ...newFields, ...(plugin.onInit(newFields) || {}) }
      }

      return newFields
    },
    onChange(field: keyof T, value: unknown) {
      for (const plugin of safePlugins) {
        plugin.onChange(field, value)
      }
    },
  }
}

export default PluginsRunner
