class GoogleMaps {
  googleMapsInstance: any
  autoCompleteServiceInstance: any
  placesServiceInstance: any
  sessionToken: any
  geocoderInstance: any

  constructor() {
    this.googleMapsInstance = (window as any).google && (window as any).google.maps
  }

  getGoogleMapsInstance() {
    if (!this.googleMapsInstance) {
      return null
    }

    return this.googleMapsInstance
  }

  getAutoCompleteService() {
    if (this.autoCompleteServiceInstance) {
      return this.autoCompleteServiceInstance
    }

    if (!this.googleMapsInstance) {
      return null
    }

    this.autoCompleteServiceInstance = new this.googleMapsInstance.places.AutocompleteService()
    return this.autoCompleteServiceInstance
  }

  getPlacesService() {
    if (this.placesServiceInstance) {
      return this.placesServiceInstance
    }

    if (!this.googleMapsInstance) {
      return null
    }

    this.placesServiceInstance = new this.googleMapsInstance.places.PlacesService(
      document.createElement('div'),
    )

    return this.placesServiceInstance
  }

  getSessionToken(refresh = false) {
    if (this.sessionToken && !refresh) {
      return this.sessionToken
    }

    if (!this.googleMapsInstance) {
      return null
    }

    this.sessionToken = new this.googleMapsInstance.places.AutocompleteSessionToken()
    return this.sessionToken
  }

  getGeocoder() {
    if (this.geocoderInstance) {
      return this.geocoderInstance
    }

    if (!this.googleMapsInstance) {
      return null
    }

    this.geocoderInstance = new this.googleMapsInstance.Geocoder()
    return this.geocoderInstance
  }
}

export default GoogleMaps
