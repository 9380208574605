enum Status {
  Success = 'success',
  Danger = 'danger',
  Warn = 'warn',
  Warning = 'warning',
  Neutral = 'neutral',
  Busy = 'busy',
}

export default Status
