import * as React from 'react'
import { LabelProps, Select as MSelect, SelectProps as MSelectProps } from '@loadsmart/loadsmart-ui'
import EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { GenericOption, Option } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { CustomFormikField } from './CustomFormikField'
import { BaseField } from './BaseField'

export interface SelectProps extends Omit<MSelectProps, 'name' | 'value' | 'form'> {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
  required?: boolean
}

const getOptionByValue = (value: string, options: GenericOption[] | undefined): Option => {
  const defaultOption = { value, label: value } as Option

  if (!options) {
    return defaultOption
  }

  const option = options.find((item) => item.value === value)

  return (option as Option) ?? defaultOption
}

const getInitialOption = (
  initialValue?: string | string[],
  options?: GenericOption[],
): Option | Option[] | undefined => {
  if (!initialValue) {
    return
  }

  if (Array.isArray(initialValue)) {
    return initialValue.map((item) => getOptionByValue(item, options))
  } else {
    return getOptionByValue(initialValue, options)
  }
}

export function Select({ children, ...props }: SelectProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onBlur: fieldOnBlur, onChange: fieldOnChange, value: fieldValue, ...field },
        form,
        baseFieldProps,
        baseProps,
      }) => {
        const { setFieldTouched, setFieldValue } = form

        const mappedProps = {
          value: getInitialOption(fieldValue, props.options),
          onBlur:
            props.onBlur ??
            ((e?: any) => {
              return fieldOnBlur(e || field.name)
            }),
          onChange: ({ target: { value } }: EventLike<Option | Option[] | null>) => {
            if (!value) {
              setFieldValue(field.name, props.multiple ? [] : '')
              setFieldTouched(field.name, true, false)
              return
            }

            let fieldValue: string[] | string
            if (Array.isArray(value)) {
              fieldValue = value.map((item) => (item as GenericOption).value)
            } else {
              fieldValue = (value as GenericOption).value
            }

            setFieldValue(field.name, fieldValue)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...baseProps,
        }

        return (
          <BaseField {...baseFieldProps}>
            <MSelect {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}
