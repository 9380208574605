import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import TextField from 'components/TextField'
import resetPasswordIcon from './reset-password-icon.png'
import { resetPassword } from 'services/auth/auth.service'
import { toast } from 'components/Toast'
import ErrorHelper, { ErrorHelperType } from 'common/Error.helpers'
import Feedback from 'components/Feedback'
import Status from 'common/types/Status'
import { useQueryParams } from 'hooks/useQueryParams'

interface ResetPasswordFormProps {
  setIsPasswordReset: (isReset: boolean) => void
  isNewUser?: boolean
}

const schema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[()[\]{}|`~!@#$%^&*_\-+=;:'",<>./?])[A-Za-z\d()[\]{}|`~!@#$%^&*_\-+=;:'",<>./?]{8,}$/,
      'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
    ),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const ResetPasswordForm = ({ setIsPasswordReset, isNewUser }: ResetPasswordFormProps) => {
  const queryParams = useQueryParams()
  const token = queryParams.get('token')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState<ErrorHelperType>()
  const history = useHistory()

  const sendResetPasswordRequest = async () => {
    if (!token) {
      return
    }

    const [, response] = await resetPassword(token, password)
    if (response) {
      setIsPasswordReset(true)
    } else {
      toast.error(isNewUser ? 'We could not reset your password' : 'We could not set your password')
    }
  }

  const handleSubmit = () => {
    setErrors(undefined)
    try {
      schema.validateSync(
        { password, confirmPassword },
        {
          abortEarly: false,
        },
      )
    } catch (e: any) {
      setErrors(ErrorHelper(e))
      return
    }
    sendResetPasswordRequest()
  }

  if (!token) {
    history.push('/login')
  }

  return (
    <>
      <div className="flex justify-center mb-6">
        <img src={resetPasswordIcon} alt="" className="w-20" />
      </div>
      <h1 className="mb-2 text-2xl font-bold text-neutral-darker text-center">
        {isNewUser ? 'Set new password' : 'Set your password'}
      </h1>
      <p className="px-8 text-sm text-center">
        {isNewUser
          ? 'You just need to set a password to finalize you account and start using the product.'
          : 'Your new password must be different to previously used passwords.'}
      </p>
      <form
        className="flex flex-col px-8 pt-4"
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <Field className="py-2">
          <Label htmlFor="password">Password</Label>
          <TextField
            name="password"
            id="password"
            type="password"
            className="h8"
            placeholder="Inform the password"
            required
            value={password}
            autoComplete="off"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            autoFocus
          />
          <span className="text-neutral text-sm">Must contain at least 8 characters</span>
          {errors?.has('password') && (
            <Feedback status={Status.Danger}>{errors.get('password')}</Feedback>
          )}
        </Field>
        <Field className="py-2">
          <Label htmlFor="confirm-password">Confirm password</Label>
          <TextField
            name="confirm-password"
            id="confirm-password"
            className="h-8"
            required
            type="password"
            placeholder="Inform the password"
            value={confirmPassword}
            autoComplete="off"
            onChange={(e) => {
              setConfirmPassword(e.target.value)
            }}
            autoFocus
          />
          {errors?.has('confirmPassword') && (
            <Feedback status={Status.Danger}>{errors.get('confirmPassword')}</Feedback>
          )}
        </Field>
        <Button type="submit" variant="primary" className="mt-6">
          {isNewUser ? 'Create Password' : 'Reset Password'}
        </Button>
      </form>
    </>
  )
}

export default ResetPasswordForm
