import { toast } from 'components/Toast'
import { Dialog } from 'components/Dialog'
import Icon from 'components/Icon'
import Layout from 'components/Layout'
import Text from 'components/Text'
import { RateProviderRule } from 'common/types/RateProviderRule'
import { deleteRateProviderRule } from 'services/rateProviderRules/rateProviderRules.service'

type RuleDeleteProps = {
  rule: RateProviderRule
  onClose: (refetch?: boolean) => void
}

export default function RuleDelete({ rule, onClose }: RuleDeleteProps) {
  async function handleSubmit() {
    const [error] = await deleteRateProviderRule(rule.id!)
    if (error) {
      toast.error('We could not delete this smart rule.')
    } else {
      toast.success('Smart rule deleted with success.')
      onClose(true)
    }
  }

  return (
    <>
      <Dialog.Header>Delete smart rule?</Dialog.Header>

      <Dialog.Body>
        <Layout.Stack space="s">
          <Layout.Group justify="center">
            <Icon name="warning" size={38} />
          </Layout.Group>

          <Text>Are you sure you want to delete this smart rule? The action cannot be undone.</Text>
        </Layout.Stack>
      </Dialog.Body>

      <Dialog.ActionConfirm onConfirm={handleSubmit}>Yes, delete smart rule</Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={() => onClose(false)} />
    </>
  )
}
