import { Fragment } from 'react'
import type { MarginConflicts as MarginConflictsType } from '../hooks/useAddMargin'
import Table from 'components/Table'
import { generateColumns } from '../MarginsList/MarginsList'
import Feedback from 'components/Feedback'
import Icon from 'components/Icon'
import Status from 'common/types/Status'

interface MarginConflictsProps {
  conflicts: MarginConflictsType[]
  clearConflicts: () => void
}

const COLUMNS = generateColumns({ editable: false })
const MARGIN_CONFLICT_WARNING = 'We found conflicts in the following margins:'

function MarginConflicts({ conflicts, clearConflicts }: MarginConflictsProps) {
  return (
    <Fragment>
      {Boolean(conflicts.length > 0) && (
        <Feedback
          className="flex justify-between w-full px-4 py-2 mt-4 mb-1 text-sm"
          status={Status.Danger}
        >
          {MARGIN_CONFLICT_WARNING}
          <button type="button" onClick={clearConflicts}>
            <Icon name="close" title="Close" />
          </button>
        </Feedback>
      )}
      {conflicts.map(({ conflict, details }) => (
        <Fragment key={details.conflict.id as string}>
          <span className="w-full px-4 py-2 text-sm bg-neutral-lighter text-neutral-darkest">
            {details.message}
          </span>
          <Table className="w-full mb-1" data-testid="margins-list-conflicts">
            <Table.Body>
              <Table.Row>
                {COLUMNS.map((column) => {
                  return (
                    <Table.Cell key={column.id} style={column.style}>
                      {column.render({ margin: conflict })}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            </Table.Body>
          </Table>
        </Fragment>
      ))}
    </Fragment>
  )
}

export default MarginConflicts
