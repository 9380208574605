import React from 'react'
import styled from 'styled-components'

import CurrencyHelper from 'common/Currency.helpers'
import { Card } from 'components/Card'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'

export interface SummaryCardProps {
  title: string
  value: number
}

const StyledCardTitle = styled(Card.Title)`
  color: ${token('color-neutral-dark')};
`

const SummaryCard = ({ title, value }: SummaryCardProps) => {
  return (
    <Card className="mr-4 w-48">
      <StyledCardTitle className="font-bold text-neutral">{title}</StyledCardTitle>
      <Card.Body>
        <p className="font-bold text-dark text-2xl">{CurrencyHelper(value).integer()}</p>
      </Card.Body>
    </Card>
  )
}

export default SummaryCard
