import React, { MouseEvent } from 'react'
import styled from 'styled-components'

import { RateGuideCard } from 'components/Card'
import { Tag } from 'components/Tag'
import Logo from 'components/Logo'
import { RateProviderIdentifiers } from 'common/types/RateProvider'
import Layout from 'components/Layout'
import Button from 'components/Button'
import { IconButton } from '@loadsmart/loadsmart-ui'
import Icon from 'components/Icon'
import Text from 'components/Text'

interface RateProviderSelectorItemProps {
  disabled?: boolean
  onClick: () => void
  onMarginEdit: (rateProvider: RateProviderIdentifiers) => void
  selected: boolean
  rateProvider: RateProviderIdentifiers
  margin?: string | number
  error?: string
}

const RATE_PROVIDERS: Record<
  RateProviderIdentifiers,
  { title: string | JSX.Element; description: string }
> = {
  rate_guide: {
    title: 'Broker rate',
    description:
      'Provide rates with your own margins to website quotes, and manage yourself how to move them.',
  },
  loadsmart: {
    title: <Logo width="100%" height="auto" className="fill-current text-primary-500" />,
    description:
      'Use the Loadsmart capability to move the quoted lanes on your website but with your own margins on the price.',
  },
}

const CustomStyledRGCard = styled(RateGuideCard)`
  width: 320px;
  height: 193px;
`

const RateProviderSelectorItem = ({
  disabled,
  onClick,
  onMarginEdit,
  selected,
  rateProvider,
  margin,
  error,
}: RateProviderSelectorItemProps) => {
  const { title, description } = RATE_PROVIDERS[rateProvider]
  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault()
    onClick()
  }

  return (
    <>
      <CustomStyledRGCard
        size="small"
        disabled={disabled}
        onClick={handleOnClick}
        highlighted={selected}
        error={Boolean(error)}
        title={rateProvider}
      >
        <Layout.Group justify="space-between" align="center" className="w-full">
          <div className="h-7 w-7/12">
            {React.isValidElement(title) ? (
              title
            ) : (
              <h2 className="text-2xl font-bold text-neutral-darker text-left">{title}</h2>
            )}
          </div>
          {selected && (
            <div>
              <Tag variant="accent" size="small">
                selected
              </Tag>
            </div>
          )}
        </Layout.Group>
        <Text className="text-left mt-2" variant="body">
          {description}
        </Text>
        <div className="flex mt-4">
          {Boolean(margin) ? (
            <>
              <Tag className="mr-2" variant="default" size="default">
                Margin: %{margin}
              </Tag>
              <IconButton
                title="Edit this margin"
                onClick={() => onMarginEdit(rateProvider)}
                scale="small"
              >
                <Icon name="edit" size={16} title="Refresh Quote" />
              </IconButton>
            </>
          ) : (
            <Button
              scale="small"
              title="Add margin for this provider"
              onClick={(e) => {
                onMarginEdit(rateProvider)
                e.stopPropagation()
              }}
            >
              add margin
            </Button>
          )}
        </div>
      </CustomStyledRGCard>
      {Boolean(error) && (
        <Text variant="caption" color="color-danger-dark">
          {error}
        </Text>
      )}
    </>
  )
}

export default RateProviderSelectorItem
