import type { SelectOption, SelectProps } from '../Select'
import type EventLike from 'common/types/EventLike'
import type ColorScheme from 'common/types/ColorScheme'
import Status from 'common/types/Status'

export interface SuggestionItemAdapter<T> {
  getValue: (item: T) => string
  getLabel: (item: T) => string
}

export type SuggestionDatasource<T> = {
  _type?: string
  adapter: SuggestionItemAdapter<T>
  fetch: ({
    query,
    options,
  }: {
    query: string
    options?: Record<string, any>
  }) => T[] | Promise<T[]>
}

interface CommonProps {
  className?: string
  datasources: (() => SuggestionDatasource<any>)[]
  delay?: number
  clearable?: boolean
  disabled?: boolean
  id?: string
  name: string
  placeholder?: string
  scheme?: ColorScheme
  status?: Status
  query?: string
  minQueryLength?: number
  disableDropdownIndicator?: boolean
  filterOption?: SelectProps['filterOption'],
  loadDataOnFocus?: boolean
}

export interface SuggestionOption extends SelectOption {
  payload?: any
}

export type SingleSuggestionValue = SuggestionOption | null
export type MultipleSuggestionValue = SuggestionOption[] | null

type ConditionalProps =
  | {
      multiple?: false
      value?: SingleSuggestionValue
      onChange?: (event: EventLike<SingleSuggestionValue>) => void
    }
  | {
      multiple: true
      value?: MultipleSuggestionValue
      onChange?: (event: EventLike<MultipleSuggestionValue>) => void
    }

export type SuggestionValue = SingleSuggestionValue | MultipleSuggestionValue
export type SuggestionProps = CommonProps & ConditionalProps

export enum SuggestionQueryStatus {
  Idle = 'idle',
  Querying = 'querying',
  PartiallyDone = 'partially_done',
  Done = 'done',
}
