import type { MarginReason } from 'common/types/Margin'
import Select, { SelectProps } from 'components/Select'
import useFetchReasons from '../hooks/useFetchReasons'

export function mapReasons(reasons: MarginReason[]) {
  return reasons.map(({ code, message }) => ({
    label: message,
    value: code,
  }))
}

function ReasonSelect(props: Omit<SelectProps, 'options'>) {
  const { disabled } = props
  const { reasons, loading } = useFetchReasons()

  return (
    <Select
      {...(props as SelectProps)}
      options={mapReasons(reasons)}
      disabled={loading || disabled}
    />
  )
}

export default ReasonSelect
