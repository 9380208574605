import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import reportWebVitals from './reportWebVitals'

import { GTMNoScript } from 'metrics/GTMNoScript'

import './metrics/hotjar'
import './metrics/gtm'
import './styles.css'
import './fonts.css'

ReactDOM.render(
  <React.StrictMode>
    <App />

    {/* <noscript> GTM */}
    <GTMNoScript />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
