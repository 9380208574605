import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from 'react'
import * as yup from 'yup'

import { Quote } from 'common/types/Quote'
import Label from 'components/Label'
import TextField, { TextFieldProps } from 'components/TextField'
import ToggleGroup from 'components/ToggleGroup'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import {
  getDefaultQuote,
  updateQuoteBySegment,
} from 'pages/Quotes/QuoteForm/QuoteAdd/QuoteAddContext/QuoteAddContext.helpers'
import EventLike from 'common/types/EventLike'
import getValueFromEvent from 'common/helpers/getValueFromEvent'
import EquipmentTypeHelper from 'common/EquipmentType.helpers'
import { EquipmentType } from 'common/types/EquipmentType'
import EquipmentTypeEntry from '../../../Quotes/QuoteForm/EquipmentType'
import Commodities from '../../../Quotes/QuoteForm/Commodities'
import { createQuote } from '../../services/whiteLabel.service'
import Stops from '../Stops'
import Field from 'components/Field'
import Section from 'components/Section'
import Feedback from 'components/Feedback'
import Status from 'common/types/Status'
import Divider from 'components/Divider'
import { PU_LEAD_TIME } from '../Stops/Stop.constants'
import ErrorHelper, { RawError } from 'common/Error.helpers'
import { toast } from 'components/Toast'
import CalculatingQuote from '../../../Quotes/QuoteForm/QuoteAdd/CalculatingQuote'
import ReCAPTCHA from 'react-google-recaptcha'
import getEnvVariable from 'common/helpers/getEnvVariable'

export interface CreateQuoteProps {
  onNext: (complete?: boolean) => void
  setQuote: Dispatch<SetStateAction<Quote>>
  quote: Quote
}

const EQUIPMENT_TYPES = EquipmentTypeHelper.getAll(function getCustomProps(type: EquipmentType) {
  return { label: <EquipmentTypeEntry mode="abbr" name={type} /> }
})

const schema = yup.object().shape({
  customer_reference: yup.string().nullable(),
  equipment_type: yup.string().required('Required field'),
  stops: yup.array().of(
    yup.object().shape({
      type: yup.string().oneOf(['pu', 'del']),
      address: yup.string().required('Required field'),
      city: yup.string().required('The address must have a city'),
      window: yup
        .object()
        .shape({
          start: yup.string().required('Required field').nullable(),
          end: yup.string().required('Required field').nullable(),
        })
        .when('type', {
          is: 'del',
          then: yup.object().notRequired().default(undefined),
        }),
    }),
  ),
  items: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          po_number: yup.string().required('Required field'),
          description: yup.string().required('Required field'),
          hazmat: yup.bool(),
        })
        .when('hazmat', {
          is: true,
          then: yup.string().required('Required field'),
        }),
    )
    .min(0),
})

function CreateQuote({ onNext, quote, setQuote }: CreateQuoteProps) {
  const [isBusy, setIsBusy] = useState(false)
  const [errors, setErrors] = useState<RawError>()
  const errorData = ErrorHelper(errors)
  const [captchaValid, setCaptchaValid] = useState(false)

  const handleSubmit = async () => {
    setIsBusy(true)
    setErrors(undefined)
    try {
      schema.validateSync(quote, {
        abortEarly: false,
      })
    } catch (e) {
      setErrors(e)
      setIsBusy(false)
      return
    }

    const [errors, response] = await createQuote(quote)

    if (errors) {
      toast.error('We could not get the quote.')
      setIsBusy(false)
      return
    }

    setQuote(response?.data!)
    setIsBusy(false)
    onNext(true)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement> | EventLike<any>) => {
    const { name } = event.target

    setQuote((quote) => updateQuoteBySegment(name, getValueFromEvent(event), quote))
  }

  const handleReset = () => {
    setErrors(undefined)
    setQuote(getDefaultQuote())
  }

  return (
    <>
      <Fragment>
        {isBusy && <CalculatingQuote />}

        <div className="px-8 pb-8 pt-6 bg-neutral-lightest">
          <form>
            <Section title="Quote Information">
              <div className="flex flex-col mt-6 space-y-4 lg:space-y-0 lg:space-x-4 lg:items-end lg:flex-row">
                <Field>
                  <Label
                    tip="Reference number for this load in this shipper's TMS."
                    htmlFor="customer_reference"
                  >
                    Customer reference
                  </Label>
                  <TextField
                    id="customer_reference"
                    name="customer_reference"
                    value={quote.customer_reference ?? ''}
                    onChange={handleChange}
                    placeholder="412415511"
                    status={errorData.status('customer_reference') as TextFieldProps['status']}
                  />
                  {errorData.has('customer_reference') && (
                    <Feedback status={Status.Danger}>
                      {errorData.get('customer_reference')}
                    </Feedback>
                  )}
                </Field>
                <Field>
                  <Label htmlFor="equipment_type" required>
                    Equipment type
                  </Label>
                  <ToggleGroup
                    className="mr-auto"
                    options={EQUIPMENT_TYPES}
                    id="equipment_type"
                    name="equipment_type"
                    onChange={handleChange}
                    value={quote.equipment_type}
                  />
                  {errorData.has('equipment_type') && (
                    <Feedback status={Status.Danger}>{errorData.get('equipment_type')}</Feedback>
                  )}
                </Field>
                <Checkbox
                  name="requirements.team_driver"
                  checked={quote.requirements?.team_driver}
                  onChange={handleChange}
                >
                  Team driver
                </Checkbox>
              </div>
            </Section>

            <Divider className="my-6" />

            <Section title="Pickup and delivery details">
              <p className="mb-8 text-sm">
                Select where will be the pickup and delivery location for this lane and also the
                dates for each one.
                <br />
                Remember, each quote requires at least {PU_LEAD_TIME} hours of lead time.
              </p>
              <Stops
                name="stops"
                stops={quote.stops}
                onChange={handleChange}
                errors={errors}
                className="mt-6"
              />
            </Section>
            <Divider className="my-6" />

            <Section title="Commodity details">
              <Commodities
                name="items"
                commodities={quote.items}
                onChange={handleChange}
                className="mt-6"
              />
            </Section>

            <Divider className="my-6" />
            <ReCAPTCHA
              sitekey={getEnvVariable('RECAPTCHA_KEY')}
              onChange={() => {
                setCaptchaValid(true)
              }}
            />
          </form>
        </div>
      </Fragment>

      <div className="flex flex-col mt-6 space-y-4 lg:flex-row lg:items-center lg:justify-start lg:space-y-0">
        <div className="flex justify-between lg:justify-start">
          <Button
            disabled={isBusy}
            type="reset"
            variant="secondary"
            className="ml-4"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>

        <Button
          disabled={isBusy || !captchaValid}
          variant="primary"
          className="lg:ml-auto"
          onClick={handleSubmit}
        >
          get quote
        </Button>
      </div>
    </>
  )
}

export default CreateQuote
